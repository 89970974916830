import React from 'react';
import PropTypes from 'prop-types';
import PropagateLoader from 'react-spinners/PropagateLoader';

const Spinner2 = (props) => {
    const { show, size, marginTop } = props;

    return (
        <div className={`x-center m-t-${marginTop}px m-b-20px`}>
            <PropagateLoader color="#2bb673" loading={show} size={size} />
        </div>
    );
};

Spinner2.propTypes = {
    show: PropTypes.bool,
    size: PropTypes.number,
    marginTop: PropTypes.number,
};

Spinner2.defaultProps = {
    show: true,
    size: 15,
    marginTop: 100,
};

export default Spinner2;
