import { SportsType } from '../../../enums/SportsType';
import { TeamWebsiteObject, TeamWebsiteTextObject } from '../../../interface/TeamInterface';
import { getDemoTeamWebsiteStylesObject } from './styles/TeamWebsiteStylesDemo';
import { getDemoTeamWebsiteImagesObject } from './TeamWebsiteImagesDemo';
import { getDemoTeamWebsiteSEOInfoObject } from './TeamWebsiteSEOInfoDemo';

export const demoTeamWebsiteTextsObject: TeamWebsiteTextObject[] = [];

export const getDemoTeamWebsite = (organizationName: string, teamName: string, sportsType: SportsType): TeamWebsiteObject => {
    return {
        images: getDemoTeamWebsiteImagesObject(sportsType),
        seoInfo: getDemoTeamWebsiteSEOInfoObject(organizationName, teamName, sportsType),
        styles: getDemoTeamWebsiteStylesObject(sportsType, teamName),
        texts: demoTeamWebsiteTextsObject,
    };
};
