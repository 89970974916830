import { SportsType } from '../../../../enums/SportsType';
import { WebsiteDarkModeType } from '../../../../enums/WebsiteDesignType';
import { TeamWebsiteStylesObject } from '../../../../interface/TeamInterface';
import { demoTeamWebsiteStylesColors } from './TeamWebsiteStylesColorsDemo';
import { demoTeamWebsiteStylesDesign } from './TeamWebsiteStylesDesignDemo';
import { getDemoTeamWebsiteStylesNavLinks } from './TeamWebsiteStylesNavLinks';

export const demoTeamWebsiteStylesDarkMode = WebsiteDarkModeType.LIGHT_MODE;

export const getDemoTeamWebsiteStylesObject = (sportsType: SportsType, teamName: string): TeamWebsiteStylesObject => {
    return (
        {
            design: demoTeamWebsiteStylesDesign,
            colors: demoTeamWebsiteStylesColors,
            darkMode: demoTeamWebsiteStylesDarkMode,
            bannerText: `${teamName} Official Website`,
            navLinks: getDemoTeamWebsiteStylesNavLinks(sportsType),
        }
    );
};
