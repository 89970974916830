import { subscriptionInfoId } from '../../catalog/demo/SubscriptionInfoDemo';
import { SubscriptionInfoObject } from '../../catalog/interface/SubscriptionInfoInterface';
import { getProductId } from '../../catalog/Stripe';
import { db } from '../FirebaseLibrary';

export const insertSubscriptionFirestoreAPI = (teamId: string, priceIds: string[], quantites: number[], successURL: string, cancelURL: string, metadata: any) => {
    return db.collection(`stripe-customers/${teamId}/checkout_sessions`).add(
        {
            line_items: priceIds.map((priceId, index) => { return { price: priceId, quantity: quantites[index] }; }),
            allow_promotion_codes: true,
            success_url: successURL,
            cancel_url: cancelURL,
            metadata,
        },
    );
};

export const fetchSubscriptionStatusFirestoreAPI = (teamId: string) => {
    return db.collection('stripe-customers').doc(teamId).collection('subscriptions')
        .get();
};

export const fetchWebsiteSubscriptionProductFirestoreAPI = () => {
    return db.collection('stripe-products').doc(getProductId(true, false, false)).get();
};

export const fetchDomainSubscriptionProductFirestoreAPI = () => {
    return db.collection('stripe-products').doc(getProductId(false, true, false)).get();
};

export const fetchDomainEmailSubscriptionProductFirestoreAPI = () => {
    return db.collection('stripe-products').doc(getProductId(false, false, true)).get();
};

/**
 * Following is for Private Subscription Info that cannot be updated by Client
 */

export const createTeamSubscriptionInfoReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/read-only`).doc(subscriptionInfoId);
};

export const insertTeamSubscriptionInfoFirestoreAPI = (teamId: string, subscriptionObject: SubscriptionInfoObject) => {
    return createTeamSubscriptionInfoReference(teamId).set(subscriptionObject);
};

export const fetchTeamSubscriptionInfoFirestoreAPI = (teamId: string) => {
    return createTeamSubscriptionInfoReference(teamId).get();
};

export const deleteTeamSubscriptionInfoFirestoreAPI = (teamId: string) => {
    return createTeamSubscriptionInfoReference(teamId).delete();
};
