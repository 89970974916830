import { SportsType } from '../../../catalog/enums/SportsType';
import Adidas from '../../../assets/partners/hgps/adidas.png';
import Nike from '../../../assets/partners/hgps/nike.png';
import Bauer from '../../../assets/partners/hgps/bauer.png';
import Howies from '../../../assets/partners/hgps/howies.png';
import True from '../../../assets/partners/hgps/true.png';
import GreenBiscuit from '../../../assets/partners/hgps/greenbiscuit.png';
import HoserHockey from '../../../assets/partners/hgps/hoserhockey.png';
import Miscellaneous from '../../../assets/partners/hgps/miscellaneous.png';
import PreventSprain from '../../../assets/partners/preventSprain/preventSprainLogo.png';
// import NewBalance from '../../../assets/partners/hgps/newbalance.png';
import Jog from '../../../assets/partners/hgps/jog.png';
// import UnderArmour from '../../../assets/partners/hgps/underarmour.png';
// import CCM from '../../../assets/partners/hgps/ccm.png';

export const getDashboardProducts = (sportsType: SportsType) => {
    if (sportsType === SportsType.MENSICEHOCKEY || sportsType === SportsType.WOMENSICEHOCKEY) {
        return ['lqa2acvi', 'lqctmvgp', 'lqa2ze02', 'lp5knzp1', 'lp5okxdp', 'lqa1mbuc', 'lqctzxpy'];
    }

    return ['lqctmvgp', 'lqa2ze02', 'lp5knzp1', 'lp5okxdp', 'lqctzxpy'];
};

export const getCategoryProducts = (category: string) => {
    if (category === '/portal/marketplace/accessories') {
        return ['lp5pg2a8', 'lp63zccd', 'lp63qlwn', 'lnt98zao', 'lnt7z0qw', 'lqa144h4', 'lqa1uwmv', 'lqa1s7ph', 'lqa1wn8w', 'lqa1yz0s', 'lqa2074m', 'lqa1mbuc'];
    } if (category === '/portal/marketplace/helmets') {
        return ['lp5uzjin', 'lqa257c8', 'lqa2acvi'];
    } if (category === '/portal/marketplace/practicegear') {
        return ['lqa17soa', 'lqa1ag1o', 'lp63ms90', 'loket8f6', 'lntg5lsm', 'lqa0qyk7', 'lqa0zugh', 'lp5vqfpy'];
    } if (category === '/portal/marketplace/skates') {
        return ['lp5pzinl', 'lp5ozuzi', 'lntgy8ht', 'lntgibtt'];
    } if (category === '/portal/marketplace/sticks') {
        return ['lozqrl5t', 'lozqfi5p', 'lqa3eij1', 'lqa3g49t', 'lqa3huc2', 'lqa3ojbb', 'lqa3p9ef', 'lqa3pzt8', 'lqa3r8g9', 'lqa3tc5m', 'lqa3uv8i', 'lqa3wqll', 'lqa3ydqu', 'lqa40f6b', 'lqa41bfm', 'lqa4314i', 'lqa4451s', 'lqa45b8r', 'lqa468ur', 'lqa4892g', 'lqa49x3f', 'lqa4b2es', 'lqa4c1zx', 'lqa4cst0', 'lqa4e2cp', 'lqa4f6mp', 'lqa4gu46', 'lqa4i1v5', 'lqa4ktu5', 'lqa4lp8g', 'lqa4mfjc', 'lqa4nbbg', 'lqa4o65m'];
    } if (category === '/portal/marketplace/teamapparel') {
        return ['lp5wcg1m', 'lp5okxdp', 'lp5nfgzk', 'lp5knzp1', 'lnt5t5l4', 'lqa0mub5', 'lqa0nzec', 'lqa2f8y0', 'lqa2h7oj', 'lqa2k19u', 'lqa2m96f', 'lqa2ze02', 'lqa33e4y', 'lqa34rhl', 'lqa39wyv', 'lqa3citp', 'lqctmvgp', 'lqctzxpy'];
    } if (category === '/portal/marketplace/adidas') {
        return ['lqa33e4y', 'lqa34rhl', 'lqa39wyv', 'lqa3citp', 'lqctmvgp', 'lqctzxpy'];
    } if (category === '/portal/marketplace/bauer') {
        return ['lp5pg2a8', 'lp5uzjin', 'lqa257c8', 'lqa2acvi', 'lp5pzinl', 'lntgy8ht', 'lntgibtt', 'lp5wcg1m', 'lqa0mub5', 'lqa0nzec'];
    } if (category === '/portal/marketplace/greenbiscuit') {
        return ['lqa17soa', 'lqa1ag1o'];
    } if (category === '/portal/marketplace/hoser') {
        return ['lp63zccd', 'lp63qlwn', 'lnt98zao', 'lnt7z0qw', 'lp63zccd', 'lqa144h4', 'lqa1uwmv', 'lqa1s7ph', 'lqa1wn8w', 'lqa1yz0s', 'lqa2074m', 'lp63ms90', 'loket8f6', 'lntg5lsm', 'lqa0qyk7', 'lp63ms90', 'lqa0zugh'];
    } if (category === '/portal/marketplace/howies') {
        return ['lqa1mbuc'];
    } if (category === '/portal/marketplace/miscellaneous') {
        return ['lp5vqfpy'];
    } if (category === '/portal/marketplace/preventsprain') {
        return ['lo30lgj6', 'lo30ipje', 'lo30qs9c', 'lo30ugcg', 'lo30y92u', 'lnyvxogt', 'lo2zy4hu', 'lo308shd', 'lo30bjhf'];
    } if (category === '/portal/marketplace/nike') {
        return ['lp5okxdp', 'lp5nfgzk', 'lp5knzp1', 'lnt5t5l4', 'lqa2f8y0', 'lqa2h7oj', 'lqa2k19u', 'lqa2m96f', 'lqa2ze02'];
    } if (category === '/portal/marketplace/true') {
        return ['lp5ozuzi', 'lozqrl5t', 'lozqfi5p', 'lqa3eij1', 'lqa3g49t', 'lqa3huc2', 'lqa3ojbb', 'lqa3p9ef', 'lqa3pzt8', 'lqa3r8g9', 'lqa3tc5m', 'lqa3uv8i', 'lqa3wqll', 'lqa3ydqu', 'lqa40f6b', 'lqa41bfm', 'lqa4314i', 'lqa4451s', 'lqa45b8r', 'lqa468ur', 'lqa4892g', 'lqa49x3f', 'lqa4b2es', 'lqa4c1zx', 'lqa4cst0', 'lqa4e2cp', 'lqa4f6mp', 'lqa4gu46', 'lqa4i1v5', 'lqa4ktu5', 'lqa4lp8g', 'lqa4mfjc', 'lqa4nbbg', 'lqa4o65m'];
    }

    return [];
};

export const getBrands = (sportsType: SportsType) => {
    if (sportsType === SportsType.MENSICEHOCKEY || sportsType === SportsType.WOMENSICEHOCKEY) {
        return [
            { color: '#F8F6ED', title: 'JOG', logo: Jog, link: '/portal/marketplace/jog' },
            { color: '#FFDEE8', title: 'Adidas', logo: Adidas, link: '/portal/marketplace/adidas' },
            { color: '#FD8022', title: 'Nike', logo: Nike, link: '/portal/marketplace/nike' },
            { color: '#FFFFFF', title: 'Bauer', logo: Bauer, link: '/portal/marketplace/bauer' },
            { color: '#FDC732', title: 'Howies', logo: Howies, link: '/portal/marketplace/howies' },
            { color: '#000000', title: 'True', logo: True, link: '/portal/marketplace/true' },
            { color: '#F8F6ED', title: 'Green Biscuit', logo: GreenBiscuit, link: '/portal/marketplace/greenbiscuit' },
            { color: '#FFFFFF', title: 'Hoser Hockey', logo: HoserHockey, link: '/portal/marketplace/hoser' },
            { color: '#877654', title: 'Prevent Sprain', logo: PreventSprain, link: '/portal/marketplace/preventsprain' },
            { color: '#FFFFFF', title: 'Miscellaneous', logo: Miscellaneous, link: '/portal/marketplace/miscellaneous' },
            // { color: '#F8F6ED', title: 'JOG', logo: Jog, link: '/portal/marketplace/jog' },
            // { color: '#212121', title: 'Under Armor', logo: UnderArmour, link: '/portal/marketplace/underarmour' },
            // { color: '#F80E21', title: 'CCM', logo: CCM, link: '/portal/marketplace/ccm' },
            // { color: '#B2D5FF', title: 'New Balance', logo: NewBalance, link: '/portal/marketplace/newbalance' },
        ];
    }

    return [
        { color: '#F8F6ED', title: 'JOG', logo: Jog, link: '/portal/marketplace/jog' },
        { color: '#FFDEE8', title: 'Adidas', logo: Adidas, link: '/portal/marketplace/adidas' },
        { color: '#FDC732', title: 'Nike', logo: Nike, link: '/portal/marketplace/nike' },
        { color: '#877654', title: 'Prevent Sprain', logo: PreventSprain, link: '/portal/marketplace/preventsprain' },
    ];
};

export const getCategories = (sportsType: SportsType) => {
    if (sportsType === SportsType.MENSICEHOCKEY || sportsType === SportsType.WOMENSICEHOCKEY) {
        return [
            { title: 'Team Apparel ', link: '/portal/marketplace/teamapparel' },
            { title: 'Sticks', link: '/portal/marketplace/sticks' },
            { title: 'Accessories', link: '/portal/marketplace/accessories' },
            { title: 'Helmets ', link: '/portal/marketplace/helmets' },
            { title: 'Practice Gear', link: '/portal/marketplace/practicegear' },
            { title: 'Skates', link: '/portal/marketplace/skates' },
            { title: 'Injury Prevention', link: '/portal/marketplace/preventsprain' },
        ];
    }

    return [
        { title: 'Team Apparel ', link: '/portal/marketplace/teamapparel' },
        { title: 'Injury Prevention', link: '/portal/marketplace/preventsprain' },
    ];
};
