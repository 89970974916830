import { licensingAgreementVersionNumber } from '../components/portal/uiElements/agreementComponents/licensingAgreement/LicensingAgreement';
import { storeLicensingAgreementVersionNumber } from '../components/portal/uiElements/agreementComponents/storeLicensingAgreement/StoreLicensingAgreement';
import { isValidBoolean, isValidObject, isValidString } from './Validity';

export const licensingAgreementStillValid = (globalState: any) => {
    if (!globalState.userLogged) {
        return true;
    }
    if (isValidObject(globalState.teamObject.licensingAgreement)) {
        const licensingAgreementInfo = globalState.teamObject.licensingAgreement;
        if (isValidObject(licensingAgreementInfo.version)) {
            if (licensingAgreementInfo.version !== licensingAgreementVersionNumber) {
                return false;
            }
        }
        if (isValidBoolean(licensingAgreementInfo.licensingSignatureNeeded)) {
            return !licensingAgreementInfo.licensingSignatureNeeded;
        }
        return false;
    }
    return false;
};

export const licensingAgreementExists = (globalState: any) => {
    if (!globalState.userLogged) {
        return false;
    }
    if (isValidObject(globalState.teamObject.licensingAgreement)) {
        return isValidString(globalState.teamObject.licensingAgreement.licensingAgreementPDF);
    }
    return false;
};

export const licensingAgreementMessage = (globalState: any) => {
    if (!globalState.userLogged) {
        return '';
    }
    if (isValidObject(globalState.teamObject.licensingAgreement)) {
        const licensingAgreementInfo = globalState.teamObject.licensingAgreement;
        if (isValidBoolean(licensingAgreementInfo.licensingSignatureNeeded)) {
            if (licensingAgreementInfo.licensingSignatureNeeded) {
                return 'Signed Licensing Agreement is required before your website can be published.';
            }
        }
        if (isValidObject(licensingAgreementInfo.version)) {
            if (licensingAgreementInfo.version !== licensingAgreementVersionNumber) {
                return 'A new version of the licensing agreement is now available. Please sign this version to make sure your website remains active.';
            }
            return '';
        }

        return 'Signed Licensing Agreement is required before your website can be published.';
    }
    return 'Signed Licensing Agreement is required before your website can be published.';
};

export const storeLicensingAgreementStillValid = (globalState: any) => {
    if (!globalState.userLogged) {
        return true;
    }
    if (isValidObject(globalState.teamObject.storeLicensingAgreement)) {
        const storeLicensingAgreementInfo = globalState.teamObject.storeLicensingAgreement;
        if (isValidObject(storeLicensingAgreementInfo.version)) {
            if (storeLicensingAgreementInfo.version !== storeLicensingAgreementVersionNumber) {
                return false;
            }
        }
        if (isValidBoolean(storeLicensingAgreementInfo.licensingSignatureNeeded)) {
            return !storeLicensingAgreementInfo.licensingSignatureNeeded;
        }
        return false;
    }
    return false;
};

export const storeLicensingAgreementExists = (globalState: any) => {
    if (!globalState.userLogged) {
        return false;
    }
    if (isValidObject(globalState.teamObject.storeLicensingAgreement)) {
        return isValidString(globalState.teamObject.storeLicensingAgreement.licensingAgreementPDF);
    }
    return false;
};

export const storeLicensingAgreementExistsButInvalid = (globalState: any) => {
    if (!globalState.userLogged) {
        return false;
    }
    if (isValidObject(globalState.teamObject.storeLicensingAgreement)) {
        const storeLicensingAgreementInfo = globalState.teamObject.storeLicensingAgreement;
        if (isValidObject(storeLicensingAgreementInfo.version)) {
            if (storeLicensingAgreementInfo.version !== storeLicensingAgreementVersionNumber) {
                return true;
            }
        }
        return false;
    }
    return false;
};

export const storeLicensingAgreementMessage = (globalState: any) => {
    if (!globalState.userLogged) {
        return '';
    }
    if (isValidObject(globalState.teamObject.storeLicensingAgreement)) {
        const storeLicensingAgreementInfo = globalState.teamObject.storeLicensingAgreement;
        if (isValidBoolean(storeLicensingAgreementInfo.licensingSignatureNeeded)) {
            if (storeLicensingAgreementInfo.licensingSignatureNeeded) {
                return 'Signed Licensing Agreement is required before your store can be published.';
            }
        }
        if (isValidObject(storeLicensingAgreementInfo.version)) {
            if (storeLicensingAgreementInfo.version !== storeLicensingAgreementVersionNumber) {
                return 'A new version of the licensing agreement for your store is now available. Please sign this version to make sure your store remains active.';
            }
            return '';
        }

        return 'Signed Licensing Agreement is required before your store can be published.';
    }
    return 'Signed Licensing Agreement is required before your store can be published.';
};
