import { createOrganizationReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { StoreTeamLicensingAgreementObject, TeamCommunicationsObject, TeamFormNotificationObject, TeamFormObject, TeamLicensingAgreementObject, TeamObject, TeamPoliciesObject } from '../../interface/TeamInterface';
import { SportsType } from '../../enums/SportsType';
import { getCurrentDateTime, getDateTimeForNext100Year } from '../../DateTime';
import { getDemoTeamInfoObject } from './TeamInfoDemo';
import { getDemoTeamLogoObject } from './TeamLogoDemo';
import { getDemoTeamSilhouetteLogoObject } from './TeamSilhouetteLogoDemo';
import { demoTeamSocials } from './TeamSocialsDemo';
import { getDemoTeamWebsite } from './website/TeamWebsiteDemo';
import { randomNumber } from '../../Others';
import { AgeType } from '../../enums/AgeType';
import { getDemoStoreInfoObject } from '../StoreDemo';
import { getReturnPolicy, getShippingPolicy } from '../../AutoGenerateText';
import { storeLicensingAgreementVersionNumber } from '../../../components/portal/uiElements/agreementComponents/storeLicensingAgreement/StoreLicensingAgreement';
import { licensingAgreementVersionNumber } from '../../../components/portal/uiElements/agreementComponents/licensingAgreement/LicensingAgreement';

export const getDemoTeamName = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'Demo University Ice Hockey Team';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'Demo University Rugby Team';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'Demo University Lacrosse Team';
    }
    return '';
};

export const demoTeamLicensingAgreement: TeamLicensingAgreementObject = {
    version: licensingAgreementVersionNumber,
    licensingAgreementPDF: '',
    signedOn: getCurrentDateTime(),
    startDate: getCurrentDateTime(),
    endDate: getDateTimeForNext100Year(),
    licensingSignatureNeeded: true,
};

export const demoStoreTeamLicensingAgreement: StoreTeamLicensingAgreementObject = {
    version: storeLicensingAgreementVersionNumber,
    licensingAgreementPDF: '',
    signedOn: getCurrentDateTime(),
    startDate: getCurrentDateTime(),
    endDate: getDateTimeForNext100Year(),
    licensingSignatureNeeded: true,
};

export const getDemoTeamForm = (email: string): TeamFormObject => {
    return {
        emails: [{ id: randomNumber(), email }],
        phones: [],
        emailsNotAllowed: {},
        phonesNotAllowed: {},
    };
};

export const demoTeamOptimXNotifications: TeamFormNotificationObject = {
    feedbackNotification: 0,
    futureNotification: 0,
    domainNotification: '',
};

export const demoTeamPolicies: TeamPoliciesObject = {
    privacy: '',
    terms: '',
    return: getReturnPolicy(),
    refund: '',
    shipping: getShippingPolicy(),
    privacyLink: 'https://optimxtechnologies.com/teamprivacypolicy',
    termsLink: 'https://optimxtechnologies.com/termsofservice',
    returnLink: '',
    refundLink: '',
    shippingLink: '',
};

export const demoTeamCommunications: TeamCommunicationsObject = {
    emails: {},
    phones: {},
    emailsNotAllowed: { account: [], updates: [], tips: [], all: [] },
    phonesNotAllowed: {},
};

export const createDemoTeam = (organizationId: string, organizationName: string, teamId: string, email: string, teamName: string, referral: string, sportsType: SportsType, ageType: AgeType): TeamObject => {
    const organizationReference = createOrganizationReference(organizationId);
    const teamReference = createTeamReference(teamId);

    return {
        id: teamId,
        reference: teamReference,
        organizationId,
        organizationReference,
        organizationName,
        email,
        name: teamName,
        info: getDemoTeamInfoObject(teamName, sportsType),
        storeInfo: getDemoStoreInfoObject(teamName, email),
        logo: getDemoTeamLogoObject(sportsType),
        silhouetteLogo: getDemoTeamSilhouetteLogoObject(sportsType),
        url: `https://www.test.optimxsports.com/?team=${teamId}`,
        website: getDemoTeamWebsite(organizationName, teamName, sportsType),
        socials: demoTeamSocials,
        sportsType,
        ageType,
        licensingAgreement: demoTeamLicensingAgreement,
        storeLicensingAgreement: demoStoreTeamLicensingAgreement,
        form: getDemoTeamForm(email),
        policies: demoTeamPolicies,
        communications: demoTeamCommunications,
        loginCount: 0,
        referral,
        optimXNotifications: demoTeamOptimXNotifications,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
