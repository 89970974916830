/**
 * Random comments used through out the project.
 */

import { maxImagesInGallery } from './Others';

export const TROUBLE_SIGNING_IN = 'Reset Password';
export const NO_ACCOUNT = 'Don\'t have an account?';
export const ACCOUNT_EXISTS = 'Sign into existing account';

export const PLAYERS_PLACEHOLDER = 'Select one of the players or create a new player profile';
export const ALUMNI_PLACEHOLDER = 'Select one of the alumni or create a new alumni profile';
export const COACHES_PLACEHOLDER = 'Select one of the coaches or create a new coach profile';
export const STAFFS_PLACEHOLDER = 'Select one of the executive board members or create a new executive board member profile';
export const EVENTS_PLACEHOLDER = 'Select one of the events/practice or create a new event/practice';
export const FORMS_SUBMITTED_PLACEHOLDER = 'No forms submitted yet';
export const NO_FORMS_PLACEHOLDER = 'No forms created yet';
export const FORMS_PLACEHOLDER = 'Select one of the forms or create a new form';
export const LEAGUES_PLACEHOLDER = 'Select one of the leagues or create a new league';
export const SEASONS_PLACEHOLDER = 'Select one of the seasons or create a new season';
export const SPONSORS_PLACEHOLDER = 'Select one of the sponsors or create a new sponsor';
export const OPPONENTS_PLACEHOLDER = 'Select one of the opponents or create a new opponent';
export const ARTICLES_PLACEHOLDER = 'Select one of the articles or create a new article';
export const GALLERY_PLACEHOLDER = 'Select one of the gallery or create a new gallery';
export const CUSTOM_CONTENT_PLACEHOLDER = 'Select one of the custom pages or create a new custom page';
export const GAME_PLACEHOLDER = 'Select one of the games or create a new game';
export const GAME_ENTER_OPPONENT_AND_LEAGUE_PLACEHOLDER = 'Select the opponent and league for this game to add more information';
export const GAME_SET_STATUS = 'Set status of game to finishied to start adding stats like goals and highlights';
export const TICKETS_PLACEHOLDER = 'No Tickets Found';

export const GAMES_NOT_TAGGED_PLACEHOLDER = 'No games found';
export const IMAGES_NOT_TAGGED_PLACEHOLDER = 'No tagged images found';
export const GALLERIES_NOT_TAGGED_PLACEHOLDER = 'No tagged galleries found ';
export const ARTICLES_NOT_TAGGED_PLACEHOLDER = 'No tagged articles found';

export const PLAYER_DELETE_CONTENT = 'Would you like to delete this player?';
export const ALUMNI_DELETE_CONTENT = 'Would you like to delete this alumni?';
export const COACH_DELETE_CONTENT = 'Would you like to delete this coach?';
export const STAFF_DELETE_CONTENT = 'Would you like to delete this executive board member?';
export const FORM_DELETE_CONTENT = 'Would you like to delete this form?';
export const LEAGUE_DELETE_CONTENT = 'Would you like to delete this league?';
export const SEASON_DELETE_CONTENT = 'Would you like to delete this season?';
export const SPONSOR_DELETE_CONTENT = 'Would you like to delete this sponsor?';
export const OPPONENT_DELETE_CONTENT = 'Would you like to delete this opponent?';
export const ARTICLE_DELETE_CONTENT = 'Would you like to delete this article?';
export const EVENT_DELETE_CONTENT = 'Would you like to delete this event?';
export const CUSTOM_PAGE_DELETE_CONTENT = 'Would you like to delete this page?';
export const CUSTOM_FORM_DELETE_CONTENT = 'Would you like to delete this form?';
export const TICKET_DELETE_CONTENT = 'Would you like to delete this ticket?';
export const PRODUCT_DELETE_CONTENT = 'Would you like to delete this product?';
export const GAME_DELETE_CONTENT = 'Would you like to delete this game?. The article and gallery will also be deleted.';
export const GALLERY_DELETE_CONTENT = 'Would you like to delete this gallery? This will also delete all the images in the gallery';
export const IMAGE_DELETE_CONTENT = 'Would you like to delete this image?';
export const IMAGE_REMOVE_TAG = 'Would you like to remove this image from your profile? This will not delete the image but will not be associated with this profile anymore';

export const DISCARD_TITLE = 'Discard Changes?';
export const DISCARD_CONTENT = 'You have unsaved changes. Are you sure you want to leave this page?';

export const STATS_OVERRIDING_HEADING = 'The stats above have been calculated using games you have been involved in. Use the following inputs to override the above stats.';

export const ARTICLE_PUBLISH_TOOLTIP = 'This article and its corresponding gallery will not appear on the website when it is private';
export const SAVE_TO_UNPUBLISH_ARTICLE = 'Click save to change this article and gallery to private';
export const SAVE_TO_PUBLISH_ARTICLE = 'Click save to change this article and gallery to public';
export const GALLERY_PUBLISH_TOOLTIP = 'This gallery and the images will not appear on the website when it is private';
export const SAVE_TO_UNPUBLISH_GALLERY = 'Click save to change this gallery to private';
export const SAVE_TO_PUBLISH_GALLERY = 'Click save to change this gallery to public';
export const EVENT_PUBLISH_TOOLTIP = 'This event and its corresponding article, gallery and the images will not appear on the website when it is private';
export const SAVE_TO_UNPUBLISH_EVENT = 'Click save to change this event to private';
export const SAVE_TO_PUBLISH_EVENT = 'Click save to change this event to public';
export const CUSTOM_PAGE_PUBLISH_TOOLTIP = 'This page will not appear on the website when it is private';
export const SAVE_TO_UNPUBLISH_CUSTOM_PAGE = 'Click save to change this custom form to private';
export const SAVE_TO_PUBLISH_CUSTOM_PAGE = 'Click save to change this custom form to public';
export const CUSTOM_FORM_PUBLISH_TOOLTIP = 'This form will not appear on the website when it is private';
export const SAVE_TO_UNPUBLISH_CUSTOM_FORM = 'Click save to change this custom form to private';
export const SAVE_TO_PUBLISH_CUSTOM_FORM = 'Click save to change this custom form to public';
export const TICKET_PUBLISH_TOOLTIP = 'This ticket will not appear on the website when it is private';
export const SAVE_TO_UNPUBLISH_TICKET = 'Click save to make this ticket unavailable for fans to buy';
export const SAVE_TO_PUBLISH_TICKET = 'Click save to make this ticket available for fans to buy';
export const TICKET_TAXABLE_TOOLTIP = 'Will you be paying taxes on the earnings of this ticket?';
export const SAVE_TO_MAKE_TICKET_TAXABLE = 'Click save to change this ticket to taxable. Once saved, this cannot be changed.';
export const SAVE_TO_MAKE_TICKET_NON_TAXABLE = 'Click save to change this ticket to non taxable. Once saved, this cannot be changed.';
export const SAVE_TO_MAKE_TICKET_FEATURED = 'Click save to change this ticket to featured';
export const SAVE_TO_MAKE_TICKET_NON_FEATURED = 'Click save to change this ticket to non featured';
export const SAVE_TO_MAKE_FORM_TAXABLE = 'Click save to change this form to taxable. Once saved, this cannot be changed.';
export const SAVE_TO_MAKE_FORM_NON_TAXABLE = 'Click save to change this form to non taxable. Once saved, this cannot be changed.';
export const SAVE_TO_UNPUBLISH_PRODUCT = 'Click save to make this product unavailable for fans to buy';
export const SAVE_TO_PUBLISH_PRODUCT = 'Click save to make this product available for fans to buy';
export const PRODUCT_TAXABLE_TOOLTIP = 'Will you be paying taxes on the earnings of this product?';
export const SAVE_TO_MAKE_PRODUCT_TAXABLE = 'Click save to change this product to taxable. Once saved, this cannot be changed.';
export const SAVE_TO_MAKE_PRODUCT_NON_TAXABLE = 'Click save to change this product to non taxable. Once saved, this cannot be changed.';
export const SAVE_TO_MAKE_PRODUCT_FEATURED = 'Click save to change this product to featured';
export const SAVE_TO_MAKE_PRODUCT_NON_FEATURED = 'Click save to change this product to non featured';
export const SAVE_TO_MAKE_PRODUCT_SHIPPABLE = 'Click save to change this product to shippable';
export const SAVE_TO_MAKE_PRODUCT_NON_SHIPPABLE = 'Click save to change this product to non shippable';

export const SOCIALS_HASHTAGS_HELPER_TEXT = 'Add comma separated hash tags. These hashtags will automatically be added when sharing an article, gallery, evets etc. on X(Previously Twitter).';
export const WEBSITE_SEO_TITLE_HELPER_TEXT = 'This title will show up on search engines like Google and Yahoo.';
export const WEBSITE_SEO_DESCRIPTION_HELPER_TEXT = 'This description will show up below the title on search engines like Google and Yahoo. Please enter 15-20 words for best results.';
export const WEBSITE_SEO_KEYWORDS_HELPER_TEXT = 'Comma separated text useful for website search on search engines.';
export const WEBSITE_NAVLINKS_LIMIT = 'Please limit the number of main nav links to 8. More than 8 main navlinks can cause issues in small screen computers';
export const SETTINGS_EMAIL_HELPER_TEXT = 'Emails after form submission from the website will be sent to this Email. To change this email, contact OptimX Sports';
export const SETTINGS_TEAM_NAME_HELPER_TEXT = 'To change the Team Name, contact OptimX Sports';
export const SETTINGS_ORGANIZATION_NAME_HELPER_TEXT = 'To change the Organization Name, contact OptimX Sports';
export const GAME_VIDEO_TEXT = 'Youtube, Facebook, Twitch, SoundCloud, Vimeo, Wistia, Mixcloud, Dailymotion supported. Live streaming videos are also supported.';

export const URL_HELPER_TEXT = 'Click above to access the link';
export const NO_URL_HELPER_TEXT = 'The url to access the website will be available within 24 hours after account creation';
export const TEST_URL_HELPER_TEXT = 'Click above to access the link. Accessing your website using this test link does not affect your analytics';

export const GALLERY_MAX_LIMIT = `Max limit for the number of photos in this gallery is ${maxImagesInGallery}.`;
export const GALLERY_CURRENT_IMAGES = (images: any[]) => {
    return `Currently there are ${images.length} ${images.length > 1 ? 'images' : 'image'} in this gallery. You can upload ${maxImagesInGallery - images.length} more ${(maxImagesInGallery - images.length) > 1 ? 'images' : 'image'} in this gallery`;
};

export const COACHES_SORTING_TEXT = 'Drag and drop the coaches or staff below to order them';
export const STAFF_SORTING_TEXT = 'Drag and drop the members below to order them';
export const PRODUCTS_SORTING_TEXT = 'Drag and drop the products below to order them';

export const CUSTOM_PAGE_ADD_IMAGE = "To add an image, upload any image to 'My Library' gallery, copy the image URL and paste it using the image button below";
export const ARTICLE_ADD_IMAGE = 'To add an image, copy the image URL from any gallery and paste it using the image button below';

export const LICENSING_AGREEMENT_SIGNED = 'Your licensing agreement is already signed';
export const SIGN_LICENSE_AGREEMENT = 'Signed Licensing Agreement is required before your website can be published.';
export const VERIFY_EMAIL = 'Please verify your email to continue using your account.';

export const CANCEL_PRODUCT_ORDER = "Are you sure you would like to cancel this product order. The customer will also be notified regarding this cancellation. This action will not refund the customer's payment. For refunds, please refer to charges section under store.";
export const UNCANCEL_PRODUCT_ORDER = 'Are you sure you would like to reinstate this product order. The customer will also be notified regarding this order. For refunds, please refer to charges section under store.';
export const CANCEL_TICKET_ORDER = "Are you sure you would like to cancel this ticket. The customer will also be notified regarding this cancellation. This action will not refund the customer's payment. For refunds, please refer to charges section under store.";
export const UNCANCEL_TICKET_ORDER = 'Are you sure you would like to reinstate this ticket order. The customer will also be notified regarding this order. For refunds, please refer to charges section under store.';
