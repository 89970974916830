import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import './css/StoreLicensingAgreement.css';
import { isValidObject, isValidString } from '../../../../../catalog/Validity';
import { getCurrentDateTime, getDateStringWithMonthsFromDate } from '../../../../../catalog/DateTime';

const StoreLicensingAgreementDocument = (props) => {
    const { name, title, clientName, accountCreationTime, sig } = props;

    const styles = StyleSheet.create({
        page: {
            padding: 40,
        },
        h1: {
            fontSize: 20,
            fontWeight: 'heavy',
            textAlign: 'center',
            marginTop: 20,
        },
        h2: {
            fontSize: 15,
            fontWeight: 'heavy',
            marginTop: 20,
        },
        h3: {
            fontSize: 15,
            fontWeight: 'heavy',
            marginTop: 20,
        },
        p: {
            fontSize: 12,
            marginTop: 20,
        },
        l: {
            marginLeft: 20,
            fontSize: 12,
            marginTop: 20,
        },
        signingSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-around',
            fontSize: 12,
        },
        signingSubSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: 20,
        },
        signingSubSectionHeading: {
            textDecoration: 'underline',
            marginBottom: 20,
        },
        signingSubSubSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginBottom: 10,
        },
        clientSignature: {
            width: 200,
            height: 100,
        },
        optimXSignature: {
            textDecoration: 'underline',
            fontStyle: 'italic',
            fontWeight: 100,
            fontFamily: 'Helvetica',
            letterSpacing: 2,
        },
    });

    const getClientName = () => {
        if (isValidString(clientName)) {
            return clientName;
        }
        return '';
    };

    const getAccountCreationTime = () => {
        if (isValidObject(accountCreationTime)) {
            return accountCreationTime;
        }
        return '';
    };

    return (
        <Document
            title={`${getClientName()} Store Licensing Agreement`}
            author="OptimX Sports"
            subject="Store Licensing Agreement"
            creator="OptimX Sports"
            producer="OptimX Sports"
        >
            <Page size="A4" style={styles.page}>
                <View>
                    <Text style={styles.h1}>Addendum to OptimX Technologies LLC Licensing Agreement</Text>
                    <Text style={styles.p}>
                        This Addendum to OptimX Technologies LLC Licensing Agreement (&ldquo;Addendum&rdquo;) is entered into by and between OptimX Technologies LLC (&ldquo;OptimX Technologies&rdquo;) and
                        {' '}
                        {getClientName()}
                        {' '}
                        (&ldquo;Client&rdquo;) (collectively &ldquo;Parties&rdquo;) as of the date last below written.
                    </Text>
                    <Text style={styles.h2}>Recitals:</Text>
                    <Text style={styles.p}>
                        A. OptimX Technologies and Client entered into a Licensing Agreement dated as of
                        {' '}
                        {getDateStringWithMonthsFromDate(getCurrentDateTime())}
                        {' '}
                        (the &ldquo;Agreement&rdquo;).
                    </Text>
                    <Text style={styles.p}>
                        B. OptimX Technologies is an online marketplace that provides a forum for Client to sell merchandise and promotional events. OptimX Technologies is strictly a payment facilitator, and not a merchant of record.
                    </Text>
                    <Text style={styles.p}>
                        C. Client manufactures and sells merchandise for a variety of customers (&ldquo;Materials&rdquo;).
                    </Text>
                    <Text style={styles.p}>Now, therefore, OptimX Technologies and Client hereby agree as follows:</Text>
                    <Text style={styles.p}>
                        1. This Addendum is incorporated into and hereby deemed to be part of the Agreement.  All capitalized terms in this Addendum that are not otherwise defined in this Addendum have the meaning as provided in the Licensing Agreement.
                    </Text>
                    <Text style={styles.p}>
                        2. Client agrees to pay OptimX a 10% service fee for any online sales, processed, and paid directly from the Client&apos;s Site administered by OptimX software for transactions at or below the price of a $249.99 transaction at the time of user checkout. Client agrees to pay OptimX a 7.5% service fee for any online sales, processed and paid directly from the Client&apos;s Site administered by OptimX software for transactions above the price of a $249.99 transaction and at or below the price of a $549.99 transaction. Client agrees to pay OptimX a 4% service fee for any online sales, processed and paid directly from the Client&apos;s Site administered by OptimX software for transactions at or above the price of a $549.99 transaction at the time of user checkout. In addition to the transaction fee, the Client agrees to incur a fifty-cent processing fee per transaction from OptimX Sport&apos;s payment processing partner at the time of user checkout.
                    </Text>
                    <Text style={styles.p}>
                        3. In the event of a cancellation, return, or request for refund by Client&apos;s customer, the Client agrees that any transaction fees incurred from the purchase of any online sales, processed, and paid directly to OptimX from Client&apos;s transactions on the Site are nonrefundable.
                    </Text>
                    <Text style={styles.p}>
                        4. Client represents and warrants that it has the underlying legal rights to sell all Materials on OptimX&apos;s website. Customer further represents and warrants that the Material being sold on OptimX&apos;s website will not violate the intellectual property rights of any third-party.
                    </Text>
                    <Text style={styles.p}>
                        5. Client will indemnify, defend, and hold harmless OptimX Technologies and its officers, directors, employees, agents, successors, and assigns (each, an &ldquo;Indemnitee&rdquo;) against all losses, damages, liabilities, costs, and expenses, including reasonable attorneys&apos; fees and other litigation costs arising out of or resulting from any third-party claim, suit, action, or proceeding (each an &ldquo;Action&rdquo;) related to, arising out of, or resulting from Client&apos;s breach of any representation, warranty, covenant, or obligation under this Addendum or the Agreement.
                    </Text>
                    <Text style={styles.p}>
                        An Indemnitee will promptly notify Client in writing of any Action and cooperate with Client at Client&apos;s sole cost and expense. Client will immediately take control of the defense and investigation of the Action and will employ counsel reasonably acceptable to Indemnitee to handle and defend the same, at Client&apos;s sole cost and expense. Client will not settle any Action in a manner that adversely affects the rights of any Indemnitee without the Indemnitee&apos;s prior written consent. The Indemnitee&apos;s failure to perform any obligations under this Section 5 will not relieve Client of its obligation under this Section 5 except to the extent Client can demonstrate that it has been materially prejudiced as a result of the failure. The Indemnitee may participate in and observe the proceedings at its own cost and expense with counsel of its own choosing.
                    </Text>
                    <Text style={styles.p}>
                        6. Parties agree, and Client acknowledges, that OptimX operates and has operated its business in such a manner as to take reasonable advantage, if and when applicable, of the safe harbors provided by Section 512 of the Digital Millennium Copyright Act and that OptimX may remove items from the website upon receipt of a takedown notice relating to the same.
                    </Text>
                    <Text style={styles.p}>
                        7. All other provisions of the Agreement will remain in full force and effect.
                    </Text>
                    <View style={styles.signingSection}>
                        <View style={styles.signingSubSection}>
                            <Text style={styles.signingSubSectionHeading}>Contract Agreement Party A</Text>
                            <View style={styles.signingSubSubSection}>
                                <Text>Date: </Text>
                                <Text>{getDateStringWithMonthsFromDate(getCurrentDateTime())}</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Name: </Text>
                                <Text>{name}</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Signature: </Text>
                                {isValidString(sig) ? <Image style={styles.clientSignature} src={sig} cache={false} /> : null}
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Title: </Text>
                                <Text>{title}</Text>
                            </View>
                        </View>
                        <View style={styles.signingSubSection}>
                            <Text style={styles.signingSubSectionHeading}>Contract Agreement Party B</Text>
                            <View style={styles.signingSubSubSection}>
                                <Text>Date: </Text>
                                <Text>{getAccountCreationTime()}</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Name: </Text>
                                <Text>Jonathan Schubauer</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Signature: </Text>
                                <Text style={styles.optimXSignature}>Jonathan Schubauer</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Title: </Text>
                                <Text>President of Operations</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

StoreLicensingAgreementDocument.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    accountCreationTime: PropTypes.any.isRequired,
    sig: PropTypes.string.isRequired,
};

export default StoreLicensingAgreementDocument;
