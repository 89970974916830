import { createGamePlayerReference } from '../../../../firebase/firebaseAPICalls/FirebaseAPICallsGamePlayer';
import { SportsType } from '../../../enums/SportsType';
import { TagType } from '../../../enums/TagType';
import { RugbyGameObject, RugbyGamePromotionalVideoObject } from './RugbyGameInterface';

export interface RugbyGamePlayerObject {
    id: string,
    reference: any,
    teamId: string,
    teamReference: any,
    organizationId: string,
    organizationReference: any,
    gameId: string,
    gameReference: any,
    seasonId: string,
    seasonReference: string,
    preDevelopedSeasonId: string,
    preDevelopedSeasonReference: any,
    leagueId: string,
    leagueReference: any,
    preDevelopedLeagueId: string,
    preDevelopedLeagueReference: any,
    preDevelopedConferenceId: string,
    preDevelopedConferenceReference: string,
    opponentId: string,
    opponentReference: any,
    winOrLoss: 'win' | 'loss' | 'tied',
    gameScore: string,

    tries: number,
    tryAssists: number,
    conversions: number,
    penaltyKicks: number,
    penaltyGoals: number,
    dropGoals: number,
    fouls: number,
    yellowCards: number,
    redCards: number,
    tackles: number,
    offloads: number,
    passes: number,
    carries: number,
    metersCarried: number,
    minsPlayed: number,

    tryPeriods: RugbyGamePlayerPeriodObject,
    tryAssistPeriods: RugbyGamePlayerPeriodObject,
    conversionPeriods: RugbyGamePlayerPeriodObject,
    penaltyKickPeriods: RugbyGamePlayerPeriodObject,
    penaltyGoalPeriods: RugbyGamePlayerPeriodObject,
    dropGoalPeriods: RugbyGamePlayerPeriodObject,
    foulPeriods: RugbyGamePlayerPeriodObject,
    yellowCardPeriods: RugbyGamePlayerPeriodObject,
    redCardPeriods: RugbyGamePlayerPeriodObject,

    title: string,
    sportsType: SportsType,
    homeGame: boolean,
    status: 'scheduled' | 'postponed' | 'finished' | 'tba' | 'tbat' | 'cancelled',
    timeOfGame: Date,
    venue: string,
    attendance: number,
    featureImage: string,
    promotionalText: string,
    promotionalVideo: RugbyGamePromotionalVideoObject,
    notes: string,
    timeModified: Date,
    timeCreated: Date,
}

export interface RugbyGamePlayerPeriodObject {
    'h1': number,
    'h2': number,
    'et1': number,
    'et2': number,
    'sd': number,
}

export const getPlayerIdsFromRugbyGameObject = (gameObject: RugbyGameObject) => {
    const playerIds: Set<string> = new Set();
    gameObject.tagIds.forEach((tagId, index) => {
        if (gameObject.tagTypes[index] === TagType.PLAYER) {
            playerIds.add(tagId);
        }
    });

    gameObject.tries.forEach((rugbyTry) => {
        if (rugbyTry.team === 'our') {
            playerIds.add(rugbyTry.playerId);
            playerIds.add(rugbyTry.assistId);
        }
    });
    gameObject.conversions.forEach((conversion) => {
        if (conversion.team === 'our') {
            playerIds.add(conversion.playerId);
        }
    });
    gameObject.penaltyKicks.forEach((penaltyKick) => {
        if (penaltyKick.team === 'our') {
            playerIds.add(penaltyKick.playerId);
        }
    });

    gameObject.dropGoals.forEach((dropGoal) => {
        if (dropGoal.team === 'our') {
            playerIds.add(dropGoal.playerId);
        }
    });

    gameObject.tackles.forEach((tackle) => {
        playerIds.add(tackle.playerId);
    });

    gameObject.offloads.forEach((offload) => {
        playerIds.add(offload.playerId);
    });

    gameObject.passes.forEach((pass) => {
        playerIds.add(pass.playerId);
    });

    gameObject.carries.forEach((carry) => {
        playerIds.add(carry.playerId);
    });

    gameObject.metersCarried.forEach((metersCarriedSingular) => {
        playerIds.add(metersCarriedSingular.playerId);
    });

    gameObject.minsPlayed.forEach((minsPlayedSingular) => {
        playerIds.add(minsPlayedSingular.playerId);
    });

    return Array.from(playerIds);
};

export const getRugbyGamePlayerObjectFromRugbyGameObject = (gameObject: RugbyGameObject, playerId: string): RugbyGamePlayerObject => {
    const playerReference = createGamePlayerReference(gameObject.id, playerId);

    let tries = 0;
    let tryAssists = 0;
    let conversions = 0;
    let penaltyKicks = 0;
    let penaltyGoals = 0;
    let dropGoals = 0;
    let fouls = 0;
    let yellowCards = 0;
    let redCards = 0;
    let tackles = 0;
    let offloads = 0;
    let passes = 0;
    let carries = 0;
    let metersCarried = 0;
    let minsPlayed = 0;
    const tryPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };
    const tryAssistPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };
    const conversionPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };
    const penaltyKickPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };
    const penaltyGoalPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };
    const dropGoalPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };
    const foulPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };
    const yellowCardPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };
    const redCardPeriods = { h1: 0, h2: 0, et1: 0, et2: 0, sd: 0 };

    gameObject.tries.forEach((rugbyTry) => {
        if (rugbyTry.team === 'our' && rugbyTry.playerId === playerId) {
            tries += 1;
            if (rugbyTry.period !== 'na') {
                tryPeriods[rugbyTry.period] += 1;
            }
        } if (rugbyTry.team === 'our' && rugbyTry.assistId === playerId) {
            tryAssists += 1;
            if (rugbyTry.period !== 'na') {
                tryAssistPeriods[rugbyTry.period] += 1;
            }
        }
    });

    gameObject.conversions.forEach((conversion) => {
        if (conversion.team === 'our' && conversion.playerId === playerId) {
            conversions += 1;
            if (conversion.period !== 'na') {
                conversionPeriods[conversion.period] += 1;
            }
        }
    });

    gameObject.penaltyKicks.forEach((penaltyKick) => {
        if (penaltyKick.team === 'our' && penaltyKick.playerId === playerId) {
            penaltyKicks += 1;
            if (penaltyKick.period !== 'na') {
                penaltyKickPeriods[penaltyKick.period] += 1;
            }
        }
        if (penaltyKick.team === 'our' && penaltyKick.playerId === playerId && penaltyKick.convertedToGoal) {
            penaltyGoals += 1;
            if (penaltyKick.period !== 'na') {
                penaltyGoalPeriods[penaltyKick.period] += 1;
            }
        }
    });

    gameObject.dropGoals.forEach((dropGoal) => {
        if (dropGoal.team === 'our' && dropGoal.playerId === playerId) {
            dropGoals += 1;
            if (dropGoal.period !== 'na') {
                dropGoalPeriods[dropGoal.period] += 1;
            }
        }
    });

    gameObject.fouls.forEach((foul) => {
        if (foul.team === 'our' && foul.playerId === playerId) {
            fouls += 1;
            if (foul.period !== 'na') {
                foulPeriods[foul.period] += 1;
            }
        }
        if (foul.team === 'our' && foul.playerId === playerId && foul.yellowCard) {
            yellowCards += 1;
            if (foul.period !== 'na') {
                yellowCardPeriods[foul.period] += 1;
            }
        }
        if (foul.team === 'our' && foul.playerId === playerId && foul.redCard) {
            redCards += 1;
            if (foul.period !== 'na') {
                redCardPeriods[foul.period] += 1;
            }
        }
    });

    gameObject.tackles.forEach((tackle) => {
        if (tackle.playerId === playerId) {
            tackles += tackle.tackles;
        }
    });

    gameObject.offloads.forEach((offload) => {
        if (offload.playerId === playerId) {
            offloads += offload.offloads;
        }
    });

    gameObject.passes.forEach((pass) => {
        if (pass.playerId === playerId) {
            passes += pass.passes;
        }
    });

    gameObject.carries.forEach((carry) => {
        if (carry.playerId === playerId) {
            carries += carry.carries;
        }
    });

    gameObject.metersCarried.forEach((metersCarriedSingular) => {
        if (metersCarriedSingular.playerId === playerId) {
            metersCarried += metersCarriedSingular.metersCarried;
        }
    });

    gameObject.minsPlayed.forEach((minsPlayedSingular) => {
        if (minsPlayedSingular.playerId === playerId) {
            minsPlayed += minsPlayedSingular.minsPlayed;
        }
    });

    return {
        id: playerId,
        reference: playerReference,
        teamId: gameObject.teamId,
        teamReference: gameObject.teamReference,
        organizationId: gameObject.organizationId,
        organizationReference: gameObject.organizationReference,
        gameId: gameObject.id,
        gameReference: gameObject.reference,
        seasonId: gameObject.seasonId,
        seasonReference: gameObject.seasonReference,
        preDevelopedSeasonId: gameObject.preDevelopedSeasonId,
        preDevelopedSeasonReference: gameObject.preDevelopedSeasonReference,
        leagueId: gameObject.leagueId,
        leagueReference: gameObject.leagueReference,
        preDevelopedLeagueId: gameObject.preDevelopedLeagueId,
        preDevelopedLeagueReference: gameObject.preDevelopedLeagueReference,
        preDevelopedConferenceId: gameObject.preDevelopedConferenceId,
        preDevelopedConferenceReference: gameObject.preDevelopedConferenceReference,
        opponentId: gameObject.opponentId,
        opponentReference: gameObject.opponentReference,
        winOrLoss: gameObject.winOrLoss,
        gameScore: gameObject.gameScore,

        tries,
        tryAssists,
        conversions,
        penaltyKicks,
        penaltyGoals,
        dropGoals,
        fouls,
        yellowCards,
        redCards,
        tackles,
        offloads,
        passes,
        carries,
        metersCarried,
        minsPlayed,

        tryPeriods,
        tryAssistPeriods,
        conversionPeriods,
        penaltyKickPeriods,
        penaltyGoalPeriods,
        dropGoalPeriods,
        foulPeriods,
        yellowCardPeriods,
        redCardPeriods,

        title: gameObject.title,
        sportsType: gameObject.sportsType,
        homeGame: gameObject.homeGame,
        status: gameObject.status,
        timeOfGame: gameObject.timeOfGame,
        venue: gameObject.venue,
        attendance: gameObject.attendance,
        featureImage: gameObject.featureImage,
        promotionalText: gameObject.promotionalText,
        promotionalVideo: gameObject.promotionalVideo,
        notes: gameObject.notes,
        timeModified: gameObject.timeModified,
        timeCreated: gameObject.timeCreated,
    };
};
