import { getDateStringFromDate } from '../../DateTime';
import { SportsType } from '../../enums/SportsType';

export const demoGameVenue = 'Main Stadium, Bloomington';
export const demoGameAttendance = 120;
export const demoPromotionalText = 'We are playing this game to raise awaress for ALS';
export const demoPromotionalVideo = { title: 'Game Live Streaming', description: '', type: '', url: 'https://www.youtube.com/watch?v=fMeKRJmLE0Y' };
export const demoGameNotes = '- Our opposition played very well in this game';
export const createGameTitle = (opponentName: string, status: string, timeOfGame: Date): string => {
    return `Game vs ${opponentName}${status !== 'tba' ? ` on ${getDateStringFromDate(timeOfGame)}` : ''}`;
};
export const demoGameStatus = 'finished';

export const demoGameFeatureImage = (sportsType: SportsType) => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGame%2FiceHockey%2FdemoFeatureImage%2FdemoFeatureImage.png?alt=media&token=b570520e-f984-47ae-b480-67b5ca848837';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGame%2Frugby%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=b570520e-f984-47ae-b480-67b5ca848837';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGame%2Flacrosse%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=b570520e-f984-47ae-b480-67b5ca848837';
    }

    return '';
};
