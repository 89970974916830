import { createDemoMyLibraryGallery, demoMyLibraryGalleryId } from '../../catalog/demo/GalleryDemo';
import { SportsType } from '../../catalog/enums/SportsType';
import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { PhotoObject } from '../../catalog/interface/PhotoInterface';
import { isValidObject } from '../../catalog/Validity';
import { db, storageRef } from '../FirebaseLibrary';
import { createGalleryReference } from './FirebaseAPICallsGallery';

export const createPhotosReference = () => {
    return db.collection('photos');
};

export const createPhotoReference = (photoId: string) => {
    return createPhotosReference().doc(photoId);
};

export const fetchPhotoFirestoreAPI = (photoId: string) => {
    return createPhotoReference(photoId).get();
};

export const insertPhotoFirestoreAPI = (organizationId: string, teamId: string, photoObject: PhotoObject, sportsType: SportsType) => {
    const galleryRef = createGalleryReference(demoMyLibraryGalleryId(teamId));
    return db.runTransaction(async (transaction) => {
        const galleryDoc: any = await transaction.get(galleryRef);
        if (galleryDoc.exists && isValidObject(galleryDoc.data())) {
            const { photoIds, photoReferences } = galleryDoc.data();
            photoIds.push(photoObject.id);
            photoReferences.push(photoObject.reference);
            transaction.update(galleryRef, { photoIds, photoReferences });
        } else {
            const photoIds = Array<string>(1);
            photoIds[0] = photoObject.id;
            const galleryObject = createDemoMyLibraryGallery(organizationId, teamId, photoIds, sportsType);
            transaction.set(galleryRef, galleryObject);
        }
        transaction.set(createPhotoReference(photoObject.id), photoObject);
    });
};

export const insertPhotosFirestoreAPI = (organizationId: string, teamId: string, photoObjects: PhotoObject[], sportsType: SportsType) => {
    const galleryRef = createGalleryReference(demoMyLibraryGalleryId(teamId));
    return db.runTransaction(async (transaction) => {
        const galleryDoc: any = await transaction.get(galleryRef);
        if (galleryDoc.exists && isValidObject(galleryDoc.data())) {
            const { photoIds, photoReferences } = galleryDoc.data();

            photoObjects.forEach((photoObject) => {
                photoIds.push(photoObject.id);
                photoReferences.push(photoObject.reference);
                transaction.set(createPhotoReference(photoObject.id), photoObject);
            });

            transaction.update(galleryRef, { photoIds, photoReferences });
        } else {
            const photoIds = Array<string>(photoObjects.length);

            photoObjects.forEach((photoObject, index) => {
                photoIds[index] = photoObject.id;
                transaction.set(createPhotoReference(photoObject.id), photoObject);
            });

            const galleryObject = createDemoMyLibraryGallery(organizationId, teamId, photoIds, sportsType);
            transaction.set(galleryRef, galleryObject);
        }
    });
};

export const updatePhotoFirestoreAPI = (photoObject: any) => {
    return createPhotoReference(photoObject.id).update(photoObject);
};

export const deletePhotoFirestoreAPI = (photoId: string) => {
    return createPhotoReference(photoId).delete();
};

export const deletePhotoFromGalleryTooFirestoreAPI = (galleryId: string, photoId: string) => {
    const galleryRef = createGalleryReference(galleryId);
    return db.runTransaction(async (transaction) => {
        const galleryDoc: any = await transaction.get(galleryRef);
        if (galleryDoc.exists && isValidObject(galleryDoc.data())) {
            const { photoIds, photoReferences } = galleryDoc.data();
            const updatedPhotoIds = Array<string>(0);
            const updatedPhotoReferences = Array<any>(0);
            photoIds.forEach((photo: string, index: number) => {
                if (photo !== photoId) {
                    updatedPhotoIds.push(photo);
                    updatedPhotoReferences.push(photoReferences[index]);
                }
            });
            transaction.update(galleryRef, { photoIds: updatedPhotoIds, photoReferences: updatedPhotoReferences });
        }
        transaction.delete(createPhotoReference(photoId));
    });
};

export const removeTagFromPhotoFirestoreAPI = (photoId: string, tagId: string) => {
    const photoRef = createPhotoReference(photoId);
    return db.runTransaction(async (transaction) => {
        const photoDoc: any = await transaction.get(photoRef);
        if (photoDoc.exists && isValidObject(photoDoc.data())) {
            const { tagIds, tagReferences, tagTypes } = photoDoc.data();
            tagIds.forEach((id: string, index: number) => {
                if (id === tagId) {
                    tagIds.splice(index, 1);
                    tagReferences.splice(index, 1);
                    tagTypes.splice(index, 1);
                }
            });
            transaction.update(photoRef, { tagIds, tagReferences, tagTypes });
        }
    });
};

export const fetchPhotosFirestoreAPI = (teamId: string) => {
    return createPhotosReference().where('teamId', '==', teamId).orderBy('timeCreated', 'desc').get();
};

export const fetchPhotosByTagFirestoreAPI = (teamId: string, tagId: string, sportsType: SportsType) => {
    return createPhotosReference().where('tagIds', 'array-contains', tagId)
        .where('teamId', '==', teamId)
        .where('sportsType', '==', sportsType)
        .orderBy('timeCreated', 'desc')
        .get();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createPhotoImageRefPathStorageAPI = (teamId: string, photoId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `teams/${teamId}/photos/${photoId}_1600x900.png`;
    }
    return `teams/${teamId}/photos/${photoId}.png`;
};

export const insertPhotoImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, photoId: string, photoTitle: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: photoId,
            title: photoTitle,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createPhotoImageRefPathStorageAPI(teamId, photoId, false));
    return { ref: storageRef.child(createPhotoImageRefPathStorageAPI(teamId, photoId, true)), promise: imageRef.put(file, metadata) };
};

export const deletePhotoImageStorageAPI = (teamId: string, photoId: string) => {
    const imageRef = storageRef.child(createPhotoImageRefPathStorageAPI(teamId, photoId, true));
    return imageRef.delete();
};
