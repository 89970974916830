import { isValidObject } from '../../../catalog/Validity';

export const initialWebsiteState = {
    teamName: '',
    teamURL: '',
    teamLogo: { file: null, link: '' },
    teamSilhouetteLogo: { file: null, link: '' },
    teamInfo: {},
    teamWebsite: {},
    teamSEOInfo: {},
    teamSocials: {},
    customPages: [],
    forms: [],
    loadingSpinner: true,
};

export function websiteReducer(state: any, action: any) {
    let newImages;
    let newDesign;
    let newContacts;
    let newNavLinks;
    let newNavLinksOptions;
    let newNavLinksOptionsOptions;
    switch (action.type) {
        case 'reset':
            return { ...initialWebsiteState };
        case 'fetchWebsite':
            return { ...state,
                teamName: action.name,
                teamURL: action.url,
                teamLogo: action.logo,
                teamSilhouetteLogo: action.silhouetteLogo,
                teamInfo: action.info,
                teamWebsite: { ...action.website,
                    images: checkNecessaryImages(action.website.images),
                    styles: { ...action.website.styles,
                        colors: { ...action.website.styles.colors,
                            primaryColor: action.website.styles.colors.primaryColor,
                            secondaryColor: action.website.styles.colors.secondaryColor,
                            fontColor: action.website.styles.colors.fontColor,
                        },
                    },
                },
                teamSEOInfo: action.seoInfo,
                teamSocials: action.socials,
            };
        case 'setLogo':
            return { ...state, teamLogo: action.logo };
        case 'setSilhouetteLogo':
            return { ...state, teamSilhouetteLogo: action.logo };
        case 'setStadiumName':
            return { ...state, teamInfo: { ...state.teamInfo, stadiumName: action.stadiumName } };
        case 'setAddress':
            return { ...state, teamInfo: { ...state.teamInfo, address: action.address } };
        case 'setAlias':
            return { ...state, teamInfo: { ...state.teamInfo, alias: action.alias } };
        case 'setSlogan':
            return { ...state, teamInfo: { ...state.teamInfo, slogan: action.slogan } };
        case 'setShortAbout':
            return { ...state, teamInfo: { ...state.teamInfo, shortAbout: action.about } };
        case 'setLongAbout':
            return { ...state, teamInfo: { ...state.teamInfo, longAbout: action.about } };
        case 'setContactTel':
            newContacts = [...state.teamInfo.contacts];
            newContacts[action.index] = { ...newContacts[action.index], tel: action.tel };
            return { ...state, teamInfo: { ...state.teamInfo, contacts: newContacts } };
        case 'setContactEmail':
            newContacts = [...state.teamInfo.contacts];
            newContacts[action.index] = { ...newContacts[action.index], email: action.email };
            return { ...state, teamInfo: { ...state.teamInfo, contacts: newContacts } };
        case 'setDesign':
            newDesign = { ...state.teamWebsite.styles.design };
            newDesign[action.key] = action.design;
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, design: newDesign } } };
        case 'setBannerText':
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, bannerText: action.text } } };
        case 'setPrimaryColor':
            if (typeof action.primaryColor === 'string' || action.primaryColor instanceof String) {
                return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, colors: { ...state.teamWebsite.styles.colors, primaryColor: action.primaryColor } } } };
            }
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, colors: { ...state.teamWebsite.styles.colors, primaryColor: action.primaryColor } } } };
        case 'setSecondaryColor':
            if (typeof action.secondaryColor === 'string' || action.secondaryColor instanceof String) {
                return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, colors: { ...state.teamWebsite.styles.colors, secondaryColor: action.secondaryColor } } } };
            }
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, colors: { ...state.teamWebsite.styles.colors, secondaryColor: action.secondaryColor } } } };
        case 'setFontColor':
            if (typeof action.fontColor === 'string' || action.fontColor instanceof String) {
                return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, colors: { ...state.teamWebsite.styles.colors, fontColor: action.fontColor } } } };
            }
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, colors: { ...state.teamWebsite.styles.colors, fontColor: action.fontColor } } } };
        case 'setDarkMode':
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, darkMode: action.darkMode } } };
        case 'sortNavLinks':
            newNavLinks = action.navLinks;
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'addNavLink':
            newNavLinks = [...state.teamWebsite.styles.navLinks, { title: '', value: '/', link: '/', options: [], openNewTab: false, required: false }];
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'removeNavLink':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinks.splice(action.index0, 1);
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkTitle':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], title: action.title };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkValue':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            if (action.value === '/custom') {
                newNavLinks[action.index0] = { ...newNavLinks[action.index0], value: action.value, link: action.link, openNewTab: true, options: [] };
            } else {
                newNavLinks[action.index0] = { ...newNavLinks[action.index0], value: action.value, link: action.link, openNewTab: false, options: [] };
            }
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkOpenNewTab':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], openNewTab: action.openNewTab };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'sortNavLinkOptions':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = action.options;
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'addNavLinkOption':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options, { title: '', value: '/', link: '/', options: [], openNewTab: false, required: false }];
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'removeNavLinkOption':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptions.splice(action.index1, 1);
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkOptionTitle':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], title: action.title };
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkOptionValue':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            if (action.value === '/custom') {
                newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], value: action.value, link: action.link, openNewTab: true };
            } else {
                newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], value: action.value, link: action.link, openNewTab: false };
            }
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkOptionOpenNewTab':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], openNewTab: action.openNewTab };
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'sortNavLinkOptionsOptions':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptionsOptions = action.options;
            newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], options: newNavLinksOptionsOptions };
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'addNavLinkOptionOption':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptionsOptions = isValidObject(newNavLinksOptions[action.index1].options) ? [...newNavLinksOptions[action.index1].options, { title: '', value: '/', link: '/', openNewTab: false, required: false }] : [{ title: '', value: '/', link: '/', openNewTab: false, required: false }];
            newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], options: newNavLinksOptionsOptions };
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'removeNavLinkOptionOption':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptionsOptions = isValidObject(newNavLinksOptions[action.index1].options) ? [...newNavLinksOptions[action.index1].options] : [];
            newNavLinksOptionsOptions.splice(action.index2, 1);
            newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], options: newNavLinksOptionsOptions };
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkOptionOptionTitle':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptionsOptions = [...newNavLinksOptions[action.index1].options];
            newNavLinksOptionsOptions[action.index2] = { ...newNavLinksOptionsOptions[action.index2], title: action.title };
            newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], options: newNavLinksOptionsOptions };
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkOptionOptionValue':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptionsOptions = [...newNavLinksOptions[action.index1].options];
            if (action.value === '/custom') {
                newNavLinksOptionsOptions[action.index2] = { ...newNavLinksOptionsOptions[action.index2], value: action.value, link: action.link, openNewTab: true };
            } else {
                newNavLinksOptionsOptions[action.index2] = { ...newNavLinksOptionsOptions[action.index2], value: action.value, link: action.link, openNewTab: false };
            }
            newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], options: newNavLinksOptionsOptions };
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinkOptionOptionOpenNewTab':
            newNavLinks = [...state.teamWebsite.styles.navLinks];
            newNavLinksOptions = [...newNavLinks[action.index0].options];
            newNavLinksOptionsOptions = [...newNavLinksOptions[action.index1].options];
            newNavLinksOptionsOptions[action.index2] = { ...newNavLinksOptionsOptions[action.index2], openNewTab: action.openNewTab };
            newNavLinksOptions[action.index1] = { ...newNavLinksOptions[action.index1], options: newNavLinksOptionsOptions };
            newNavLinks[action.index0] = { ...newNavLinks[action.index0], options: newNavLinksOptions };
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: newNavLinks } } };
        case 'setNavLinks':
            return { ...state, teamWebsite: { ...state.teamWebsite, styles: { ...state.teamWebsite.styles, navLinks: action.navLinks } } };
        case 'setSEOTitle':
            return { ...state, teamWebsite: { ...state.teamWebsite, seoInfo: { ...state.teamWebsite.seoInfo, title: action.title } } };
        case 'setSEODescription':
            return { ...state, teamWebsite: { ...state.teamWebsite, seoInfo: { ...state.teamWebsite.seoInfo, description: action.description } } };
        case 'setSEOKeywords':
            return { ...state, teamWebsite: { ...state.teamWebsite, seoInfo: { ...state.teamWebsite.seoInfo, keywords: action.keywords } } };
        case 'setImage':
            newImages = [...state.teamWebsite.images];
            newImages[action.index] = { ...state.teamWebsite.images[action.index], image: action.image };
            return { ...state, teamWebsite: { ...state.teamWebsite, images: newImages } };
        case 'setImageTitle':
            newImages = [...state.teamWebsite.images];
            newImages[action.index] = { ...state.teamWebsite.images[action.index], title: action.title };
            return { ...state, teamWebsite: { ...state.teamWebsite, images: newImages } };
        case 'setImageLink':
            newImages = [...state.teamWebsite.images];
            newImages[action.index] = { ...state.teamWebsite.images[action.index], link: action.link };
            return { ...state, teamWebsite: { ...state.teamWebsite, images: newImages } };
        case 'setImageDescription':
            newImages = [...state.teamWebsite.images];
            newImages[action.index] = { ...state.teamWebsite.images[action.index], description: action.description };
            return { ...state, teamWebsite: { ...state.teamWebsite, images: newImages } };
        case 'setTwitterURL':
            return { ...state, teamSocials: { ...state.teamSocials, twitterURL: action.url } };
        case 'setFacebookURL':
            return { ...state, teamSocials: { ...state.teamSocials, facebookURL: action.url } };
        case 'setTiktokURL':
            return { ...state, teamSocials: { ...state.teamSocials, tiktokURL: action.url } };
        case 'setInstagramURL':
            return { ...state, teamSocials: { ...state.teamSocials, instagramURL: action.url } };
        case 'setYoutubeURL':
            return { ...state, teamSocials: { ...state.teamSocials, youtubeURL: action.url } };
        case 'setSharingHashTags':
            return { ...state, teamSocials: { ...state.teamSocials, sharingHashTags: action.hashTags } };
        case 'setTwitterWidget':
            return { ...state, teamSocials: { ...state.teamSocials, twitterWidget: action.widget } };
        case 'setFacebookWidget':
            return { ...state, teamSocials: { ...state.teamSocials, facebookWidget: action.widget } };
        case 'setTiktokWidget':
            return { ...state, teamSocials: { ...state.teamSocials, tiktokWidget: action.widget } };
        case 'setInstagramWidget':
            return { ...state, teamSocials: { ...state.teamSocials, instagramWidget: action.widget } };
        case 'setYoutubeWidget':
            return { ...state, teamSocials: { ...state.teamSocials, youtubeWidget: action.widget } };
        case 'fetchCustomPages':
            return { ...state, customPages: action.pages };
        case 'fetchForms':
            return { ...state, forms: action.forms };
        case 'setLoadingSpinner':
            return { ...state, loadingSpinner: action.loadingSpinner };
        default:
            return initialWebsiteState;
    }
}

export const checkNecessaryImages = (images: [any]) => {
    let hasEvent = false;
    let hasPractice = false;
    let hasMobileFeatureImages = false;
    let hasStoreImages = false;
    images.forEach((image) => {
        if (image.id === 'eventFeatureImage') {
            hasEvent = true;
        }
        if (image.id === 'practiceFeatureImage') {
            hasPractice = true;
        }
        if (image.id === 'mFeatureImage1') {
            hasMobileFeatureImages = true;
        }
        if (image.id === 'storeFeatureImage1') {
            hasStoreImages = true;
        }
    });

    if (!hasEvent) {
        images.push({
            description: 'This is the feature image shown for every event if the feature image is not available',
            id: 'eventFeatureImage',
            image: { file: null, link: '' },
            imageTitle: 'Default Event Feature Image',
            link: '',
            title: 'Default Event Feature Image',
        });
    }
    if (!hasPractice) {
        images.push({
            description: 'This is the feature image shown for every practice if the feature image is not available',
            id: 'practiceFeatureImage',
            image: { file: null, link: '' },
            imageTitle: 'Default Practice Feature Image',
            link: '',
            title: 'Default Practice Feature Image',
        });
    }
    if (!hasMobileFeatureImages) {
        images.splice(3, 0, {
            id: 'mFeatureImage1',
            imageTitle: 'Mobile Feature Image 1',
            image: { file: null, link: '' },
            title: 'Mobile Feature Image 1',
            link: '',
            description: 'This is the feature image 1 for the website on mobile',
        },
        {
            id: 'mFeatureImage2',
            imageTitle: 'Mobile Feature Image 2',
            image: { file: null, link: '' },
            title: 'Mobile Feature Image 2',
            link: '',
            description: 'This is the feature image 2 for the website on mobile',
        },
        {
            id: 'mFeatureImage3',
            imageTitle: 'Mobile Feature Image 3',
            image: { file: null, link: '' },
            title: 'Mobile Feature Image 3',
            link: '',
            description: 'This is the feature image 3 for the website on mobile',
        });
    }
    if (!hasStoreImages) {
        images.push({
            id: 'storeFeatureImage1',
            required: false,
            imageTitle: 'Store Feature Image',
            image: '',
            title: 'Store Feature Image',
            link: '',
            description: 'This is the feature image shown in Store',
        });
        images.push({
            id: 'mStoreFeatureImage1',
            required: false,
            imageTitle: 'Mobile Store Feature Image',
            image: '',
            title: 'Mobile Store Feature Image',
            link: '',
            description: 'This is the feature image shown in Store on mobile',
        });
    }

    return images;
};
