import { TeamWebsiteStylesColorsObject } from '../../../../interface/TeamInterface';

export const demoTeamWebsiteColorsPrimaryColor = '#000000';
export const demoTeamWebsiteColorsSecondaryColor = '#2bb673';
export const demoTeamWebsiteColorsFontColor = '#ffffff';

export const demoTeamWebsiteStylesColors: TeamWebsiteStylesColorsObject = {
    primaryColor: demoTeamWebsiteColorsPrimaryColor,
    secondaryColor: demoTeamWebsiteColorsSecondaryColor,
    fontColor: demoTeamWebsiteColorsFontColor,
};
