import { createMailReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsMail';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { MailerSendMailObject } from '../interface/mailInterface/MailerSendMailInterface';

export const createSignUpClientMailObject = (
    mailId: string,
    teamId: string,
    teamName: string,
    sportsType: SportsType,
    email: string,
): MailerSendMailObject => {
    return {
        id: mailId,
        reference: createMailReference(mailId),
        teamId,
        teamReference: createTeamReference(teamId),
        from: { email: 'welcome@optimxsports.com', name: 'OptimX Sports' },
        to: [{ email, name: '' }],
        cc: [],
        bcc: [],
        reply_to: { email: 'contact@optimxtechnologies.com', name: 'OptimX Sports' },
        subject: createSignUpClientMailSubject(),
        html: createSignUpClientMailHTML(teamId, teamName),
        text: createSignUpClientMailText(teamId, teamName),
        template_id: '',
        variables: [],
        personalization: [],
        tags: ['signup', 'signup_client_email', `signup_${teamId}`, `signup_email_${teamId}`, teamId],
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

const createSignUpClientMailText = (teamId: string, teamName: string) => {
    return `
    Hello ${teamName},\n\n

    Thank you for registering with OptimX Sports! We are so excited to have your team on board! Your account has been assigned a domain url for your team to get started creating your website! You can find the url here: https://test.optimxsports.com/?team=${teamId}. Feel free to start creating anytime!!\n\n
    
    Even better news!! After signing up with our platform your first month subscription is free of charge!! This includes the building and hosting of your website. Enjoy a free year of team collaboration on us!!\n\n
    
    If you want to change your domain url that has been assigned to your team, please reach out to us on our Contact Us page. Once we have received your request, we will work with your team to have the new domain assigned to your team’s new website.\n\n
    
    Need to transfer or acquire a domain? No problem! Acquiring or transferring domains is free of charge for your organization. We’ll take care of it for your team!\n\n
    
    You can also reach out to us directly at contact@optimxtechnolgies.com for any other related inquiries. We wish your team the best success and look forward to partnering with your team!!\n\n
    
    Disclaimer: Please note the domain url your team is assigned is initially intended to allow your team to start building your website immediately after signing up and is not necessarily intended to be used as your team’s website domain. Please reach out to our team to change your initial domain url.\n\n

    Thanks,\n
    Your OptimX Sports Team
    `;
};

const createSignUpClientMailHTML = (teamId: string, teamName: string) => {
    return `
    <!DOCTYPE html>
    <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
            <meta name="color-scheme" content="light only">
            <meta name="supported-color-schemes" content="light only">
            <title>Signup Email</title>
            <style type="text/css">
                @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300;400;500;600&display=swap');
            </style>
        </head>
        <body style="margin:0 auto;padding:3%;border:0;font-family:Helvetica,sans-serif;">
            <table style="margin:50px auto;padding:0;border:0;max-width:800px;overflow:hidden;background-color:#fafafa;">
                <table style="margin:auto;padding:20px 0px;border:0;border-radius:10px 10px 0px 0px;width:100%;height:100px;background-color:#ffffff;">
                    <tr>
                        <td>
                            <a style="margin:0;padding:0;border:0;" href="https://optimxsports.com" target="_blank">
                                <img style="margin:auto;padding:0;border:0;display:block;text-align:center;max-width:100px;" src="https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/optimx-sports-admin%2Femail%2Fsmall-optimx-sports-logo_1600x900.png?alt=media&token=699fa73b-5997-48cd-8206-6d4268e28ebf" alt="OptimX Sports Logo"/>
                            </a>
                        </td>
                    </tr>
                </table>
                <table style="margin:0;padding:50px 5%;border:0;min-height:250px;width:100%;background-color:#101010;color:white;">
                    <tr>
                        <td>
                            <h1 style="margin:0;margin-bottom:50px;padding:0;border:0;font-size:30px;">Hello ${teamName},</h1>
                            <h2 style="margin:0;margin-top:30px;padding:0;border:0;font-size:25px;">Here is your website's temporary URL:</h2>
                            <a style="margin:0;padding:0;border:0;font-size:15px;color:rgb(2, 120, 206);font-weight:800;" href="https://test.optimxsports.com/?team=${teamId}">https://test.optimxsports.com/?team=${teamId}</a>
                            <h2 style="margin:0;margin-top:30px;padding:0;border:0;font-size:25px;">Host your OptimX Website on your own domain:</h2>
                            <p style="margin:0;padding:0;border:0;font-size:15px;color:white;">If you want to change your domain url that has been assigned to your team, please reach out to us on our <a href="https://optimxsports.com/contactus" style="padding:0;border:0;margin:0;font-size:15px;color:rgb(2, 120, 206);font-weight:800;">Contact Us</a> page. Once we have received your request, we will work with your team to have the new domain assigned to your team’s new website.</p>
                        </td>
                    </tr>
                </table>
                <tr>
                    <td>
                        <a style="margin:0;padding:50px 3%;border:0;display:block;width:auto;background-color:#ffffff;" href="https://optimxsports.com">
                            <img style="margin:0;padding:0;border:0;display:block;width:100%;margin:auto;" src="https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/optimx-sports-admin%2Femail%2F5phone_2_1600x900.png?alt=media&token=a1e064f6-efbd-40d8-8d5c-c10b68069fbe" alt="OptimX Sports Banner Image"/>
                        </a>
                    </td>
                </tr>
                <table style="margin:0;padding:0px 5% 50px 5%;border:0;background-color:#ffffff;">
                    <tr>
                        <td>
                            <h1 style="margin:0;margin-bottom:20px;padding:0;border:0;">Welcome to OptimX Sports</h1>
                            <p style="margin:0;margin-bottom:20px;padding:0;border:0;font-size:15px;">Hello ${teamName},</p>
                            <p style="margin:0;margin-bottom:20px;padding:0;border:0;font-size:15px;">Thank you for registering with OptimX Sports! We are so excited to have your team on board! Your account has been assigned a domain url for your team to get started creating your website! You can find the url here: <a href="https://test.optimxsports.com/?team=${teamId}" style="margin:0;padding:0;border:0;">https://test.optimxsports.com/?team=${teamId}</a>. Feel free to start creating anytime!!</p>
                            <p style="margin:0;margin-bottom:20px;padding:0;border:0;font-size:15px;">Even better news!! After signing up with our platform your first month subscription is free of charge!! This includes the building and hosting of your website. Enjoy a free year of team collaboration on us!!</p>
                            <p style="margin:0;margin-bottom:20px;padding:0;border:0;font-size:15px;">If you want to <b style="margin:0;padding:0;border:0;">change your domain url</b> that has been assigned to your team, please reach out to us on our <a href="https://optimxsports.com/contactus" style="margin:0;padding:0;border:0;">Contact Us</a> page. Once we have received your request, we will work with your team to have the new domain assigned to your team’s new website.</p>
                            <p style="margin:0;margin-bottom:20px;padding:0;border:0;font-size:15px;"><b style="margin:0;padding:0;border:0;">Need to transfer or acquire a domain?</b><br style="margin:0;padding:0;border:0;"/>No problem! Acquiring or transferring domains is <b style="margin:0;padding:0;border:0;">free</b> of charge for your organization. We’ll take care of it for your team!</p>
                            <p style="margin:0;margin-bottom:20px;padding:0;border:0;font-size:15px;">You can also reach out to us directly at <a href="mailto:contact@optimxtechnolgies.com" style="margin:0;padding:0;border:0;">contact@optimxtechnolgies.com</a> for any other related inquiries. We wish your team the best success and look forward to partnering with your team!!</p>
                            <p style="margin:0;margin-bottom:20px;padding:0;border:0;font-size:15px;"><b style="margin:0;padding:0;border:0;">Disclaimer:</b> Please note the domain url your team is assigned is initially intended to allow your team to start building your website immediately after signing up and is not necessarily intended to be used as your team’s website domain. Please reach out to our team to change your initial domain url.</p>
                            <p style="margin:0;margin-bottom:20px;padding:0;border:0;font-size:15px;">Thanks,<br style="margin:0;padding:0;border:0;"/>OptimX Sports Team</p>
                            <a style="margin:0px auto;padding:8px 12px;border:0;border-width:1px;border-radius:8px;display:block;text-align:center;width:200px;background-color:#ffffff;color:#000000;text-decoration:none;font-weight:800;border-style:solid;border-color:#000000;letter-spacing:1px;font-weight:800;" href="https://test.optimxsports.com/?team=${teamId}"> Check out your website</a>
                        </td>
                    </tr>
                </table>
                <table style="margin:0;padding:20px 0px;border:0;border-radius:0px 0px 10px 10px;width:100%;background-color:#101010;">
                    <tr>
                        <td>
                            <a style="margin:0;padding:0;border:0;" href="https://optimxsports.com" target="_blank">
                                <img style="margin:auto;padding:0;border:0;display:block;text-align:center;max-width:100px;" src="https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/optimx-sports-admin%2Femail%2Fsmall-optimx-sports-logo_1600x900.png?alt=media&token=699fa73b-5997-48cd-8206-6d4268e28ebf" alt="OptimX Sports Logo"/>
                            </a>
                            <a style="margin:10px auto auto auto;padding:0;border:0;display:block;text-align:center;text-decoration:none;color:white;font-size:12px;width:100%;" href="mailto:contact@optimxtechnolgies.com">contact@optimxtechnolgies.com</a>
                            <ul style="margin:10px auto auto auto;padding:0;border:0;display:block;text-align:center;list-style-type:none;">
                                <li style="margin:0px 5px;padding:0;border:0;display:inline;"><a href="https://www.instagram.com/optimxsports" target="_blank" style="margin:0;padding:0;border:0;"><img src="https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/optimx-sports-admin%2Femail%2Fin-color_1600x900.png?alt=media&token=504bdd1d-48b2-4424-9f87-ca7eb5836248" alt="" style="margin:0;padding:0;border:0;max-width:35px;"/></a></li>
                                <li style="margin:0px 5px;padding:0;border:0;display:inline;"><a href="https://www.facebook.com/optimxsports" target="_blank" style="margin:0;padding:0;border:0;"><img src="https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/optimx-sports-admin%2Femail%2Ffb-color_1600x900.png?alt=media&token=bc41b7ac-7ee3-4d22-b1cc-392b130c04a4" alt="" style="margin:0;padding:0;border:0;max-width:35px;"/></a></li>
                                <li style="margin:0px 5px;padding:0;border:0;display:inline;"><a href="https://x.com/optimxsports" target="_blank" style="margin:0;padding:0;border:0;"><img src="https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/optimx-sports-admin%2Femail%2Ftw-color_1600x900.png?alt=media&token=f8e0eb69-dbe6-4efc-888c-606f9f522232" alt="" style="margin:0;padding:0;border:0;max-width:35px;"/></a></li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </table>
        </body>
    </html>
    `;
};

const createSignUpClientMailSubject = () => {
    return 'Registration Complete - Welcome to OptimX Sports';
};
