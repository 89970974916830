import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import HockeySpinner from './hockeyLoadingSpinner.gif';
import RugbySpinner from './rugbyLoadingSpinner.gif';
import LacrosseSpinner from './lacrosseLoadingSpinner.gif';
import { SportsType } from '../../../../catalog/enums/SportsType';
import { context } from '../../../../globalState/GlobalState';
import Spinner2 from '../spinner2/Spinner2';
import { isValidString } from '../../../../catalog/Validity';

const Spinner3 = (props) => {
    const { show, size, marginTop, text } = props;
    const { globalState } = useContext(context);

    const getLoader = () => {
        if (globalState.sportsType === SportsType.WOMENSICEHOCKEY || globalState.sportsType === SportsType.MENSICEHOCKEY) {
            return (<img className="x-center" style={{ display: show ? 'block' : 'none', height: `${size}px`, marginTop: `${marginTop}px` }} src={HockeySpinner} alt="Loading Spinner" />);
        }
        if (globalState.sportsType === SportsType.WOMENSRUGBY || globalState.sportsType === SportsType.MENSRUGBY) {
            return (<img className="x-center" style={{ display: show ? 'block' : 'none', height: `${size}px`, marginTop: `${marginTop}px` }} src={RugbySpinner} alt="Loading Spinner" />);
        }
        if (globalState.sportsType === SportsType.WOMENSLACROSSE || globalState.sportsType === SportsType.MENSLACROSSE) {
            return (<img className="x-center" style={{ display: show ? 'block' : 'none', height: `${size}px`, marginTop: `${marginTop}px` }} src={LacrosseSpinner} alt="Loading Spinner" />);
        }
        return <Spinner2 size={size / 10} marginTop={marginTop + 30} />;
    };

    return (
        <>
            {getLoader()}
            {isValidString(text) ? <p className="m-t-0 m-b-0 text-secondary-color font-weight-800 font-size-2xl">{text}</p> : '' }
        </>
    );
};

Spinner3.propTypes = {
    show: PropTypes.bool,
    size: PropTypes.number,
    marginTop: PropTypes.number,
    text: PropTypes.string,
};

Spinner3.defaultProps = {
    show: true,
    size: 200,
    marginTop: 20,
    text: '',
};

export default memo(Spinner3);
