/* eslint-disable no-unused-vars */
export enum EventType {
    NONE,
    PRACTICE,
    EVENT,
}

export const getEventTypeName = (eventType: EventType): string => {
    if (eventType === EventType.PRACTICE) {
        return 'Practice';
    } if (eventType === EventType.EVENT) {
        return 'Event';
    }
    return '';
};
