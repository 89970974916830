import { getCurrentDateTime } from '../../catalog/DateTime';
import { ArticleObject } from '../../catalog/interface/ArticleInterface';
import { EventObject } from '../../catalog/interface/EventInterface';
import { GalleryObject } from '../../catalog/interface/GalleryInterface';
import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { PhotoObject } from '../../catalog/interface/PhotoInterface';
import { isValidObject } from '../../catalog/Validity';
import { db, storageRef } from '../FirebaseLibrary';
import { createArticleReference } from './FirebaseAPICallsArticle';
import { createGalleryReference } from './FirebaseAPICallsGallery';
import { createPhotoReference } from './FirebaseAPICallsPhoto';
import { createTicketReference } from './FirebaseAPICallsTicket';

export const createEventsReference = () => {
    return db.collection('events');
};

export const createEventReference = (eventId: string) => {
    return createEventsReference().doc(eventId);
};

export const fetchEventFirestoreAPI = (eventId: string) => {
    return createEventReference(eventId).get();
};

export const fetchEventsFirestoreAPI = (teamId: string) => {
    return createEventsReference().where('teamId', '==', teamId).orderBy('timeOfEvent', 'desc').get();
};

export const insertEventFirestoreAPI = (eventObject: EventObject) => {
    return createEventReference(eventObject.id).set(eventObject);
};

export const insertEventArticleAndGalleryFirestoreAPI = (eventObject: EventObject, articleObject: ArticleObject, galleryObject: GalleryObject, photoObjects: PhotoObject[]) => {
    const batch = db.batch();

    photoObjects.forEach((photoObject) => {
        batch.set(createPhotoReference(photoObject.id), photoObject);
    });
    batch.set(createGalleryReference(galleryObject.id), galleryObject);
    batch.set(createArticleReference(articleObject.id), articleObject);
    batch.set(createEventReference(eventObject.id), eventObject);

    return batch.commit();
};

export const updateEventFirestoreAPI = (eventObject: any) => {
    return createEventReference(eventObject.id).update(eventObject);
};

export const updateEventArticleGalleryAndTicketsFirestoreAPI = (eventObject: any, articleObject: any, galleryObject: any, photoObjects: any[], ticketIds: string[], ticketObject: any) => {
    const batch = db.batch();

    photoObjects.forEach((photoObject) => {
        if (isValidObject(photoObject.reference)) {
            batch.set(createPhotoReference(photoObject.id), photoObject);
        } else {
            batch.update(createPhotoReference(photoObject.id), photoObject);
        }
    });
    ticketIds.forEach((ticketId) => {
        batch.update(createTicketReference(ticketId), ticketObject);
    });
    batch.update(createGalleryReference(galleryObject.id), galleryObject);
    batch.update(createArticleReference(articleObject.id), articleObject);
    batch.update(createEventReference(eventObject.id), eventObject);

    return batch.commit();
};

export const deleteEventFirestoreAPI = (eventId: string) => {
    return createEventReference(eventId).delete();
};

export const deleteEventArticleGalleryAndTicketsFirestoreAPI = (eventId: string, articleId: string, galleryId: string, ticketIds: string[], pseudoDeleteTickets: boolean) => {
    const eventRef = createEventReference(eventId);
    const articleRef = createArticleReference(articleId);
    const galleryRef = createGalleryReference(galleryId);
    return db.runTransaction(async (transaction) => {
        const galleryDoc: any = await transaction.get(galleryRef);
        if (galleryDoc.exists && isValidObject(galleryDoc.data())) {
            const { photoIds } = galleryDoc.data();
            await Promise.all(photoIds.map(async (photoId: any) => {
                const photoDoc: any = await transaction.get(createPhotoReference(photoId));
                if (photoDoc.exists && isValidObject(photoDoc.data())) {
                    transaction.delete(createPhotoReference(photoId));
                }
            }));
            if (pseudoDeleteTickets) {
                await Promise.all(ticketIds.map(async (ticketId: any) => {
                    const ticketDoc: any = await transaction.get(createTicketReference(ticketId));
                    if (ticketDoc.exists && isValidObject(ticketDoc.data())) {
                        transaction.update(createTicketReference(ticketId), { deleted: true, onTicketUpdateNeeded: true, timeModified: getCurrentDateTime() });
                    }
                }));
            } else {
                await Promise.all(ticketIds.map(async (ticketId: any) => {
                    const ticketDoc: any = await transaction.get(createTicketReference(ticketId));
                    if (ticketDoc.exists && isValidObject(ticketDoc.data())) {
                        transaction.delete(createTicketReference(ticketId));
                    }
                }));
            }
            transaction.delete(galleryRef);
            transaction.delete(articleRef);
            transaction.delete(eventRef);
        }
    });
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createEventFeatureImageRefPathStorageAPI = (eventId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `events/${eventId}/featureImage/featureImage_1600x900.png`;
    }
    return `events/${eventId}/featureImage/featureImage.png`;
};

export const insertEventFeatureImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, eventId: string, eventTitle: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: eventId,
            title: eventTitle,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createEventFeatureImageRefPathStorageAPI(eventId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deleteEventFeatureImageStorageAPI = (eventId: string) => {
    const imageRef = storageRef.child(createEventFeatureImageRefPathStorageAPI(eventId, true));
    return imageRef.delete();
};
