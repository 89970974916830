import { createGalleryReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsGallery';
import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createPhotoReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPhoto';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { TagType } from '../enums/TagType';
import { PhotoObject } from '../interface/PhotoInterface';

export const demoPhotoTitle = 'Sport at its best!';
export const getDemoPhotoImage1 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2FiceHockey%2FdemoGallery%2FdemoGallery1.jpg?alt=media&token=9ab53bd4-a33b-4f7c-8910-bdd249f76e71';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Frugby%2FdemoGallery%2FdemoGallery1_1600x900.png?alt=media&token=9ab53bd4-a33b-4f7c-8910-bdd249f76e71';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Flacrosse%2FdemoGallery%2FdemoGallery1_1600x900.png?alt=media&token=9ab53bd4-a33b-4f7c-8910-bdd249f76e71';
    }
    return '';
};
export const getDemoPhotoImage2 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2FiceHockey%2FdemoGallery%2FdemoGallery2.jpg?alt=media&token=9f730498-d4bb-4bf5-80bf-eb18e22d5a4b';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Frugby%2FdemoGallery%2FdemoGallery2_1600x900.png?alt=media&token=9f730498-d4bb-4bf5-80bf-eb18e22d5a4b';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Flacrosse%2FdemoGallery%2FdemoGallery2_1600x900.png?alt=media&token=9f730498-d4bb-4bf5-80bf-eb18e22d5a4b';
    }
    return '';
};
export const getDemoPhotoImage3 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2FiceHockey%2FdemoGallery%2FdemoGallery3.jpg?alt=media&token=cfb72c03-c871-43a6-92ef-f279a39c6f77';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Frugby%2FdemoGallery%2FdemoGallery3_1600x900.png?alt=media&token=cfb72c03-c871-43a6-92ef-f279a39c6f77';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Flacrosse%2FdemoGallery%2FdemoGallery3_1600x900.png?alt=media&token=cfb72c03-c871-43a6-92ef-f279a39c6f77';
    }
    return '';
};
export const getDemoPhotoImage4 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2FiceHockey%2FdemoArticleGallery%2FdemoGallery1.png?alt=media&token=213989ac-4d0f-4ecd-9d36-e729c212a798';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Frugby%2FdemoArticleGallery%2FdemoGallery1_1600x900.png?alt=media&token=213989ac-4d0f-4ecd-9d36-e729c212a798';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Flacrosse%2FdemoArticleGallery%2FdemoGallery1_1600x900.png?alt=media&token=213989ac-4d0f-4ecd-9d36-e729c212a798';
    }
    return '';
};
export const getDemoPhotoImage5 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2FiceHockey%2FdemoArticleGallery%2FdemoGallery2.png?alt=media&token=28d75871-9412-4991-b6e7-d77ce3beb6ed';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Frugby%2FdemoArticleGallery%2FdemoGallery2_1600x900.png?alt=media&token=28d75871-9412-4991-b6e7-d77ce3beb6ed';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Flacrosse%2FdemoArticleGallery%2FdemoGallery2_1600x900.png?alt=media&token=28d75871-9412-4991-b6e7-d77ce3beb6ed';
    }
    return '';
};

export const createPhotoDemo = (organizationId: string, teamId: string, galleryId: string, photoId: string, imageLink: string, tagIds: string[], tagReferences: any[], tagTypes: TagType[], sportsType: SportsType): PhotoObject => {
    const photoReference = createPhotoReference(photoId);
    const galleryReference = createGalleryReference(galleryId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: photoId,
        reference: photoReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        galleryId,
        galleryReference,
        title: demoPhotoTitle,
        sportsType,
        tagIds,
        tagReferences,
        tagTypes,
        image: imageLink,
        published: true,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
