import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createStoreAdminInfoReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsStoreAdminInfo';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { StoreAdminInfoObject } from '../interface/StoreAdminInfoInterface';

export const storeInfoId = 'store-info';

export const createDemoInfoStore = (organizationId: string, teamId: string, sportsType: SportsType): StoreAdminInfoObject => {
    const storeInfoReference = createStoreAdminInfoReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: storeInfoId,
        reference: storeInfoReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,

        client: {
            storeActive: true,
            productsActive: true,
            ticketsActive: true,
            formsActive: true,
        },
        portal: {
            storeActive: true,
            productsActive: true,
            ticketsActive: true,
            formsActive: true,
        },

        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
