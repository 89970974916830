import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createSponsorReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsSponsor';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SponsorObject } from '../interface/SponsorInterface';
import { SportsType } from '../enums/SportsType';

export const demoSponsorName = 'OptimX Sports';
export const demoSponsorURL = 'https://optimxsports.com/';
export const demoSponsorNotes = '- Our website sponsors';
export const demoSponsorLogo = 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoSponsorLogo%2FoptimXSponsor_1600x900.png?alt=media&token=68d3b39c-8259-4f4e-8170-842ca63ac424';

export const createDemoSponsor = (organizationId: string, teamId: string, sponsorId: string, sportsType: SportsType): SponsorObject => {
    const sponsorReference = createSponsorReference(teamId, sponsorId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: sponsorId,
        urlId: sponsorId,
        reference: sponsorReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        name: demoSponsorName,
        logo: demoSponsorLogo,
        url: demoSponsorURL,
        notes: demoSponsorNotes,
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
