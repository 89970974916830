import { sendCustomTeamAnalytics, sendErrorTeamAnalytics } from '../../../../../catalog/Analytics';
import { getCurrentDateTime, increaseYearsInDate } from '../../../../../catalog/DateTime';
import { SportsType } from '../../../../../catalog/enums/SportsType';
import { MailerSendMailObject } from '../../../../../catalog/interface/mailInterface/MailerSendMailInterface';
import { createStoreLicensingAgreementMailObject } from '../../../../../catalog/mail/LicensingAgreementEmail';
import { LICENSING_AGREEMENT_ERROR, LICENSING_AGREEMENT_SUCCESS } from '../../../../../catalog/NotificationComments';
import { randomNumber } from '../../../../../catalog/Others';
import { insertStoreLicensingAgreementPDFStorageAPI } from '../../../../../firebase/firebaseAPICalls/FirebaseAPICallsLicensingAgreement';
import { insertMailFirestoreAPI } from '../../../../../firebase/firebaseAPICalls/FirebaseAPICallsMailerSendMail';
import { fetchTeamFirestoreAPI, updateTeamFirestoreAPI } from '../../../../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { fetchPDFURLStorageAPI } from '../../../../../firebase/firebaseAPICalls/StorageAPICalls';
import { storeLicensingAgreementVersionNumber } from './StoreLicensingAgreement';

const saveStoreLicensingAgreementPDF = async (organizationId: string, organizationName: string, teamId: string, teamName: string, file: File): Promise<string> => {
    const { ref, promise } = insertStoreLicensingAgreementPDFStorageAPI(organizationId, organizationName, teamId, teamName, file);
    await promise;
    const url = await fetchPDFURLStorageAPI(10, ref);
    return url;
};

export const storeLicensingAgreementAPI = async (
    organizationId: string,
    organizationName: string,
    teamId: string,
    teamName: string,
    sportsType: SportsType,
    email: string,
    url: string,
    file: File,
    setGlobalState: Function,
    snackbar: Function,
) => {
    try {
        const newFile = await saveStoreLicensingAgreementPDF(organizationId, organizationName, teamId, teamName, file);
        await updateStoreLicensingAgreementInTeamAPICall(teamId, newFile, setGlobalState);
        const mailId = randomNumber();
        const mailObject: MailerSendMailObject = createStoreLicensingAgreementMailObject(mailId, teamId, teamName, organizationId, organizationName, sportsType, email, url);
        await insertMailFirestoreAPI(mailObject);
        sendCustomTeamAnalytics('store_agreement_signed');
        snackbar(LICENSING_AGREEMENT_SUCCESS, { variant: 'success' });
    } catch (error) {
        sendErrorTeamAnalytics(LICENSING_AGREEMENT_ERROR);
        snackbar(LICENSING_AGREEMENT_ERROR, { variant: 'error' });
    }
};

const updateStoreLicensingAgreementInTeamAPICall = async (teamId: string, file: string, setGlobalState: Function) => {
    const teamDoc = await fetchTeamFirestoreAPI(teamId);
    const team = teamDoc.data();

    const storeLicensingAgreement = {
        version: storeLicensingAgreementVersionNumber,
        licensingAgreementPDF: file,
        signedOn: getCurrentDateTime(),
        startDate: getCurrentDateTime(),
        endDate: increaseYearsInDate(getCurrentDateTime(), 100),
        licensingSignatureNeeded: false,
    };

    const teamTemp: any = {
        id: team?.id,
        storeLicensingAgreement,
        timeModified: getCurrentDateTime(),
    };

    await updateTeamFirestoreAPI(teamTemp);
    setGlobalState({ type: 'updateStoreLicensingAgreement', storeLicensingAgreement });
};
