import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime, getDateTimeLastMinute } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { createGalleryReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsGallery';
import { GalleryObject } from '../interface/GalleryInterface';
import { TagType } from '../enums/TagType';
import { createArticleReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsArticle';
import { demoArticleTitle } from './ArticleDemo';
import { createPhotoReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPhoto';
import { createSeasonReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsSeason';
import { createGameReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsGame';
import { createGameTitle, demoGameStatus } from './game/GameDemo';
import { demoOpponentName } from './OpponentDemo';
import { demoEventTitle } from './EventDemo';

export const demoMyLibraryGalleryId = (teamId: string) => {
    return `${teamId}-mylibrary`;
};
export const demoMyLibraryGalleryTitle = 'My Library';
export const demoGalleryTitle = 'Match day 19';
export const createGalleryTitleForArticle = (articleTitle: string): string => {
    return `${articleTitle}`;
};
export const createGalleryTitleForGame = (gameTitle: string): string => {
    return `${gameTitle}`;
};
export const createGalleryTitleForEvent = (gameTitle: string): string => {
    return `${gameTitle}`;
};

export const createDemoGallery = (organizationId: string, teamId: string, galleryId: string, tagIds: string[], tagReferences: any[], tagTypes: TagType[], photoIds: string[], sportsType: SportsType): GalleryObject => {
    const galleryReference = createGalleryReference(galleryId);
    const photoReferences = photoIds.map((id) => {
        return createPhotoReference(id);
    });
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: galleryId,
        urlId: galleryId,
        reference: galleryReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        seasonId: '',
        seasonReference: null,
        gameId: '',
        gameReference: null,
        eventId: '',
        eventReference: null,
        practiceId: '',
        practiceReference: null,
        playerId: '',
        playerReference: null,
        coachId: '',
        coachReference: null,
        articleId: '',
        articleReference: null,
        title: demoGalleryTitle,
        tagIds,
        tagReferences,
        tagTypes,
        sportsType,
        photoIds,
        photoReferences,
        published: true,
        timeOfGallery: getCurrentDateTime(),
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

export const createDemoArticleGallery = (organizationId: string, teamId: string, galleryId: string, tagIds: string[], tagReferences: any[], tagTypes: TagType[], articleId: string, photoIds: string[], sportsType: SportsType): GalleryObject => {
    const galleryReference = createGalleryReference(galleryId);
    const articleReference = createArticleReference(articleId);
    const photoReferences = photoIds.map((id) => {
        return createPhotoReference(id);
    });
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: galleryId,
        urlId: galleryId,
        reference: galleryReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        seasonId: '',
        seasonReference: null,
        gameId: '',
        gameReference: null,
        eventId: '',
        eventReference: null,
        practiceId: '',
        practiceReference: null,
        playerId: '',
        playerReference: null,
        coachId: '',
        coachReference: null,
        articleId,
        articleReference,
        title: createGalleryTitleForArticle(demoArticleTitle),
        tagIds,
        tagReferences,
        tagTypes,
        sportsType,
        photoIds,
        photoReferences,
        published: true,
        timeOfGallery: getCurrentDateTime(),
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

export const createDemoMyLibraryGallery = (organizationId: string, teamId: string, photoIds: string[], sportsType: SportsType): GalleryObject => {
    const galleryReference = createGalleryReference(demoMyLibraryGalleryId(teamId));
    const photoReferences = photoIds.map((id) => {
        return createPhotoReference(id);
    });
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: demoMyLibraryGalleryId(teamId),
        urlId: demoMyLibraryGalleryId(teamId),
        reference: galleryReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        seasonId: '',
        seasonReference: null,
        gameId: '',
        gameReference: null,
        eventId: '',
        eventReference: null,
        practiceId: '',
        practiceReference: null,
        playerId: '',
        playerReference: null,
        coachId: '',
        coachReference: null,
        articleId: '',
        articleReference: null,
        title: demoMyLibraryGalleryTitle,
        tagIds: [],
        tagReferences: [],
        tagTypes: [],
        sportsType,
        photoIds,
        photoReferences,
        published: true,
        timeOfGallery: getCurrentDateTime(),
        timeModified: getDateTimeLastMinute(),
        timeCreated: getDateTimeLastMinute(),
    };
};

export const createDemoGameGallery = (organizationId: string, teamId: string, galleryId: string, tagIds: string[], tagReferences: any[], tagTypes: TagType[], seasonId: string, gameId: string, articleId: string, photoIds: string[], sportsType: SportsType): GalleryObject => {
    const galleryReference = createGalleryReference(galleryId);
    const seasonReference = createSeasonReference(teamId, seasonId);
    const gameReference = createGameReference(gameId);
    const articleReference = createArticleReference(articleId);
    const photoReferences = photoIds.map((id) => {
        return createPhotoReference(id);
    });
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: galleryId,
        urlId: galleryId,
        reference: galleryReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        seasonId,
        seasonReference,
        gameId,
        gameReference,
        eventId: '',
        eventReference: null,
        practiceId: '',
        practiceReference: null,
        playerId: '',
        playerReference: null,
        coachId: '',
        coachReference: null,
        articleId,
        articleReference,
        title: createGalleryTitleForGame(createGameTitle(demoOpponentName, demoGameStatus, new Date())),
        tagIds,
        tagReferences,
        tagTypes,
        sportsType,
        photoIds,
        photoReferences,
        published: true,
        timeOfGallery: getCurrentDateTime(),
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

export const createDemoEventGallery = (organizationId: string, teamId: string, galleryId: string, tagIds: string[], tagReferences: any[], tagTypes: TagType[], seasonId: string, eventId: string, articleId: string, photoIds: string[], sportsType: SportsType): GalleryObject => {
    const galleryReference = createGalleryReference(galleryId);
    const seasonReference = createSeasonReference(teamId, seasonId);
    const eventReference = createGameReference(eventId);
    const articleReference = createArticleReference(articleId);
    const photoReferences = photoIds.map((id) => {
        return createPhotoReference(id);
    });
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: galleryId,
        urlId: galleryId,
        reference: galleryReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        seasonId,
        seasonReference,
        gameId: '',
        gameReference: null,
        eventId,
        eventReference,
        practiceId: '',
        practiceReference: null,
        playerId: '',
        playerReference: null,
        coachId: '',
        coachReference: null,
        articleId,
        articleReference,
        title: createGalleryTitleForEvent(demoEventTitle),
        tagIds,
        tagReferences,
        tagTypes,
        sportsType,
        photoIds,
        photoReferences,
        published: true,
        timeOfGallery: getCurrentDateTime(),
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
