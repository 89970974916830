import { SeasonObject } from '../../catalog/interface/SeasonInterface';
import { db } from '../FirebaseLibrary';

export const createSeasonsReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/seasons`);
};

export const createSeasonReference = (teamId: string, seasonId: string) => {
    return createSeasonsReference(teamId).doc(seasonId);
};

export const fetchSeasonFirestoreAPI = (teamId: string, seasonId: string) => {
    return createSeasonReference(teamId, seasonId).get();
};

export const fetchSeasonsFirestoreAPI = (teamId: string) => {
    return createSeasonsReference(teamId).orderBy('timeCreated', 'desc').get();
};

export const insertSeasonFirestoreAPI = (teamId: string, seasonObject: SeasonObject) => {
    return createSeasonReference(teamId, seasonObject.id).set(seasonObject);
};

export const updateSeasonFirestoreAPI = (teamId: string, seasonObject: any) => {
    return createSeasonReference(teamId, seasonObject.id).update(seasonObject);
};

export const deleteSeasonFirestoreAPI = (teamId: string, seasonId: string) => {
    return createSeasonReference(teamId, seasonId).delete();
};
