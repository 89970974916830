import { isValidObject } from '../../Validity';

export const ourTriesPointsFromTries = (tries: any[]) => {
    let total = 0;
    if (isValidObject(tries)) {
        tries.forEach((rugbyTry: any) => {
            total += rugbyTry.team === 'our' ? 5 : 0;
        });
    }
    return total;
};

export const ourConversionsPointsFromConversions = (conversions: any[]) => {
    let total = 0;
    if (isValidObject(conversions)) {
        conversions.forEach((conversion: any) => {
            total += conversion.team === 'our' ? 2 : 0;
        });
    }
    return total;
};

export const ourPenaltyGoalsPointsFromPenaltyKicks = (penaltyKicks: any[]) => {
    let total = 0;
    if (isValidObject(penaltyKicks)) {
        penaltyKicks.forEach((penaltyKick: any) => {
            total += penaltyKick.team === 'our' && penaltyKick.convertedToGoal ? 3 : 0;
        });
    }
    return total;
};

export const ourPenaltyKicksFromPenaltyKicksAndKickingGameStats = (penaltyKicks: any[], kickingGameStats: any) => {
    if (isValidObject(kickingGameStats) && isValidObject(kickingGameStats.penaltyKicks) && kickingGameStats.penaltyKicks !== 0) {
        return kickingGameStats.penaltyKicks;
    }
    let total = 0;
    if (isValidObject(penaltyKicks)) {
        penaltyKicks.forEach((penaltyKick: any) => {
            total += penaltyKick.team === 'our' ? 1 : 0;
        });
    }
    return total;
};

export const ourDropGoalsPointsFromDropGoals = (dropGoals: any[]) => {
    let total = 0;
    if (isValidObject(dropGoals)) {
        dropGoals.forEach((dropGoal: any) => {
            total += dropGoal.team === 'our' ? 3 : 0;
        });
    }
    return total;
};

export const opponentTriesPointsFromTries = (tries: any[]) => {
    let total = 0;
    if (isValidObject(tries)) {
        tries.forEach((gameTry: any) => {
            total += gameTry.team === 'opponent' ? 5 : 0;
        });
    }
    return total;
};

export const opponentConversionsPointsFromConversions = (conversions: any[]) => {
    let total = 0;
    if (isValidObject(conversions)) {
        conversions.forEach((conversion: any) => {
            total += conversion.team === 'opponent' ? 2 : 0;
        });
    }
    return total;
};

export const opponentPenaltyGoalsPointsFromPenaltyKicks = (penaltyKicks: any[]) => {
    let total = 0;
    if (isValidObject(penaltyKicks)) {
        penaltyKicks.forEach((penaltyKick: any) => {
            total += penaltyKick.team === 'opponent' && penaltyKick.convertedToGoal ? 3 : 0;
        });
    }
    return total;
};

export const opponentPenaltyKicksFromPenaltyKicksAndKickingGameStats = (penaltyKicks: any[], kickingGameStats: any) => {
    if (isValidObject(kickingGameStats) && isValidObject(kickingGameStats.opponentPenaltyKicks) && kickingGameStats.opponentPenaltyKicks !== 0) {
        return kickingGameStats.opponentPenaltyKicks;
    }
    let total = 0;
    if (isValidObject(penaltyKicks)) {
        penaltyKicks.forEach((penaltyKick: any) => {
            total += penaltyKick.team === 'opponent' ? 1 : 0;
        });
    }
    return total;
};

export const opponentDropGoalsPointsFromDropGoals = (dropGoals: any[]) => {
    let total = 0;
    if (isValidObject(dropGoals)) {
        dropGoals.forEach((dropGoal: any) => {
            total += dropGoal.team === 'opponent' ? 3 : 0;
        });
    }
    return total;
};

export const ourPointsFromStats = (tries: any[], conversions: any[], penaltyKicks: any[], dropGoals: any[]) => {
    let total = 0;
    total += ourTriesPointsFromTries(tries);
    total += ourConversionsPointsFromConversions(conversions);
    total += ourPenaltyGoalsPointsFromPenaltyKicks(penaltyKicks);
    total += ourDropGoalsPointsFromDropGoals(dropGoals);
    return total;
};

export const opponentPointsFromStats = (tries: any[], conversions: any[], penaltyKicks: any[], dropGoals: any[]) => {
    let total = 0;
    total += opponentTriesPointsFromTries(tries);
    total += opponentConversionsPointsFromConversions(conversions);
    total += opponentPenaltyGoalsPointsFromPenaltyKicks(penaltyKicks);
    total += opponentDropGoalsPointsFromDropGoals(dropGoals);
    return total;
};

export const lineoutsWonPercentageFromLineouts = (lineoutsWon: number, lineoutsLost: number) => {
    return (lineoutsWon / (lineoutsWon + lineoutsLost)).toFixed(2);
};

export const scrumsWonPercentageFromScrums = (scrumsWon: number, scrumsLost: number) => {
    return (scrumsWon / (scrumsWon + scrumsLost)).toFixed(2);
};
