import { LeagueObject } from '../../catalog/interface/LeagueInterface';
import { db } from '../FirebaseLibrary';
import { createGamesReference } from './FirebaseAPICallsGame';
import { createGameCoachesCollectionGroupReference } from './FirebaseAPICallsGameCoach';
import { createGamePlayersCollectionGroupReference } from './FirebaseAPICallsGamePlayer';

export const createLeaguesReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/leagues`);
};

export const createLeagueReference = (teamId: string, leagueId: string) => {
    return createLeaguesReference(teamId).doc(leagueId);
};

export const fetchLeagueFirestoreAPI = (teamId: string, leagueId: string) => {
    return createLeagueReference(teamId, leagueId).get();
};

export const fetchLeaguesFirestoreAPI = (teamId: string) => {
    return createLeaguesReference(teamId).where('deleted', '==', false).orderBy('timeCreated').get();
};

export const fetchLeaguesIncludingDeletedFirestoreAPI = (teamId: string) => {
    return createLeaguesReference(teamId).orderBy('timeCreated').get();
};

export const insertLeagueFirestoreAPI = (teamId: string, leagueObject: LeagueObject) => {
    return createLeagueReference(teamId, leagueObject.id).set(leagueObject);
};

export const updateLeagueFirestoreAPI = async (teamId: string, leagueId: string, leagueObject: any) => {
    const leagueRef = createLeagueReference(teamId, leagueId);
    const gameRefs: any[] = [];
    const gamePlayerRefs: any[] = [];
    const gameCoachRefs: any[] = [];
    await createGamesReference().where('teamId', '==', teamId).where('leagueId', '==', leagueId)
        .get()
        .then((docs) => {
            docs.forEach((doc) => {
                if (doc.exists) {
                    gameRefs.push(doc.data().reference);
                }
            });
        });
    await createGamePlayersCollectionGroupReference().where('teamId', '==', teamId).where('leagueId', '==', leagueId)
        .get()
        .then((docs) => {
            docs.forEach((doc) => {
                if (doc.exists) {
                    gamePlayerRefs.push(doc.data().reference);
                }
            });
        });
    await createGameCoachesCollectionGroupReference().where('teamId', '==', teamId).where('leagueId', '==', leagueId)
        .get()
        .then((docs) => {
            docs.forEach((doc) => {
                if (doc.exists) {
                    gameCoachRefs.push(doc.data().reference);
                }
            });
        });

    const batch = db.batch();

    gameRefs.forEach((gameRef) => {
        batch.update(gameRef, leagueObject);
    });
    gamePlayerRefs.forEach((gamePlayerRef) => {
        batch.update(gamePlayerRef, leagueObject);
    });
    gameCoachRefs.forEach((gameCoachRef) => {
        batch.update(gameCoachRef, leagueObject);
    });
    batch.update(leagueRef, leagueObject);

    await batch.commit();
};

export const pseduDeleteLeagueFirestoreAPI = (teamId: string, leagueObject: any) => {
    return createLeagueReference(teamId, leagueObject.id).update(leagueObject);
};

export const deleteLeagueFirestoreAPI = (teamId: string, leagueId: string) => {
    return createLeagueReference(teamId, leagueId).delete();
};
