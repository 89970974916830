import { SportsType } from '../enums/SportsType';
import { TagType } from '../enums/TagType';
import { IceHockeyGameCoachObject } from '../interface/gameInterface/iceHockey/IceHockeyGameCoachInterface';
import { IceHockeyGameObject } from '../interface/gameInterface/iceHockey/IceHockeyGameInterface';
import { getIceHockeyGamePlayerObjectFromIceHockeyGameObject } from '../interface/gameInterface/iceHockey/IceHockeyGamePlayerInterface';
import { LacrosseGameCoachObject } from '../interface/gameInterface/lacrosse/LacrosseGameCoachInterface';
import { LacrosseGameObject } from '../interface/gameInterface/lacrosse/LacrosseGameInterface';
import { getLacrosseGamePlayerObjectFromLacrosseGameObject } from '../interface/gameInterface/lacrosse/LacrosseGamePlayerInterface';
import { RugbyGameCoachObject } from '../interface/gameInterface/rugby/RugbyGameCoachInterface';
import { RugbyGameObject } from '../interface/gameInterface/rugby/RugbyGameInterface';
import { getRugbyGamePlayerObjectFromRugbyGameObject } from '../interface/gameInterface/rugby/RugbyGamePlayerInterface';
import { createDemoIceHockeyGame } from './game/IceHockeyGameDemo';
import { createDemoLacrosseGame } from './game/LacrosseGameDemo';
import { createDemoRugbyGame } from './game/RugbyGameDemo';

export const createDemoGamePlayer = (
    organizationId: string,
    teamId: string,
    gameId: string,
    tagIds: string[],
    tagReferences: any[],
    tagTypes: TagType[],
    seasonId: string,
    articleId: string,
    galleryId: string,
    leagueId: string,
    opponentId: string,
    playerId: string,
    sportsType: SportsType,
): IceHockeyGameCoachObject | RugbyGameCoachObject | LacrosseGameCoachObject => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        const demoIceHockeyGameObject = createDemoIceHockeyGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, articleId, galleryId, leagueId, opponentId, playerId, sportsType);
        return getIceHockeyGamePlayerObjectFromIceHockeyGameObject(demoIceHockeyGameObject, playerId);
    }

    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        const demoRugbyGameObject = createDemoRugbyGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, articleId, galleryId, leagueId, opponentId, playerId, sportsType);
        return getRugbyGamePlayerObjectFromRugbyGameObject(demoRugbyGameObject, playerId);
    }

    const demoLacrosseGameObject = createDemoLacrosseGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, articleId, galleryId, leagueId, opponentId, playerId, sportsType);
    return getLacrosseGamePlayerObjectFromLacrosseGameObject(demoLacrosseGameObject, playerId);
};

export const createDemoIceHockeyGamePlayerFromDemoIceHockeyGame = (demoGameObject: IceHockeyGameObject, playerId: string): IceHockeyGameCoachObject => {
    return getIceHockeyGamePlayerObjectFromIceHockeyGameObject(demoGameObject, playerId);
};

export const createDemoRugbyGamePlayerFromDemoRugbyGame = (demoGameObject: RugbyGameObject, playerId: string): RugbyGameCoachObject => {
    return getRugbyGamePlayerObjectFromRugbyGameObject(demoGameObject, playerId);
};

export const createDemoLacrosseGamePlayerFromDemoLacrosseGame = (demoGameObject: LacrosseGameObject, playerId: string): RugbyGameCoachObject => {
    return getLacrosseGamePlayerObjectFromLacrosseGameObject(demoGameObject, playerId);
};
