import { SportsType } from '../../enums/SportsType';
import { TeamLogoObject } from '../../interface/TeamInterface';

export const getDemoTeamLogo = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo_1600x900.png?alt=media&token=57e87aae-c273-45d1-9b62-6729c79fe1c1';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo_1600x900.png?alt=media&token=57e87aae-c273-45d1-9b62-6729c79fe1c1';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo_1600x900.png?alt=media&token=57e87aae-c273-45d1-9b62-6729c79fe1c1';
    }
    return '';
};
export const getDemoTeamLogo32 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo32_1600x900.png?alt=media&token=a9d56093-1858-42ec-9517-da7e86e2e543';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo32_1600x900.png?alt=media&token=a9d56093-1858-42ec-9517-da7e86e2e543';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo32_1600x900.png?alt=media&token=a9d56093-1858-42ec-9517-da7e86e2e543';
    }
    return '';
};
export const getDemoTeamLogo196 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo196_1600x900.png?alt=media&token=75b07743-3c88-4150-b777-64092ceb437f';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo196_1600x900.png?alt=media&token=75b07743-3c88-4150-b777-64092ceb437f';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo196_1600x900.png?alt=media&token=75b07743-3c88-4150-b777-64092ceb437f';
    }
    return '';
};
export const getDemoTeamLogo512 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo512_1600x900.png?alt=media&token=99878cda-0d03-4b30-b13a-e07f0873c5a8';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo512_1600x900.png?alt=media&token=99878cda-0d03-4b30-b13a-e07f0873c5a8';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo512_1600x900.png?alt=media&token=99878cda-0d03-4b30-b13a-e07f0873c5a8';
    }
    return '';
};
export const getDemoTeamLogo1024 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo1024_1600x900.png?alt=media&token=117c8da6-5527-4f7f-89d3-320ac5787ebb';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo1024_1600x900.png?alt=media&token=117c8da6-5527-4f7f-89d3-320ac5787ebb';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamLogo1024_1600x900.png?alt=media&token=117c8da6-5527-4f7f-89d3-320ac5787ebb';
    }
    return '';
};

export const getDemoTeamLogoObject = (sportsType: SportsType): TeamLogoObject => {
    return {
        logo: getDemoTeamLogo(sportsType),
        logo1: getDemoTeamLogo32(sportsType),
        logo2: getDemoTeamLogo196(sportsType),
        logo3: getDemoTeamLogo512(sportsType),
        logo4: getDemoTeamLogo1024(sportsType),
    };
};
