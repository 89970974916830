import { getSportsTypeName, SportsType } from '../../../enums/SportsType';
import { TeamWebsiteSEOObject } from '../../../interface/TeamInterface';
import { getDemoTeamInfoAbout } from '../TeamInfoDemo';

export const demoTeamWebsiteSEOInfoTitle = (organizationName: string, teamName: string, sportsType: SportsType) => {
    return `${organizationName} - ${teamName} - ${getSportsTypeName(sportsType)}`;
};
export const demoTeamWebsiteSEOInfoKeywords = (organizationName: string, teamName: string, sportsType: SportsType) => {
    return `${organizationName}, ${teamName}, ${getSportsTypeName(sportsType)}, Team City, Team State`;
};
export const getDemoTeamWebsiteSEOInfoObject = (organizationName: string, teamName: string, sportsType: SportsType): TeamWebsiteSEOObject => {
    return (
        {
            title: demoTeamWebsiteSEOInfoTitle(organizationName, teamName, sportsType),
            description: getDemoTeamInfoAbout(sportsType),
            keywords: demoTeamWebsiteSEOInfoKeywords(organizationName, teamName, sportsType),
        }
    );
};
