import { OpponentObject } from '../../catalog/interface/OpponentInterface';
import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { db, storageRef } from '../FirebaseLibrary';

export const createOpponentsReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/opponents`);
};

export const createOpponentReference = (teamId: string, opponentId: string) => {
    return createOpponentsReference(teamId).doc(opponentId);
};

export const fetchOpponentFirestoreAPI = (teamId: string, opponentId: string) => {
    return createOpponentReference(teamId, opponentId).get();
};

export const fetchOpponentsFirestoreAPI = (teamId: string) => {
    return createOpponentsReference(teamId).where('deleted', '==', false).orderBy('timeCreated', 'desc').get();
};

export const fetchOpponentsIncludingDeletedFirestoreAPI = (teamId: string) => {
    return createOpponentsReference(teamId).orderBy('timeCreated', 'desc').get();
};

export const insertOpponentFirestoreAPI = (teamId: string, opponentObject: OpponentObject) => {
    return createOpponentReference(teamId, opponentObject.id).set(opponentObject);
};

export const updateOpponentFirestoreAPI = (teamId: string, opponentObject: any) => {
    return createOpponentReference(teamId, opponentObject.id).update(opponentObject);
};

export const deleteOpponentFirestoreAPI = (teamId: string, opponentId: string) => {
    return createOpponentReference(teamId, opponentId).delete();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createOpponentLogoRefPathStorageAPI = (teamId: string, opponentId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `teams/${teamId}/opponents/${opponentId}/opponentLogo/opponentLogo_1600x900.png`;
    }
    return `teams/${teamId}/opponents/${opponentId}/opponentLogo/opponentLogo.png`;
};

export const insertOpponentLogoStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, opponentId: string, opponentName: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: opponentId,
            title: opponentName,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createOpponentLogoRefPathStorageAPI(teamId, opponentId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deleteOpponentLogoStorageAPI = (teamId: string, opponentId: string) => {
    const imageRef = storageRef.child(createOpponentLogoRefPathStorageAPI(teamId, opponentId, true));
    return imageRef.delete();
};
