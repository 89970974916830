/**
 * This class includes authentication related functions. For example, the signup function signs up
 * the user, creates a demo organization and then takes the user to sign up screen.
 */
import { getUIDFirebaseAPI, signInFirebaseAPI, signUpFirebaseAPI, sendEmailVerificationFirebaseAPI, isInitialized, signOutFirebaseAPI, changeEmailFirebaseAPI, changePasswordFirebaseAPI } from '../../firebase/FirebaseAuthenticationAPICalls';
import { ORGANIZATION_NOT_FOUND, NOT_VERIFIED_EMAIL, VERIFICATION_EMAIL_SENT, PASSWORD_VERIFICATION_EMAIL_SENT, USER_NOT_FOUND } from '../../catalog/NotificationComments';
import { insertOrganizationFirestoreAPI, fetchOrganizationFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { fetchTeamFirestoreAPI, fetchTeamUsingEmailFirestoreAPI, updateTeamFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { SportsType } from '../../catalog/enums/SportsType';
import { getCurrentDateTime } from '../../catalog/DateTime';
import { loginsAllowedBeforeEmailVerification, optimXDevelopment, optimXTesting, randomNumber } from '../../catalog/Others';
import { insertMailFirestoreAPI } from '../../firebase/firebaseAPICalls/FirebaseAPICallsMailerSendMail';
import { resetTeamAnalytics, sendCustomTeamAnalytics, sendCustomTeamAnalyticsWithTeamInfo, sendErrorTeamAnalytics, setTeamAnalytics } from '../../catalog/Analytics';
import { createSignUpMailObject } from '../../catalog/mail/SignUpEmail';
import { createSignUpClientMailObject } from '../../catalog/mail/SignUpClientEmail';
import { isValidObject } from '../../catalog/Validity';
import { dbIncrement } from '../../firebase/FirebaseLibrary';
import { AgeType } from '../../catalog/enums/AgeType';
import { createSignUpButtonPressedMailObject } from '../../catalog/mail/SignUpButtonPressedEmail';
import { signInEmailWebsiteAPI } from '../portal/website/WebsiteAPICalls';
import { MailerSendMailObject } from '../../catalog/interface/mailInterface/MailerSendMailInterface';

/**
 * This function signs out the user and redirects to the signin page.
 *
 * @param {*} history history is used for redirecting pages
 * @param {*} setGlobalState sets the state of the whole application
 * @param {*} snackbar used for notifications
 */
export const signOutAPICall = async (history: any, setGlobalState: Function, snackbar: Function): Promise<void> => {
    try {
        await signOutFirebaseAPI();
        history.replace('/');
        setGlobalState({ type: 'signOutUser' });
        resetTeamAnalytics();
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
        snackbar(error.message, { variant: 'error' });
    }
};

/**
 * This function is used for signing in the user provided that the email is verified and
 * the organization exists.
 *
 * @param {*} email this email is used for signing in.
 * @param {*} password this password is used for signing in
 * @param {*} history history is used for redirecting pages
 * @param {*} setState sets the state of the current page
 * @param {*} setGlobalState sets the state of the whole application
 * @param {*} snackbar used for notifications
 */
export const signInAPICall = async (email: string, password: string, history: any, setState: Function, setGlobalState: Function, snackbar: Function) => {
    try {
        const userInfo = await signInFirebaseAPI(email, password);

        if (isValidObject(userInfo) && isValidObject(userInfo.user) && userInfo !== null && userInfo.user !== null) {
            const accountId = userInfo.user.uid;
            const organizationDoc = await fetchOrganizationFirestoreAPI(accountId);
            const teamDoc = await fetchTeamFirestoreAPI(accountId);

            // Check if the organization document exists or not. If not then do not sign in the user.
            if (organizationDoc.exists) {
                setGlobalState({ type: 'signInOrganization' });
            } else if (teamDoc.exists) {
                // Checks if the email is verified. If not then do not sign in the user.
                if (teamDoc.data()?.loginCount > loginsAllowedBeforeEmailVerification && !userInfo?.user?.emailVerified) {
                    await signOutAPICall(history, setGlobalState, snackbar);
                    snackbar(NOT_VERIFIED_EMAIL, { variant: 'error' });
                    setState({ type: 'error' });
                } else {
                    setGlobalState({ type: 'signInTeam',
                        user: userInfo.user,
                        userEmail: email,
                        emailVerified: userInfo?.user?.emailVerified,
                        teamObject: teamDoc.data(),
                        sportsType: teamDoc.data()?.sportsType,
                    });
                    setTeamAnalytics(teamDoc.data()?.organizationId, teamDoc.data()?.organizationName, teamDoc.data()?.id, teamDoc.data()?.name, teamDoc.data()?.sportsType);
                    sendCustomTeamAnalyticsWithTeamInfo('signin', teamDoc.data()?.organizationId, teamDoc.data()?.organizationName, teamDoc.data()?.id, teamDoc.data()?.name, teamDoc.data()?.sportsType);
                    updateLoginCountAPICall(teamDoc, snackbar);
                    await signInEmailWebsiteAPI(teamDoc.data()?.organizationId, teamDoc.data()?.organizationName, teamDoc.data()?.id, teamDoc.data()?.name, teamDoc.data()?.sportsType, email, teamDoc.data()?.url, snackbar);
                    history.replace('/portal/dashboard');
                }
            } else {
                await signOutAPICall(history, setGlobalState, snackbar);
                snackbar(ORGANIZATION_NOT_FOUND, { variant: 'error' });
                setState({ type: 'error' });
            }
        } else {
            await signOutAPICall(history, setGlobalState, snackbar);
            snackbar(USER_NOT_FOUND, { variant: 'error' });
            setState({ type: 'error' });
        }
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
        await signOutAPICall(history, setGlobalState, snackbar);
        snackbar(error.message, { variant: 'error' });
        setState({ type: 'error' });
    }
};

/**
 * This function is used to check everytime the user comes back the web app if they are logged in.
 *
 * @param {*} history history is used for redirecting pages
 * @param {*} setGlobalState sets the state of the whole application
 * @param {*} snackbar used for notifications
 */
export const checkAuthentication = async (history: any, setGlobalState: Function, snackbar: Function) => {
    try {
        isInitialized().then(async (user: any) => {
            // If user is not verified then redirect to sign in page.
            if (user === null || user === undefined) {
                await signOutAPICall(history, setGlobalState, snackbar);
            } else if (isValidObject(user) && user !== false) {
                const accountId = user.uid;
                const { email } = user;
                const organizationDoc = await fetchOrganizationFirestoreAPI(accountId);
                const teamDoc = await fetchTeamFirestoreAPI(accountId);

                // Check if the organization document exists or not. If not then do not sign in the user.
                if (organizationDoc.exists) {
                    setGlobalState({ type: 'signInOrganization' });
                } else if (teamDoc.exists) {
                    if (teamDoc.data()?.loginCount > loginsAllowedBeforeEmailVerification && !user.emailVerified) {
                        await signOutAPICall(history, setGlobalState, snackbar);
                        snackbar(NOT_VERIFIED_EMAIL, { variant: 'error' });
                    } else {
                        setGlobalState({ type: 'signInTeam',
                            user,
                            userEmail: email,
                            emailVerified: user.emailVerified,
                            teamObject: teamDoc.data(),
                            sportsType: teamDoc.data()?.sportsType,
                        });
                        setTeamAnalytics(teamDoc.data()?.organizationId, teamDoc.data()?.organizationName, teamDoc.data()?.id, teamDoc.data()?.name, teamDoc.data()?.sportsType);
                        sendCustomTeamAnalyticsWithTeamInfo('signin_auto', teamDoc.data()?.organizationId, teamDoc.data()?.organizationName, teamDoc.data()?.id, teamDoc.data()?.name, teamDoc.data()?.sportsType);
                        updateLoginCountAPICall(teamDoc, snackbar);
                        await signInEmailWebsiteAPI(teamDoc.data()?.organizationId, teamDoc.data()?.organizationName, teamDoc.data()?.id, teamDoc.data()?.name, teamDoc.data()?.sportsType, email, teamDoc.data()?.url, snackbar);
                        if (history.location.pathname.split('/')[1] !== 'portal') {
                            history.push('/portal/dashboard');
                        }
                    }
                } else {
                    await signOutAPICall(history, setGlobalState, snackbar);
                    snackbar(ORGANIZATION_NOT_FOUND, { variant: 'error' });
                }
            }
        });
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
        await signOutAPICall(history, setGlobalState, snackbar);
        snackbar(error.message, { variant: 'error' });
    }
};

/**
 * This is used for testing purpose. To access someone's account for testing
 *
 * @param teamId
 * @param history
 * @param setState
 * @param setGlobalState
 * @param snackbar
 */
export const teamIdSignInAPICall = async (teamId: string, emailVerified: boolean, history: any, setState: Function, setGlobalState: Function, snackbar: Function) => {
    if (optimXDevelopment) {
        const teamDoc = await fetchTeamFirestoreAPI(teamId);
        if (isValidObject(teamDoc) && teamDoc.exists) {
            setGlobalState({ type: 'signInTeam',
                user: null,
                userEmail: teamDoc.data()?.email,
                emailVerified,
                teamObject: teamDoc.data(),
                sportsType: teamDoc.data()?.sportsType,
            });

            history.replace('/portal/dashboard');
        } else {
            snackbar('Invalid Team Id', { variant: 'error' });
        }
    }
};

/**
 * This is used for testing purpose. To access someone's account for testing
 *
 * @param teamId
 * @param history
 * @param setState
 * @param setGlobalState
 * @param snackbar
 */
export const teamEmailSignInAPICall = async (email: string, emailVerified: boolean, history: any, setState: Function, setGlobalState: Function, snackbar: Function) => {
    if (optimXDevelopment) {
        const docs = await fetchTeamUsingEmailFirestoreAPI(email);
        let teamDoc: any;
        docs.forEach((doc) => {
            if (doc.exists) {
                teamDoc = doc;
            }
        });
        if (isValidObject(teamDoc) && teamDoc.exists) {
            setGlobalState({ type: 'signInTeam',
                user: null,
                userEmail: teamDoc.data()?.email,
                emailVerified,
                teamObject: teamDoc.data(),
                sportsType: teamDoc.data()?.sportsType,
            });

            history.replace('/portal/dashboard');
        } else {
            snackbar('Invalid Team Email', { variant: 'error' });
        }
    }
};

/**
 * This function is used when the sign up button is pressed
 *
 * @param email
 * @param organizationName
 * @param teamName
 * @param sportsType
 * @param referralId
 */
export const signUpButtonPressedAPICall = (
    email: string,
    organizationName: string,
    teamName: string,
    sportsType: SportsType,
    referralId: string,
) => {
    try {
        const mailId1 = randomNumber();
        const mailObject1: MailerSendMailObject = createSignUpButtonPressedMailObject(mailId1, teamName, organizationName, sportsType, referralId, email);
        insertMailFirestoreAPI(mailObject1);
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
    }
};

/**
 * This function is used for signing up the user. After signing up, the user is logged out so that
 * they first verify the email and only then can enter the portal.
 *
 * @param email
 * @param password
 * @param organizationName
 * @param teamName
 * @param sportsType
 * @param referralId
 * @param history
 * @param setState
 * @param setGlobalState
 * @param snackbar
 */
export const signUpAPICall = async (
    email: string,
    password: string,
    organizationName: string,
    teamName: string,
    sportsType: SportsType,
    ageType: AgeType,
    referralId: string,
    history: any,
    setState: Function,
    setGlobalState: Function,
    snackbar: Function,
) => {
    try {
        const userInfo = await signUpFirebaseAPI(email, password);
        const teamId = getUIDFirebaseAPI();
        const mailId1 = randomNumber();
        const mailId2 = randomNumber();
        if (teamId) {
            await insertOrganizationFirestoreAPI(teamId, organizationName, email, teamName, referralId, sportsType, ageType);
            await sendEmailVerificationFirebaseAPI();
            const mailObject1: MailerSendMailObject = createSignUpMailObject(mailId1, teamId, teamName, organizationName, sportsType, referralId, email);
            await insertMailFirestoreAPI(mailObject1);
            const mailObject2: MailerSendMailObject = createSignUpClientMailObject(mailId2, teamId, teamName, sportsType, email);
            await insertMailFirestoreAPI(mailObject2);
            sendCustomTeamAnalyticsWithTeamInfo('signup', '', organizationName, teamId, teamName, sportsType);

            const signInTeam = async () => {
                // eslint-disable-next-line no-console
                console.log('Signing In Team');
                const teamDoc = await fetchTeamFirestoreAPI(teamId);
                if (teamDoc.exists) {
                    setGlobalState({ type: 'signInTeam',
                        user: userInfo.user,
                        userEmail: email,
                        emailVerified: userInfo?.user?.emailVerified,
                        teamObject: teamDoc.data(),
                        sportsType: teamDoc.data()?.sportsType,
                    });
                    snackbar(VERIFICATION_EMAIL_SENT, { variant: 'success' });
                    history.replace('/portal/dashboard');
                } else {
                    setTimeout(signInTeam, 2000);
                }
            };

            signInTeam();
        } else {
            sendErrorTeamAnalytics('Account was created but an error occurred while creating organization. Please contact OptimX Sports for further questions');
            snackbar('Account was created but an error occurred while creating organization. Please contact OptimX Sports for further questions', { variant: 'error' });
        }
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
        await signOutFirebaseAPI();
        history.replace('/');
        snackbar(error.message, { variant: 'error' });
        setState({ type: 'error' });
    }
};

/**
 * This function is used for changing the email id for the account
 *
 * @param {*} email new email to which it is changed
 * @param {*} history history is used for redirecting pages
 * @param {*} setState sets the state of the current page
 * @param {*} setGlobalState sets the state of the whole application
 * @param {*} snackbar used for notifications
 */
export const changeEmailAPICall = async (teamId: string, email: string, history: any, setState: Function, setGlobalState: Function, snackbar: Function) => {
    try {
        await changeEmailFirebaseAPI(email);
        await sendEmailVerificationFirebaseAPI();
        const teamTemp: any = {
            id: teamId,
            email,
            timeModified: getCurrentDateTime(),
        };
        await updateTeamFirestoreAPI(teamTemp);
        await signOutAPICall(history, setGlobalState, snackbar);
        sendCustomTeamAnalytics('reset_email');
        snackbar(VERIFICATION_EMAIL_SENT, { variant: 'success' });
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
        snackbar(error.message, { variant: 'error' });
        setState({ type: 'error' });
    }
};

/**
 * This function is used for sending an email for changing password
 *
 * @param {*} email email to which the password change will be sent
 * @param {*} history history is used for redirecting pages
 * @param {*} setState sets the state of the current page
 * @param {*} setGlobalState sets the state of the whole application
 * @param {*} snackbar used for notifications
 */
export const changePasswordAPICall = async (email: string, history: any, setGlobalState: Function, snackbar: Function) => {
    try {
        await changePasswordFirebaseAPI(email);
        await signOutAPICall(history, setGlobalState, snackbar);
        sendCustomTeamAnalytics('reset_password');
        snackbar(PASSWORD_VERIFICATION_EMAIL_SENT, { variant: 'success' });
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
        snackbar(error.message, { variant: 'error' });
    }
};

/**
 * This function is used for sending an verification email
 *
 * @param snackbar used for notifications
 */
export const resendEmailVerification = async (snackbar: Function) => {
    try {
        await sendEmailVerificationFirebaseAPI();
        sendCustomTeamAnalytics('resend_verification_email');
        snackbar(VERIFICATION_EMAIL_SENT, { variant: 'success' });
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
        snackbar(error.message, { variant: 'error' });
    }
};

const updateLoginCountAPICall = async (teamDoc: any, snackbar: Function) => {
    try {
        const team = teamDoc.data();

        let teamTemp: any = {
            id: team?.id,
        };

        if (isValidObject(team) && teamDoc.exists && isValidObject(team?.id)) {
            if (!isValidObject(team?.loginCount)) {
                teamTemp = { ...teamTemp, loginCount: 1 };
            } else if (!optimXTesting) {
                teamTemp = { ...teamTemp, loginCount: dbIncrement };
            }

            if (!optimXTesting) {
                teamTemp = { ...teamTemp, loginTime: getCurrentDateTime() };
            }
        }

        await updateTeamFirestoreAPI(teamTemp);
    } catch (error: any) {
        sendErrorTeamAnalytics(error.message);
        snackbar(error.message, { variant: 'error' });
    }
};
