/**
 * This function is used if emails and password entered are valid or not
 */
// eslint-disable-next-line no-unused-vars
import { NO_6_CHARACTERS_PASSWORD, NO_DIGIT_PASSWORD, NO_LETTER_PASSWORD, INVALID_EMAIL } from './NotificationComments';

/**
 * This function checks password
 *
 * @param {*} password this is the passwword that needs to be checked
 * @param {*} setState this is used for emptying the text field
 * @param {*} snackbar this is used for notifications
 */
export const checkPasswordValidity = (password: string, setState: Function, snackbar: Function) => {
    if (password.length < 6) {
        snackbar(NO_6_CHARACTERS_PASSWORD, { variant: 'error' });
        setState({ type: 'removePassword' });
        return false;
    }
    // if (!/\d/g.test(password)) {
    //     snackbar(NO_DIGIT_PASSWORD, { variant: 'error' });
    //     setState({ type: 'removePassword' });
    //     return false;
    // }
    if (!/[a-z]/i.test(password)) {
        snackbar(NO_LETTER_PASSWORD, { variant: 'error' });
        setState({ type: 'removePassword' });
        return false;
    }
    return true;
};

/**
 * This function checks email
 *
 * @param {*} email this is the passwword that needs to be checked
 * @param {*} setState this is used for emptying the text field
 * @param {*} snackbar this is used for notifications
 */
export const checkEmailValidity = (email: string, setState: Function, snackbar: Function) => {
    if (/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    }
    snackbar(INVALID_EMAIL, { variant: 'error' });
    setState({ type: 'error' });
    return false;
};

export const isValidBoolean = (object: any) => {
    if (object !== null && object !== undefined) {
        return true;
    }
    return false;
};

export const isValidObject = (object: any) => {
    if (object !== null && object !== undefined) {
        return true;
    }
    return false;
};

export const isValidString = (object: string) => {
    if (isValidObject(object) && object.length !== 0) {
        return true;
    }
    return false;
};

export const isValidId = (id: string) => {
    if (id === null || id === undefined || id.length === 0) {
        return false;
    }
    return true;
};

export const isNewEventId = (id: string) => {
    if (id === 'addevent' || id === 'newevent' || id === 'createevent') {
        return true;
    }
    return false;
};

export const isValidEventId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewEventId(id)) {
        return false;
    }
    return true;
};

export const isNewPracticeId = (id: string) => {
    if (id === 'addpractice' || id === 'newpractice' || id === 'createpractice') {
        return true;
    }
    return false;
};

export const isValidPracticeId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewPracticeId(id)) {
        return false;
    }
    return true;
};

export const isNewEventPracticeId = (id: string) => {
    if (id === 'addevent' || id === 'newevent' || id === 'createevent' || id === 'addpractice' || id === 'newpractice' || id === 'createpractice') {
        return true;
    }
    return false;
};

export const isValidEventPracticeId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewPracticeId(id)) {
        return false;
    }
    if (isNewEventId(id)) {
        return false;
    }
    return true;
};

export const isNewArticleId = (id: string) => {
    if (id === 'addarticle' || id === 'newarticle' || id === 'createarticle') {
        return true;
    }
    return false;
};

export const isValidArticleId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewArticleId(id)) {
        return false;
    }
    return true;
};

export const isNewGalleryId = (id: string) => {
    if (id === 'addgallery' || id === 'newgallery' || id === 'creategallery') {
        return true;
    }
    return false;
};

export const isValidGalleryId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewGalleryId(id)) {
        return false;
    }
    return true;
};

export const isNewCoachId = (id: string) => {
    if (id === 'addcoach' || id === 'newcoach' || id === 'createcoach') {
        return true;
    }
    return false;
};

export const isValidCoachId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewCoachId(id)) {
        return false;
    }
    return true;
};

export const isNewStaffId = (id: string) => {
    if (id === 'addexecutiveboard' || id === 'newexecutiveboard' || id === 'createexecutiveboard') {
        return true;
    }
    return false;
};

export const isValidStaffId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewStaffId(id)) {
        return false;
    }
    return true;
};

export const isNewPlayerId = (id: string) => {
    if (id === 'addplayer' || id === 'newplayer' || id === 'createplayer' || id === 'addalumni' || id === 'newalumni' || id === 'createalumni') {
        return true;
    }
    return false;
};

export const isValidPlayerId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewPlayerId(id)) {
        return false;
    }
    return true;
};

export const isNewLeagueId = (id: string) => {
    if (id === 'addleague' || id === 'newleague' || id === 'createleague') {
        return true;
    }
    return false;
};

export const isValidLeagueId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewLeagueId(id)) {
        return false;
    }
    return true;
};

export const isNewSeasonId = (id: string) => {
    if (id === 'addseason' || id === 'newseason' || id === 'createseason') {
        return true;
    }
    return false;
};

export const isValidSeasonId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewSeasonId(id)) {
        return false;
    }
    return true;
};

export const isNewGameId = (id: string) => {
    if (id === 'addgame' || id === 'newgame' || id === 'creategame') {
        return true;
    }
    return false;
};

export const isValidGameId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewGameId(id)) {
        return false;
    }
    return true;
};

export const isNewSponsorId = (id: string) => {
    if (id === 'addsponsor' || id === 'newsponsor' || id === 'createsponsor') {
        return true;
    }
    return false;
};

export const isValidSponsorId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewSponsorId(id)) {
        return false;
    }
    return true;
};

export const isNewOpponentId = (id: string) => {
    if (id === 'addopponent' || id === 'newopponent' || id === 'createopponent') {
        return true;
    }
    return false;
};

export const isValidOpponentId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewOpponentId(id)) {
        return false;
    }
    return true;
};

export const isNewCustomContentId = (id: string) => {
    if (id === 'addcustom' || id === 'newcustom' || id === 'createcustom' || id === 'addcustomcontent' || id === 'newcustomcontent' || id === 'createcustomcontent' || id === 'addcustompage' || id === 'newcustompage' || id === 'createcustompage' || id === 'addcustomform' || id === 'newcustomform' || id === 'createcustomform') {
        return true;
    }
    return false;
};

export const isValidCustomId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewCustomContentId(id)) {
        return false;
    }
    return true;
};

export const emailVerificationCompleted = (globalState: any) => {
    if (!globalState.userLogged) {
        return true;
    }
    if (isValidBoolean(globalState.emailVerified)) {
        return globalState.emailVerified;
    }
    return false;
};

export const isNewTicketId = (id: string) => {
    if (id.startsWith('addticket') || id.startsWith('newticket') || id.startsWith('createticket')) {
        return true;
    }
    return false;
};

export const isNewProductId = (id: string) => {
    if (id.startsWith('addproduct') || id.startsWith('newproduct') || id.startsWith('createproduct')) {
        return true;
    }
    return false;
};

export const isValidTicketId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewTicketId(id)) {
        return false;
    }
    return true;
};

export const isValidProductId = (id: string) => {
    if (!isValidId(id)) {
        return false;
    }
    if (isNewProductId(id)) {
        return false;
    }
    return true;
};
