import { getDateStringWithMonthsFromDate, getLocalTimeFromDatabaseTime } from './DateTime';
import { WebsiteType } from './enums/WebsiteType';
import { optimXDevelopment, optimXTesting } from './Others';
import { isValidObject, isValidString } from './Validity';

export const getProductId = (website: boolean, domain: boolean, email: boolean): string => {
    if (website) {
        return 'prod_KtW1YTss1rDMvU';
    }
    if (domain) {
        return 'prod_KtW16FPUPQagy7';
    }
    if (email) {
        return 'prod_KtW126Kuikem60';
    }
    return '';
};

export const getPriceId = (website: boolean, domain: boolean, email: boolean, monthly: boolean): string => {
    if (website) {
        if (monthly) {
            return 'price_1KDiz8Ho1LnNEW1wyNghYfIg';
        }
        return 'price_1KDiz8Ho1LnNEW1wBBcxMExO';
    }
    if (domain) {
        if (monthly) {
            return 'price_1KDizaHo1LnNEW1wxFS9DXgJ';
        }
        return 'price_1KDizaHo1LnNEW1wMd31R4wt';
    }
    if (email) {
        if (monthly) {
            return 'price_1KDizhHo1LnNEW1wk2xnGnnC';
        }
        return 'price_1KDizhHo1LnNEW1wYEfQXDKl';
    }
    return '';
};

export const getRedirectLink = (endpoint: string) => {
    if (optimXDevelopment) {
        return `http://localhost:3000/${endpoint}`;
    } if (optimXTesting) {
        return `https://optimx-sports-portal.web.app/${endpoint}`;
    }
    return `https://optimxsports.com/${endpoint}`;
};

export const startSubscriptionMessage = (websiteSubscriptionActive: boolean, websiteSubscriptionExists: boolean, domainSubscriptionActive: boolean, domainSubscriptionExists: boolean, subscriptionInfo: any, websiteType: WebsiteType): string => {
    if (websiteType === WebsiteType.STORE) {
        return '';
    }
    let websiteSubscriptionNeeded = false;
    let domainSubscriptionNeeded = false;
    if (subscriptionInfo.websiteCharge && !websiteSubscriptionActive) {
        websiteSubscriptionNeeded = true;
    }
    if (subscriptionInfo.domain && subscriptionInfo.domainCharge && isValidString(subscriptionInfo.domainURL) && !domainSubscriptionActive) {
        domainSubscriptionNeeded = true;
    }
    if (websiteSubscriptionNeeded && domainSubscriptionNeeded) {
        if (subscriptionInfo.websiteTrial && !websiteSubscriptionExists && !domainSubscriptionExists) {
            if (isValidObject(subscriptionInfo.websiteTrialValidity)) {
                return `Your free trial is valid till ${getDateStringWithMonthsFromDate(getLocalTimeFromDatabaseTime(subscriptionInfo.websiteTrialValidity))}. Please start your subscription before this date to make sure your website remains active.`;
            }
            return 'A Valid Website and Domain Subscription is required before your website can be published. Your 1 month free trial for your website will start as soon as your website subscription starts.';
        }
        return 'A Valid Website and Domain Subscription is required before your website can be activated.';
    }
    if (websiteSubscriptionNeeded) {
        if (subscriptionInfo.websiteTrial && !websiteSubscriptionExists) {
            if (isValidObject(subscriptionInfo.websiteTrialValidity)) {
                return `Your free trial is valid till ${getDateStringWithMonthsFromDate(getLocalTimeFromDatabaseTime(subscriptionInfo.websiteTrialValidity))}. Please start your subscription before this date to make sure your website remains active.`;
            }
            return 'A Valid Website Subscription is required before your website can be published. Your 1 month free trial for your website will start as soon as your website subscription starts.';
        }
        return 'A Valid Website Subscription is required before your website can be published.';
    }
    if (domainSubscriptionNeeded) {
        return 'A Valid Domain Subscription is required before your website can be activated.';
    }
    return '';
};
