import { CoachObject } from '../../catalog/interface/CoachInterface';
import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { isValidString } from '../../catalog/Validity';
import { db, storageRef } from '../FirebaseLibrary';

export const createCoachesReference = () => {
    return db.collection('coaches');
};

export const createCoachReference = (coachId: string) => {
    return createCoachesReference().doc(coachId);
};

export const fetchCoachFirestoreAPI = (coachId: string) => {
    return createCoachReference(coachId).get();
};

export const insertCoachFirestoreAPI = (coachObject: CoachObject) => {
    return createCoachReference(coachObject.id).set(coachObject);
};

export const updateCoachFirestoreAPI = (coachObject: any) => {
    return createCoachReference(coachObject.id).update(coachObject);
};

export const deleteCoachFirestoreAPI = (coachId: string) => {
    return createCoachReference(coachId).delete();
};

export const fetchCoachesFirestoreAPI = (teamId: string) => {
    return createCoachesReference().where('teamId', '==', teamId).where('deleted', '==', false).orderBy('order', 'desc')
        .orderBy('timeCreated')
        .get();
};

export const fetchCoachesWithoutOrderFirestoreAPI = (teamId: string) => {
    return createCoachesReference().where('teamId', '==', teamId).where('deleted', '==', false)
        .orderBy('timeCreated')
        .get();
};

export const fetchCoachesIncludingDeletedFirestoreAPI = (teamId: string) => {
    return createCoachesReference().where('teamId', '==', teamId).get();
};

export const updateCoachesOrderFirestoreAPI = (coachObjects: [any]) => {
    const batch = db.batch();

    coachObjects.forEach((coachObject, index) => {
        if (isValidString(coachObject.id)) {
            batch.update(createCoachReference(coachObject.id), { order: coachObjects.length - index - 1 });
        }
    });

    return batch.commit();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createCoachProfileImageRefPathStorageAPI = (coachId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `coaches/${coachId}/profileImage/profileImage_1600x900.png`;
    }
    return `coaches/${coachId}/profileImage/profileImage.png`;
};

export const insertCoachProfileImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, coachId: string, coachName: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: coachId,
            title: coachName,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createCoachProfileImageRefPathStorageAPI(coachId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deleteCoachProfileImageStorageAPI = (coachId: string) => {
    const imageRef = storageRef.child(createCoachProfileImageRefPathStorageAPI(coachId, true));
    return imageRef.delete();
};
