import React, { memo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { checkAuthentication } from '../../authentication/AuthenticationAPICalls';
import { context } from '../../../globalState/GlobalState';

const AuthenticationCheck = (props) => {
    const { children } = props;
    const history = useHistory();
    const { setGlobalState } = useContext(context);
    const { enqueueSnackbar } = useSnackbar();

    const AUTHENTICATION_CHECK_URL_SEGMENTS = ['', 'portal'];

    useEffect(() => {
        const firstSegment = history.location.pathname.split('/')[1];
        if (AUTHENTICATION_CHECK_URL_SEGMENTS.includes(firstSegment)) {
            checkAuthentication(history, setGlobalState, enqueueSnackbar);
        }
    }, [history]);

    return (
        <>
            {children}
        </>
    );
};

AuthenticationCheck.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(AuthenticationCheck);
