import { SportsType } from '../../enums/SportsType';
import { TeamSilhouetteLogoObject } from '../../interface/TeamInterface';

export const getDemoSilhouetteTeamLogo = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo_1600x900.png?alt=media&token=1f7f08e4-dab9-4dff-a3dc-ad37857c1b8b';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo_1600x900.png?alt=media&token=1f7f08e4-dab9-4dff-a3dc-ad37857c1b8b';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo_1600x900.png?alt=media&token=1f7f08e4-dab9-4dff-a3dc-ad37857c1b8b';
    }
    return '';
};
export const getDemoSilhouetteTeamLogo32 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo32_1600x900.png?alt=media&token=9f41e7a3-b93e-4b33-83dc-2ceeac95c299';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo32_1600x900.png?alt=media&token=9f41e7a3-b93e-4b33-83dc-2ceeac95c299';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo32_1600x900.png?alt=media&token=9f41e7a3-b93e-4b33-83dc-2ceeac95c299';
    }
    return '';
};
export const getDemoSilhouetteTeamLogo196 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo196_1600x900.png?alt=media&token=ac08606c-be01-4bad-b50a-8c7c518fb1f5';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo196_1600x900.png?alt=media&token=ac08606c-be01-4bad-b50a-8c7c518fb1f5';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo196_1600x900.png?alt=media&token=ac08606c-be01-4bad-b50a-8c7c518fb1f5';
    }
    return '';
};
export const getDemoSilhouetteTeamLogo512 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo512_1600x900.png?alt=media&token=dc59ab9f-6f16-438d-988a-4671523eaae3';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo512_1600x900.png?alt=media&token=dc59ab9f-6f16-438d-988a-4671523eaae3';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo512_1600x900.png?alt=media&token=dc59ab9f-6f16-438d-988a-4671523eaae3';
    }
    return '';
};
export const getDemoSilhouetteTeamLogo1024 = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo1024_1600x900.png?alt=media&token=3251a900-ca6d-42e6-bfcd-d8f54aa811b8';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo1024_1600x900.png?alt=media&token=3251a900-ca6d-42e6-bfcd-d8f54aa811b8';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamLogo%2FdemoTeamSilhouetteLogo1024_1600x900.png?alt=media&token=3251a900-ca6d-42e6-bfcd-d8f54aa811b8';
    }
    return '';
};

export const getDemoTeamSilhouetteLogoObject = (sportsType: SportsType): TeamSilhouetteLogoObject => {
    return {
        logo: getDemoSilhouetteTeamLogo(sportsType),
        logo1: getDemoSilhouetteTeamLogo32(sportsType),
        logo2: getDemoSilhouetteTeamLogo196(sportsType),
        logo3: getDemoSilhouetteTeamLogo512(sportsType),
        logo4: getDemoSilhouetteTeamLogo1024(sportsType),
    };
};
