import { FormSubmittedObject } from '../../catalog/interface/FormSubmittedInterface';
import { db } from '../FirebaseLibrary';

export const createFormsSubmittedReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/formsSubmitted`);
};

export const createFormSubmittedReference = (teamId: string, formId: string) => {
    return createFormsSubmittedReference(teamId).doc(formId);
};

export const fetchFormSubmittedFirestoreAPI = (teamId: string, formId: string) => {
    return createFormSubmittedReference(teamId, formId).get();
};

export const fetchFormsSubmittedFirestoreAPI = (teamId: string) => {
    return createFormsSubmittedReference(teamId)
        .where('deleted', '==', false)
        .orderBy('timeCreated', 'desc')
        .get();
};

export const fetchFormsSubmittedByDateFirestoreAPI = (teamId: string, startDate: Date, endDate: Date) => {
    return createFormsSubmittedReference(teamId)
        .where('deleted', '==', false)
        .where('timeCreated', '>=', startDate)
        .where('timeCreated', '<=', endDate)
        .orderBy('timeCreated', 'desc')
        .get();
};

export const fetchFormsSubmittedByFormIdFirestoreAPI = (teamId: string, formId: string) => {
    return createFormsSubmittedReference(teamId)
        .where('formId', '==', formId)
        .where('deleted', '==', false)
        .orderBy('timeCreated', 'desc')
        .get();
};

export const fetchFormsSubmittedByFormIdDateFirestoreAPI = (teamId: string, formId: string, startDate: Date, endDate: Date) => {
    return createFormsSubmittedReference(teamId)
        .where('formId', '==', formId)
        .where('deleted', '==', false)
        .where('timeCreated', '>=', startDate)
        .where('timeCreated', '<=', endDate)
        .orderBy('timeCreated', 'desc')
        .get();
};

export const fetchFormsSubmittedIncludingDeletedFirestoreAPI = (teamId: string) => {
    return createFormsSubmittedReference(teamId)
        .orderBy('timeCreated', 'desc')
        .get();
};

export const insertFormSubmittedFirestoreAPI = (teamId: string, formObject: FormSubmittedObject) => {
    return createFormSubmittedReference(teamId, formObject.id).set(formObject);
};

export const updateFormSubmittedFirestoreAPI = (teamId: string, formObject: any) => {
    return createFormSubmittedReference(teamId, formObject.id).update(formObject);
};

export const deleteFormSubmittedFirestoreAPI = (teamId: string, formId: string) => {
    return createFormSubmittedReference(teamId, formId).delete();
};
