import { SportsType } from '../../catalog/enums/SportsType';
import { ArticleObject } from '../../catalog/interface/ArticleInterface';
import { GalleryObject } from '../../catalog/interface/GalleryInterface';
import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { PhotoObject } from '../../catalog/interface/PhotoInterface';
import { isValidObject } from '../../catalog/Validity';
import { db, storageRef } from '../FirebaseLibrary';
import { createGalleryReference } from './FirebaseAPICallsGallery';
import { createPhotoReference } from './FirebaseAPICallsPhoto';

export const createArticlesReference = () => {
    return db.collection('articles');
};

export const createArticleReference = (articleId: string) => {
    return createArticlesReference().doc(articleId);
};

export const fetchArticleFirestoreAPI = (articleId: string) => {
    return createArticleReference(articleId).get();
};

export const insertArticleFirestoreAPI = (articleObject: ArticleObject) => {
    return createArticleReference(articleObject.id).set(articleObject);
};

export const insertArticleAndGalleryFirestoreAPI = (articleObject: ArticleObject, galleryObject: GalleryObject, photoObjects: PhotoObject[]) => {
    const batch = db.batch();

    photoObjects.forEach((photoObject) => {
        batch.set(createPhotoReference(photoObject.id), photoObject);
    });
    batch.set(createGalleryReference(galleryObject.id), galleryObject);
    batch.set(createArticleReference(articleObject.id), articleObject);

    return batch.commit();
};

export const updateArticleFirestoreAPI = (articleObject: any) => {
    return createArticleReference(articleObject.id).update(articleObject);
};

export const updateArticleAndGalleryFirestoreAPI = (articleObject: any, galleryObject: any, photoObjects: any[]) => {
    const batch = db.batch();

    photoObjects.forEach((photoObject) => {
        if (isValidObject(photoObject.reference)) {
            batch.set(createPhotoReference(photoObject.id), photoObject);
        } else {
            batch.update(createPhotoReference(photoObject.id), photoObject);
        }
    });
    batch.update(createGalleryReference(galleryObject.id), galleryObject);
    batch.update(createArticleReference(articleObject.id), articleObject);

    return batch.commit();
};

export const deleteArticleFirestoreAPI = (articleId: string) => {
    return createArticleReference(articleId).delete();
};

export const deleteArticleAndGalleryFirestoreAPI = (articleId: string, galleryId: string) => {
    const articleRef = createArticleReference(articleId);
    const galleryRef = createGalleryReference(galleryId);
    return db.runTransaction(async (transaction) => {
        const galleryDoc: any = await transaction.get(galleryRef);
        if (galleryDoc.exists && isValidObject(galleryDoc.data())) {
            const { photoIds } = galleryDoc.data();
            await Promise.all(photoIds.map(async (photoId: any) => {
                transaction.delete(createPhotoReference(photoId));
            }));
            transaction.delete(galleryRef);
            transaction.delete(articleRef);
        }
    });
};

export const fetchAllArticlesFirestoreAPI = () => {
    return createArticlesReference().get();
};

export const fetchArticlesFirestoreAPI = (teamId: string) => {
    return createArticlesReference().where('teamId', '==', teamId).orderBy('timeOfArticle', 'desc').get();
};

export const fetchArticlesByTagFirestoreAPI = (teamId: string, tagId: string, sportsType: SportsType) => {
    return createArticlesReference().where('teamId', '==', teamId)
        .where('tagIds', 'array-contains', tagId)
        .where('sportsType', '==', sportsType)
        .orderBy('timeOfArticle', 'desc')
        .get();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createArticleFeatureImageRefPathStorageAPI = (articleId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `articles/${articleId}/featureImage/featureImage_1600x900.png`;
    }
    return `articles/${articleId}/featureImage/featureImage.png`;
};

export const insertArticleFeatureImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, articleId: string, articleTitle: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: articleId,
            title: articleTitle,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createArticleFeatureImageRefPathStorageAPI(articleId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deleteArticleFeatureImageStorageAPI = (articleId: string) => {
    const imageRef = storageRef.child(createArticleFeatureImageRefPathStorageAPI(articleId, true));
    return imageRef.delete();
};
