import { getSportsTypePronoun } from './enums/SportsType';
import { isValidObject, isValidString } from './Validity';

export const getPlayerInfo = (details: any[], infoTitle: string): string => {
    let info = '';
    details.forEach((detail) => {
        if (detail.title === infoTitle) {
            info = detail.value;
        }
    });
    return info;
};

export const getPlayerSummary = (name: string, alumni: boolean, details: any[], globalState: any) => {
    const pronoun = getSportsTypePronoun(globalState.sportsType);
    const teamAlias = isValidObject(globalState.info) && isValidString(globalState.info.alias) ? globalState.info.alias : globalState.name;
    const position = getPlayerInfo(details, 'Position');
    const teamNumber = getPlayerInfo(details, 'Team Number');
    const previousTeam = getPlayerInfo(details, 'Previous Team');
    const hometown = getPlayerInfo(details, 'Hometown');
    const year = getPlayerInfo(details, 'Year');

    if (alumni) {
        let summary = `${name} was a ${position} for ${teamAlias}. `;

        if (isValidString(teamNumber)) {
            summary += `${name} represented the ${teamAlias} wearing jersey number ${teamNumber}. `;
        }

        if (isValidString(previousTeam)) {
            summary += `Before playing for the ${teamAlias} ${pronoun.toLowerCase()} previously played for ${previousTeam}. `;
        }

        return summary;
    }

    let summary = `${name} is a ${position} for ${teamAlias}. `;

    if (isValidString(hometown) && isValidString(year)) {
        summary += `${getSportsTypePronoun(globalState.sportsType)} is from ${hometown} and is part of the ${year} class. `;
    } else if (isValidString(hometown)) {
        summary += `${getSportsTypePronoun(globalState.sportsType)} is from ${hometown}. `;
    } else if (isValidString(year)) {
        summary += `${getSportsTypePronoun(globalState.sportsType)} is part of the ${year} class on the team. `;
    }

    if (isValidString(teamNumber)) {
        summary += `${name} will be representing the ${teamAlias} wearing jersey number ${teamNumber} this year. `;
    }

    if (isValidString(previousTeam)) {
        summary += `${getSportsTypePronoun(globalState.sportsType)} previously played for ${previousTeam}. `;
    }

    return summary;
};

export const getCoachSummary = (name: string, alumni: boolean, details: any[], globalState: any) => {
    const tense = alumni ? 'was' : 'is';
    const teamAlias = isValidObject(globalState.info) && isValidString(globalState.info.alias) ? globalState.info.alias : globalState.name;
    const hometown = getPlayerInfo(details, 'Hometown');
    const title = getPlayerInfo(details, 'Title');

    let summary = `${name} ${tense} the ${title} for the ${teamAlias}. `;

    if (isValidString(hometown)) {
        summary += `${name} is from ${hometown}. `;
    }

    return summary;
};

export const getReturnPolicy = () => {
    return 'All sales are final. No exchanges or returns are available.';
};

export const getShippingPolicy = () => {
    return 'Expect 2-3 days before orders are shipped. For jerseys, please be mindful of the 7-10 week manufacturing period. You can also collect jerseys during games.';
};

export const getPrivacyPolicyLink = () => {
    return 'https://optimxtechnologies.com/teamprivacypolicy';
};

export const getTermsLink = () => {
    return 'https://optimxtechnologies.com/termsofservice';
};
