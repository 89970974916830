import { createGameCoachReference } from '../../../../firebase/firebaseAPICalls/FirebaseAPICallsGameCoach';
import { SportsType } from '../../../enums/SportsType';
import { TagType } from '../../../enums/TagType';
import { RugbyGameObject, RugbyGamePromotionalVideoObject } from './RugbyGameInterface';

export interface RugbyGameCoachObject {
    id: string,
    reference: any,
    teamId: string,
    teamReference: any,
    organizationId: string,
    organizationReference: any,
    gameId: string,
    gameReference: any,
    seasonId: string,
    seasonReference: string,
    preDevelopedSeasonId: string,
    preDevelopedSeasonReference: any,
    leagueId: string,
    leagueReference: any,
    preDevelopedLeagueId: string,
    preDevelopedLeagueReference: any,
    preDevelopedConferenceId: string,
    preDevelopedConferenceReference: string,
    opponentId: string,
    opponentReference: any,
    winOrLoss: 'win' | 'loss' | 'tied'
    gameScore: string,
    title: string,
    sportsType: SportsType,
    homeGame: boolean,
    status: 'scheduled' | 'postponed' | 'finished' | 'tba' | 'tbat' | 'cancelled',
    timeOfGame: Date,
    venue: string,
    attendance: number,
    featureImage: string,
    promotionalText: string,
    promotionalVideo: RugbyGamePromotionalVideoObject,
    notes: string,
    timeModified: Date,
    timeCreated: Date,
}

export const getCoachIdsFromRugbyGameObject = (gameObject: RugbyGameObject) => {
    const coachIds: Set<string> = new Set();
    gameObject.tagIds.forEach((tagId, index) => {
        if (gameObject.tagTypes[index] === TagType.COACH) {
            coachIds.add(tagId);
        }
    });

    return Array.from(coachIds);
};

export const getRugbyGameCoachObjectFromRugbyGameObject = (gameObject: RugbyGameObject, coachId: string): RugbyGameCoachObject => {
    const coachReference = createGameCoachReference(gameObject.id, coachId);

    return {
        id: coachId,
        reference: coachReference,
        teamId: gameObject.teamId,
        teamReference: gameObject.teamReference,
        organizationId: gameObject.organizationId,
        organizationReference: gameObject.organizationReference,
        gameId: gameObject.id,
        gameReference: gameObject.reference,
        seasonId: gameObject.seasonId,
        seasonReference: gameObject.seasonReference,
        preDevelopedSeasonId: gameObject.preDevelopedSeasonId,
        preDevelopedSeasonReference: gameObject.preDevelopedSeasonReference,
        leagueId: gameObject.leagueId,
        leagueReference: gameObject.leagueReference,
        preDevelopedLeagueId: gameObject.preDevelopedLeagueId,
        preDevelopedLeagueReference: gameObject.preDevelopedLeagueReference,
        preDevelopedConferenceId: gameObject.preDevelopedConferenceId,
        preDevelopedConferenceReference: gameObject.preDevelopedConferenceReference,
        opponentId: gameObject.opponentId,
        opponentReference: gameObject.opponentReference,
        winOrLoss: gameObject.winOrLoss,
        gameScore: gameObject.gameScore,
        title: gameObject.title,
        sportsType: gameObject.sportsType,
        homeGame: gameObject.homeGame,
        status: gameObject.status,
        timeOfGame: gameObject.timeOfGame,
        venue: gameObject.venue,
        attendance: gameObject.attendance,
        featureImage: gameObject.featureImage,
        promotionalText: gameObject.promotionalText,
        promotionalVideo: gameObject.promotionalVideo,
        notes: gameObject.notes,
        timeModified: gameObject.timeModified,
        timeCreated: gameObject.timeCreated,
    };
};
