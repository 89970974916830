import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { PlayerObject } from '../../catalog/interface/PlayerInterface';
import { db, storageRef } from '../FirebaseLibrary';

export const createPlayersReference = () => {
    return db.collection('players');
};

export const createPlayerReference = (playerId: string) => {
    return createPlayersReference().doc(playerId);
};

export const fetchPlayerFirestoreAPI = (playerId: string) => {
    return createPlayerReference(playerId).get();
};

export const insertPlayerFirestoreAPI = (playerObject: PlayerObject) => {
    return createPlayerReference(playerObject.id).set(playerObject);
};

export const updatePlayerFirestoreAPI = (playerObject: any) => {
    return createPlayerReference(playerObject.id).update(playerObject);
};

export const deletePlayerFirestoreAPI = (playerId: string) => {
    return createPlayerReference(playerId).delete();
};

export const fetchAllPlayersFirestoreAPI = () => {
    return createPlayersReference().where('deleted', '==', false).get();
};

export const fetchPlayersAndAlumniIncludingDeletedFirestoreAPI = (teamId: string) => {
    return createPlayersReference().where('teamId', '==', teamId)
        // .orderBy('timeCreated', 'desc')
        .orderBy('name')
        .get();
};

export const fetchPlayersAndAlumniFirestoreAPI = (teamId: string) => {
    return createPlayersReference().where('teamId', '==', teamId).where('deleted', '==', false)
        // .orderBy('timeCreated', 'desc')
        .orderBy('name')
        .get();
};

export const fetchPlayersFirestoreAPI = (teamId: string) => {
    return createPlayersReference().where('teamId', '==', teamId).where('alumni', '==', false).where('deleted', '==', false)
        // .orderBy('timeCreated', 'desc')
        .orderBy('name')
        .get();
};

export const fetchAlumniFirestoreAPI = (teamId: string) => {
    return createPlayersReference().where('teamId', '==', teamId).where('alumni', '==', true).where('deleted', '==', false)
        // .orderBy('timeCreated', 'desc')
        .orderBy('name')
        .get();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createPlayerProfileImageRefPathStorageAPI = (playerId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `players/${playerId}/profileImage/profileImage_1600x900.png`;
    }
    return `players/${playerId}/profileImage/profileImage.png`;
};

export const createPlayerFeatureImageRefPathStorageAPI = (playerId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `players/${playerId}/featureImage/featureImage_1600x900.png`;
    }
    return `players/${playerId}/featureImage/featureImage.png`;
};

export const insertPlayerProfileImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, playerId: string, playerName: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: playerId,
            title: playerName,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createPlayerProfileImageRefPathStorageAPI(playerId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const insertPlayerFeatureImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, playerId: string, playerName: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: playerId,
            title: playerName,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createPlayerFeatureImageRefPathStorageAPI(playerId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deletePlayerProfileImageStorageAPI = (playerId: string) => {
    const imageRef = storageRef.child(createPlayerProfileImageRefPathStorageAPI(playerId, true));
    return imageRef.delete();
};

export const deletePlayerFeatureImageStorageAPI = (playerId: string) => {
    const imageRef = storageRef.child(createPlayerFeatureImageRefPathStorageAPI(playerId, true));
    return imageRef.delete();
};
