import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Button1 from '../buttons/button1/Button1';
import Button2 from '../buttons/button2/Button2';
import Button3 from '../buttons/button3/Button3';
import { ButtonType } from '../buttons/ButtonType.ts';
import Button4 from '../buttons/button4/Button4';

const NavItems = (props) => {
    const { navItems } = props;

    const findButton = (item) => {
        if (item.buttonType === ButtonType.Button1) {
            return <Button1 title={item.title} />;
        } if (item.buttonType === ButtonType.Button2) {
            return <Button2 title={item.title} />;
        } if (item.buttonType === ButtonType.Button4) {
            return <Button4 title={item.title} transparent fontSize="1rem" backgroundColor="#ffffff" fontColor="#000000" borderColor="#ffffff" borderRadius="5px" fontWeight="300" height="40px" borderWidth="1px" width="150px" />;
        }
        return <Button3 title={item.title} />;
    };

    return (
        <div className="nav-items-normal">
            {navItems.map((item) => {
                return (
                    <NavLink key={item.title} className="text-no-underline m-l-10px m-r-15px" to={item.link} exact={item.exact}>
                        {findButton(item)}
                    </NavLink>
                );
            })}
        </div>
    );
};

NavItems.propTypes = {
    navItems: PropTypes.array.isRequired,
};

export default (memo(NavItems));
