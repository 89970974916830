/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useContext } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/NavBar.css';
import { ButtonType } from '../buttons/ButtonType.ts';
import NavItems from './NavItems';
import DropDownNavItems from './DropDownNavItems';
import OptimxGreenIcon from '../../../assets/icons/optimxGreenIcon.png';
import OptimxNameIcon from '../../../assets/icons/optimxNameIcon.png';
import { context } from '../../../globalState/GlobalState';
import { referralPrice } from '../../../catalog/Others';

const NavBar2 = () => {
    const history = useHistory();
    const params = useParams();
    const { globalState } = useContext(context);

    const PORTAL_URL_SEGMENTS = ['portal'];

    useEffect(() => {
    }, [history, params]);

    const firstSegment = history.location.pathname.split('/')[1];

    const settingsDesktop = {
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        infinite: true,
        slidesToShow: 1,
        draggable: false,
        swipeToSlide: false,
        centerMode: true,
    };

    return (
        <>
            {PORTAL_URL_SEGMENTS.includes(firstSegment) ? (
                <div className="navbar-container2">
                    <Slider {...settingsDesktop}>
                        <div className="m-t-10px" onClick={() => { history.push('/portal/marketplace'); }}>
                            <p className="text-uppercase x-center font-weight-800 m-t-0 m-b-0">Buy sports gear at incredible low prices. Click Here to start!</p>
                        </div>
                        <div className="m-t-10px" onClick={() => { history.push('/portal/dashboard/partner/hgps'); }}>
                            <p className="text-uppercase x-center font-weight-800 m-t-0 m-b-0">{`Get ${globalState.teamName} Merch and start a 24x7 shop for your fans. Click Here to start!`}</p>
                        </div>
                        <div className="m-t-10px" onClick={() => { history.push('/portal/store'); }}>
                            <p className="text-uppercase x-center font-weight-800 m-t-0 m-b-0">Now sell merchandise, tickets and collect dues. Click Here to start!</p>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </>
    );
};

export default memo(NavBar2);
