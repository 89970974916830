import { db } from '../FirebaseLibrary';
import { documentIdWithRandomNumber, randomNumber } from '../../catalog/Others';
import { createDemoOrganization } from '../../catalog/demo/OrganizationDemo';
import { createDemoTeam } from '../../catalog/demo/team/TeamDemo';
import { createDemoPlayer, demoPlayerName } from '../../catalog/demo/PlayerDemo';
import { SportsType } from '../../catalog/enums/SportsType';
import { createDemoOpponent, demoOpponentName } from '../../catalog/demo/OpponentDemo';
import { createDemoLeague } from '../../catalog/demo/LeagueDemo';
import { createDemoCoach, demoCoachName } from '../../catalog/demo/CoachDemo';
import { createDemoSponsor, demoSponsorName } from '../../catalog/demo/SponsorDemo';
import { createDemoArticle, createDemoGameArticle, demoArticleTitle, createArticleTitleForGame, createArticleTitleForEvent, createDemoEventArticle } from '../../catalog/demo/ArticleDemo';
import { createTeamReference } from './FirebaseAPICallsTeam';
import { createSponsorReference } from './FirebaseAPICallsSponsor';
import { createOpponentReference } from './FirebaseAPICallsOpponent';
import { createLeagueReference } from './FirebaseAPICallsLeague';
import { createPlayerReference } from './FirebaseAPICallsPlayer';
import { createCoachReference } from './FirebaseAPICallsCoach';
import { createArticleReference } from './FirebaseAPICallsArticle';
import { TagType } from '../../catalog/enums/TagType';
import { createGalleryReference } from './FirebaseAPICallsGallery';
import { createDemoArticleGallery, createDemoGallery, createDemoGameGallery, createDemoMyLibraryGallery, demoGalleryTitle, demoMyLibraryGalleryId, createGalleryTitleForArticle, createGalleryTitleForGame, createGalleryTitleForEvent, createDemoEventGallery } from '../../catalog/demo/GalleryDemo';
import { createPhotoReference } from './FirebaseAPICallsPhoto';
import { createPhotoDemo, getDemoPhotoImage1, getDemoPhotoImage2, getDemoPhotoImage3, getDemoPhotoImage4, getDemoPhotoImage5 } from '../../catalog/demo/PhotoDemo';
import { createGameReference } from './FirebaseAPICallsGame';
import { createDemoGamePlayer } from '../../catalog/demo/GamePlayerDemo';
import { createGamePlayerReference } from './FirebaseAPICallsGamePlayer';
import { createSeasonReference } from './FirebaseAPICallsSeason';
import { createDemoSeason } from '../../catalog/demo/SeasonDemo';
import { createFormSubmittedReference } from './FirebaseAPICallsFormSubmitted';
import { createDemoFormSubmitted } from '../../catalog/demo/FormSubmittedDemo';
import { createGameCoachReference } from './FirebaseAPICallsGameCoach';
import { createDemoGameCoach } from '../../catalog/demo/GameCoachDemo';
import { createEventReference } from './FirebaseAPICallsEvent';
import { createDemoEvent, demoEventTitle } from '../../catalog/demo/EventDemo';
import { createGameTitle, demoGameStatus } from '../../catalog/demo/game/GameDemo';
import { createDemoRugbyGame } from '../../catalog/demo/game/RugbyGameDemo';
import { createDemoIceHockeyGame } from '../../catalog/demo/game/IceHockeyGameDemo';
import { AgeType } from '../../catalog/enums/AgeType';
import { createTeamSubscriptionInfoReference } from './FirebaseAPICallsSubscriptionInfo';
import { createDemoInfoSubscription } from '../../catalog/demo/SubscriptionInfoDemo';
import { createOptimXAdminInfoReference } from './FirebaseAPICallsOptimXAdminInfo';
import { createDemoOptimXAdminInfo } from '../../catalog/demo/OptimXAdminInfoDemo';
import { createStoreAdminInfoReference } from './FirebaseAPICallsStoreAdminInfo';
import { createDemoInfoStore } from '../../catalog/demo/StoreAdminInfoDemo';
import { createDemoLacrosseGame } from '../../catalog/demo/game/LacrosseGameDemo';

export const createOrganizationsReference = () => {
    return db.collection('organizations');
};

export const createOrganizationReference = (organizationId: string) => {
    return createOrganizationsReference().doc(organizationId);
};

export const fetchOrganizationFirestoreAPI = (organizationId: string) => {
    return createOrganizationReference(organizationId).get();
};

export const deleteOrganizationFirestoreAPI = (organizationId: string) => {
    return createOrganizationReference(organizationId).delete();
};

export const insertOrganizationFirestoreAPI = (teamId: string, organizationName: string, email: string, teamName: string, referral: string, sportsType: SportsType, ageType: AgeType) => {
    const batch = db.batch();
    const organizationId = randomNumber();
    const sponsorId = documentIdWithRandomNumber(demoSponsorName);
    const opponentId = documentIdWithRandomNumber(demoOpponentName);
    const leagueId = randomNumber();
    const playerId = documentIdWithRandomNumber(demoPlayerName);
    const coachId = documentIdWithRandomNumber(demoCoachName);
    const articleId = documentIdWithRandomNumber(demoArticleTitle);
    const photoId1 = randomNumber();
    const photoId2 = randomNumber();
    const photoId3 = randomNumber();
    const photoId4 = randomNumber();
    const photoId5 = randomNumber();
    const galleryId = documentIdWithRandomNumber(demoGalleryTitle);
    const articleGalleryId = documentIdWithRandomNumber(createGalleryTitleForArticle(demoArticleTitle));
    const seasonId = randomNumber();
    const formId = randomNumber();
    const gameId = documentIdWithRandomNumber(createGameTitle(demoOpponentName, demoGameStatus, new Date()));
    const eventId = documentIdWithRandomNumber(demoEventTitle);
    const gameGalleryId = documentIdWithRandomNumber(createGalleryTitleForGame(createGameTitle(demoOpponentName, demoGameStatus, new Date())));
    const gameArticleId = documentIdWithRandomNumber(createArticleTitleForGame(createGameTitle(demoOpponentName, demoGameStatus, new Date())));
    const eventGalleryId = documentIdWithRandomNumber(createGalleryTitleForEvent(demoEventTitle));
    const eventArticleId = documentIdWithRandomNumber(createArticleTitleForEvent(demoEventTitle));

    const tagIds = [opponentId, playerId, coachId];
    const tagReferences = [createOpponentReference(teamId, opponentId), createPlayerReference(playerId), createCoachReference(coachId)];
    const tagTypes = [TagType.OPPONENT, TagType.PLAYER, TagType.COACH];

    const tagIdsOnlyRosterAndCoachs = [playerId, coachId];
    const tagReferencesOnlyRosterAndCoachs = [createPlayerReference(playerId), createCoachReference(coachId)];
    const tagTypesOnlyRosterAndCoachs = [TagType.PLAYER, TagType.COACH];

    batch.set(createOrganizationReference(organizationId), createDemoOrganization(organizationId, organizationName));
    batch.set(createTeamReference(teamId), createDemoTeam(organizationId, organizationName, teamId, email, teamName, referral, sportsType, ageType));
    batch.set(createTeamSubscriptionInfoReference(teamId), createDemoInfoSubscription(organizationId, teamId, sportsType));
    batch.set(createOptimXAdminInfoReference(teamId), createDemoOptimXAdminInfo(organizationId, teamId, sportsType));
    batch.set(createStoreAdminInfoReference(teamId), createDemoInfoStore(organizationId, teamId, sportsType));
    batch.set(createSponsorReference(teamId, sponsorId), createDemoSponsor(organizationId, teamId, sponsorId, sportsType));
    batch.set(createOpponentReference(teamId, opponentId), createDemoOpponent(organizationId, teamId, opponentId, sportsType));
    batch.set(createLeagueReference(teamId, leagueId), createDemoLeague(organizationId, teamId, leagueId, sportsType));
    batch.set(createPlayerReference(playerId), createDemoPlayer(organizationId, teamId, playerId, sportsType));
    batch.set(createCoachReference(coachId), createDemoCoach(organizationId, teamId, coachId, sportsType));
    batch.set(createSeasonReference(teamId, seasonId), createDemoSeason(organizationId, teamId, seasonId, tagIdsOnlyRosterAndCoachs, tagReferencesOnlyRosterAndCoachs, tagTypesOnlyRosterAndCoachs, sportsType));
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        batch.set(createGameReference(gameId), createDemoIceHockeyGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, gameArticleId, gameGalleryId, leagueId, opponentId, playerId, sportsType));
    } else if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        batch.set(createGameReference(gameId), createDemoRugbyGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, gameArticleId, gameGalleryId, leagueId, opponentId, playerId, sportsType));
    } else if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        batch.set(createGameReference(gameId), createDemoLacrosseGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, gameArticleId, gameGalleryId, leagueId, opponentId, playerId, sportsType));
    }
    batch.set(createGamePlayerReference(gameId, playerId), createDemoGamePlayer(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, gameArticleId, gameGalleryId, leagueId, opponentId, playerId, sportsType));
    batch.set(createGameCoachReference(gameId, coachId), createDemoGameCoach(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, gameArticleId, gameGalleryId, leagueId, opponentId, coachId, sportsType));
    batch.set(createEventReference(eventId), createDemoEvent(organizationId, teamId, eventId, eventArticleId, eventGalleryId, sportsType));
    batch.set(createArticleReference(eventArticleId), createDemoEventArticle(organizationId, teamId, eventArticleId, tagIds, tagReferences, tagTypes, seasonId, gameId, eventGalleryId, sportsType));
    batch.set(createGalleryReference(eventGalleryId), createDemoEventGallery(organizationId, teamId, eventGalleryId, tagIds, tagReferences, tagTypes, seasonId, gameId, eventArticleId, [], sportsType));
    batch.set(createArticleReference(gameArticleId), createDemoGameArticle(organizationId, teamId, gameArticleId, tagIds, tagReferences, tagTypes, seasonId, gameId, gameGalleryId, sportsType));
    batch.set(createGalleryReference(gameGalleryId), createDemoGameGallery(organizationId, teamId, gameGalleryId, tagIds, tagReferences, tagTypes, seasonId, gameId, gameArticleId, [], sportsType));
    batch.set(createArticleReference(articleId), createDemoArticle(organizationId, teamId, teamName, articleId, tagIds, tagReferences, tagTypes, articleGalleryId, sportsType));
    batch.set(createGalleryReference(demoMyLibraryGalleryId(teamId)), createDemoMyLibraryGallery(organizationId, teamId, [], sportsType));
    batch.set(createGalleryReference(galleryId), createDemoGallery(organizationId, teamId, galleryId, tagIds, tagReferences, tagTypes, [photoId1, photoId2, photoId3], sportsType));
    batch.set(createGalleryReference(articleGalleryId), createDemoArticleGallery(organizationId, teamId, articleGalleryId, tagIds, tagReferences, tagTypes, articleId, [photoId4, photoId5], sportsType));
    batch.set(createPhotoReference(photoId1), createPhotoDemo(organizationId, teamId, galleryId, photoId1, getDemoPhotoImage1(sportsType), tagIds, tagReferences, tagTypes, sportsType));
    batch.set(createPhotoReference(photoId2), createPhotoDemo(organizationId, teamId, galleryId, photoId2, getDemoPhotoImage2(sportsType), tagIds, tagReferences, tagTypes, sportsType));
    batch.set(createPhotoReference(photoId3), createPhotoDemo(organizationId, teamId, galleryId, photoId3, getDemoPhotoImage3(sportsType), tagIds, tagReferences, tagTypes, sportsType));
    batch.set(createPhotoReference(photoId4), createPhotoDemo(organizationId, teamId, articleGalleryId, photoId4, getDemoPhotoImage4(sportsType), tagIds, tagReferences, tagTypes, sportsType));
    batch.set(createPhotoReference(photoId5), createPhotoDemo(organizationId, teamId, articleGalleryId, photoId5, getDemoPhotoImage5(sportsType), tagIds, tagReferences, tagTypes, sportsType));
    batch.set(createFormSubmittedReference(teamId, formId), createDemoFormSubmitted(organizationId, teamId, formId, sportsType));

    return batch.commit();
};
