import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button, MenuItem, Popper, Grow, Paper, MenuList } from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';

const DropDownNavItems = (props) => {
    const { navItems } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    /**
     * This function is used for opening and closing dishes and categories when menu is pressed
     */
    const handleToggle = () => {
        setMenuOpen((prevOpen) => { return !prevOpen; });
    };

    /**
     * This function is called when popper needs to be closed
     */
    const handleClose = (event) => {
        if (anchorRef?.current?.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    };

    return (
        <div className="nav-items-drop-down">
            <Button ref={anchorRef} className="text-white" onClick={handleToggle} onBlur={() => { setMenuOpen(false); }}>
                <MenuRounded />
            </Button>
            <Popper open={menuOpen} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => {
                    return (
                        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                            <Paper>
                                <MenuList>
                                    {navItems.map((item) => {
                                        return (
                                            <NavLink key={item.title} className="text-black text-no-underline" to={item.link} exact={item.exact}>
                                                <MenuItem onClick={handleClose}>{item.title}</MenuItem>
                                            </NavLink>
                                        );
                                    })}
                                </MenuList>
                            </Paper>
                        </Grow>
                    );
                }}
            </Popper>
        </div>
    );
};

DropDownNavItems.propTypes = {
    navItems: PropTypes.array.isRequired,
};

export default (memo(DropDownNavItems));
