import { WebsiteDesignType } from '../../../../enums/WebsiteDesignType';

export const demoTeamWebsiteStylesDesign = {
    homepage: WebsiteDesignType.DESIGN_1,
    articles: WebsiteDesignType.DESIGN_1,
    article: WebsiteDesignType.DESIGN_1,
    galleries: WebsiteDesignType.DESIGN_1,
    gallery: WebsiteDesignType.DESIGN_1,
    events: WebsiteDesignType.DESIGN_1,
    event: WebsiteDesignType.DESIGN_1,
    forms: WebsiteDesignType.DESIGN_1,
    about: WebsiteDesignType.DESIGN_1,
    sponsors: WebsiteDesignType.DESIGN_1,
    players: WebsiteDesignType.DESIGN_1,
    player: WebsiteDesignType.DESIGN_1,
    coaches: WebsiteDesignType.DESIGN_1,
    coach: WebsiteDesignType.DESIGN_1,
    games: WebsiteDesignType.DESIGN_1,
    game: WebsiteDesignType.DESIGN_1,
    staffs: WebsiteDesignType.DESIGN_1,
    staff: WebsiteDesignType.DESIGN_1,
};
