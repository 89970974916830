import { createMailReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsMail';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { MailerSendMailObject } from '../interface/mailInterface/MailerSendMailInterface';

export const createSignInMailObject = (
    mailId: string,
    teamId: string,
    teamName: string,
    organizationId: string,
    organizationName: string,
    sportsType: SportsType,
    email: string,
    url: string,
): MailerSendMailObject => {
    return {
        id: mailId,
        reference: createMailReference(mailId),
        teamId,
        teamReference: createTeamReference(teamId),
        from: { email: 'admin@optimxsports.com', name: 'OptimX Sports' },
        to: [{ email: 'contact@optimxtechnologies.com', name: 'OptimX Sports' }],
        cc: [],
        bcc: [],
        reply_to: {},
        subject: createSignInMailSubject(teamName),
        html: createSignInMailText(teamId, teamName, organizationId, organizationName, email, url),
        text: createSignInMailText(teamId, teamName, organizationId, organizationName, email, url),
        template_id: '',
        variables: [],
        personalization: [],
        tags: ['signin', `signin_${teamId}`, teamId],
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

const createSignInMailText = (
    teamId: string,
    teamName: string,
    organizationId: string,
    organizationName: string,
    email: string,
    url: string,
) => {
    return `SIGNIN for this team --- Team Name is: ${teamName}. Team Id is: ${teamId}. Organization Name is: ${organizationName}. Organization Id is: ${organizationId}. Email is: ${email}. URL is: ${url}`;
};

const createSignInMailSubject = (teamName: string) => {
    return `SIGNIN FROM ${teamName}`;
};
