/**
 * This class is used for all authentication api calls
 */
import { isValidObject } from '../catalog/Validity';
import { auth } from './FirebaseLibrary';

/**
 * This function is called when user needs to login
 *
 * @param {*} email email with which user wants to login
 * @param {*} password password with which user wants to login
 */
export const signInFirebaseAPI = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
};

/**
 * This function is used for sending email verification
 */
export const sendEmailVerificationFirebaseAPI = () => {
    return auth?.currentUser?.sendEmailVerification();
};

/**
 * This function is used for signing out
 */
export const signOutFirebaseAPI = () => {
    return auth?.signOut();
};

/**
 * This function is called when user needs to signup
 *
 * @param {*} email email with which user wants to signup
 * @param {*} password password with which user wants to signup
 */
export const signUpFirebaseAPI = (email: string, password: string) => {
    return auth?.createUserWithEmailAndPassword(email, password);
};

/**
 * This function is used for changing the email
 *
 * @param {*} email new email which it will be changed to
 */
export const changeEmailFirebaseAPI = (email: string) => {
    return auth?.currentUser?.updateEmail(email);
};

/**
 * This function is called to send the email for password change
 *
 * @param {*} email email where password reset email will be sent
 */
export const changePasswordFirebaseAPI = (email: string) => {
    return auth.sendPasswordResetEmail(email);
};

/**
 * This is used for changing the display name of profile
 *
 * @param {*} organizationID display name will be set to organizationID
 */
export const setDisplayNameFirebaseAPI = (organizationID: string) => {
    if (isValidObject(auth) && isValidObject(auth.currentUser) && auth.currentUser !== null) {
        auth.currentUser.uid = organizationID;
        auth?.currentUser?.updateProfile({
            displayName: organizationID,
        });
    }
};

/**
 * This is used for getting the uid
 */
export const getUIDFirebaseAPI = () => {
    return auth?.currentUser?.uid;
};

/**
 * This function is used for checking if the user is logged in. If not then this returns null.
 * If logged in then it returns the user.
 */
export const isInitialized = () => {
    return new Promise((resolve) => {
        auth.onAuthStateChanged(resolve);
    });
};

/**
 * This function gets the current user
 */
export const getCurrentUser = () => {
    return auth.currentUser;
};
