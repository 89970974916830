import { createGamePlayerReference } from '../../../../firebase/firebaseAPICalls/FirebaseAPICallsGamePlayer';
import { SportsType } from '../../../enums/SportsType';
import { TagType } from '../../../enums/TagType';
import { LacrosseGameObject, LacrosseGamePromotionalVideoObject } from './LacrosseGameInterface';

export interface LacrosseGamePlayerObject {
    id: string,
    reference: any,
    teamId: string,
    teamReference: any,
    organizationId: string,
    organizationReference: any,
    gameId: string,
    gameReference: any,
    seasonId: string,
    seasonReference: string,
    preDevelopedSeasonId: string,
    preDevelopedSeasonReference: any,
    leagueId: string,
    leagueReference: any,
    preDevelopedLeagueId: string,
    preDevelopedLeagueReference: any,
    preDevelopedConferenceId: string,
    preDevelopedConferenceReference: string,
    opponentId: string,
    opponentReference: any,
    winOrLoss: 'win' | 'loss' | 'tied',
    gameScore: string,
    shutOut: boolean,
    goals: number,
    assists: number,
    emptyNetGoals: number,
    shortHandedGoals: number,
    powerPlayGoals: number,
    gameWinningGoals: number,
    goalieGoalsConceded: number,
    goalieEmptyNetGoals: number,
    goalieShortHandedGoals: number,
    goaliePowerPlayGoals: number,
    goalieGameWinningGoals: number,
    shots: number,
    blocks: number,
    turnOvers: number,
    groundBalls: number,
    saves: number,
    penalties: number,
    penaltyMinutes: number,
    faceOffWins: number,
    goalieShotsOnGoal: number,
    goalieMinutes: number,
    goalieShotsSaved: number,
    bodyChecks: number,
    goalPeriods: LacrosseGamePlayerPeriodObject,
    assistPeriods: LacrosseGamePlayerPeriodObject,
    goalsConcededPeriods: LacrosseGamePlayerPeriodObject,
    penaltyPeriods: LacrosseGamePlayerPeriodObject,
    title: string,
    sportsType: SportsType,
    homeGame: boolean,
    status: 'scheduled' | 'postponed' | 'finished' | 'tba' | 'tbat' | 'cancelled',
    timeOfGame: Date,
    venue: string,
    attendance: number,
    featureImage: string,
    promotionalText: string,
    promotionalVideo: LacrosseGamePromotionalVideoObject,
    notes: string,
    timeModified: Date,
    timeCreated: Date,
}

export interface LacrosseGamePlayerPeriodObject {
    'p1': number,
    'p2': number,
    'p3': number,
    'p4': number,
    'ot1': number,
    'ot2': number,
}

export const getPlayerIdsFromLacrosseGameObject = (gameObject: LacrosseGameObject) => {
    const playerIds: Set<string> = new Set();
    gameObject.tagIds.forEach((tagId, index) => {
        if (gameObject.tagTypes[index] === TagType.PLAYER) {
            playerIds.add(tagId);
        }
    });
    gameObject.goals.forEach((goal) => {
        if (goal.team === 'our') {
            playerIds.add(goal.scorerId);
            playerIds.add(goal.assist1Id);
            playerIds.add(goal.assist2Id);
        }
        if (goal.team === 'opponent') {
            playerIds.add(goal.goalieId);
        }
    });
    gameObject.highlightsPerPlayer.forEach((highlight) => {
        playerIds.add(highlight.playerId);
    });
    gameObject.penalties.forEach((penalty) => {
        if (penalty.team === 'our') {
            playerIds.add(penalty.playerId);
        }
    });

    gameObject.faceOffs.forEach((faceOff) => {
        playerIds.add(faceOff.playerId);
    });

    gameObject.groundBalls.forEach((groundBall) => {
        playerIds.add(groundBall.playerId);
    });

    gameObject.turnOvers.forEach((turnOver) => {
        playerIds.add(turnOver.playerId);
    });

    gameObject.goalieActions.forEach((goalieAction) => {
        playerIds.add(goalieAction.playerId);
    });

    gameObject.bodyChecks.forEach((bodyCheck) => {
        playerIds.add(bodyCheck.playerId);
    });

    return Array.from(playerIds);
};

export const getLacrosseGamePlayerObjectFromLacrosseGameObject = (gameObject: LacrosseGameObject, playerId: string): LacrosseGamePlayerObject => {
    const playerReference = createGamePlayerReference(gameObject.id, playerId);

    let goals = 0;
    const goalPeriods = { p1: 0, p2: 0, p3: 0, p4: 0, ot1: 0, ot2: 0 };
    let assists = 0;
    const assistPeriods = { p1: 0, p2: 0, p3: 0, p4: 0, ot1: 0, ot2: 0 };
    let emptyNetGoals = 0;
    let shortHandedGoals = 0;
    let powerPlayGoals = 0;
    let gameWinningGoals = 0;
    let goalieGoalsConceded = 0;
    let goalieEmptyNetGoals = 0;
    let goalieShortHandedGoals = 0;
    let goaliePowerPlayGoals = 0;
    let goalieGameWinningGoals = 0;
    const goalieGoalsConcededPeriods = { p1: 0, p2: 0, p3: 0, p4: 0, ot1: 0, ot2: 0 };
    let shots = 0;
    let groundBalls = 0;
    let turnOvers = 0;
    let blocks = 0;
    let saves = 0;
    let penalties = 0;
    let penaltyMinutes = 0;
    const penaltyPeriods = { p1: 0, p2: 0, p3: 0, p4: 0, ot1: 0, ot2: 0 };
    let faceOffWins = 0;
    let goalieMinutes = 0;
    let goalieShotsOnGoal = 0;
    let goalieShotsSaved = 0;
    let bodyChecks = 0;

    gameObject.goals.forEach((goal) => {
        if (goal.team === 'our' && goal.scorerId === playerId) {
            goals += 1;
            if (goal.period !== 'na') {
                goalPeriods[goal.period] += 1;
            }
        }
        if (goal.team === 'our' && goal.assist1Id === playerId) {
            assists += 1;
            if (goal.period !== 'na') {
                assistPeriods[goal.period] += 1;
            }
        }
        if (goal.team === 'our' && goal.assist2Id === playerId) {
            assists += 1;
            if (goal.period !== 'na') {
                assistPeriods[goal.period] += 1;
            }
        }
        if (goal.team === 'our' && goal.scorerId === playerId && goal.emptyNetGoal) {
            emptyNetGoals += 1;
        }
        if (goal.team === 'our' && goal.scorerId === playerId && goal.shortHandedGoal) {
            shortHandedGoals += 1;
        }
        if (goal.team === 'our' && goal.scorerId === playerId && goal.powerPlayGoal) {
            powerPlayGoals += 1;
        }
        if (goal.team === 'our' && goal.scorerId === playerId && goal.gameWinningGoal) {
            gameWinningGoals += 1;
        }
        if (goal.team === 'opponent' && goal.emptyNetGoal && goal.goalieId === playerId) {
            goalieEmptyNetGoals += 1;
        }
        if (goal.team === 'opponent' && goal.shortHandedGoal && goal.goalieId === playerId) {
            goalieShortHandedGoals += 1;
        }
        if (goal.team === 'opponent' && goal.powerPlayGoal && goal.goalieId === playerId) {
            goaliePowerPlayGoals += 1;
        }
        if (goal.team === 'opponent' && goal.gameWinningGoal && goal.goalieId === playerId) {
            goalieGameWinningGoals += 1;
        }
        if (goal.team === 'opponent' && goal.goalieId === playerId) {
            goalieGoalsConceded += 1;
            if (goal.period !== 'na') {
                goalieGoalsConcededPeriods[goal.period] += 1;
            }
        }
    });

    gameObject.highlightsPerPlayer.forEach((highlight) => {
        if (highlight.type === 'shots' && highlight.playerId === playerId) {
            shots += highlight.number;
        }
        if (highlight.type === 'blocks' && highlight.playerId === playerId) {
            blocks += highlight.number;
        }
        if (highlight.type === 'groundBalls' && highlight.playerId === playerId) {
            groundBalls += highlight.number;
        }
        if (highlight.type === 'turnOvers' && highlight.playerId === playerId) {
            turnOvers += highlight.number;
        }
        if (highlight.type === 'saves' && highlight.playerId === playerId) {
            saves += highlight.number;
        }
    });

    gameObject.penalties.forEach((penalty) => {
        if (penalty.team === 'our' && penalty.playerId === playerId) {
            penalties += 1;
            if (penalty.period !== 'na') {
                penaltyPeriods[penalty.period] += 1;
            }
        }
        if (penalty.team === 'our' && penalty.playerId === playerId) {
            penaltyMinutes += penalty.penaltyTimeInSeconds;
        }
    });

    gameObject.faceOffs.forEach((faceOff) => {
        if (faceOff.team === 'our' && faceOff.playerId === playerId) {
            faceOffWins += faceOff.wins;
        }
    });

    gameObject.goalieActions.forEach((goalieAction) => {
        if (goalieAction.playerId === playerId) {
            goalieMinutes += goalieAction.minutes;
        }
        if (goalieAction.playerId === playerId) {
            goalieShotsOnGoal += goalieAction.shotsOnGoal;
        }
        if (goalieAction.playerId === playerId) {
            goalieShotsSaved += goalieAction.shotsSaved;
        }
    });

    gameObject.bodyChecks.forEach((bodyCheck) => {
        if (bodyCheck.playerId === playerId) {
            bodyChecks += bodyCheck.hits;
        }
    });

    return {
        id: playerId,
        reference: playerReference,
        teamId: gameObject.teamId,
        teamReference: gameObject.teamReference,
        organizationId: gameObject.organizationId,
        organizationReference: gameObject.organizationReference,
        gameId: gameObject.id,
        gameReference: gameObject.reference,
        seasonId: gameObject.seasonId,
        seasonReference: gameObject.seasonReference,
        preDevelopedSeasonId: gameObject.preDevelopedSeasonId,
        preDevelopedSeasonReference: gameObject.preDevelopedSeasonReference,
        leagueId: gameObject.leagueId,
        leagueReference: gameObject.leagueReference,
        preDevelopedLeagueId: gameObject.preDevelopedLeagueId,
        preDevelopedLeagueReference: gameObject.preDevelopedLeagueReference,
        preDevelopedConferenceId: gameObject.preDevelopedConferenceId,
        preDevelopedConferenceReference: gameObject.preDevelopedConferenceReference,
        opponentId: gameObject.opponentId,
        opponentReference: gameObject.opponentReference,
        winOrLoss: gameObject.winOrLoss,
        gameScore: gameObject.gameScore,
        shutOut: gameObject.shutOut,
        goals,
        assists,
        emptyNetGoals,
        shortHandedGoals,
        powerPlayGoals,
        gameWinningGoals,
        goalieGoalsConceded,
        goalieEmptyNetGoals,
        goalieShortHandedGoals,
        goaliePowerPlayGoals,
        goalieGameWinningGoals,
        shots,
        blocks,
        groundBalls,
        turnOvers,
        saves,
        penalties,
        penaltyMinutes,
        faceOffWins,
        goalieMinutes,
        goalieShotsOnGoal,
        goalieShotsSaved,
        bodyChecks,
        goalPeriods,
        assistPeriods,
        goalsConcededPeriods: goalieGoalsConcededPeriods,
        penaltyPeriods,
        title: gameObject.title,
        sportsType: gameObject.sportsType,
        homeGame: gameObject.homeGame,
        status: gameObject.status,
        timeOfGame: gameObject.timeOfGame,
        venue: gameObject.venue,
        attendance: gameObject.attendance,
        featureImage: gameObject.featureImage,
        promotionalText: gameObject.promotionalText,
        promotionalVideo: gameObject.promotionalVideo,
        notes: gameObject.notes,
        timeModified: gameObject.timeModified,
        timeCreated: gameObject.timeCreated,
    };
};
