import { dbTimestamp } from '../firebase/FirebaseLibrary';
import { isValidObject, isValidString } from './Validity';

/**
 * This function gets the current date and time
 */
export const getCurrentDateTime = (): Date => {
    const myDate = new Date();
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    return myDate;
};

/**
 * This function gets the date and time for a year later. Used for subscriptions
 */
export const getDateTimeForNextYear = (): Date => {
    const myDate = new Date();
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    myDate.setFullYear(myDate.getFullYear() + 1);
    return myDate;
};

/**
 * This function gets the date and time for 100 years later. Used for subscriptions
 */
export const getDateTimeForNext100Year = (): Date => {
    const myDate = new Date();
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    myDate.setFullYear(myDate.getFullYear() + 100);
    return myDate;
};

export const getDateTimeForNext200Year = (): Date => {
    const myDate = new Date();
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    myDate.setFullYear(myDate.getFullYear() + 200);
    return myDate;
};

/**
 * This function gets the current date and time but a minute ago. Used to create My Library in galleries
 */
export const getDateTimeLastMinute = (): Date => {
    const myDate = new Date();
    myDate.setMinutes(myDate.getMinutes() - 1);
    myDate.setSeconds(0);
    myDate.setMilliseconds(0);
    return myDate;
};

export const increaseHoursInDate = (myDate: Date, increaseBy: number) => {
    const newDate = new Date(myDate);
    newDate.setHours(newDate.getHours() + increaseBy);
    return newDate;
};

export const increaseDaysInDate = (myDate: Date, increaseBy: number) => {
    const newDate = new Date(myDate);
    newDate.setDate(newDate.getDate() + increaseBy);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    // newDate.setHours(newDate.getHours() + increaseBy * 24);
    return newDate;
};

export const increaseMonthsInDate = (myDate: Date, increaseBy: number) => {
    const newDate = new Date(myDate);
    newDate.setMonth(newDate.getMonth() + increaseBy);
    return newDate;
};

export const increaseYearsInDate = (myDate: Date, increaseBy: number) => {
    const newDate = new Date(myDate);
    newDate.setFullYear(newDate.getFullYear() + increaseBy);
    return newDate;
};

export const getMinutesFromSeconds = (seconds: number): number => {
    return Math.floor(seconds / 60);
};

export const getSecondsFromSeconds = (seconds: number): number => {
    return seconds - (Math.floor(seconds / 60) * 60);
};

export const getDateFromSeconds = (seconds: number): Date => {
    if (seconds === null || seconds === undefined || Number.isNaN(seconds)) {
        const myDate = new Date();
        myDate.setMinutes(0);
        myDate.setSeconds(0);
        myDate.setMilliseconds(0);
        return myDate;
    }
    const myDate = new Date();
    myDate.setMinutes(Math.floor(seconds / 60));
    myDate.setSeconds(seconds - (Math.floor(seconds / 60) * 60));
    myDate.setMilliseconds(0);
    return myDate;
};

export const getMonthStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    return monthNames[date.getMonth()];
};

export const getSecondsFromDate = (date: Date): number => {
    if (date === null || date === undefined) {
        return 0;
    }
    return date.getMinutes() * 60 + date.getSeconds();
};

export const getTimeInAMPMFromDate = (date: Date) => {
    let hour = date.getHours();
    const minute = date.getMinutes();
    const PM = hour > 11;
    hour -= hour > 12 ? 12 : 0;
    hour = hour === 0 ? 12 : hour;
    return `${hour < 10 ? '0' : ''}${hour.toString()}:${minute < 10 ? '0' : ''}${minute.toString()} ${PM ? 'PM' : 'AM'} ${getTimeZone(date)}`;
};

export const getDateStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    let finalDate;
    if (date.getDate() < 10) {
        finalDate = `0${date.getDate()}`;
    } else {
        finalDate = date.getDate();
    }
    let finalMonth;
    if (date.getMonth() < 9) {
        finalMonth = `0${date.getMonth() + 1}`;
    } else {
        finalMonth = date.getMonth() + 1;
    }
    return `${finalMonth}-${finalDate}-${date.getFullYear()}`;
};

export const getDateStringWithMonthsFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    let finalDate;
    if (date.getDate() < 10) {
        finalDate = `0${date.getDate()}`;
    } else {
        finalDate = date.getDate();
    }

    return `${getMonthStringFromDate(date)} ${finalDate}, ${date.getFullYear()}`;
};

export const getDateTimeStringWithMonthsFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    let finalDate;
    if (date.getDate() < 10) {
        finalDate = `0${date.getDate()}`;
    } else {
        finalDate = date.getDate();
    }

    return `${getMonthStringFromDate(date)} ${finalDate}, ${date.getFullYear()} ${getTimeInAMPMFromDate(date)}`;
};

export const getDateTimeStringFromDate = (date: Date): string => {
    if (!isValidObject(date)) {
        return '';
    }
    return `${getDateStringFromDate(date)} ${getTimeInAMPMFromDate(date)}`;
};

export const getDateForStore = (date: Date) => {
    if (date > increaseDaysInDate(new Date(), 0)) {
        return 'Today';
    } if (date > increaseDaysInDate(new Date(), -1)) {
        return 'Yesterday';
    } if (date > increaseDaysInDate(new Date(), -6)) {
        return 'Last 7 Days';
    }
    return getDateStringWithMonthsFromDate(date);
};

export const isDateInLast7days = (date: Date) => {
    return date > increaseDaysInDate(new Date(), -7);
};

export const getSecondsFromMinutesAndSecondsString = (str: string): number => {
    try {
        let newStr = str.replace('"', '');
        newStr = newStr.replace(/'/g, '');
        let minute = newStr.split(' ')[0];
        if (minute.length <= 0) {
            minute = '0';
        }
        let second = newStr.split(' ')[1];
        if (second.length <= 0) {
            second = '0';
        }
        return parseInt(minute, 10) * 60 + parseInt(second, 10);
    } catch (error) {
        return 0;
    }
};

export const getSecondsFromMinutesString = (minutes: string, totalTimeInSeconds: number): number => {
    try {
        if (isValidString(minutes)) {
            return (parseInt(minutes, 10) * 60) + (totalTimeInSeconds % 60);
        }
        return (totalTimeInSeconds % 60);
    } catch (error) {
        return (totalTimeInSeconds % 60);
    }
};

export const getSecondsFromSecondsString = (seconds: string, totalTimeInSeconds: number): number => {
    try {
        if (isValidString(seconds)) {
            if (parseInt(seconds, 10) >= 60) {
                return Math.floor(totalTimeInSeconds / 60) * 60 + 59;
            }
            return Math.floor(totalTimeInSeconds / 60) * 60 + parseInt(seconds, 10);
        }
        return Math.floor(totalTimeInSeconds / 60) * 60;
    } catch (error) {
        return Math.floor(totalTimeInSeconds / 60) * 60;
    }
};

export const getMinutesAndSecondsStringFromSeconds = (seconds: number): string => {
    try {
        if (seconds <= 0) {
            return '00:00';
        }
        const minutes = getMinutesFromSeconds(seconds);
        const newSeconds = getSecondsFromSeconds(seconds) < 10 ? `0${getSecondsFromSeconds(seconds)}` : getSecondsFromSeconds(seconds);

        return `${minutes}:${newSeconds}`;
    } catch (error) {
        return '00:00';
    }
};

export const getLocalTimeFromStripeTime = (time: any): Date => {
    if (time instanceof Date) {
        return time;
    }

    const date = new Date(time * 1000);

    return date;
};

export const getLocalTimeFromDatabaseTime = (time: any): Date => {
    if (time instanceof Date) {
        return time;
    }
    return time.toDate();
};

export const getDatabaseTimeFromLocalTime = (date: Date): any => {
    return dbTimestamp.fromDate(date);
};

export const getTimeDescriptionForTicket = (date: Date): string => {
    return `Starts from ${getDateTimeStringWithMonthsFromDate(date)}`;
};

export const getTimeZone = (date: Date) => {
    return date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
};

export const getCurrentYear = (): number => {
    const myDate = new Date();
    return myDate.getFullYear();
};
