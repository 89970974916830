import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { SportsType } from '../enums/SportsType';
import { getCurrentDateTime } from '../DateTime';
import { CoachDetailObject, CoachDetailPlaceholderObject, CoachObject, CoachTextObject, CoachTextPlaceholderObject } from '../interface/CoachInterface';
import { createCoachReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsCoach';

export const demoCoachName = 'John Doe';
export const demoCoachEmailAddress = 'johndoe@optimxsports.com';
export const demoCoachPhone = '+1-(123)-456-7890';
export const getDemoCoachDetails = (sportsType: SportsType): CoachDetailObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return ([
            { title: 'Title', required: true, value: 'Head Coach' },
            { title: 'Hometown', required: false, value: 'Skillman, NJ' },
            { title: 'Born', required: false, value: '2000' },
            // { title: 'Nationality', required: false, value: 'Spain' },
        ]);
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return ([
            { title: 'Title', required: true, value: 'Head Coach' },
            { title: 'Hometown', required: false, value: 'Skillman, NJ' },
            { title: 'Born', required: false, value: '2000' },
            // { title: 'Nationality', required: false, value: 'Spain' },
        ]);
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return ([
            { title: 'Title', required: true, value: 'Head Coach' },
            { title: 'Hometown', required: false, value: 'Skillman, NJ' },
            { title: 'Born', required: false, value: '2000' },
            // { title: 'Nationality', required: false, value: 'Spain' },
        ]);
    }
    return [];
};
export const getPlaceholderCoachDetails = (sportsType: SportsType): CoachDetailPlaceholderObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return ([
            { title: 'Title', required: true, value: '', placeholder: 'Head Coach' },
            { title: 'Hometown', required: false, value: '', placeholder: 'Skillman, NJ' },
            { title: 'Born', required: false, value: '', placeholder: '2000' },
            { title: 'Nationality', required: false, value: '', placeholder: 'Spain' },
        ]);
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return ([
            { title: 'Title', required: true, value: '', placeholder: 'Head Coach' },
            { title: 'Hometown', required: false, value: '', placeholder: 'Skillman, NJ' },
            { title: 'Born', required: false, value: '', placeholder: '2000' },
            { title: 'Nationality', required: false, value: '', placeholder: 'Spain' },
        ]);
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return ([
            { title: 'Title', required: true, value: '', placeholder: 'Head Coach' },
            { title: 'Hometown', required: false, value: '', placeholder: 'Skillman, NJ' },
            { title: 'Born', required: false, value: '', placeholder: '2000' },
            { title: 'Nationality', required: false, value: '', placeholder: 'Spain' },
        ]);
    }
    return [];
};
export const demoCoachBio = '';
export const getDemoCoachProfileImage = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoCoach%2FiceHockey%2FdemoProfileImage%2FdemoProfileImage.png?alt=media&token=f669b5c9-9f0e-4fad-b820-59d5dc2a051a';
    } if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoCoach%2Frugby%2FdemoProfileImage%2FdemoProfileImage_1600x900.png?alt=media&token=f669b5c9-9f0e-4fad-b820-59d5dc2a051a';
    } if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoCoach%2Flacrosse%2FdemoProfileImage%2FdemoProfileImage_1600x900.png?alt=media&token=f669b5c9-9f0e-4fad-b820-59d5dc2a051a';
    }
    return '';
};
export const getDemoCoachTexts = (sportsType: SportsType): CoachTextObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return [
            { title: 'Personal Achievement', required: false, value: '- Coached 386 games' },
        ];
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return [
            { title: 'Personal Achievement', required: false, value: '- Coached 386 games' },
        ];
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return [
            { title: 'Personal Achievement', required: false, value: '- Coached 386 games' },
        ];
    }
    return [];
};
export const getPlaceholderCoachTexts = (sportsType: SportsType): CoachTextPlaceholderObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return ([
            { title: 'Personal Achievement', required: false, value: '', placeholder: '- Coached 386 games' },
        ]);
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return ([
            { title: 'Personal Achievement', required: false, value: '', placeholder: '- Coached 386 games' },
        ]);
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return ([
            { title: 'Personal Achievement', required: false, value: '', placeholder: '- Coached 386 games' },
        ]);
    }
    return [];
};

export const createDemoCoach = (organizationId: string, teamId: string, coachId: string, sportsType: SportsType): CoachObject => {
    const organizationReference = createOrganizationReference(organizationId);
    const teamReference = createTeamReference(teamId);
    const coachReference = createCoachReference(coachId);

    return {
        id: coachId,
        urlId: coachId,
        reference: coachReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        name: demoCoachName,
        sportsType,
        email: demoCoachEmailAddress,
        phone: demoCoachPhone,
        details: getDemoCoachDetails(sportsType),
        summary: '',
        bio: '',
        profileImage: getDemoCoachProfileImage(sportsType),
        texts: getDemoCoachTexts(sportsType),
        alumni: false,
        deleted: false,
        order: 0,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
