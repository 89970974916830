import { createLeagueReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsLeague';
import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createPreDevelopedConferenceReference, createPreDevelopedLeagueReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPreDevelopedLeague';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { LeagueObject } from '../interface/LeagueInterface';
import { SportsType } from '../enums/SportsType';

export const getDemoLeaguePreDevelopedLeagueId = (sportsType: SportsType) => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'kNNxlMKJXeVJleTGI13d';
    }
    if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'jR8BfmsagmUmJoFdq6eb';
    }
    if (sportsType === SportsType.WOMENSRUGBY) {
        return '24fGbp1fe6GtwXxckk64';
    }
    if (sportsType === SportsType.MENSRUGBY) {
        return 'Q7mIqHIrqHmfqOVeOe7S';
    }
    if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'O3uKeQ58XT8mZC4mB8og';
    }
    if (sportsType === SportsType.MENSLACROSSE) {
        return 'DUMegZVw7vog7yXMsvNb';
    }

    return '';
};
export const getDemoLeaguePreDevelopedLeagueReference = (sportsType: SportsType) => {
    return createPreDevelopedLeagueReference(`${getDemoLeaguePreDevelopedLeagueId(sportsType)}`);
};
export const getDemoLeaguePreDevelopedConferenceId = (sportsType: SportsType) => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'Hxmzoxp3aOJtOFMQOY1N';
    }
    if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'DeFqV0t2hcTxHFKkfhbl';
    }
    if (sportsType === SportsType.WOMENSRUGBY) {
        return 'GLBaLig33xC6yF1yjiz6';
    }
    if (sportsType === SportsType.MENSRUGBY) {
        return '3MvVZPeNL7lMH9miD8qN';
    }
    if (sportsType === SportsType.WOMENSLACROSSE) {
        return '0Z9Iz590bEnG2y9xSbE3';
    }
    if (sportsType === SportsType.MENSLACROSSE) {
        return 'ibN9F5Jl3Dxgb7mKohlM';
    }

    return '';
};
export const getDemoLeaguePreDevelopedConferenceReference = (sportsType: SportsType) => {
    return createPreDevelopedConferenceReference(getDemoLeaguePreDevelopedLeagueId(sportsType), getDemoLeaguePreDevelopedConferenceId(sportsType));
};

export const createDemoLeague = (organizationId: string, teamId: string, leagueId: string, sportsType: SportsType): LeagueObject => {
    const leagueReference = createLeagueReference(teamId, leagueId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);

    return {
        id: leagueId,
        reference: leagueReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        preDevelopedLeagueId: getDemoLeaguePreDevelopedLeagueId(sportsType),
        preDevelopedLeagueReference: getDemoLeaguePreDevelopedLeagueReference(sportsType),
        preDevelopedConferenceId: getDemoLeaguePreDevelopedConferenceId(sportsType),
        preDevelopedConferenceReference: getDemoLeaguePreDevelopedConferenceReference(sportsType),
        sportsType,
        deleted: false,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
