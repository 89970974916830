import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { SponsorObject } from '../../catalog/interface/SponsorInterface';
import { isValidString } from '../../catalog/Validity';
import { db, storageRef } from '../FirebaseLibrary';

export const createSponsorsReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/sponsors`);
};

export const createSponsorReference = (teamId: string, sponsorId: string) => {
    return createSponsorsReference(teamId).doc(sponsorId);
};

export const fetchSponsorFirestoreAPI = (teamId: string, sponsorId: string) => {
    return createSponsorReference(teamId, sponsorId).get();
};

export const fetchSponsorsFirestoreAPI = (teamId: string) => {
    return createSponsorsReference(teamId).orderBy('order', 'desc').orderBy('timeCreated').get();
};

export const fetchSponsorsWithoutOrderFirestoreAPI = (teamId: string) => {
    return createSponsorsReference(teamId).orderBy('timeCreated').get();
};

export const updateSponsorsOrderFirestoreAPI = (teamId: string, sponsorObjects: [any]) => {
    const batch = db.batch();

    sponsorObjects.forEach((coachObject, index) => {
        if (isValidString(coachObject.id)) {
            batch.update(createSponsorReference(teamId, coachObject.id), { order: sponsorObjects.length - index - 1 });
        }
    });

    return batch.commit();
};

export const insertSponsorFirestoreAPI = (teamId: string, sponsorObject: SponsorObject) => {
    return createSponsorReference(teamId, sponsorObject.id).set(sponsorObject);
};

export const updateSponsorFirestoreAPI = (teamId: string, sponsorObject: any) => {
    return createSponsorReference(teamId, sponsorObject.id).update(sponsorObject);
};

export const deleteSponsorFirestoreAPI = (teamId: string, sponsorId: string) => {
    return createSponsorReference(teamId, sponsorId).delete();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createSponsorLogoRefPathStorageAPI = (teamId: string, sponsorId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `teams/${teamId}/sponsors/${sponsorId}/sponsorLogo/sponsorLogo_1600x900.png`;
    }
    return `teams/${teamId}/sponsors/${sponsorId}/sponsorLogo/sponsorLogo.png`;
};

export const insertSponsorLogoStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, sponsorId: string, sponsorName: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: sponsorId,
            title: sponsorName,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createSponsorLogoRefPathStorageAPI(teamId, sponsorId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deleteSponsorLogoStorageAPI = (teamId: string, sponsorId: string) => {
    const imageRef = storageRef.child(createSponsorLogoRefPathStorageAPI(teamId, sponsorId, true));
    return imageRef.delete();
};
