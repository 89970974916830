import { createOrganizationReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../../DateTime';
import { SportsType } from '../../enums/SportsType';
import { createArticleReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsArticle';
import { TagType } from '../../enums/TagType';
import { createGalleryReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsGallery';
import { createGameReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsGame';
import { createSeasonReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsSeason';
import { demoOpponentName } from '../OpponentDemo';
import { getDemoSeasonPreDevelopedSeasonId, getDemoSeasonPreDevelopedSeasonReference } from '../SeasonDemo';
import { createLeagueReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsLeague';
import { createOpponentReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsOpponent';
import { getDemoLeaguePreDevelopedConferenceId, getDemoLeaguePreDevelopedConferenceReference, getDemoLeaguePreDevelopedLeagueId, getDemoLeaguePreDevelopedLeagueReference } from '../LeagueDemo';
import { createGameTitle, demoGameAttendance, demoGameNotes, demoGameStatus, demoPromotionalText, demoPromotionalVideo } from './GameDemo';
import { RugbyGameCarryObject, RugbyGameMetersCarriedObject, RugbyGameMinsPlayedObject, RugbyGameObject, RugbyGameOffloadObject, RugbyGameOpponentConversionObject, RugbyGameOpponentDropGoalObject, RugbyGameOpponentPenaltyKickObject, RugbyGameOpponentTryObject, RugbyGameOurConversionObject, RugbyGameOurDropGoalObject, RugbyGameOurPenaltyKickObject, RugbyGameOurTryObject, RugbyGamePassObject, RugbyGameTackleObject } from '../../interface/gameInterface/rugby/RugbyGameInterface';
import { opponentPointsFromStats, ourPointsFromStats } from '../../stats/rugby/RugbyStats';
import { demoTeamInfoStadium } from '../team/TeamInfoDemo';

export const demoRugbyGameFeatureImage = 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGame%2Frugby%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=b570520e-f984-47ae-b480-67b5ca848837';

export const getDemoRugbyGameWinOrLoss = (tries: (RugbyGameOurTryObject | RugbyGameOpponentTryObject)[], conversions: (RugbyGameOurConversionObject | RugbyGameOpponentConversionObject)[], penaltyKicks: (RugbyGameOurPenaltyKickObject | RugbyGameOpponentPenaltyKickObject)[], dropGoals: (RugbyGameOurDropGoalObject | RugbyGameOpponentDropGoalObject)[]): ('win' | 'loss' | 'tied') => {
    const ourPoints = ourPointsFromStats(tries, conversions, penaltyKicks, dropGoals);
    const opponentPoints = opponentPointsFromStats(tries, conversions, penaltyKicks, dropGoals);

    if (ourPoints > opponentPoints) {
        return 'win';
    } if (ourPoints === opponentPoints) {
        return 'tied';
    }
    return 'loss';
};

export const getDemoRugbyGameScore = (tries: (RugbyGameOurTryObject | RugbyGameOpponentTryObject)[], conversions: (RugbyGameOurConversionObject | RugbyGameOpponentConversionObject)[], penaltyKicks: (RugbyGameOurPenaltyKickObject | RugbyGameOpponentPenaltyKickObject)[], dropGoals: (RugbyGameOurDropGoalObject | RugbyGameOpponentDropGoalObject)[], homeGame: boolean): string => {
    const ourPoints = ourPointsFromStats(tries, conversions, penaltyKicks, dropGoals);
    const opponentPoints = opponentPointsFromStats(tries, conversions, penaltyKicks, dropGoals);

    if (homeGame) {
        return `${ourPoints}-${opponentPoints}`;
    }
    return `${opponentPoints}-${ourPoints}`;
};

export const getDemoRugbyGameTries = (playerId: string, assistId: string): (RugbyGameOurTryObject | RugbyGameOpponentTryObject)[] => {
    return [{ team: 'our', period: 'h1', timeInSeconds: 540, playerId, assistId }];
};

export const getDemoRugbyGameConversions = (playerId: string): (RugbyGameOurConversionObject | RugbyGameOpponentConversionObject)[] => {
    return [{ team: 'our', period: 'h1', timeInSeconds: 900, playerId }];
};

export const getDemoRugbyGamePenaltyKicks = (playerId: string): (RugbyGameOurPenaltyKickObject | RugbyGameOpponentPenaltyKickObject)[] => {
    return [{ team: 'our', period: 'h1', timeInSeconds: 900, playerId, convertedToGoal: true }];
};

export const getDemoRugbyGameDropGoals = (playerId: string): (RugbyGameOurDropGoalObject | RugbyGameOpponentDropGoalObject)[] => {
    return [{ team: 'our', period: 'h1', timeInSeconds: 900, playerId }];
};

export const getDemoRugbyGameTackles = (playerId: string): RugbyGameTackleObject[] => {
    return [{ playerId, tackles: 10 }];
};

export const getDemoRugbyGameOffloads = (playerId: string): RugbyGameOffloadObject[] => {
    return [{ playerId, offloads: 10 }];
};

export const getDemoRugbyGamePasses = (playerId: string): RugbyGamePassObject[] => {
    return [{ playerId, passes: 10 }];
};

export const getDemoRugbyGameCarries = (playerId: string): RugbyGameCarryObject[] => {
    return [{ playerId, carries: 10 }];
};

export const getDemoRugbyGameMetersCarried = (playerId: string): RugbyGameMetersCarriedObject[] => {
    return [{ playerId, metersCarried: 10 }];
};

export const getDemoRugbyGameMinsPlayed = (playerId: string): RugbyGameMinsPlayedObject[] => {
    return [{ playerId, minsPlayed: 10 }];
};

export const createDemoRugbyGame = (
    organizationId: string,
    teamId: string,
    gameId: string,
    tagIds: string[],
    tagReferences: any[],
    tagTypes: TagType[],
    seasonId: string,
    articleId: string,
    galleryId: string,
    leagueId: string,
    opponentId: string,
    playerId: string,
    sportsType: SportsType,
): RugbyGameObject => {
    const gameReference = createGameReference(gameId);
    const leagueReference = createLeagueReference(teamId, leagueId);
    const seasonReference = createSeasonReference(teamId, seasonId);
    const opponentReference = createOpponentReference(teamId, opponentId);
    const articleReference = createArticleReference(articleId);
    const galleryReference = createGalleryReference(galleryId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);
    const timeOfGame = new Date(2020, 12, 17, 0, 0, 0, 0);

    return {
        id: gameId,
        urlId: gameId,
        reference: gameReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        seasonId,
        seasonReference,
        preDevelopedSeasonId: getDemoSeasonPreDevelopedSeasonId(sportsType),
        preDevelopedSeasonReference: getDemoSeasonPreDevelopedSeasonReference(sportsType),
        leagueId,
        leagueReference,
        preDevelopedLeagueId: getDemoLeaguePreDevelopedLeagueId(sportsType),
        preDevelopedLeagueReference: getDemoLeaguePreDevelopedLeagueReference(sportsType),
        preDevelopedConferenceId: getDemoLeaguePreDevelopedConferenceId(sportsType),
        preDevelopedConferenceReference: getDemoLeaguePreDevelopedConferenceReference(sportsType),
        opponentId,
        opponentReference,
        articleId,
        articleReference,
        galleryId,
        galleryReference,
        title: createGameTitle(demoOpponentName, demoGameStatus, timeOfGame),
        sportsType,
        homeGame: true,
        status: demoGameStatus,
        timeOfGame,
        venue: demoTeamInfoStadium,
        attendance: demoGameAttendance,
        tagIds,
        tagReferences,
        tagTypes,
        featureImage: demoRugbyGameFeatureImage,
        articleImage: demoRugbyGameFeatureImage,
        winOrLoss: getDemoRugbyGameWinOrLoss(getDemoRugbyGameTries(playerId, playerId), getDemoRugbyGameConversions(playerId), getDemoRugbyGamePenaltyKicks(playerId), getDemoRugbyGameDropGoals(playerId)),
        gameScore: getDemoRugbyGameScore(getDemoRugbyGameTries(playerId, playerId), getDemoRugbyGameConversions(playerId), getDemoRugbyGamePenaltyKicks(playerId), getDemoRugbyGameDropGoals(playerId), true),
        tries: getDemoRugbyGameTries(playerId, playerId),
        conversions: getDemoRugbyGameConversions(playerId),
        penaltyKicks: getDemoRugbyGamePenaltyKicks(playerId),
        dropGoals: getDemoRugbyGameDropGoals(playerId),
        fouls: [],
        offensiveGameStats: { defendersBeaten: 0, cleanBreaks: 0, turnoversConceded: 0, plusAndMinus: 0, passes: 0, opponentPasses: 0, offloads: 0, opponentOffloads: 0, metersCarried: 0, opponentMetersCarried: 0, carries: 0, opponentCarries: 0 },
        defensiveGameStats: { missedTackles: 0, turnoversWon: 0, tackles: 0, opponentTackles: 0 },
        kickingGameStats: { penaltyKicks: 0, opponentPenaltyKicks: 0, kickingInPlay: 0, conversionsAccuracy: 0, penaltyKicksAccuracy: 0, dropGoalsAccuracy: 0 },
        setPlaysGameStats: { lineoutsWon: 0, lineoutsLost: 0, scrumsWon: 0, scrumsLost: 0 },
        tackles: getDemoRugbyGameTackles(playerId),
        offloads: getDemoRugbyGameOffloads(playerId),
        carries: getDemoRugbyGameCarries(playerId),
        passes: getDemoRugbyGamePasses(playerId),
        metersCarried: getDemoRugbyGameMetersCarried(playerId),
        minsPlayed: getDemoRugbyGameMinsPlayed(playerId),
        promotionalText: demoPromotionalText,
        promotionalVideo: demoPromotionalVideo,
        notes: demoGameNotes,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
