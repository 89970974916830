import { sendCustomTeamAnalytics, sendErrorTeamAnalytics } from '../../../../../catalog/Analytics';
import { getCurrentDateTime, getLocalTimeFromDatabaseTime, increaseYearsInDate } from '../../../../../catalog/DateTime';
import { SportsType } from '../../../../../catalog/enums/SportsType';
import { MailerSendMailObject } from '../../../../../catalog/interface/mailInterface/MailerSendMailInterface';
import { createLicensingAgreementMailObject } from '../../../../../catalog/mail/LicensingAgreementEmail';
import { LICENSING_AGREEMENT_ERROR, LICENSING_AGREEMENT_SUCCESS } from '../../../../../catalog/NotificationComments';
import { randomNumber } from '../../../../../catalog/Others';
import { insertLicensingAgreementPDFStorageAPI } from '../../../../../firebase/firebaseAPICalls/FirebaseAPICallsLicensingAgreement';
import { insertMailFirestoreAPI } from '../../../../../firebase/firebaseAPICalls/FirebaseAPICallsMailerSendMail';
import { fetchTeamFirestoreAPI, updateTeamFirestoreAPI } from '../../../../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { fetchPDFURLStorageAPI } from '../../../../../firebase/firebaseAPICalls/StorageAPICalls';
import { licensingAgreementVersionNumber } from './LicensingAgreement';

const saveLicensingAgreementPDF = async (organizationId: string, organizationName: string, teamId: string, teamName: string, file: File): Promise<string> => {
    const { ref, promise } = insertLicensingAgreementPDFStorageAPI(organizationId, organizationName, teamId, teamName, file);
    await promise;
    const url = await fetchPDFURLStorageAPI(10, ref);
    return url;
};

export const licensingAgreementAPI = async (
    organizationId: string,
    organizationName: string,
    teamId: string,
    teamName: string,
    sportsType: SportsType,
    email: string,
    url: string,
    file: File,
    accountCreationTimestamp: Date,
    setGlobalState: Function,
    snackbar: Function,
) => {
    try {
        const newFile = await saveLicensingAgreementPDF(organizationId, organizationName, teamId, teamName, file);
        await updateLicensingAgreementInTeamAPICall(teamId, newFile, accountCreationTimestamp, setGlobalState);
        const mailId = randomNumber();
        const mailObject: MailerSendMailObject = createLicensingAgreementMailObject(mailId, teamId, teamName, organizationId, organizationName, sportsType, email, url);
        await insertMailFirestoreAPI(mailObject);
        sendCustomTeamAnalytics('agreement_signed');
        snackbar(LICENSING_AGREEMENT_SUCCESS, { variant: 'success' });
    } catch (error) {
        sendErrorTeamAnalytics(LICENSING_AGREEMENT_ERROR);
        snackbar(LICENSING_AGREEMENT_ERROR, { variant: 'error' });
    }
};

const updateLicensingAgreementInTeamAPICall = async (teamId: string, file: string, accountCreationTimestamp: Date, setGlobalState: Function) => {
    const teamDoc = await fetchTeamFirestoreAPI(teamId);
    const team = teamDoc.data();

    const licensingAgreement = {
        version: licensingAgreementVersionNumber,
        licensingAgreementPDF: file,
        signedOn: getCurrentDateTime(),
        startDate: getLocalTimeFromDatabaseTime(accountCreationTimestamp),
        endDate: increaseYearsInDate(getLocalTimeFromDatabaseTime(accountCreationTimestamp), 100),
        licensingSignatureNeeded: false,
    };

    const teamTemp: any = {
        id: team?.id,
        licensingAgreement,
        timeModified: getCurrentDateTime(),
    };

    await updateTeamFirestoreAPI(teamTemp);
    setGlobalState({ type: 'updateLicensingAgreement', licensingAgreement });
};
