import { createOpponentReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOpponent';
import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { OpponentObject } from '../interface/OpponentInterface';
import { SportsType } from '../enums/SportsType';

export const demoOpponentName = 'Opponent University';
export const demoOpponentAddress = '610 Purdue Mall, West Lafayette, IN 47907';
export const demoOpponentNotes = '- Our biggest rivals';
export const getDemoOpponentURL = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'https://recsports.nd.edu/club-sports/womens-club-sports/womens-ice-hockey/';
    } if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://und.com/sports/mhockey/schedule/';
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return 'https://sites.google.com/view/cornellrugby/women?authuser=0';
    } if (sportsType === SportsType.MENSRUGBY) {
        return 'https://sites.google.com/view/cornellrugby/';
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'https://recsports.nd.edu/club-sports/womens-club-sports/womens-lacrosse/';
    } if (sportsType === SportsType.MENSLACROSSE) {
        return 'https://recsports.nd.edu/club-sports/mens-club-sports/mens-lacrosse/';
    }
    return '';
};
export const getDemoOpponentLogo = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoOpponentLogo%2FdemoOpponentLogo.png?alt=media&token=f3f3fb08-ec8f-4b4a-8c39-9b642afe1785';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoOpponentLogo%2FdemoOpponentLogo.png?alt=media&token=f3f3fb08-ec8f-4b4a-8c39-9b642afe1785';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoOpponentLogo%2FdemoOpponentLogo.png?alt=media&token=f3f3fb08-ec8f-4b4a-8c39-9b642afe1785';
    }
    return '';
};

export const createDemoOpponent = (organizationId: string, teamId: string, opponentId: string, sportsType: SportsType): OpponentObject => {
    const opponentReference = createOpponentReference(teamId, opponentId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: opponentId,
        urlId: opponentId,
        reference: opponentReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        name: demoOpponentName,
        logo: getDemoOpponentLogo(sportsType),
        address: demoOpponentAddress,
        url: getDemoOpponentURL(sportsType),
        notes: demoOpponentNotes,
        sportsType,
        deleted: false,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
