import { db } from '../FirebaseLibrary';
import { storeInfoId } from '../../catalog/demo/StoreAdminInfoDemo';
import { StoreAdminInfoObject } from '../../catalog/interface/StoreAdminInfoInterface';

export const createStoreAdminInfoReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/read-only`).doc(storeInfoId);
};

export const insertStoreAdminInfoFirestoreAPI = (teamId: string, storeAdminObject: StoreAdminInfoObject) => {
    return createStoreAdminInfoReference(teamId).set(storeAdminObject);
};

export const fetchStoreAdminInfoFirestoreAPI = (teamId: string) => {
    return createStoreAdminInfoReference(teamId).get();
};

export const deleteStoreAdminInfoFirestoreAPI = (teamId: string) => {
    return createStoreAdminInfoReference(teamId).delete();
};
