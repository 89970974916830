import { createContext } from 'react';
// import { demoTeamCommunications, demoTeamPolicies } from '../catalog/demo/team/TeamDemo';
import { AccountType } from '../catalog/enums/AccountType';
import { SportsType } from '../catalog/enums/SportsType';
import { WebsiteType } from '../catalog/enums/WebsiteType';
import { isValidObject, isValidString } from '../catalog/Validity';

const initialState = {
    userLogged: false,
    user: null,
    userEmail: '',
    emailVerified: false,

    teamId: '',
    teamName: '',
    teamObject: {},
    organizationId: '',
    organizationName: '',
    organizationObject: {},
    loginCount: 0,

    accountId: '',
    stripeAccountId: '',
    accountType: AccountType.NONE,
    sportsType: SportsType.NONE,
    websiteType: WebsiteType.WEBSITEANDSTORE,

    referralId: '',
};

/**
 * This is the reducer for global state. It uses account rather than team or organization because account can either be organization or team
 *
 * @param state
 * @param action
 */
function reducerLocal(state: any, action: any) {
    switch (action.type) {
        case 'signInTeam':
            return {
                ...state,
                userLogged: true,
                user: action.user,
                userEmail: action.userEmail,
                emailVerified: action.emailVerified,

                teamId: action.teamObject.id,
                teamName: action.teamObject.name,
                teamObject: action.teamObject,
                organizationId: action.teamObject.organizationId,
                organizationName: action.teamObject.organizationName,
                organizationObject: {},
                loginCount: action.teamObject.loginCount,

                accountId: action.teamObject.id,
                stripeAccountId: isValidString(action.teamObject.stripeAccountId) ? action.teamObject.stripeAccountId : '',
                accountType: AccountType.TEAM,
                sportsType: action.sportsType,
                websiteType: isValidObject(action.teamObject.websiteType) ? action.teamObject.websiteType : WebsiteType.WEBSITEANDSTORE,
            };
        case 'updateTeamLogoAndName':
            return { ...state, teamName: action.name, teamObject: { ...state.teamObject, silhouetteLogo: { ...state.teamObject.silhouetteLogo, logo: action.logo }, name: action.name } };
        case 'updateLicensingAgreement':
            return { ...state, teamObject: { ...state.teamObject, licensingAgreement: action.licensingAgreement } };
        case 'updateStoreLicensingAgreement':
            return { ...state, teamObject: { ...state.teamObject, storeLicensingAgreement: action.storeLicensingAgreement } };
        case 'updateEmailCommunication':
            return { ...state, teamObject: { ...state.teamObject, communications: action.communications } };
        case 'updatePolicy1':
            // if (isValidObject(state.teamObject.policies)) {
            return { ...state, teamObject: { ...state.teamObject, policies: { ...state.teamObject.policies, [action.policyTitle]: action.policyValue } } };
            // }
            // return { ...state, teamObject: { ...state.teamObject, policies: { ...demoTeamPolicies, [action.policyTitle]: action.policyValue } } };
        case 'updatePolicy':
            return { ...state, teamObject: { ...state.teamObject, policies: action.policies } };
        case 'setWebsiteType':
            return { ...state, websiteType: action.websiteType };
        case 'signOutUser':
            return { ...state, userLogged: false, user: null, userEmail: '', accountType: AccountType.NONE, accountObject: null, accountId: null, sportsType: SportsType.NONE };
        case 'setReferralId':
            return { ...state, referralId: action.referralId };
        default:
            return initialState;
    }
}

const StateContext = createContext({
    state: initialState,
    dispatch: () => { return 0; },
});

export const { Provider } = StateContext; // To pass down state and dispatcher

export const reducer = reducerLocal; // We can use it in the main file with useReducer

export const context = StateContext; // useContext

export const defaultState = initialState; // useContext
