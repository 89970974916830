import { createSeasonReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsSeason';
import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createPreDevelopedSeasonReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPreDevelopedSeason';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { SeasonObject } from '../interface/SeasonInterface';
import { TagType } from '../enums/TagType';

export const getDemoSeasonPreDevelopedSeasonId = (sportsType: SportsType) => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'kmQcD1D5ZMYt9N8c8VnD';
    }
    if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'ZTjqWsSqULDWhzAb3cTr';
    }
    if (sportsType === SportsType.WOMENSRUGBY) {
        return 'b1zpVLaWknSU6djDgAil';
    }
    if (sportsType === SportsType.MENSRUGBY) {
        return 'ZbY0Lw5woynOFLYnD5dS';
    }
    if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'svAetTfD46XLwU6vBlsu';
    }
    if (sportsType === SportsType.MENSLACROSSE) {
        return '4RRX4o0mDIFAl1JHigcE';
    }

    return '';
};
export const getDemoSeasonPreDevelopedSeasonReference = (sportsType: SportsType) => {
    return createPreDevelopedSeasonReference(`${getDemoSeasonPreDevelopedSeasonId(sportsType)}`);
};

export const createDemoSeason = (organizationId: string, teamId: string, seasonId: string, tagIds: string[], tagReferences: any[], tagTypes: TagType[], sportsType: SportsType): SeasonObject => {
    const seasonReference = createSeasonReference(teamId, seasonId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);

    return {
        id: seasonId,
        reference: seasonReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        preDevelopedSeasonId: getDemoSeasonPreDevelopedSeasonId(sportsType),
        preDevelopedSeasonReference: getDemoSeasonPreDevelopedSeasonReference(sportsType),
        tagIds,
        tagReferences,
        tagTypes,
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
