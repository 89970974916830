import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { getCurrentDateTime } from '../DateTime';
import { OrganizationObject } from '../interface/OrganizationInterface';

export const demoOrganizationName = 'Demo University';

export const createDemoOrganization = (organizationId: string, organizationName: string): OrganizationObject => {
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: organizationId,
        reference: organizationReference,
        name: organizationName,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
