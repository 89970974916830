import { createMailReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsMail';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { MailerSendMailObject } from '../interface/mailInterface/MailerSendMailInterface';
import { isValidString } from '../Validity';

export const createSignUpMailObject = (
    mailId: string,
    teamId: string,
    teamName: string,
    organizationName: string,
    sportsType: SportsType,
    referralId: string,
    email: string,
): MailerSendMailObject => {
    return {
        id: mailId,
        reference: createMailReference(mailId),
        teamId,
        teamReference: createTeamReference(teamId),
        from: { email: 'admin@optimxsports.com', name: 'OptimX Sports' },
        to: [{ email: 'contact@optimxtechnologies.com', name: 'OptimX Sports' }],
        cc: [],
        bcc: [],
        reply_to: {},
        subject: createSignUpMailSubject(teamName, email, referralId),
        html: createSignUpMailText(teamId, teamName, organizationName, email, referralId),
        text: createSignUpMailText(teamId, teamName, organizationName, email, referralId),
        template_id: '',
        variables: [],
        personalization: [],
        tags: ['signup', 'signup_email', `signup_${teamId}`, `signup_email_${teamId}`, teamId],
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

const createSignUpMailText = (
    teamId: string,
    teamName: string,
    organizationName: string,
    email: string,
    referralId: string,
) => {
    return `Signup --- Team Name is: ${teamName}. Team Id is: ${teamId}. Organization Name is: ${organizationName}. Email is: ${email}. Referral Id: ${referralId}`;
};

const createSignUpMailSubject = (teamName: string, email: string, referralId: string) => {
    if (isValidString(referralId)) {
        return `SIGNUP FROM ${teamName} USING EMAIL: ${email} THROUGH: ${referralId}`;
    }
    return `SIGNUP FROM ${teamName} USING EMAIL: ${email}`;
};
