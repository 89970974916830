/* eslint-disable no-unused-vars */
import React, { memo, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Button, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelineIcon from '@material-ui/icons/Timeline';
import WebIcon from '@material-ui/icons/Web';
import EventIcon from '@material-ui/icons/Event';
import SportsIcon from '@material-ui/icons/Sports';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
import StorefrontIcon from '@material-ui/icons/Storefront';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { context } from '../../../../globalState/GlobalState';
import './css/ExpandedDrawer.css';
import { isValidObject, isValidString } from '../../../../catalog/Validity';
import { MARKETPLACE_NAVLINKS, ANALYTICS_NAVLINKS, ARTICLES_NAVLINKS, CUSTOM_CONTENTS_NAVLINKS, EVENTS_NAVLINKS, FORMS_NAVLINKS, GALLERIES_NAVLINKS, ROSTER_NAVLINKS, SETTINGS_NAVLINKS, STORE_NAVLINKS, HELP_NAVLINKS, WEBSITE_NAVLINKS } from '../../../../catalog/NavLinks';
import TooltipComponent3 from '../../../portal/uiElements/tooltipComponents/TooltipComponent3';
import { WebsiteType } from '../../../../catalog/enums/WebsiteType';
import { getWebsiteURL } from '../../../../catalog/Others';

const initialState = {
    expandedAccordian: '/portal/dashboard',
};

function reducer(state, action) {
    switch (action.type) {
        case 'setExpandedAccordian':
            return { ...state, expandedAccordian: action.expandedAccordian };
        default:
            return initialState;
    }
}

const ExpandedDrawer = (props) => {
    const { children } = props;
    const [state, setState] = useReducer(reducer, initialState);
    const history = useHistory();
    const params = useParams();
    const { globalState } = useContext(context);

    useEffect(() => {
        if (history.location.pathname !== state.expandedAccordian && history.location.pathname !== '/') {
            setState({ type: 'setExpandedAccordian', expandedAccordian: history.location.pathname });
        }
    }, [history.location.pathname, params]);

    const getNavItems = () => {
        if (globalState.websiteType === WebsiteType.STORE) {
            return [
                { title: 'Dashboard', icon: <DashboardIcon className="m-r-20px" />, link: '/portal/dashboard' },
                { title: 'Analytics', icon: <TimelineIcon className="m-r-20px" />, link: '/portal/analytics/store' },
                { title: 'Marketplace', icon: <StorefrontIcon className="m-r-20px" />, link: '/portal/marketplace', navItems: MARKETPLACE_NAVLINKS(globalState.sportsType) },
                { title: 'Website', icon: <WebIcon className="m-r-20px" />, link: '/portal/website', navItems: WEBSITE_NAVLINKS(globalState.websiteType) },
                { title: 'Store, Tickets & Registration Forms', icon: <AttachMoneyIcon className="m-r-20px" />, link: '/portal/store', navItems: isValidString(globalState.stripeAccountId) ? STORE_NAVLINKS() : null },
                { title: 'Forms, Dues & Registrations', icon: <ListAltIcon className="m-r-20px" />, link: '/portal/forms', navItems: FORMS_NAVLINKS() },
                { title: 'Events', icon: <EventIcon className="m-r-20px" />, link: '/portal/events/events' },
                { title: 'Custom', icon: <NoteAddIcon className="m-r-20px" />, link: '/portal/custom', navItems: CUSTOM_CONTENTS_NAVLINKS() },
                { title: 'Help', icon: <LiveHelpIcon className="m-r-20px" />, link: '/portal/tutorials', navItems: HELP_NAVLINKS(globalState.websiteType) },
                { title: 'Settings', icon: <SettingsIcon className="m-r-20px" />, link: '/portal/settings', navItems: SETTINGS_NAVLINKS() },
            ];
        } if (globalState.websiteType === WebsiteType.WEBSITEANDSTORE) {
            return [
                { title: 'Dashboard', icon: <DashboardIcon className="m-r-20px" />, link: '/portal/dashboard' },
                { title: 'Analytics', icon: <TimelineIcon className="m-r-20px" />, link: '/portal/analytics', navItems: ANALYTICS_NAVLINKS() },
                { title: 'Website', icon: <WebIcon className="m-r-20px" />, link: '/portal/website', navItems: WEBSITE_NAVLINKS(globalState.websiteType) },
                { title: 'Marketplace', icon: <StorefrontIcon className="m-r-20px" />, link: '/portal/marketplace', navItems: MARKETPLACE_NAVLINKS(globalState.sportsType) },
                { title: 'Store, Tickets & Registration Forms', icon: <AttachMoneyIcon className="m-r-20px" />, link: '/portal/store', navItems: isValidString(globalState.stripeAccountId) ? STORE_NAVLINKS() : null },
                { title: 'Player Dues & Forms', icon: <ListAltIcon className="m-r-20px" />, link: '/portal/forms', navItems: FORMS_NAVLINKS() },
                { title: 'Seasons & Games', icon: <SportsVolleyballIcon className="m-r-20px" />, link: '/portal/seasons' },
                { title: 'Events & Practice', icon: <EventIcon className="m-r-20px" />, link: '/portal/events', navItems: EVENTS_NAVLINKS() },
                { title: 'Articles', icon: <DescriptionIcon className="m-r-20px" />, link: '/portal/articles', navItems: ARTICLES_NAVLINKS() },
                { title: 'Galleries', icon: <PhotoAlbumIcon className="m-r-20px" />, link: '/portal/galleries', navItems: GALLERIES_NAVLINKS() },
                { title: 'Roster', icon: <SportsHandballIcon className="m-r-20px" />, link: '/portal/roster', navItems: ROSTER_NAVLINKS() },
                { title: 'Coaches & Staff', icon: <SportsIcon className="m-r-20px" />, link: '/portal/coaches' },
                { title: 'Executive Board', icon: <RecentActorsIcon className="m-r-20px" />, link: '/portal/executiveboard' },
                { title: 'Opponents', icon: <SportsKabaddiIcon className="m-r-20px" />, link: '/portal/opponents' },
                { title: 'Leagues', icon: <BusinessIcon className="m-r-20px" />, link: '/portal/leagues' },
                { title: 'Sponsors', icon: <MonetizationOnIcon className="m-r-20px" />, link: '/portal/sponsors' },
                { title: 'Custom', icon: <NoteAddIcon className="m-r-20px" />, link: '/portal/custom', navItems: CUSTOM_CONTENTS_NAVLINKS() },
                { title: 'Help', icon: <LiveHelpIcon className="m-r-20px" />, link: '/portal/tutorials', navItems: HELP_NAVLINKS(globalState.websiteType) },
                { title: 'Settings', icon: <SettingsIcon className="m-r-20px" />, link: '/portal/settings', navItems: SETTINGS_NAVLINKS() },
            ];
        }
        return [];
    };

    const DRAWER_VISBLE_URL_SEGMENTS = ['portal'];
    const firstSegment = history.location.pathname.split('/')[1];

    const fetchTeamLogo = () => {
        if (isValidObject(globalState) && isValidObject(globalState.teamObject) && isValidObject(globalState.teamObject.silhouetteLogo) && isValidObject(globalState.teamObject.silhouetteLogo.logo) && isValidObject(globalState.teamName)) {
            return (<img className="side-drawer-team-logo p-t-10px p-b-10px p-l-10px p-r-10px x-center m-t-20px" src={globalState.teamObject.silhouetteLogo.logo} alt={globalState.teamName || 'Team Logo'} />);
        }
        return null;
    };

    return (
        <>
            {DRAWER_VISBLE_URL_SEGMENTS.includes(firstSegment) ? (
                <Drawer className="side-drawer" variant="permanent" open>
                    {fetchTeamLogo()}
                    <a href={getWebsiteURL(globalState)} target="_blank">
                        <Button className="side-drawer-team-website-button button font-size-m m-t-10px m-b-10px">
                            Visit your Website
                        </Button>
                    </a>
                    <nav className="side-drawer-nav-items full-width m-t-10px">
                        {getNavItems().map((item) => {
                            return (
                                <React.Fragment key={item.link}>
                                    {isValidObject(item.navItems) ? (
                                        <Accordion
                                            expanded={state.expandedAccordian.startsWith(item.link)}
                                            onChange={
                                                () => {
                                                    setState({ type: 'setExpandedAccordian', expandedAccordian: item.link });
                                                    if (!history.location.pathname.startsWith(item.link)) {
                                                        history.push(item.link);
                                                    }
                                                }
                                            }
                                            className="side-drawer-accordian-nav-item"
                                        >
                                            <TooltipComponent3
                                                title={(
                                                    <>
                                                        {item.navItems.map((navItem) => {
                                                            return (
                                                                <NavLink className="text-no-underline" key={navItem.title} to={navItem.link} onClick={() => { setState({ type: 'setExpandedAccordian', expandedAccordian: navItem.link }); }}>
                                                                    <p className="x-center font-size-l font-weight-400 full-width text-left-align p-r-10px text-white text-lightgray-hover m-t-0 m-b-0">
                                                                        {navItem.title}
                                                                    </p>
                                                                </NavLink>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                                placement="right"
                                                interactive
                                            >
                                                <AccordionSummary className={`side-drawer-accordian-nav-item-summary ${history.location.pathname.startsWith(item.link) ? 'side-drawer-accordian-nav-item-summary-active' : ''}`}>
                                                    <p className="x-center font-size-xl font-weight-400 full-width text-left-align p-r-10px">
                                                        {item.icon}
                                                        {item.title}
                                                    </p>
                                                </AccordionSummary>
                                            </TooltipComponent3>
                                            <AccordionDetails className="side-drawer-accordian-nav-item-details">
                                                {item.navItems.map((navItem) => {
                                                    return (
                                                        <NavLink activeClassName="side-drawer-nav-item-active x-center" className="side-drawer-nav-item text-no-underline x-center" key={navItem.title} to={navItem.link} onClick={() => { setState({ type: 'setExpandedAccordian', expandedAccordian: navItem.link }); }}>
                                                            <p className="x-center font-size-l font-weight-400 full-width text-left-align p-r-10px">
                                                                {navItem.title}
                                                            </p>
                                                        </NavLink>
                                                    );
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : (
                                        <NavLink activeClassName="side-drawer-nav-item-active x-center" className={`side-drawer-nav-item text-no-underline x-center ${item.title === 'Store, Tickets & Registration Forms' ? 'side-drawer-nav-item-green' : ''}`} key={item.title} to={item.link} onClick={() => { setState({ type: 'setExpandedAccordian', expandedAccordian: item.link }); }}>
                                            <p className="x-center font-size-xl font-weight-400 full-width text-left-align p-r-10px">
                                                {item.icon}
                                                {item.title}
                                            </p>
                                        </NavLink>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </nav>
                    <div className="p-b-5" />
                    {/* <div className="side-drawer-optimx x-center m-b-20px full-width">
                        <img src={OptimXSportsIcon} className="side-drawer-optimx-logo x-center full-width" alt="OptimX Sports" />
                        <p className="side-drawer-optimx-text text-no-underline text-white font-size-3xl full-width">OPTIMXSPORTS</p>
                    </div> */}
                </Drawer>
            ) : null}
            {children}
        </>
    );
};

ExpandedDrawer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(ExpandedDrawer);
