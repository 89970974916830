// @ts-ignore
import skaler from 'skaler';
import { sendCustomTeamAnalytics, sendErrorTeamAnalytics } from '../../../catalog/Analytics';
import { getCurrentDateTime } from '../../../catalog/DateTime';
import { getDemoStoreInfoObject } from '../../../catalog/demo/StoreDemo';
import { demoTeamCommunications, demoTeamOptimXNotifications, demoTeamPolicies } from '../../../catalog/demo/team/TeamDemo';
import { SportsType } from '../../../catalog/enums/SportsType';
import { ImageObject, linkToImageObject } from '../../../catalog/interface/OtherInterface';
import { TeamCommunicationsObject, TeamFormObject, TeamInfoObject, TeamSocialsObject } from '../../../catalog/interface/TeamInterface';
import { WEBSITE_DOES_NOT_EXIST, FETCH_WEBSITE_ERROR, UPDATE_WEBSITE_ERROR, UPDATE_WEBSITE_SUCCESS, PUBLISH_WEBSITE_ERROR } from '../../../catalog/NotificationComments';
import { convertURLTo1600x900, optimXTesting, randomNumber, resizeImage } from '../../../catalog/Others';
import { isValidObject, isValidString } from '../../../catalog/Validity';
import { insertMailFirestoreAPI } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsMailerSendMail';
import { fetchTeamFirestoreAPI, insertTeamLogoStorageAPI, insertTeamImageStorageAPI, updateTeamFirestoreAPI, insertTeamSilhouetteLogoStorageAPI } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { fetchImageURLStorageAPI } from '../../../firebase/firebaseAPICalls/StorageAPICalls';
import { checkNecessaryImages } from './WebsiteState';
import { MailerSendMailObject } from '../../../catalog/interface/mailInterface/MailerSendMailInterface';
import { createSignInMailObject } from '../../../catalog/mail/SignInEmail';

const saveTeamLogo = async (organizationId: string, organizationName: string, teamId: string, teamName: string, image: ImageObject): Promise<any> => {
    if (image && image.file) {
        let finalLogo = {};
        await Promise.all([32, 196, 512, 1024].map(async (height, index) => {
            const file = await skaler(image.file, { height });
            const { ref, promise } = insertTeamLogoStorageAPI(organizationId, organizationName, teamId, teamName, file, height);
            await promise;
            let url = await fetchImageURLStorageAPI(false, 10, ref);
            url = convertURLTo1600x900(url);
            finalLogo = { ...finalLogo, [`logo${index + 1}`]: url };
        }));
        const { ref, promise } = insertTeamLogoStorageAPI(organizationId, organizationName, teamId, teamName, image.file, -1);
        await promise;
        let url = await fetchImageURLStorageAPI(false, 10, ref);
        url = convertURLTo1600x900(url);
        finalLogo = { ...finalLogo, logo: url };
        return finalLogo;
    }
    return {
        logo: '',
        logo1: '',
        logo2: '',
        logo3: '',
        logo4: '',
    };
};

const saveTeamSilhouetteLogo = async (organizationId: string, organizationName: string, teamId: string, teamName: string, image: ImageObject): Promise<any> => {
    if (image && image.file) {
        let finalLogo = {};
        await Promise.all([32, 196, 512, 1024].map(async (height, index) => {
            const file = await skaler(image.file, { height });
            const { ref, promise } = insertTeamSilhouetteLogoStorageAPI(organizationId, organizationName, teamId, teamName, file, height);
            await promise;
            let url = await fetchImageURLStorageAPI(false, 10, ref);
            url = convertURLTo1600x900(url);
            finalLogo = { ...finalLogo, [`logo${index + 1}`]: url };
        }));
        const { ref, promise } = insertTeamSilhouetteLogoStorageAPI(organizationId, organizationName, teamId, teamName, image.file, -1);
        await promise;
        let url = await fetchImageURLStorageAPI(false, 10, ref);
        url = convertURLTo1600x900(url);
        finalLogo = { ...finalLogo, logo: url };
        return finalLogo;
    }
    return {
        logo: '',
        logo1: '',
        logo2: '',
        logo3: '',
        logo4: '',
    };
};

export const saveTeamImage = async (organizationId: string, organizationName: string, teamId: string, teamName: string, imageId: string, imageTitle: string, image: ImageObject): Promise<string> => {
    if (image && image.file) {
        const file = await resizeImage(image.file, 1600, 700, 100);
        const { ref, promise } = insertTeamImageStorageAPI(organizationId, organizationName, teamId, teamName, imageId, imageTitle, file);
        await promise;
        let url = await fetchImageURLStorageAPI(false, 10, ref);
        url = convertURLTo1600x900(url);
        return url;
    }
    if (isValidObject(image) && isValidString(image.link)) {
        return image.link;
    }
    return '';
};

export const fetchWebsiteAPI = async (teamId: string, setState: Function, snackbar: Function) => {
    try {
        const teamDoc = await fetchTeamFirestoreAPI(teamId);
        const team = teamDoc.data();

        if (isValidObject(team) && teamDoc.exists) {
            setState({ type: 'fetchWebsite',
                name: team?.name,
                email: team?.email,
                url: team?.url,
                logo: linkToImageObject(team?.logo.logo),
                silhouetteLogo: linkToImageObject(team?.silhouetteLogo.logo),
                info: team?.info,
                storeInfo: {
                    title: isValidObject(team?.storeInfo) && isValidObject(team?.storeInfo.title) ? team?.storeInfo.title : getDemoStoreInfoObject(team?.name, team?.email).title,
                    address: isValidObject(team?.storeInfo) && isValidObject(team?.storeInfo.address) ? team?.storeInfo.address : getDemoStoreInfoObject(team?.name, team?.email).address,
                    storeActivate: isValidObject(team?.storeInfo) && isValidObject(team?.storeInfo.storeActivate) ? team?.storeInfo.storeActivate : getDemoStoreInfoObject(team?.name, team?.email).storeActivate,
                    productsActivate: isValidObject(team?.storeInfo) && isValidObject(team?.storeInfo.productsActivate) ? team?.storeInfo.productsActivate : getDemoStoreInfoObject(team?.name, team?.email).productsActivate,
                    ticketsActivate: isValidObject(team?.storeInfo) && isValidObject(team?.storeInfo.ticketsActivate) ? team?.storeInfo.ticketsActivate : getDemoStoreInfoObject(team?.name, team?.email).ticketsActivate,
                    formsActivate: isValidObject(team?.storeInfo) && isValidObject(team?.storeInfo.formsActivate) ? team?.storeInfo.formsActivate : getDemoStoreInfoObject(team?.name, team?.email).formsActivate,
                    productNotificationEmails: isValidObject(team?.storeInfo) && isValidObject(team?.storeInfo.productNotificationEmails) ? team?.storeInfo.productNotificationEmails : getDemoStoreInfoObject(team?.name, team?.email).productNotificationEmails,
                    ticketNotificationEmails: isValidObject(team?.storeInfo) && isValidObject(team?.storeInfo.ticketNotificationEmails) ? team?.storeInfo.ticketNotificationEmails : getDemoStoreInfoObject(team?.name, team?.email).ticketNotificationEmails,
                },
                website: { ...team?.website,
                    images: checkNecessaryImages(team?.website.images).map((image: any) => {
                        return ({ id: image.id, imageTitle: image.imageTitle, image: linkToImageObject(image.image), title: image.title, description: image.description, link: isValidString(image.link) ? image.link : '' });
                    }),
                    styles:
                    { ...team?.website.styles,
                        colors: { ...team?.website.styles.colors,
                            fontColor: isValidString(team?.website.styles.colors.fontColor) ? team?.website.styles.colors.fontColor : '#ffffff',
                        },
                        bannerText: (isValidString(team?.website.styles.bannerText) && team?.website.styles.bannerText !== 'We will welcome you soon to our stadium') ? team?.website.styles.bannerText : '',
                    },
                },
                form: isValidObject(team?.form) ? team?.form : { emails: [{ id: randomNumber(), email: team?.email }], phones: [], emailsNotAllowed: {}, phonesNotAllowed: {} },
                socials: team?.socials,
                optimXNotifications: isValidObject(team?.optimXNotifications) ? team?.optimXNotifications : demoTeamOptimXNotifications,
                loginCount: isValidObject(team?.loginCount) ? team?.loginCount : 1,
                policies: isValidObject(team?.policies) ? team?.policies : demoTeamPolicies,
                stripeAccountId: isValidObject(team?.stripeAccountId) ? team?.stripeAccountId : '',
                communications: isValidObject(team?.communications) ? team?.communications : demoTeamCommunications,
            });
        } else {
            sendErrorTeamAnalytics(WEBSITE_DOES_NOT_EXIST);
            snackbar(WEBSITE_DOES_NOT_EXIST, { variant: 'error' });
        }
    } catch (error) {
        sendErrorTeamAnalytics(FETCH_WEBSITE_ERROR);
        snackbar(FETCH_WEBSITE_ERROR, { variant: 'error' });
    }
};

export const updateWebsiteInfoAPI = async (
    organizationId: string,
    organizationName: string,
    teamId: string,
    teamName: string,
    logo: any,
    silhouetteLogo: any,
    teamInfo: TeamInfoObject,
    setLocalState: Function,
    setGlobalState: Function,
    snackbar: Function,
) => {
    try {
        let teamTemp: any = {
            id: teamId,
            info: teamInfo,
            timeModified: getCurrentDateTime(),
        };

        if (isValidObject(logo.file)) {
            const teamLogoLinks = await saveTeamLogo(organizationId, organizationName, teamId, teamName, logo);
            teamTemp = { ...teamTemp, logo: teamLogoLinks };
        }

        if (isValidObject(silhouetteLogo.file)) {
            const teamLogoLinks = await saveTeamSilhouetteLogo(organizationId, organizationName, teamId, teamName, silhouetteLogo);
            teamTemp = { ...teamTemp, silhouetteLogo: teamLogoLinks };
            setTimeout(() => {
                setGlobalState({ type: 'updateTeamLogoAndName', logo: teamLogoLinks.logo, name: teamName });
            }, 2000);
        }

        await updateTeamFirestoreAPI(teamTemp);
        sendCustomTeamAnalytics('website_info_update');
        snackbar(UPDATE_WEBSITE_SUCCESS, { variant: 'success' });
        setLocalState({ type: 'setDataChanged', dataChanged: false });
    } catch (error) {
        sendErrorTeamAnalytics(UPDATE_WEBSITE_ERROR);
        snackbar(UPDATE_WEBSITE_ERROR, { variant: 'error' });
    }
};

export const updateWebsiteDesignAPI = async (
    organizationId: string,
    organizationName: string,
    teamId: string,
    teamName: string,
    sportsType: SportsType,
    email: string,
    url: string,
    teamWebsite: any,
    setLocalState: Function,
    snackbar: Function,
) => {
    try {
        const teamDoc = await fetchTeamFirestoreAPI(teamId);
        const teamWebsiteFromDatabase = teamDoc.data()?.website;

        const teamTemp = {
            id: teamId,
            website: { ...teamWebsiteFromDatabase,
                styles: { ...teamWebsite.styles,
                    colors: { ...teamWebsite.styles.colors,
                        primaryColor: teamWebsite.styles.colors.primaryColor,
                        secondaryColor: teamWebsite.styles.colors.secondaryColor,
                        fontColor: teamWebsite.styles.colors.fontColor,
                    },
                },
            },
            timeModified: getCurrentDateTime(),
        };

        await updateTeamFirestoreAPI(teamTemp);
        sendCustomTeamAnalytics('website_design_update');
        snackbar(UPDATE_WEBSITE_SUCCESS, { variant: 'success' });
        setLocalState({ type: 'setDataChanged', dataChanged: false });
    } catch (error) {
        sendErrorTeamAnalytics(UPDATE_WEBSITE_ERROR);
        snackbar(UPDATE_WEBSITE_ERROR, { variant: 'error' });
    }
};

export const updateWebsiteSEOAPI = async (
    organizationId: string,
    organizationName: string,
    teamId: string,
    teamName: string,
    sportsType: SportsType,
    email: string,
    url: string,
    teamWebsite: any,
    setLocalState: Function,
    snackbar: Function,
) => {
    try {
        const teamDoc = await fetchTeamFirestoreAPI(teamId);
        const teamWebsiteFromDatabase = teamDoc.data()?.website;

        const teamTemp = {
            id: teamId,
            website: { ...teamWebsiteFromDatabase, seoInfo: teamWebsite.seoInfo },
            timeModified: getCurrentDateTime(),
        };

        await updateTeamFirestoreAPI(teamTemp);
        sendCustomTeamAnalytics('website_seo_update');
        snackbar(UPDATE_WEBSITE_SUCCESS, { variant: 'success' });
        setLocalState({ type: 'setDataChanged', dataChanged: false });
    } catch (error) {
        sendErrorTeamAnalytics(UPDATE_WEBSITE_ERROR);
        snackbar(UPDATE_WEBSITE_ERROR, { variant: 'error' });
    }
};

export const updateWebsiteImagesAPI = async (
    organizationId: string,
    organizationName: string,
    teamId: string,
    teamName: string,
    teamWebsite: any,
    setLocalState: Function,
    snackbar: Function,
) => {
    try {
        const teamDoc = await fetchTeamFirestoreAPI(teamId);
        const teamWebsiteFromDatabase = teamDoc.data()?.website;

        const finalImages = Array<any>(teamWebsite.images.length);
        await Promise.all(teamWebsite.images.map(async (tempImage: any, index: number) => {
            if (isValidObject(tempImage.image.file)) {
                const imageLink = await saveTeamImage(organizationId, organizationName, teamId, teamName, tempImage.id, tempImage.imageTitle, tempImage.image);
                finalImages[index] = { ...tempImage, image: imageLink };
            } else {
                finalImages[index] = { ...tempImage, image: tempImage.image.link };
            }
        }));

        const teamTemp = {
            id: teamId,
            website: { ...teamWebsiteFromDatabase, images: finalImages },
            timeModified: getCurrentDateTime(),
        };

        await updateTeamFirestoreAPI(teamTemp);
        sendCustomTeamAnalytics('website_images_update');
        snackbar(UPDATE_WEBSITE_SUCCESS, { variant: 'success' });
        setLocalState({ type: 'setDataChanged', dataChanged: false });
    } catch (error) {
        sendErrorTeamAnalytics(UPDATE_WEBSITE_ERROR);
        snackbar(UPDATE_WEBSITE_ERROR, { variant: 'error' });
    }
};

export const updateWebsiteSocialsAPI = async (
    teamId: string,
    teamSocials: TeamSocialsObject,
    setLocalState: Function,
    snackbar: Function,
) => {
    try {
        const newTeamSocials = { ...teamSocials, twitterURL: teamSocials.twitterURL.replace('x.com', 'twitter.com') };
        const teamTemp = {
            id: teamId,
            socials: newTeamSocials,
            timeModified: getCurrentDateTime(),
        };

        await updateTeamFirestoreAPI(teamTemp);
        sendCustomTeamAnalytics('website_socials_update');
        snackbar(UPDATE_WEBSITE_SUCCESS, { variant: 'success' });
        setLocalState({ type: 'setDataChanged', dataChanged: false });
    } catch (error) {
        sendErrorTeamAnalytics(UPDATE_WEBSITE_ERROR);
        snackbar(UPDATE_WEBSITE_ERROR, { variant: 'error' });
    }
};

export const updateWebsiteFormAPI = async (
    teamId: string,
    teamForm: TeamFormObject,
    setLocalState: Function,
    snackbar: Function,
) => {
    try {
        const teamTemp = {
            id: teamId,
            form: teamForm,
            timeModified: getCurrentDateTime(),
        };

        await updateTeamFirestoreAPI(teamTemp);
        sendCustomTeamAnalytics('website_form_update');
        snackbar(UPDATE_WEBSITE_SUCCESS, { variant: 'success' });
        setLocalState({ type: 'setDataChanged', dataChanged: false });
    } catch (error) {
        sendErrorTeamAnalytics(UPDATE_WEBSITE_ERROR);
        snackbar(UPDATE_WEBSITE_ERROR, { variant: 'error' });
    }
};

export const updateWebsiteCommunicationsAPI = async (
    teamId: string,
    teamCommunications: TeamCommunicationsObject,
    setLocalState: Function,
    snackbar: Function,
) => {
    try {
        const teamTemp = {
            id: teamId,
            communications: teamCommunications,
            timeModified: getCurrentDateTime(),
        };

        await updateTeamFirestoreAPI(teamTemp);
        sendCustomTeamAnalytics('website_email_communication_update');
        snackbar(UPDATE_WEBSITE_SUCCESS, { variant: 'success' });
        setLocalState({ type: 'setDataChanged', dataChanged: false });
    } catch (error) {
        sendErrorTeamAnalytics(UPDATE_WEBSITE_ERROR);
        snackbar(UPDATE_WEBSITE_ERROR, { variant: 'error' });
    }
};

export const signInEmailWebsiteAPI = async (
    organizationId: string,
    organizationName: string,
    teamId: string,
    teamName: string,
    sportsType: SportsType,
    email: string,
    url: string,
    snackbar: Function,
) => {
    try {
        if (!optimXTesting) {
            const mailId = randomNumber();
            const mailObject: MailerSendMailObject = createSignInMailObject(mailId, teamId, teamName, organizationId, organizationName, sportsType, email, url);
            await insertMailFirestoreAPI(mailObject);
        }
    } catch (error) {
        sendErrorTeamAnalytics(PUBLISH_WEBSITE_ERROR);
        snackbar(PUBLISH_WEBSITE_ERROR, { variant: 'error' });
    }
};
