import { ArticleObject } from '../../catalog/interface/ArticleInterface';
import { GalleryObject } from '../../catalog/interface/GalleryInterface';
import { IceHockeyGameCoachObject, getCoachIdsFromIceHockeyGameObject, getIceHockeyGameCoachObjectFromIceHockeyGameObject } from '../../catalog/interface/gameInterface/iceHockey/IceHockeyGameCoachInterface';
import { IceHockeyGameObject } from '../../catalog/interface/gameInterface/iceHockey/IceHockeyGameInterface';
import { getPlayerIdsFromIceHockeyGameObject, IceHockeyGamePlayerObject, getIceHockeyGamePlayerObjectFromIceHockeyGameObject } from '../../catalog/interface/gameInterface/iceHockey/IceHockeyGamePlayerInterface';
import { RugbyGameObject } from '../../catalog/interface/gameInterface/rugby/RugbyGameInterface';
import { getCoachIdsFromRugbyGameObject, getRugbyGameCoachObjectFromRugbyGameObject, RugbyGameCoachObject } from '../../catalog/interface/gameInterface/rugby/RugbyGameCoachInterface';
import { getPlayerIdsFromRugbyGameObject, getRugbyGamePlayerObjectFromRugbyGameObject, RugbyGamePlayerObject } from '../../catalog/interface/gameInterface/rugby/RugbyGamePlayerInterface';
import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { SportsType } from '../../catalog/enums/SportsType';
import { PhotoObject } from '../../catalog/interface/PhotoInterface';
import { isValidObject, isValidString } from '../../catalog/Validity';
import { db, storageRef } from '../FirebaseLibrary';
import { createArticleReference } from './FirebaseAPICallsArticle';
import { createGalleryReference } from './FirebaseAPICallsGallery';
import { createGameCoachReference } from './FirebaseAPICallsGameCoach';
import { createGamePlayerReference } from './FirebaseAPICallsGamePlayer';
import { createPhotoReference } from './FirebaseAPICallsPhoto';
import { createTicketReference } from './FirebaseAPICallsTicket';
import { getCurrentDateTime } from '../../catalog/DateTime';
import { getLacrosseGamePlayerObjectFromLacrosseGameObject, getPlayerIdsFromLacrosseGameObject, LacrosseGamePlayerObject } from '../../catalog/interface/gameInterface/lacrosse/LacrosseGamePlayerInterface';
import { getCoachIdsFromLacrosseGameObject, getLacrosseGameCoachObjectFromLacrosseGameObject, LacrosseGameCoachObject } from '../../catalog/interface/gameInterface/lacrosse/LacrosseGameCoachInterface';
import { LacrosseGameObject } from '../../catalog/interface/gameInterface/lacrosse/LacrosseGameInterface';

export const createGamesReference = () => {
    return db.collection('games');
};

export const createGameReference = (gameId: string) => {
    return createGamesReference().doc(gameId);
};

export const fetchGameFirestoreAPI = (gameId: string) => {
    return createGameReference(gameId).get();
};

export const insertGameFirestoreAPI = (gameObject: IceHockeyGameObject | RugbyGameObject) => {
    return createGameReference(gameObject.id).set(gameObject);
};

export const insertIceHockeyGameArticleAndGalleryFirestoreAPI = (gameObject: IceHockeyGameObject, articleObject: ArticleObject, galleryObject: GalleryObject, photoObjects: PhotoObject[]) => {
    const batch = db.batch();

    if (gameObject.sportsType !== SportsType.WOMENSICEHOCKEY && gameObject.sportsType !== SportsType.MENSICEHOCKEY) {
        return null;
    }

    const playerIds = getPlayerIdsFromIceHockeyGameObject(gameObject);
    const gamePlayerObjects: (IceHockeyGamePlayerObject)[] = [];
    playerIds.forEach((playerId) => {
        if (isValidString(playerId)) {
            gamePlayerObjects.push(getIceHockeyGamePlayerObjectFromIceHockeyGameObject(gameObject, playerId));
        }
    });

    const coachIds = getCoachIdsFromIceHockeyGameObject(gameObject);
    const gameCoachObjects: (IceHockeyGameCoachObject)[] = [];
    coachIds.forEach((coachId) => {
        if (isValidString(coachId)) {
            gameCoachObjects.push(getIceHockeyGameCoachObjectFromIceHockeyGameObject(gameObject, coachId));
        }
    });

    photoObjects.forEach((photoObject) => {
        batch.set(createPhotoReference(photoObject.id), photoObject);
    });
    batch.set(createGalleryReference(galleryObject.id), galleryObject);
    batch.set(createArticleReference(articleObject.id), articleObject);
    batch.set(createGameReference(gameObject.id), gameObject);
    gamePlayerObjects.forEach((gamePlayerObject) => {
        batch.set(createGamePlayerReference(gameObject.id, gamePlayerObject.id), gamePlayerObject);
    });
    gameCoachObjects.forEach((gameCoachObject) => {
        batch.set(createGameCoachReference(gameObject.id, gameCoachObject.id), gameCoachObject);
    });

    return batch.commit();
};

export const insertRugbyGameArticleAndGalleryFirestoreAPI = (gameObject: RugbyGameObject, articleObject: ArticleObject, galleryObject: GalleryObject, photoObjects: PhotoObject[]) => {
    const batch = db.batch();

    if (gameObject.sportsType !== SportsType.WOMENSRUGBY && gameObject.sportsType !== SportsType.MENSRUGBY) {
        return null;
    }

    const playerIds = getPlayerIdsFromRugbyGameObject(gameObject);
    const gamePlayerObjects: (RugbyGamePlayerObject)[] = [];
    playerIds.forEach((playerId) => {
        if (isValidString(playerId)) {
            gamePlayerObjects.push(getRugbyGamePlayerObjectFromRugbyGameObject(gameObject, playerId));
        }
    });

    const coachIds = getCoachIdsFromRugbyGameObject(gameObject);
    const gameCoachObjects: (RugbyGameCoachObject)[] = [];
    coachIds.forEach((coachId) => {
        if (isValidString(coachId)) {
            gameCoachObjects.push(getRugbyGameCoachObjectFromRugbyGameObject(gameObject, coachId));
        }
    });

    photoObjects.forEach((photoObject) => {
        batch.set(createPhotoReference(photoObject.id), photoObject);
    });
    batch.set(createGalleryReference(galleryObject.id), galleryObject);
    batch.set(createArticleReference(articleObject.id), articleObject);
    batch.set(createGameReference(gameObject.id), gameObject);
    gamePlayerObjects.forEach((gamePlayerObject) => {
        batch.set(createGamePlayerReference(gameObject.id, gamePlayerObject.id), gamePlayerObject);
    });
    gameCoachObjects.forEach((gameCoachObject) => {
        batch.set(createGameCoachReference(gameObject.id, gameCoachObject.id), gameCoachObject);
    });

    return batch.commit();
};

export const insertLacrosseGameArticleAndGalleryFirestoreAPI = (gameObject: LacrosseGameObject, articleObject: ArticleObject, galleryObject: GalleryObject, photoObjects: PhotoObject[]) => {
    const batch = db.batch();

    if (gameObject.sportsType !== SportsType.WOMENSLACROSSE && gameObject.sportsType !== SportsType.MENSLACROSSE) {
        return null;
    }

    const playerIds = getPlayerIdsFromLacrosseGameObject(gameObject);
    const gamePlayerObjects: (LacrosseGamePlayerObject)[] = [];
    playerIds.forEach((playerId) => {
        if (isValidString(playerId)) {
            gamePlayerObjects.push(getLacrosseGamePlayerObjectFromLacrosseGameObject(gameObject, playerId));
        }
    });

    const coachIds = getCoachIdsFromLacrosseGameObject(gameObject);
    const gameCoachObjects: (LacrosseGameCoachObject)[] = [];
    coachIds.forEach((coachId) => {
        if (isValidString(coachId)) {
            gameCoachObjects.push(getLacrosseGameCoachObjectFromLacrosseGameObject(gameObject, coachId));
        }
    });

    photoObjects.forEach((photoObject) => {
        batch.set(createPhotoReference(photoObject.id), photoObject);
    });
    batch.set(createGalleryReference(galleryObject.id), galleryObject);
    batch.set(createArticleReference(articleObject.id), articleObject);
    batch.set(createGameReference(gameObject.id), gameObject);
    gamePlayerObjects.forEach((gamePlayerObject) => {
        batch.set(createGamePlayerReference(gameObject.id, gamePlayerObject.id), gamePlayerObject);
    });
    gameCoachObjects.forEach((gameCoachObject) => {
        batch.set(createGameCoachReference(gameObject.id, gameCoachObject.id), gameCoachObject);
    });

    return batch.commit();
};

export const updateGameFirestoreAPI = (gameObject: any) => {
    return createGameReference(gameObject.id).update(gameObject);
};

export const updateIceHockeyGameArticleGalleryAndTicketsFirestoreAPI = (gameObject: any, articleObject: any, galleryObject: any, photoObjects: any[], oldGamePlayerIds: string[], ticketIds: string[], ticketObject: any) => {
    const batch = db.batch();

    ticketIds.forEach((ticketId) => {
        batch.update(createTicketReference(ticketId), ticketObject);
    });

    const playerIds = getPlayerIdsFromIceHockeyGameObject(gameObject);
    const gamePlayerObjects: IceHockeyGamePlayerObject[] = [];
    playerIds.forEach((playerId) => {
        if (isValidString(playerId)) {
            gamePlayerObjects.push(getIceHockeyGamePlayerObjectFromIceHockeyGameObject(gameObject, playerId));
        }
    });

    const coachIds = getCoachIdsFromIceHockeyGameObject(gameObject);
    const gameCoachObjects: IceHockeyGameCoachObject[] = [];
    coachIds.forEach((coachId) => {
        if (isValidString(coachId)) {
            gameCoachObjects.push(getIceHockeyGameCoachObjectFromIceHockeyGameObject(gameObject, coachId));
        }
    });

    photoObjects.forEach((photoObject) => {
        if (isValidObject(photoObject.reference)) {
            batch.set(createPhotoReference(photoObject.id), photoObject);
        } else {
            batch.update(createPhotoReference(photoObject.id), photoObject);
        }
    });
    batch.update(createGalleryReference(galleryObject.id), galleryObject);
    batch.update(createArticleReference(articleObject.id), articleObject);
    oldGamePlayerIds.forEach((oldGamePlayerId) => {
        batch.delete(createGamePlayerReference(gameObject.id, oldGamePlayerId));
    });
    batch.delete(createGameReference(gameObject.id));
    batch.set(createGameReference(gameObject.id), gameObject);
    gamePlayerObjects.forEach((gamePlayerObject) => {
        batch.set(createGamePlayerReference(gameObject.id, gamePlayerObject.id), gamePlayerObject);
    });
    gameCoachObjects.forEach((gameCoachObject) => {
        batch.set(createGameCoachReference(gameObject.id, gameCoachObject.id), gameCoachObject);
    });

    return batch.commit();
};

export const updateRugbyGameArticleGalleryAndTicketsFirestoreAPI = (gameObject: any, articleObject: any, galleryObject: any, photoObjects: any[], oldGamePlayerIds: string[], ticketIds: string[], ticketObject: any) => {
    const batch = db.batch();

    ticketIds.forEach((ticketId) => {
        batch.update(createTicketReference(ticketId), ticketObject);
    });

    const playerIds = getPlayerIdsFromRugbyGameObject(gameObject);
    const gamePlayerObjects: RugbyGamePlayerObject[] = [];
    playerIds.forEach((playerId) => {
        if (isValidString(playerId)) {
            gamePlayerObjects.push(getRugbyGamePlayerObjectFromRugbyGameObject(gameObject, playerId));
        }
    });

    const coachIds = getCoachIdsFromIceHockeyGameObject(gameObject);
    const gameCoachObjects: RugbyGameCoachObject[] = [];
    coachIds.forEach((coachId) => {
        if (isValidString(coachId)) {
            gameCoachObjects.push(getRugbyGameCoachObjectFromRugbyGameObject(gameObject, coachId));
        }
    });

    photoObjects.forEach((photoObject) => {
        if (isValidObject(photoObject.reference)) {
            batch.set(createPhotoReference(photoObject.id), photoObject);
        } else {
            batch.update(createPhotoReference(photoObject.id), photoObject);
        }
    });
    batch.update(createGalleryReference(galleryObject.id), galleryObject);
    batch.update(createArticleReference(articleObject.id), articleObject);
    oldGamePlayerIds.forEach((oldGamePlayerId) => {
        batch.delete(createGamePlayerReference(gameObject.id, oldGamePlayerId));
    });
    batch.delete(createGameReference(gameObject.id));
    batch.set(createGameReference(gameObject.id), gameObject);
    gamePlayerObjects.forEach((gamePlayerObject) => {
        batch.set(createGamePlayerReference(gameObject.id, gamePlayerObject.id), gamePlayerObject);
    });
    gameCoachObjects.forEach((gameCoachObject) => {
        batch.set(createGameCoachReference(gameObject.id, gameCoachObject.id), gameCoachObject);
    });

    return batch.commit();
};

export const updateLacrosseGameArticleGalleryAndTicketsFirestoreAPI = (gameObject: any, articleObject: any, galleryObject: any, photoObjects: any[], oldGamePlayerIds: string[], ticketIds: string[], ticketObject: any) => {
    const batch = db.batch();

    ticketIds.forEach((ticketId) => {
        batch.update(createTicketReference(ticketId), ticketObject);
    });

    const playerIds = getPlayerIdsFromLacrosseGameObject(gameObject);
    const gamePlayerObjects: LacrosseGamePlayerObject[] = [];
    playerIds.forEach((playerId) => {
        if (isValidString(playerId)) {
            gamePlayerObjects.push(getLacrosseGamePlayerObjectFromLacrosseGameObject(gameObject, playerId));
        }
    });

    const coachIds = getCoachIdsFromLacrosseGameObject(gameObject);
    const gameCoachObjects: LacrosseGameCoachObject[] = [];
    coachIds.forEach((coachId) => {
        if (isValidString(coachId)) {
            gameCoachObjects.push(getLacrosseGameCoachObjectFromLacrosseGameObject(gameObject, coachId));
        }
    });

    photoObjects.forEach((photoObject) => {
        if (isValidObject(photoObject.reference)) {
            batch.set(createPhotoReference(photoObject.id), photoObject);
        } else {
            batch.update(createPhotoReference(photoObject.id), photoObject);
        }
    });
    batch.update(createGalleryReference(galleryObject.id), galleryObject);
    batch.update(createArticleReference(articleObject.id), articleObject);
    oldGamePlayerIds.forEach((oldGamePlayerId) => {
        batch.delete(createGamePlayerReference(gameObject.id, oldGamePlayerId));
    });
    batch.delete(createGameReference(gameObject.id));
    batch.set(createGameReference(gameObject.id), gameObject);
    gamePlayerObjects.forEach((gamePlayerObject) => {
        batch.set(createGamePlayerReference(gameObject.id, gamePlayerObject.id), gamePlayerObject);
    });
    gameCoachObjects.forEach((gameCoachObject) => {
        batch.set(createGameCoachReference(gameObject.id, gameCoachObject.id), gameCoachObject);
    });

    return batch.commit();
};

export const deleteGameFirestoreAPI = (gameId: string) => {
    return createGameReference(gameId).delete();
};

export const deleteGameArticleGalleryAndTicketsFirestoreAPI = (gameId: string, articleId: string, galleryId: string, gamePlayerIds: string[], gameCoachIds: string[], ticketIds: string[], pseudoDeleteTickets: boolean) => {
    const gameRef = createGameReference(gameId);
    const articleRef = createArticleReference(articleId);
    const galleryRef = createGalleryReference(galleryId);
    const gamePlayerRefs = gamePlayerIds.map((gamePlayerId) => {
        return createGamePlayerReference(gameId, gamePlayerId);
    });
    const gameCoachRefs = gameCoachIds.map((gameCoachId) => {
        return createGameCoachReference(gameId, gameCoachId);
    });

    return db.runTransaction(async (transaction) => {
        const galleryDoc: any = await transaction.get(galleryRef);
        if (galleryDoc.exists && isValidObject(galleryDoc.data())) {
            const { photoIds } = galleryDoc.data();
            await Promise.all(photoIds.map(async (photoId: any) => {
                const photoDoc: any = await transaction.get(createPhotoReference(photoId));
                if (photoDoc.exists && isValidObject(photoDoc.data())) {
                    transaction.delete(createPhotoReference(photoId));
                }
            }));
            await Promise.all(gamePlayerRefs.map(async (gamePlayerRef: any) => {
                transaction.delete(gamePlayerRef);
            }));
            await Promise.all(gameCoachRefs.map(async (gameCoachRef: any) => {
                transaction.delete(gameCoachRef);
            }));
            if (pseudoDeleteTickets) {
                await Promise.all(ticketIds.map(async (ticketId: any) => {
                    const ticketDoc: any = await transaction.get(createTicketReference(ticketId));
                    if (ticketDoc.exists && isValidObject(ticketDoc.data())) {
                        transaction.update(createTicketReference(ticketId), { deleted: true, onTicketUpdateNeeded: true, timeModified: getCurrentDateTime() });
                    }
                }));
            } else {
                await Promise.all(ticketIds.map(async (ticketId: any) => {
                    const ticketDoc: any = await transaction.get(createTicketReference(ticketId));
                    if (ticketDoc.exists && isValidObject(ticketDoc.data())) {
                        transaction.delete(createTicketReference(ticketId));
                    }
                }));
            }
            transaction.delete(galleryRef);
            transaction.delete(articleRef);
            transaction.delete(gameRef);
        }
    });
};

export const fetchAllAllGamesFirestoreAPI = () => {
    return createGamesReference().get();
};

export const fetchGamesFirestoreAPI = (teamId: string, seasonId: string) => {
    return createGamesReference().where('teamId', '==', teamId).where('seasonId', '==', seasonId).orderBy('timeOfGame', 'desc')
        .get();
};

export const fetchUpcomingGamesFirestoreAPI = (teamId: string, seasonId: string) => {
    return createGamesReference().where('teamId', '==', teamId).where('seasonId', '==', seasonId).where('timeOfGame', '>=', new Date())
        .orderBy('timeOfGame')
        .get();
};

export const fetchPreviousGamesFirestoreAPI = (teamId: string, seasonId: string) => {
    return createGamesReference().where('teamId', '==', teamId).where('seasonId', '==', seasonId).where('timeOfGame', '<=', new Date())
        .orderBy('timeOfGame', 'desc')
        .get();
};

export const fetchGamesByLeagueFirestoreAPI = (teamId: string, leagueId: string) => {
    return createGamesReference().where('teamId', '==', teamId).where('leagueId', '==', leagueId).orderBy('timeOfGame', 'desc')
        .get();
};

export const fetchUpcomingGamesByLeagueFirestoreAPI = (teamId: string, leagueId: string) => {
    return createGamesReference().where('teamId', '==', teamId).where('leagueId', '==', leagueId).where('timeOfGame', '>=', new Date())
        .orderBy('timeOfGame')
        .get();
};

export const fetchPreviousGamesByLeagueFirestoreAPI = (teamId: string, leagueId: string) => {
    return createGamesReference().where('teamId', '==', teamId).where('leagueId', '==', leagueId).where('timeOfGame', '<=', new Date())
        .orderBy('timeOfGame', 'desc')
        .get();
};

export const fetchAllGamesFirestoreAPI = (teamId: string) => {
    return createGamesReference().where('teamId', '==', teamId)
        .get();
};

export const fetchFirstUpcomingHomeGameFirestoreAPI = (teamId: string) => {
    return createGamesReference().where('teamId', '==', teamId).where('timeOfGame', '>=', new Date()).where('homeGame', '==', true)
        .orderBy('timeOfGame')
        .limit(1)
        .get();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createGameFeatureImageRefPathStorageAPI = (gameId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `games/${gameId}/featureImage/featureImage_1600x900.png`;
    }
    return `games/${gameId}/featureImage/featureImage.png`;
};

export const createGameArticleImageRefPathStorageAPI = (gameId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `games/${gameId}/articleImage/articleImage_1600x900.png`;
    }
    return `games/${gameId}/articleImage/articleImage.png`;
};

export const insertGameFeatureImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, gameId: string, gameTitle: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: gameId,
            title: gameTitle,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createGameFeatureImageRefPathStorageAPI(gameId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const insertGameArticleImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, gameId: string, gameTitle: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: gameId,
            title: gameTitle,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createGameArticleImageRefPathStorageAPI(gameId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deleteGameFeatureImageStorageAPI = (gameId: string) => {
    const imageRef = storageRef.child(createGameFeatureImageRefPathStorageAPI(gameId, true));
    return imageRef.delete();
};

export const deleteGameArticleImageStorageAPI = (gameId: string) => {
    const imageRef = storageRef.child(createGameArticleImageRefPathStorageAPI(gameId, true));
    return imageRef.delete();
};
