/* eslint-disable no-unused-vars */
export enum WebsiteType {
    NONE,
    WEBSITEANDSTORE,
    STORE,
}

export const getAccountTypeName = (websiteType: WebsiteType): string => {
    if (websiteType === WebsiteType.WEBSITEANDSTORE) {
        return 'Website and Store';
    } if (websiteType === WebsiteType.STORE) {
        return 'Store';
    }
    return '';
};
