import { getCurrentDateTime, getDateStringFromDate } from '../../catalog/DateTime';
import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { randomNumber } from '../../catalog/Others';
import { storageRef } from '../FirebaseLibrary';

export const createLicensingAgreementRefPathStorageAPI = (teamId: string) => {
    return `teams/${teamId}/documents/licensing_agreement_${getDateStringFromDate(getCurrentDateTime())}_${randomNumber()}.pdf`;
};

export const createStoreLicensingAgreementRefPathStorageAPI = (teamId: string) => {
    return `teams/${teamId}/documents/store_licensing_agreement_${getDateStringFromDate(getCurrentDateTime())}_${randomNumber()}.pdf`;
};

export const insertLicensingAgreementPDFStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            title: `Licensing_Agreement_${getDateStringFromDate(getCurrentDateTime())}`,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createLicensingAgreementRefPathStorageAPI(teamId));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const insertStoreLicensingAgreementPDFStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            title: `Store_Licensing_Agreement_${getDateStringFromDate(getCurrentDateTime())}`,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createStoreLicensingAgreementRefPathStorageAPI(teamId));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};
