import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import profileImage from '../../../../../assets/homePageImages/blogs/jonathanSchubauer.png';
import blogImage2 from '../../../../../assets/homePageImages/blogs/blog1_2.png';
import blogImage3 from '../../../../../assets/homePageImages/blogs/blog1_3.png';
import blogImage4 from '../../../../../assets/homePageImages/blogs/blog1_4.png';
import blogImage5 from '../../../../../assets/homePageImages/blogs/blog1_5.png';
import ShareBlogComponent from '../../ShareBlogComponent';
import './css/Blog1.css';

export const blog1URL = 'https://optimxsports.com/blogs/collegiate-club-hockey-fixing-the-stigma-of-club-hockey';
export const blog1NavlinkURL = '/blogs/collegiate-club-hockey-fixing-the-stigma-of-club-hockey';
export const blog1WriterName = 'Jonathan Schubauer';
export const blog1Name = 'Collegiate Club Hockey - Fixing the Stigma of "Club Hockey"';
export const blog1Date = 'November 1, 2021';
export const blog1Desc = 'Check out our first opinion piece by Jonathan Schubauer where he discussed the stigma of Club Hockey';
export const blog1FeatureImage = 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/optimx-sports-admin%2Fblogs%2Fblog1FeatureImage_1600x900.png?alt=media&token=eef7283c-339d-4824-a9be-4c6db0a59e49';

const Blog1 = () => {
    return (
        <div className="blog-container bg-white p-t-50px p-b-100px">
            <Helmet>
                <title>{`${blog1Name} - OptimX Sports`}</title>
                <meta name="description" content={blog1Desc} />
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
                <meta name="twitter:title" content={blog1Name} data-react-helmet="true" />
                <meta
                    name="twitter:description"
                    content={blog1Desc}
                    data-react-helmet="true"
                />
                <meta name="twitter:image" content={blog1FeatureImage} data-react-helmet="true" />
                <meta name="twitter:image:alt" content="OptimX Sports" data-react-helmet="true" />
                <meta property="og:url" content="https://optimxsports.com/" data-react-helmet="true" />
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:title" content={blog1Name} data-react-helmet="true" />
                <meta
                    property="og:description"
                    content={blog1Desc}
                    data-react-helmet="true"
                />
                <meta property="og:image" content={blog1FeatureImage} data-react-helmet="true" />
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Blogs",
                                "item": "https://optimxsports.com/blogs"
                            },{
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Collegiate Club Hockey - Fixing the Stigma of Club Hockey"
                            }]
                        }
                    `}
                </script>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "NewsArticle",
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "${blog1URL}"
                            },
                            "headline": "Collegiate Club Hockey - Fixing the Stigma of Club Hockey",
                            "image": [
                                "${blog1FeatureImage}"
                            ],
                            "datePublished": "2021-11-01T09:00:00+08:00",
                            "dateModified": "2021-11-01T09:00:00+08:00",
                            "author": {
                                "@type": "Person",
                                "name": "${blog1WriterName}",
                                "url": "https://hockeyiu.com/roster/jonathan-schubauer-0b69nfvbsgzc"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "OptimX Sports",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/optimx-sports-admin%2Ficons%2Fsmall-optimx-sports-logo_1600x900.png?alt=media&token=f16482bf-f29a-43c3-ad95-7531553404a7"
                                }
                            }
                        }
                    `}
                </script>
            </Helmet>
            <div className="x-center max-w-1200px text-left-align p-l-50px p-r-50px p-b-50px p-t-0px mobile-p-l-20px mobile-p-r-20px">
                <h1 className="alegreya-font-family font-weight-700 font-size-7xl text-uppercase">{blog1Name}</h1>
                <img className="blog-1-image-1 full-width curve-border-20px" src={blog1FeatureImage} alt={blog1Name} />
                <div className="flex flex-direction-row flex-justify-content-center mobile-flex-direction-column mobile-flex-justify-content-start alegreya-font-family m-t-30px p-l-50px p-r-50px mobile-p-l-20px mobile-p-r-20px">
                    <ShareBlogComponent
                        url={blog1URL}
                        shareTitle={`${blog1Name}`}
                        hashtags={['CollegeHockey', 'CollegeSports', 'SportsWebsite', 'SportsTeamWebsite']}
                    />
                    <div className="font-spacing-wide line-height-wider">
                        <div className="flex flex-direction-row flex-align-items-center">
                            <img className="w-50px h-50px" src={profileImage} alt={blog1WriterName} />
                            <p className="alegreya-font-family p-l-30px font-weight-800">{`By: ${blog1WriterName}`}</p>
                        </div>
                        <p className="alegreya-font-family text-gray">{`published on ${blog1Date}`}</p>
                        <p>It&apos;s a Friday night in the city of Lynchburg, Virginia. Just 9 minutes away is a 4,000 seat capacity ice area in the middle of Liberty University&apos;s campus. Inside the LaHaye Ice Center you&apos;ll see thousands of hockey fans celebrating after a hard fought win. While it&apos;s a regularly occurring scene to witness at a Liberty University hockey game, it&apos;s not just an ordinary win.</p>
                        <img className="blog-1-image-2" src={blogImage2} alt="Collegiate Club Hockey - Fixing the Stigma of Club Hockey - 2" />
                        <p>On January 22nd, 2021 the Liberty Flame&apos;s premier American Collegiate Hockey Association (ACHA) Division 1 hockey team defeated the National Collegiate Athletic Association (NCAA) Division 1 Long Island Sharks in a close 2-1 contest. The win would mark the first ACHA-D1 to NCAA-D1 win in program history. It&apos;s also important to note that this would be the first ACHA-D1 to NCAA-D1 win in well over a decade.</p>
                        <p>What some spectators may find surprising is that the Liberty University hockey team is technically dubbed a recreational club sport and is not officially recognized as a collegiate varsity team by the university. This is the unfortunate reality for many other hockey teams that compete in the ACHA.</p>
                        <p>The ACHA is a collegiate hockey league and is offered as an alternative to NCAA hockey. Teams participating in the league do not offer scholarships and funding of the programs vary greatly across universities. Since its inception in 1991, the ACHA has grown from 15 teams to well over 400 teams today across five divisions, supporting three men&apos;s divisions and two for women.The league is the fastest growing collegiate hockey association in the US, with representation in 49 of 50 states.</p>
                        <p>Apart from the ACHA, the reformation of the National Association of Intercollegiate Athletics (NAIA) participating in hockey and recent formation of the Collegiate Hockey Federation (CHF) now adds to the alternative leagues in which players can play hockey while in college. While recent expansion of options may seem positive, the &ldquo;club hockey&rdquo; stigma still persists collectively amongst all alternative leagues separate from the NCAA.</p>
                        <p>In fairness, there are some teams participating in alternative collegiate hockey leagues that may fit the &ldquo;club hockey&rdquo; stigma. Of course, these types of teams are hindered significantly by lack of available resources, geographical interest, and limited university support. However, a considerable number of teams similar to Liberty University have comparable talent to what might be found on an NCAA team roster.</p>
                        <p>Unfortunately for teams like Liberty, which likely could remain competitive against NCAA D3 teams and some NCAA D1 teams, are tied to the label of &ldquo;club hockey&rdquo; by league association. While this label fits some club hockey teams operating within these alternative collegiate leagues, the stigma is unfairly blanketed to all teams competing in leagues like the ACHA or CHF.</p>
                        <p>Consequently, attaching the club stigma to such teams diminishes the perceived level of talent and play represented by many ACHA programs. On the surface, the term &ldquo;club hockey&rdquo; invites the public perception of an ACHA team like Liberty being associated as more of a &ldquo;recreational&rdquo; or &ldquo;Intramural&rdquo; when in reality, the club persona tied to such programs couldn&apos;t be further from the truth.</p>
                        <img className="blog-1-image-3" src={blogImage3} alt="Collegiate Club Hockey - Fixing the Stigma of Club Hockey - 3" />
                        <p>Perhaps the most disappointing effect attached to the &ldquo;club hockey&rdquo; stigma is how it is connected to the players. Many ACHA rosters spot players coming in with Tier 1 and Tier 2 junior level experience. Even more so, ACHA D1 and some ACHA D2 rosters are comparable to NCAA D3 rosters. Some exceptional ACHA players have also managed to play in respectable pro leagues; most notably Daniel Walcott, who currently plays in the NHL. Beyond the rosters, several ACHA teams participating in Divisions I and II have similar training commitments of what would be expected from your typical student athlete. Yet many of the students playing on these teams are not recognized as conventional &ldquo;student-athletes&rdquo; collectively by their universities or by some members of the public.</p>
                        <p>By comparison, many other collegiate sports such as football have several intercollegiate leagues supporting player participation. Aside from division I football programs, teams participating in leagues such as the NAIA or NCAA D3 operate in similar form to well established ACHA teams, but do not compare to similar levels of play. For example, an NAIA football program would likely not be able to compete against a NCAA DI program in the same manner that an ACHA hockey team might compete against their NCAA counterpart vice versa.</p>
                        <p>In fact, some NAIA or NCAA D3 football programs would struggle against top high school football programs across the country if exhibitions of such contests existed. Yet many of the students that participate in these brittle leagues are recognized unanimously as &ldquo;student-athletes&rdquo; despite a far less superior level of talent and play replicated by comparison. The disconnect continues to be rooted by the term &ldquo;club&rdquo; and unfairly presents a stigma that is difficult to disentangle.</p>
                        <p>Unfortunately the &ldquo;club&rdquo; stigma is likely not going to change anytime soon. Most universities are not considering legitimizing collegiate hockey teams of any form outside of the NCAA. Beyond university support, the upkeep of a hockey team is expensive by comparison to other collegiate sports and convenience of a well maintained rink in a geographically appealing area close to campus is rare. Even in the event where the stars do align for a club hockey program and a decent rink is nearby with a sizable fan base established, the legitimization of the team is dependent on the university&apos;s willingness to accept the legal risks of a team representing the school&apos;s likeness; a risk that the overwhelming majority of universities are not willing to accept.</p>
                        <p>Aside from the few problems already mentioned, program consistency is also a recurring issue. The operational management of club hockey teams vary greatly and program success is largely determined based on the team budget and available university resources. Access to such resources are not consistent amongst teams across the leagues. High team turnover rates have also proven to be a problem.</p>
                        <p>The problems that attribute to the club hockey stigma are complicated. A lack of legitimate recognition by universities is near universal and members both inside (talking about you junior hockey leagues) and outside of hockey circles unfairly distort the level of talent that can be found in leagues like the ACHA. While these problems club hockey teams face are significant and for the most part, out of their control, there are several actions teams universally can take to better their reputation and move away from the &ldquo;club stigma&rdquo;. Below, I offer three practical recommendations that nearly any team participating in the ACHA or CHF can reasonably accomplish.</p>

                        <h2>Improving Digital Representation</h2>
                        <p>Across the intercollegiate hockey leagues, online presence varies from program to program. The vast majority of club hockey teams have well established social media profiles, but some lack quality websites that effectively brand their identity and promotion of team events to their online audience. Based on observation, there also does not seem to be a consistent website provider in which the majority of teams use.</p>
                        <p>Consequently, club hockey web support appears to be fragmented. Many teams use website providers such as Poinstreaks, Wordpress, Wix, etc. Even more so, teams that do have websites do not appear to update content consistently. This fact is likely due to the constraint of team resources available throughout the year. Many teams are unable to support a professional web administrator every season, forcing members of the team to handle website operations/maintenance themselves. To make a bad situation worse, some of the website solutions teams use are not purposed specifically for designing sport themed websites, causing the team&apos;s website to look less than representative of a collegiate sports team. Problematic usability of such website solutions also plague the upkeep of teams managing their own websites.</p>
                        <p>Such problems further compound the larger reputational issue of how club hockey is labeled. Teams that use outdated or non-sport specific website solutions project a lack of professionalism to their online viewers who may wrongly interpret the organization as more of an intramural team based on the appearance of the website. This fact furthers the perception to online viewers that teams participating in leagues such as the ACHA are more aligned to recreational teams as opposed to legitimate hockey teams with high level talent.</p>
                        <p>Fortunately, club hockey teams in the ACHA and other alternative leagues do have options to change the look and feel of their website. There are several competent vendors such as OptimX Sports that offer hockey-specific website builds.</p>
                        <p>
                            OptimX Sports was founded recently by a former ACHA player and appears to be working with a number of ACHA and CHF teams. The company offers very professional designs that are specifically tailored to hockey teams. Some examples of their designs can be found on the
                            {' '}
                            <a href="https://hockeyiu.com">Indiana University Hockey Team</a>
                            {' '}
                            and
                            {' '}
                            <a href="https://txstatehockey.com">Texas State Hockey Team</a>
                            {' '}
                            websites respectively. The company also appears to be offering the first month subscription for free. After the first month, the annual subscription is marked at $200. By comparison amongst other sports website vendors, OptimX Sports is considerably inexpensive and may offer teams participating in leagues like the ACHA and CHF a reasonably priced web solution that is practical for already low budgets.
                        </p>
                        <p>Bottom line, if club teams want to improve their reputation and reduce the &ldquo;club hockey&rdquo; stigma, upgrading their team websites to something that appears maintained and modern to their online audience is essential. Such a change is likely doable for the majority of teams participating in the ACHA and CHF. There are several resources available to help teams get started. If the majority of such teams consider improving their digital representation, at the very least, online viewers will likely not confuse the organization as being a recreational hockey team.</p>

                        <h2>Maintaining a Team Alumni Network</h2>
                        <p>Many club hockey teams have been functioning as organizations for well over a decade, which means there are likely several alumni who have moved on to successful careers. Unfortunately, the way in which teams maintain communication with their alumni greatly varies in effectiveness. Some utilize aspects of social media or parts of their website, while other programs do not track alumni entirely. A lack of engagement with alumni means direct losses to donation opportunities and most importantly, engagement with current players on the roster. Supporting alumni does not break a team, but organizations with a strong alumni foundation are much better off.  </p>
                        <p>In any event, there are outreach methods that have been proven to be successful in most cases. Some club teams have managed to build and maintain newsletter lists to better engage with the alumni they have remained in contact with, while others maintain active Facebook groups to post games and alumni events. Regardless, corresponding with alumni consistently will only better the team foundation.   </p>
                        <p>Establishing an effective alumni network presents many different benefits for not only the team&apos;s internal needs, but also for the players individually. Alumni are very likely willing to generously donate to the team, but are also likely to network with current players who may be seeking similar careers after the conclusion of college. If these alumni interactions are positive and consistent, that trend is likely to continue by players that graduate each year.</p>
                        <p>In turn, a strong alumni foundation is formed with all the associated benefits and who knows, maybe a notable alumni like Daniel Walcott will be a part of your network and donate generously to the team.</p>

                        <h2>Game Transportation Options for Fans</h2>
                        <p>Most club hockey teams&apos; biggest struggle is undeniably the rink they inherit. Many teams are afforded whichever rink is closest to campus. For some club teams, that could mean driving as far as an hour away to play hockey. In which case, the incentive to attract university hockey fans is greatly diminished, resulting in empty games with no fan interest or income generated. This problem further compounds teams to the club stigma. A collegiate hockey team with zero fans will not likely be publicly considered legitimate or functional outside of the team&apos;s point of view.</p>
                        <p>While the rink situation for most teams can&apos;t be fixed, many club hockey teams, such as University of Kentucky and Mary University, have taken initiatives to get fans to the games on their own accord. Several club teams rent bus/shuttle services to get students to home games. It&apos;s proven to be an effective strategy based on the results. For Kentucky, midnight hockey games have become a university novelty that many Wildcat students will partake in at least once during their four years in college. At Mary University, a mix of local fans and students consistently attend midnight hockey games. Both team&apos;s rinks are packed full nearly every single home game.</p>
                        <img className="blog-1-image-4" src={blogImage4} alt="Collegiate Club Hockey - Fixing the Stigma of Club Hockey - 4" />
                        <p>The larger point of what these teams have managed to do so successfully is make it easier for their fans to attend their home games. Fans are much more incentivized to attend an event if it&apos;s easy for them to get there. This results in increased event income and larger team notoriety if the fan engagement remains consistent. Shuttle services are available in nearly every city within the US. Club teams should consider the return on investment and scope out a shuttle service that is willing to pick up university students at entertainment establishments on campus (e.g. bars, nightclubs, fraternities) that they have coordinated events with in advance.</p>
                        <p>Beyond the increased fan engagement, larger crowd attendance will likely lead to increased retention rates of players on teams. It&apos;s much harder to care about playing in a game with zero fans attending. By comparison, a packed rink full of energized fans will likely get anyone playing in the game much more excited to be there. In turn, this will generate a stronger degree of interest to remain playing on the team by the players. This will also compel prospective players who may want to try out for the team in the future, making the roster more talented with increased competition.</p>

                        <img className="blog-1-image-5" src={blogImage5} alt="Collegiate Club Hockey - Fixing the Stigma of Club Hockey - 5" />
                        <h2>Conclusion</h2>
                        <p>Alternative leagues like the ACHA are densely packed with talented hockey players on respectable programs that far exceed recreation levels of play. Many viewers outside the inner circle of hockey have a distorted view of these leagues due to the &ldquo;club&rdquo; label many of these teams are unfairly assigned. Further compounding this issue is the way in which some teams inconsistently operate.</p>
                        <p>There are many circumstances club hockey teams are not able to change, but the little things they can influence can go a long way. If teams across intercollegiate leagues are willing to take shots at opportunities it could change the makeup of their organization dramatically over the course of just a couple of years. Like Wayne Gretzky states</p>
                        <p><i>&ldquo;You miss one hundred percent of the shots you don&apos;t take. Even though there is only a 1-5% probability of scoring&rdquo;</i></p>
                        <p>Teams participating in the ACHA and CHF can learn a thing or two from the great one. Most attempts at organizational success will be a miss, but you won&apos;t know until you try.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Blog1);
