import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { TicketObject } from '../../catalog/interface/TicketInterface';
import { db, storageRef } from '../FirebaseLibrary';

export const createTicketsReference = () => {
    return db.collection('tickets');
};

export const createTicketReference = (ticketId: string) => {
    return createTicketsReference().doc(ticketId);
};

export const fetchTicketFirestoreAPI = (ticketId: string) => {
    return createTicketReference(ticketId).get();
};

export const insertTicketFirestoreAPI = (ticketObject: TicketObject) => {
    return createTicketReference(ticketObject.id).set(ticketObject);
};

export const updateTicketFirestoreAPI = (ticketObject: any) => {
    return createTicketReference(ticketObject.id).update(ticketObject);
};

export const deleteTicketFirestoreAPI = (ticketId: string) => {
    return createTicketReference(ticketId).delete();
};

export const fetchTicketsFirestoreAPI = (teamId: string) => {
    return createTicketsReference().where('teamId', '==', teamId).where('deleted', '==', false).orderBy('timeCreated', 'desc')
        .get();
};

export const fetchFutureTicketsFirestoreAPI = (teamId: string, date: Date) => {
    return createTicketsReference().where('teamId', '==', teamId).where('deleted', '==', false).where('endTimeOfEvent', '<=', date)
        .orderBy('endTimeOfEvent')
        .get();
};

export const fetchTicketsByEventIdFirestoreAPI = (teamId: string, eventId: string) => {
    return createTicketsReference().where('teamId', '==', teamId).where('eventId', '==', eventId).where('deleted', '==', false)
        .orderBy('timeCreated')
        .get();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createTicketFeatureImageRefPathStorageAPI = (ticketId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `tickets/${ticketId}/featureImage/featureImage_1600x900.png`;
    }
    return `tickets/${ticketId}/featureImage/featureImage.png`;
};

export const insertTicketFeatureImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, ticketId: string, ticketTitle: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: ticketId,
            title: ticketTitle,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createTicketFeatureImageRefPathStorageAPI(ticketId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deleteTicketFeatureImageStorageAPI = (ticketId: string) => {
    const imageRef = storageRef.child(createTicketFeatureImageRefPathStorageAPI(ticketId, true));
    return imageRef.delete();
};
