import { SportsType } from '../../../../enums/SportsType';
import { TeamWebsiteStylesNavLinkObject } from '../../../../interface/TeamInterface';

const getTeamWebsiteStylesNavLinksContactUsLink = (sportsType: SportsType) => {
    if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'contact-us-form-mens-hockey';
    } if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'contact-us-form-womens-hockey';
    } if (sportsType === SportsType.MENSRUGBY) {
        return 'contact-us-form-mens-rugby';
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return 'contact-us-form-womens-rugby';
    } if (sportsType === SportsType.MENSLACROSSE) {
        return 'contact-us-form-mens-lacrosse';
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'contact-us-form-womens-lacrosse';
    }
    return '';
};

const getTeamWebsiteStylesNavLinksPlayerRecruitmentFormLink = (sportsType: SportsType) => {
    if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'player-recruitment-form-mens-hockey';
    } if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'player-recruitment-form-womens-hockey';
    } if (sportsType === SportsType.MENSRUGBY) {
        return 'player-recruitment-form-mens-rugby';
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return 'player-recruitment-form-womens-rugby';
    } if (sportsType === SportsType.MENSLACROSSE) {
        return 'player-recruitment-form-mens-lacrosse';
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'player-recruitment-form-womens-lacrosse';
    }
    return '';
};

const getTeamWebsiteStylesNavLinksAlumniFormLink = (sportsType: SportsType) => {
    if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'alumni-form-mens-hockey';
    } if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'alumni-form-womens-hockey';
    } if (sportsType === SportsType.MENSRUGBY) {
        return 'alumni-form-mens-rugby';
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return 'alumni-form-womens-rugby';
    } if (sportsType === SportsType.MENSLACROSSE) {
        return 'alumni-form-mens-lacrosse';
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'alumni-form-womens-lacrosse';
    }
    return '';
};

export const getDemoTeamWebsiteStylesNavLinks = (sportsType: SportsType): TeamWebsiteStylesNavLinkObject[] => {
    return [
        { title: 'Home', value: '/', link: '/', options: [], openNewTab: false, required: false },
        { title: 'Team',
            value: '/options',
            link: '/options',
            options: [
                { title: 'Roster', value: '/roster', link: '/roster', options: [], openNewTab: false, required: false },
                { title: 'Staff', value: '/staff', link: '/staff', options: [], openNewTab: false, required: false },
                { title: 'All Rosters', value: '/season/:seasonid', link: '/season/:seasonid', options: [], openNewTab: false, required: false },
            ],
            openNewTab: false,
            required: false },
        { title: 'Schedule',
            value: '/options',
            link: '/options',
            options: [
                { title: 'Game Schedule', value: '/schedule', link: '/schedule', options: [], openNewTab: false, required: false },
                { title: 'Events and Practice', value: '/eventsandpractice', link: '/eventsandpractice', options: [], openNewTab: false, required: false },
                { title: 'All Seasons', value: '/seasonschedule/:seasonid', link: '/seasonschedule/:seasonid', options: [], openNewTab: false, required: false },
            ],
            openNewTab: false,
            required: false },
        { title: 'News',
            value: '/options',
            link: '/options',
            options: [
                { title: 'Articles', value: '/articles', link: '/articles', options: [], openNewTab: false, required: false },
                { title: 'Galleries', value: '/galleries', link: '/galleries', options: [], openNewTab: false, required: false },
            ],
            openNewTab: false,
            required: false },
        { title: 'Recruits', value: '/forms', link: getTeamWebsiteStylesNavLinksPlayerRecruitmentFormLink(sportsType), options: [], openNewTab: false, required: false },
        { title: 'Alumni',
            value: '/options',
            link: '/options',
            options: [
                { title: 'Alumni', value: '/alumni', link: '/alumni', options: [], openNewTab: false, required: false },
                { title: 'Alumni Form', value: '/forms', link: getTeamWebsiteStylesNavLinksAlumniFormLink(sportsType), options: [], openNewTab: false, required: false },
            ],
            openNewTab: false,
            required: false },
        { title: 'Sponsors', value: '/sponsors', link: '/sponsors', options: [], openNewTab: false, required: false },
        { title: 'About',
            value: '/options',
            link: '/options',
            options: [
                { title: 'About Us', value: '/about', link: '/about', options: [], openNewTab: false, required: false },
                { title: 'Contact Us', value: '/forms', link: getTeamWebsiteStylesNavLinksContactUsLink(sportsType), options: [], openNewTab: false, required: false },
            ],
            openNewTab: false,
            required: false,
        },
    ];
};
