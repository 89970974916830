/* eslint-disable no-unused-vars */
export enum WebsiteDesignType {
    DESIGN_1,
    DESIGN_2,
    DESIGN_3,
    DESIGN_4,
    DESIGN_5,
    DESIGN_6,
    DESIGN_7,
    DESIGN_8,
    DESIGN_9,
    DESIGN_10,
    DESIGN_11,
    DESIGN_12,
    DESIGN_13,
    DESIGN_14,
    DESIGN_15,
    DESIGN_16,
}

export enum WebsiteDarkModeType {
    LIGHT_MODE,
    DARK_MODE,
}
