import { SportsType } from '../../catalog/enums/SportsType';
import { FormObject } from '../../catalog/interface/FormInterface';
import { db } from '../FirebaseLibrary';

export const createPreDevelopedFormsReference = () => {
    return db.collection('pre-developed-forms');
};

export const createPreDevelopedFormReference = (formId: string) => {
    return createPreDevelopedFormsReference().doc(formId);
};

export const fetchPreDevelopedFormFirestoreAPI = (formId: string) => {
    return createPreDevelopedFormReference(formId).get();
};

export const fetchPreDevelopedFormsFirestoreAPI = (sportsType: SportsType) => {
    return createPreDevelopedFormsReference().where('sportsType', '==', sportsType).where('deleted', '==', false).where('published', '==', true)
        .orderBy('title')
        .get();
};

export const fetchPreDevelopedFormsIncludingDeletedFirestoreAPI = (sportsType: SportsType) => {
    return createPreDevelopedFormsReference().where('sportsType', '==', sportsType).orderBy('title').get();
};

export const insertPreDevelopedFormFirestoreAPI = (formObject: FormObject) => {
    return createPreDevelopedFormReference(formObject.id).set(formObject);
};
