import { SportsType } from '../enums/SportsType';
import { TagType } from '../enums/TagType';
import { getIceHockeyGameCoachObjectFromIceHockeyGameObject, IceHockeyGameCoachObject } from '../interface/gameInterface/iceHockey/IceHockeyGameCoachInterface';
import { IceHockeyGameObject } from '../interface/gameInterface/iceHockey/IceHockeyGameInterface';
import { getLacrosseGameCoachObjectFromLacrosseGameObject, LacrosseGameCoachObject } from '../interface/gameInterface/lacrosse/LacrosseGameCoachInterface';
import { LacrosseGameObject } from '../interface/gameInterface/lacrosse/LacrosseGameInterface';
import { getRugbyGameCoachObjectFromRugbyGameObject, RugbyGameCoachObject } from '../interface/gameInterface/rugby/RugbyGameCoachInterface';
import { RugbyGameObject } from '../interface/gameInterface/rugby/RugbyGameInterface';
import { createDemoIceHockeyGame } from './game/IceHockeyGameDemo';
import { createDemoLacrosseGame } from './game/LacrosseGameDemo';
import { createDemoRugbyGame } from './game/RugbyGameDemo';

export const createDemoGameCoach = (
    organizationId: string,
    teamId: string,
    gameId: string,
    tagIds: string[],
    tagReferences: any[],
    tagTypes: TagType[],
    seasonId: string,
    articleId: string,
    galleryId: string,
    leagueId: string,
    opponentId: string,
    coachId: string,
    sportsType: SportsType,
): IceHockeyGameCoachObject | RugbyGameCoachObject | LacrosseGameCoachObject => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        const demoIceHockeyGameObject = createDemoIceHockeyGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, articleId, galleryId, leagueId, opponentId, coachId, sportsType);
        return getIceHockeyGameCoachObjectFromIceHockeyGameObject(demoIceHockeyGameObject, coachId);
    }

    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        const demoRugbyGameObject = createDemoRugbyGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, articleId, galleryId, leagueId, opponentId, coachId, sportsType);
        return getRugbyGameCoachObjectFromRugbyGameObject(demoRugbyGameObject, coachId);
    }

    const demoLacrosseGameObject = createDemoLacrosseGame(organizationId, teamId, gameId, tagIds, tagReferences, tagTypes, seasonId, articleId, galleryId, leagueId, opponentId, coachId, sportsType);
    return getLacrosseGameCoachObjectFromLacrosseGameObject(demoLacrosseGameObject, coachId);
};

export const createDemoIceHockeyGameCoachFromDemoIceHockeyGame = (demoGameObject: IceHockeyGameObject, coachId: string): IceHockeyGameCoachObject => {
    return getIceHockeyGameCoachObjectFromIceHockeyGameObject(demoGameObject, coachId);
};

export const createDemoRugbyGameCoachFromDemoRugbyGame = (demoGameObject: RugbyGameObject, coachId: string): RugbyGameCoachObject => {
    return getRugbyGameCoachObjectFromRugbyGameObject(demoGameObject, coachId);
};

export const createDemoLacrosseGameCoachFromDemoLacrosseGame = (demoGameObject: LacrosseGameObject, coachId: string): LacrosseGameCoachObject => {
    return getLacrosseGameCoachObjectFromLacrosseGameObject(demoGameObject, coachId);
};
