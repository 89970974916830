export interface ImageObject {
    file: File | null,
    link: string,
}

export interface ImageObjectWhileSaving {
    ref: any,
    promise: any,
}

export const imageObjectToLink = (imageObject: ImageObject | null): string => {
    if (imageObject === null || imageObject === undefined) {
        return '';
    }
    return imageObject.link;
};

export const linkToImageObject = (imageLink: string | null): ImageObject => {
    if (imageLink === null || imageLink === undefined) {
        return {
            file: null,
            link: '',
        };
    }
    return {
        file: null,
        link: imageLink,
    };
};

export const imageObjectsToLinks = (imageObjects: ImageObject[] | null): string[] => {
    if (imageObjects === null || imageObjects === undefined) {
        return [];
    }
    const finalImageOjects = imageObjects.map((image) => {
        return imageObjectToLink(image);
    });
    return finalImageOjects;
};

export const linksToImageObjects = (imageObjects: string[] | null): ImageObject[] => {
    if (imageObjects === null || imageObjects === undefined) {
        return [];
    }
    const finalImageOjects = imageObjects.map((image) => {
        return linkToImageObject(image);
    });
    return finalImageOjects;
};
