import { ImageObjectWhileSaving } from '../../catalog/interface/OtherInterface';
import { db, storageRef } from '../FirebaseLibrary';

export const createTeamsReference = () => {
    return db.collection('teams');
};

export const createTeamReference = (teamId: string) => {
    return createTeamsReference().doc(teamId);
};

export const fetchTeamFirestoreAPI = (teamId: string) => {
    return createTeamReference(teamId).get();
};

export const fetchTeamUsingEmailFirestoreAPI = (email: string) => {
    return createTeamsReference().where('email', '==', email).get();
};

export const updateTeamFirestoreAPI = (teamObject: any) => {
    return createTeamReference(teamObject.id).update(teamObject);
};

export const deleteTeamFirestoreAPI = (teamId: string) => {
    return createTeamReference(teamId).delete();
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createTeamLogoRefPathStorageAPI = (teamId: string, dimensions: number, withDimensions: boolean) => {
    if (dimensions < 0) {
        if (withDimensions) {
            return `teams/${teamId}/teamLogo_1600x900.png`;
        }
        return `teams/${teamId}/teamLogo.png`;
    }
    if (withDimensions) {
        return `teams/${teamId}/teamLogo_${dimensions}_1600x900.png`;
    }
    return `teams/${teamId}/teamLogo_${dimensions}.png`;
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createTeamSilhouetteLogoRefPathStorageAPI = (teamId: string, dimensions: number, withDimensions: boolean) => {
    if (dimensions < 0) {
        if (withDimensions) {
            return `teams/${teamId}/teamLogo_silhouette_1600x900.png`;
        }
        return `teams/${teamId}/teamLogo_silhouette.png`;
    }
    if (withDimensions) {
        return `teams/${teamId}/teamLogo_silhouette_${dimensions}_1600x900.png`;
    }
    return `teams/${teamId}/teamLogo_silhouette_${dimensions}.png`;
};

// NOTE There are two dimensions because when uploading, it does not add the dimensions and firebase does it on its own.
// NOTE If it did then it will be uploaded as 1600x900_1600x900
// NOTE When deleting the dimensions are added because the image is now saves as 1600x900
export const createTeamImageRefPathStorageAPI = (teamId: string, imageId: string, withDimensions: boolean) => {
    if (withDimensions) {
        return `teams/${teamId}/images/${imageId}_1600x900.png`;
    }
    return `teams/${teamId}/images/${imageId}.png`;
};

export const insertTeamLogoStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, file: File, dimensions: number): any => {
    const metadata = {
        customMetadata: {
            id: teamId,
            title: teamName,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createTeamLogoRefPathStorageAPI(teamId, dimensions, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const insertTeamSilhouetteLogoStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, file: File, dimensions: number): any => {
    const metadata = {
        customMetadata: {
            id: teamId,
            title: teamName,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createTeamSilhouetteLogoRefPathStorageAPI(teamId, dimensions, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const insertTeamImageStorageAPI = (organizationId: string, organizationName: string, teamId: string, teamName: string, imageId: string, imageTitle: string, file: File): ImageObjectWhileSaving => {
    const metadata = {
        customMetadata: {
            id: imageId,
            title: imageTitle,
            teamId,
            teamName,
            organizationId,
            organizationName,
        },
    };
    const imageRef = storageRef.child(createTeamImageRefPathStorageAPI(teamId, imageId, false));
    return { ref: imageRef, promise: imageRef.put(file, metadata) };
};

export const deleteTeamLogoStorageAPI = (teamId: string, dimensions: number) => {
    const imageRef = storageRef.child(createTeamLogoRefPathStorageAPI(teamId, dimensions, true));
    return imageRef.delete();
};

export const deleteTeamSilhouetteLogoStorageAPI = (teamId: string, dimensions: number) => {
    const imageRef = storageRef.child(createTeamSilhouetteLogoRefPathStorageAPI(teamId, dimensions, true));
    return imageRef.delete();
};

export const deleteTeamImageStorageAPI = (teamId: string, imageId: string) => {
    const imageRef = storageRef.child(createTeamImageRefPathStorageAPI(teamId, imageId, true));
    return imageRef.delete();
};
