/* eslint-disable no-console */
import { analytics } from '../firebase/FirebaseLibrary';
import { getSportsTypeName, SportsType } from './enums/SportsType';
import { optimXTesting } from './Others';
import { websiteVersion } from './Version';

/**
 * ##############################################################################################
 * ##############################################################################################
 */

let organizationId = '';
let organizationName = '';
let teamId = '';
let teamName = '';
let sportsType = SportsType.NONE;

export const setTeamAnalytics = (organizationIdLocal: string, organizationNameLocal: string, teamIdLocal: string, teamNameLocal: string, sportsTypeLocal: SportsType) => {
    organizationId = organizationIdLocal;
    organizationName = organizationNameLocal;
    teamId = teamIdLocal;
    teamName = teamNameLocal;
    sportsType = sportsTypeLocal;

    if (!optimXTesting) {
        analytics.setUserProperties({
            type: 'team_portal',
            organizationName: organizationNameLocal,
            organizationId: organizationIdLocal,
            teamName: teamNameLocal,
            teamId: teamIdLocal,
            sportsType: sportsTypeLocal,
            sportsTypeName: getSportsTypeName(sportsTypeLocal),
            version: websiteVersion,
        });
    }
};

export const resetTeamAnalytics = () => {
    organizationId = '';
    organizationName = '';
    teamId = '';
    teamName = '';
    sportsType = SportsType.NONE;
};

/**
 * ##############################################################################################
 * ##############################################################################################
 */

export const sendWebsiteAnalytics = () => {
    if (!optimXTesting) {
        analytics.logEvent(
            'sports_portal_website',
            {},
        );
        console.log('Analytics sent for sports_portal_website');
    }
};

export const sendCustomTeamAnalytics = (text: string, parameters: object = {}) => {
    if (!optimXTesting) {
        analytics.logEvent(
            `sports_portal_website_team_${text}`,
            {
                ...parameters,
                title: `${organizationName} - ${teamName} - ${getSportsTypeName(sportsType)}`,
                titleWithId: `${organizationName} - ${organizationId} - ${teamName} - ${teamId} - ${getSportsTypeName(sportsType)}`,
                organizationName,
                organizationId,
                teamName,
                teamId,
                sportsType,
                sportsTypeName: getSportsTypeName(sportsType),
                version: websiteVersion,
            },
        );
        console.log(`Analytics sent for sports_portal_website_team_${text}`);
    }
};

export const sendCustomTeamAnalyticsWithTeamInfo = (text: string, organizationIdLocal: string, organizationNameLocal: string, teamIdLocal: string, teamNameLocal: string, sportsTypeLocal: SportsType) => {
    if (!optimXTesting) {
        analytics.logEvent(
            `sports_portal_website_team_${text}`,
            {
                title: `${organizationNameLocal} - ${teamNameLocal} - ${getSportsTypeName(sportsTypeLocal)}`,
                titleWithId: `${organizationNameLocal} - ${organizationIdLocal} - ${teamNameLocal} - ${teamIdLocal} - ${getSportsTypeName(sportsTypeLocal)}`,
                organizationName: organizationNameLocal,
                organizationId: organizationIdLocal,
                teamName: teamNameLocal,
                teamId: teamIdLocal,
                sportsType: sportsTypeLocal,
                sportsTypeName: getSportsTypeName(sportsTypeLocal),
                version: websiteVersion,
            },
        );
        console.log(`Analytics sent for sports_portal_website_team_${text}`);
    }
};

export const sendErrorTeamAnalytics = (message: string) => {
    if (!optimXTesting) {
        analytics.logEvent(
            'sports_portal_website_team_error',
            {
                message,
                title: `${organizationName} - ${teamName} - ${getSportsTypeName(sportsType)}`,
                titleWithId: `${organizationName} - ${organizationId} - ${teamName} - ${teamId} - ${getSportsTypeName(sportsType)}`,
                organizationName,
                organizationId,
                teamName,
                teamId,
                sportsType,
                sportsTypeName: getSportsTypeName(sportsType),
                version: websiteVersion,
            },
        );
        console.log('Analytics sent for sports_portal_website_team_error');
    }
};
