/**
 * Notification Comments used through out the project. This is different from normal comments because
 * these normally appear due to some trigger.
 */

import { contactEmail, maxImagesInGallery } from './Others';

export const NO_6_CHARACTERS_PASSWORD = 'Password must be atleast 6 characters long';
export const NO_DIGIT_PASSWORD = 'Password must contain atleat 1 digit (0-9)';
export const NO_LETTER_PASSWORD = 'Password must contain atleat 1 letter (a-z, A-Z)';
export const INVALID_PASSWORD = 'Invalid password. Please enter a valid password';
export const NO_PASSWORD = 'Password not entered. Please enter a valid password';
export const PASSWORD_VERIFICATION_EMAIL_SENT = 'A password reset email has been sent to your email address';

export const INVALID_EMAIL = 'Invalid email. Please enter a valid Email';
export const NO_FIRST_NAME = 'First name not entered. Please enter a valid First Name';
export const NO_LAST_NAME = 'Last name not entered. Please enter a valid Last Name';
export const NO_EMAIL = 'Email not entered. Please enter a valid Email';
export const NOT_SAME_EMAIL = 'Email address provided are not the same';
export const NOT_VERIFIED_EMAIL = 'Please verify your email address to sign in';
export const VERIFICATION_EMAIL_SENT = 'A Verification email has been sent to your email address';
export const ADD_USER_EMAIL_SENT = 'Verification email sent to add a new user. Please verify your email address before logging in again';
export const USER_NOT_FOUND = 'User not found';
export const LICENSING_AGREEMENT_SUCCESS = 'Licensing Agreement Signed';
export const LICENSING_AGREEMENT_ERROR = 'Error signing Licensing Agreement';

export const NO_ORGANIZATION_NAME = 'Organization name not entered. Please enter a valid Organization Name';
export const NO_TEAM_NAME = 'Team name not entered. Please enter a valid Team Name';
export const NO_AGE_TYPE = 'Please select the age category for your team';
export const YOUTH_AGE_TYPE = 'Unfotunately we do not support Youth Teams at the moment due to COPPA compliance';
export const PRIVACY_CHECKBOX_NOT_CHECKED = 'Please read and accept the Privacy Policy before signing up';
export const TERMS_CHECKBOX_NOT_CHECKED = 'Please read and accept the Terms of Service before signing up';
export const RECAPTCHA_NOT_VERIFIED = 'Please let us know if you are a robot';
export const ORGANIZATION_NOT_FOUND = 'Team or Organization not found';

export const ERROR_CONTACT_INFO_FIRST_NAME = 'Please enter your first name';
export const ERROR_CONTACT_INFO_LAST_NAME = 'Please enter your last name';
export const ERROR_CONTACT_INFO_EMAIL = 'Please enter your email';
export const ERROR_CONTACT_INFO_PHONE = 'Please enter your phone';
export const ERROR_CONTACT_INFO_MESSAGE = 'Please enter a message for us describing your requirements';
export const MESSAGE_SEND_SUCCESS = 'Thank you. Someone from our team will get in touch with you soon';
export const MESSAGE_SEND_FAILURE = `There was an error sending message. Please use ${contactEmail} to send us a message`;
export const REFERRAL_SEND_SUCCESS = (referralLink: string, referralSignupLink: string) => {
    let str = 'Congratulations on successfully subscribing to our Referral Program. You can now use your referral links(';
    str += referralLink;
    str += ' and ';
    str += referralSignupLink;
    str += ') to invite teams to our platform.';
    return str;
};
export const REFERRAL_SEND_FAILURE = `There was an error with the Referral Application. Please use ${contactEmail} to send us a message`;

export const ERROR_FETCHING_TAGS = 'Error fetching tags';

export const ERROR_FETCHING_STATS = 'Error fetching stats';

export const FETCH_WEBSITE_ERROR = 'Error while fetching website information';
export const WEBSITE_DOES_NOT_EXIST = 'Website information does not exist';
export const UPDATE_WEBSITE_SUCCESS = 'Website information updated successfully';
export const UPDATE_WEBSITE_ERROR = 'Error while updating website information';
export const PUBLISH_WEBSITE_SUCCESS = 'Website publishing information sent successfully';
export const PUBLISH_WEBSITE_ERROR = 'Error while publishing website';
export const WEBSITE_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const UPDATE_STORE_SUCCESS = 'Store information updated';
export const UPDATE_STORE_ERROR = 'Error while updating store information';
export const CREATE_STRIPE_STORE_ERROR = 'Error while creating store';
export const FETCH_STRIPE_STORE_ERROR = 'Error while redirecting to your Stripe Account';
export const FETCH_STORE_INFO_ERROR = 'Error while fetching store info';
export const FETCH_STORE_CUSTOMER_ERROR = 'Error while fetching customer info';
export const FETCH_STORE_CUSTOMERS_ERROR = 'Error while fetching customers info';
export const FETCH_STORE_CHARGE_ERROR = 'Error while fetching charge info';
export const FETCH_STORE_CHARGES_ERROR = 'Error while fetching charges info';
export const FETCH_STORE_REFUNDS_ERROR = 'Error while fetching refunds info';
export const FETCH_STORE_DISPUTES_ERROR = 'Error while fetching disputes info';
export const STORE_PAYOUT_ERROR = 'Error while initiating payout';
export const STORE_REFUND_ERROR = 'Error while initiating refund';
export const FETCH_STORE_BALANCES_ERROR = 'Error while fetching balances info';
export const FETCH_STORE_BALANCE_ERROR = 'Error while fetching balance info';
export const STORE_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_PLAYERS_ERROR = 'Error while fetching players';
export const FETCH_PLAYER_ERROR = 'Error while fetching player';
export const PLAYER_DOES_NOT_EXIST = 'Player does not exist';
export const INSERT_PLAYER_SUCCESS = 'Player created successfully';
export const INSERT_PLAYER_ERROR = 'Error while creating player';
export const UPDATE_PLAYER_SUCCESS = 'Player updated successfully';
export const UPDATE_PLAYER_ERROR = 'Error while updating player';
export const DELETE_PLAYER_SUCCESS = 'Player deleted successfully';
export const DELETE_PLAYER_ERROR = 'Error while deleting player';
export const SWITCH_TO_ALUMNI = 'Click save to transfer this player to alumni list';
export const INCOMPLETE_SUMMARY = 'Please enter a summary of minimum 70 characters or leave the summary textfield empty. Empty summary textfield will be replaced with auto generated summary';
export const PLAYER_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_ALUMNI_ERROR = 'Error while fetching alumni';
export const FETCH_ALMUNI_ERROR = 'Error while fetching alumni';
export const ALUMNI_DOES_NOT_EXIST = 'Alumni does not exist';
export const INSERT_ALUMNI_SUCCESS = 'Alumni created successfully';
export const INSERT_ALUMNI_ERROR = 'Error while creating alumni';
export const UPDATE_ALUMNI_SUCCESS = 'Alumni updated successfully';
export const UPDATE_ALUMNI_ERROR = 'Error while updating alumni';
export const DELETE_ALUMNI_SUCCESS = 'Alumni deleted successfully';
export const DELETE_ALUMNI_ERROR = 'Error while deleting alumni';
export const SWITCH_TO_PLAYER = 'Click save to transfer this player to your current roster';
export const ALUMNI_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_COACHES_ERROR = 'Error while fetching coaches';
export const REORDER_COACHES_ERROR = 'Error while reordering coaches';
export const FETCH_COACH_ERROR = 'Error while fetching coach';
export const COACH_DOES_NOT_EXIST = 'Coach does not exist';
export const INSERT_COACH_SUCCESS = 'Coach created successfully';
export const INSERT_COACH_ERROR = 'Error while creating coach';
export const UPDATE_COACH_SUCCESS = 'Coach updated successfully';
export const UPDATE_COACH_ERROR = 'Error while updating coach';
export const DELETE_COACH_SUCCESS = 'Coach deleted successfully';
export const DELETE_COACH_ERROR = 'Error while deleting coach';
export const SWITCH_TO_CURRENT_COACH = 'Click save to transfer to your current team';
export const SWITCH_TO_PAST_COACH = 'Click save to transfer to your previous team';
export const COACH_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_STAFFS_ERROR = 'Error while fetching executive board';
export const REORDER_STAFFS_ERROR = 'Error while reordering executive board';
export const FETCH_STAFF_ERROR = 'Error while fetching executive board member';
export const STAFF_DOES_NOT_EXIST = 'Executive board member does not exist';
export const INSERT_STAFF_SUCCESS = 'Executive board member created successfully';
export const INSERT_STAFF_ERROR = 'Error while creating executive board member';
export const UPDATE_STAFF_SUCCESS = 'Executive board member updated successfully';
export const UPDATE_STAFF_ERROR = 'Error while updating executive board member';
export const DELETE_STAFF_SUCCESS = 'Executive board member deleted successfully';
export const DELETE_STAFF_ERROR = 'Error while deleting executive board member';
export const SWITCH_TO_CURRENT_STAFF = 'Click save to transfer to your current team';
export const SWITCH_TO_PAST_STAFF = 'Click save to transfer to your previous team';
export const STAFF_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_OPPONENTS_ERROR = 'Error while fetching opponents';
export const FETCH_OPPONENT_ERROR = 'Error while fetching opponent';
export const OPPONENT_DOES_NOT_EXIST = 'Opponent does not exist';
export const INSERT_OPPONENT_SUCCESS = 'Opponent created successfully';
export const INSERT_OPPONENT_ERROR = 'Error while creating opponent';
export const UPDATE_OPPONENT_SUCCESS = 'Opponent updated successfully';
export const UPDATE_OPPONENT_ERROR = 'Error while updating opponent';
export const DELETE_OPPONENT_SUCCESS = 'Opponent deleted successfully';
export const DELETE_OPPONENT_ERROR = 'Error while deleting opponent';
export const OPPONENT_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_SPONSORS_ERROR = 'Error while fetching sponsors';
export const REORDER_SPONSORS_ERROR = 'Error while reordering sponsors';
export const FETCH_SPONSOR_ERROR = 'Error while fetching sponsor';
export const SPONSOR_DOES_NOT_EXIST = 'Sponsor does not exist';
export const INSERT_SPONSOR_SUCCESS = 'Sponsor created successfully';
export const INSERT_SPONSOR_ERROR = 'Error while creating sponsor';
export const UPDATE_SPONSOR_SUCCESS = 'Sponsor updated successfully';
export const UPDATE_SPONSOR_ERROR = 'Error while updating sponsor';
export const DELETE_SPONSOR_SUCCESS = 'Sponsor deleted successfully';
export const DELETE_SPONSOR_ERROR = 'Error while deleting sponsor';
export const SPONSOR_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_LEAGUES_ERROR = 'Error while fetching leagues';
export const FETCH_LEAGUE_ERROR = 'Error while fetching league';
export const LEAGUE_DOES_NOT_EXIST = 'League does not exist';
export const INSERT_LEAGUE_SUCCESS = 'League created successfully';
export const INSERT_LEAGUE_ERROR = 'Error while creating league';
export const UPDATE_LEAGUE_SUCCESS = 'League updated successfully';
export const UPDATE_LEAGUE_ERROR = 'Error while updating league';
export const DELETE_LEAGUE_SUCCESS = 'League deleted successfully';
export const DELETE_LEAGUE_ERROR = 'Error while deleting league';
export const FETCH_PRE_DEVELOPED_LEAGUE_ERROR = 'Error fetching pre developed leagues';
export const LEAGUE_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_SEASONS_ERROR = 'Error while fetching seasons';
export const FETCH_SEASON_ERROR = 'Error while fetching season';
export const SEASON_DOES_NOT_EXIST = 'Season does not exist';
export const INSERT_SEASON_SUCCESS = 'Season created successfully';
export const INSERT_SEASON_ERROR = 'Error while creating season';
export const UPDATE_SEASON_SUCCESS = 'Season updated successfully';
export const UPDATE_SEASON_ERROR = 'Error while updating season';
export const DELETE_SEASON_SUCCESS = 'Season deleted successfully';
export const DELETE_SEASON_ERROR = 'Error while deleting season';
export const FETCH_PRE_DEVELOPED_SEASON_ERROR = 'Error fetching pre developed seasons';
export const DELETE_GAME_FIRST_SEASON = 'To delete the season, please delete all the games in this season first';
export const DELETE_GAME_FIRST_LEAGUE = 'To delete the league, please delete all the games in this league first';
export const SEASON_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_GAMES_ERROR = 'Error while fetching games';
export const FETCH_GAME_ERROR = 'Error while fetching game';
export const GAME_DOES_NOT_EXIST = 'Game does not exist';
export const INSERT_GAME_SUCCESS = 'Game created successfully';
export const INSERT_GAME_ERROR = 'Error while creating game';
export const UPDATE_GAME_SUCCESS = 'Game updated successfully';
export const UPDATE_GAME_ERROR = 'Error while updating game';
export const DELETE_GAME_SUCCESS = 'Game deleted successfully';
export const DELETE_GAME_ERROR = 'Error while deleting game';
export const DELETE_GAME_ERROR_BECAUSE_SOLD_TICKET = 'Game cannot be deleted because tickets related to this game are already purchased';
export const PLAYER_ALSO_ADDED_TO_ROSTER = 'Player also added to roster';
export const GAME_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_EVENTS_ERROR = 'Error while fetching events';
export const FETCH_EVENT_ERROR = 'Error while fetching event';
export const EVENT_DOES_NOT_EXIST = 'Event does not exist';
export const INSERT_EVENT_SUCCESS = 'Event created successfully';
export const INSERT_EVENT_ERROR = 'Error while creating event';
export const UPDATE_EVENT_SUCCESS = 'Event updated successfully';
export const UPDATE_EVENT_ERROR = 'Error while updating event';
export const DELETE_EVENT_SUCCESS = 'Event deleted successfully';
export const DELETE_EVENT_ERROR = 'Error while deleting event';
export const DELETE_EVENT_ERROR_BECAUSE_SOLD_TICKET = 'Event cannot be deleted because tickets related to this event are already purchased. You can still change this event to private';
export const EVENT_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_ARTICLES_ERROR = 'Error while fetching articles';
export const FETCH_ARTICLE_ERROR = 'Error while fetching article';
export const ARTICLE_DOES_NOT_EXIST = 'Article does not exist';
export const INSERT_ARTICLE_SUCCESS = 'Article created successfully';
export const INSERT_ARTICLE_ERROR = 'Error while creating article';
export const UPDATE_ARTICLE_SUCCESS = 'Article updated successfully';
export const UPDATE_ARTICLE_ERROR = 'Error while updating article';
export const DELETE_ARTICLE_SUCCESS = 'Article deleted successfully';
export const DELETE_ARTICLE_ERROR = 'Error while deleting article';
export const ARTICLE_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_GALLERIES_ERROR = 'Error while fetching galleries';
export const FETCH_GALLERY_ERROR = 'Error while fetching gallery';
export const GALLERY_DOES_NOT_EXIST = 'Gallery does not exist';
export const INSERT_GALLERY_SUCCESS = 'Gallery created successfully';
export const INSERT_GALLERY_ERROR = 'Error while creating gallery';
export const UPDATE_GALLERY_SUCCESS = 'Gallery updated successfully';
export const UPDATE_GALLERY_ERROR = 'Error while updating gallery';
export const DELETE_GALLERY_SUCCESS = 'Gallery deleted successfully';
export const DELETE_GALLERY_ERROR = 'Error while deleting gallery';
export const DELETE_ARTICLE_GALLERY_ERROR = 'Error while deleting article gallery. To delete this gallery, please delete the article';
export const UPDATE_ARTICLE_GALLERY_ERROR = 'Error while updating gallery. Update this gallery from its corresponding article';
export const DELETE_MY_LIBRARY_GALLERY_ERROR = 'Error while deleting My Library. My Library cannot be deleted';
export const UPDATE_MY_LIBRARY_GALLERY_ERROR = 'Error while updating My Library. My Library cannot be updated';
export const GALLERY_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_PHOTOS_ERROR = 'Error while fetching imges';
export const FETCH_PHOTO_ERROR = 'Error while fetching image';
export const PHOTO_DOES_NOT_EXIST = 'Image does not exist';
export const INSERT_PHOTO_SUCCESS = 'Image uploaded successfully';
export const INSERT_PHOTOS_SUCCESS = 'Images uploaded successfully';
export const INSERT_PHOTO_ERROR = 'Error while uploading image';
export const INSERT_PHOTOS_ERROR = 'Error while uploading images';
export const UPDATE_PHOTO_SUCCESS = 'Image updated successfully';
export const UPDATE_PHOTO_ERROR = 'Error while updating image';
export const DELETE_PHOTO_SUCCESS = 'Image deleted successfully';
export const REMOVE_PHOTO_SUCCESS = 'Image removed from your profile successfully';
export const REMOVE_PHOTO_ERROR = 'Error while removing image from your profile successfully';
export const DELETE_PHOTO_ERROR = 'Error while deleting image';
export const PHOTO_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_CUSTOM_PAGES_ERROR = 'Error while fetching custom pages';
export const FETCH_CUSTOM_PAGE_ERROR = 'Error while fetching custom page';
export const CUSTOM_PAGE_DOES_NOT_EXIST = 'Custom Page does not exist';
export const INSERT_CUSTOM_PAGE_SUCCESS = 'Custom Page created successfully';
export const INSERT_CUSTOM_PAGE_ERROR = 'Error while creating custom page';
export const UPDATE_CUSTOM_PAGE_SUCCESS = 'Custom Page updated successfully';
export const UPDATE_CUSTOM_PAGE_ERROR = 'Error while updating custom page';
export const DELETE_CUSTOM_PAGE_SUCCESS = 'Custom Page deleted successfully';
export const DELETE_CUSTOM_PAGE_ERROR = 'Error while deleting custom page';
export const CUSTOM_PAGE_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_TICKETS_ERROR = 'Error while fetching tickets';
export const FETCH_TICKET_ERROR = 'Error while fetching ticket';
export const TICKET_DOES_NOT_EXIST = 'Ticket does not exist';
export const INSERT_TICKET_SUCCESS = 'Ticket created successfully';
export const INSERT_TICKET_ERROR = 'Error while creating ticket';
export const UPDATE_TICKET_SUCCESS = 'Ticket updated successfully';
export const UPDATE_TICKET_ERROR = 'Error while updating ticket';
export const DELETE_TICKET_SUCCESS = 'Ticket deleted successfully';
export const DELETE_TICKET_ERROR = 'Error while deleting ticket';
export const DELETE_TICKET_ERROR_BECAUSE_SOLD_TICKET = 'Ticket cannot be deleted because it is already been purchased. You can still change this ticket to private';
export const TICKET_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const FETCH_SOLD_TICKETS_ERROR = 'Error while fetching tickets';
export const FETCH_SOLD_TICKET_ERROR = 'Error while fetching ticket';
export const UPDATE_SOLD_TICKET_SUCCESS = 'Sold Ticket updated successfully';
export const UPDATE_SOLD_TICKET_ERROR = 'Error while updating sold ticket';
export const CANCEL_TICKET_ORDER = 'Order has been cancelled. Customer has been notified';
export const UNCANCEL_TICKET_ORDER = 'Ticket order is now reinstated. Customer has been notified';
export const CANCEL_TICKET_ORDERS = 'Orders have been cancelled. Customer has been notified';
export const UNCANCEL_TICKET_ORDERS = 'Ticket orders are now reinstated. Customer has been notified';
export const SOLD_TICKET_DOES_NOT_EXIST = 'Ticket does not exist';
export const ERROR_UPDATING_SOLD_TICKET = 'Error updating ticket';
export const ERROR_UPDATING_SOLD_TICKETS = 'Error updating tickets';

export const FETCH_PRODUCTS_ERROR = 'Error while fetching products';
export const FETCH_PRODUCT_ERROR = 'Error while fetching product';
export const FETCH_LINKED_PRODUCTS_ERROR = 'Error while fetching linked products';
export const CANCEL_PRODUCT_ORDER = 'Order has been cancelled. Customer has been notified';
export const UNCANCEL_PRODUCT_ORDER = 'Product order is now reinstated. Customer has been notified';
export const CANCEL_PRODUCT_ORDERS = 'Orders have been cancelled. Customer has been notified';
export const UNCANCEL_PRODUCT_ORDERS = 'Product orders are now reinstated. Customer has been notified';
export const SETUP_CAHOOT_SHIPPING_IDS = 'Cahoot Shipping IDs successfully setup';
export const SETUP_CAHOOT_SHIPPING_IDS_ERROR = 'Error while setting up Cahoot Shipping IDs';
export const REORDER_PRODUCTS_ERROR = 'Error while reordering products';
export const PRODUCT_DOES_NOT_EXIST = 'Product does not exist';
export const INSERT_PRODUCT_SUCCESS = 'Product created successfully';
export const INSERT_PRODUCT_ERROR = 'Error while creating product';
export const UPDATE_PRODUCT_SUCCESS = 'Product updated successfully';
export const UPDATE_PRODUCT_ERROR = 'Error while updating product';
export const DELETE_PRODUCT_SUCCESS = 'Product deleted successfully';
export const DELETE_PRODUCT_ERROR = 'Error while deleting product';
export const DELETE_PRODUCT_ERROR_BECAUSE_SOLD_PRODUCT = 'Product cannot be deleted because it is already been purchased. You can still change this product to private';
export const PRODUCT_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};
export const LINK_CODE_INCORRECT = 'Incorrect link code. To connect your products with external store, please enter the correct Link Code first.';

export const FETCH_SOLD_PRODUCTS_ERROR = 'Error while fetching products';
export const FETCH_SOLD_PRODUCT_ERROR = 'Error while fetching product';
export const UPDATE_SOLD_PRODUCT_SUCCESS = 'Sold Product updated successfully';
export const UPDATE_SOLD_PRODUCT_ERROR = 'Error while updating sold product';
export const SOLD_PRODUCT_DOES_NOT_EXIST = 'Product does not exist';
export const ERROR_UPDATING_SOLD_PRODUCT = 'Error updating sold product';
export const ERROR_UPDATING_SOLD_PRODUCTS = 'Error updating sold products';

export const FETCH_ANALYTICS_ERROR = 'Error while fetching analytics';

export const FORM_INFO_NOTIFICATION_SUCCESS = 'Email has been sent';
export const FORM_INFO_NOTIFICATION_ERROR = 'Error while sending email. Please try again';
export const FORM_INFO_NOTIFICATION_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};
export const FETCH_FORM_ERROR = 'Error while fetching form';
export const FETCH_FORMS_SUBMITTED_ERROR = 'Error while fetching submitted forms';
export const FETCH_FORMS_ERROR = 'Error while fetching forms';
export const FORM_DOES_NOT_EXIST = 'Form does not exist';
export const INSERT_FORM_SUCCESS = 'Form created successfully';
export const INSERT_FORM_ERROR = 'Error while creating form';
export const UPDATE_FORM_SUCCESS = 'Form updated successfully';
export const UPDATE_FORM_ERROR = 'Error while updating form';
export const DELETE_FORM_SUCCESS = 'Form deleted successfully';
export const DELETE_FORM_ERROR = 'Error while deleting form';
export const FORM_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};
export const ISEMAIL_LIMIT_EXCEEDED = 'Only one User Email Textfield allowed';
export const ENTER_ISEMAIL = "Please select a Textfield where the User's Email will be entered";
export const EMAIL_TEXTFIELD_SHOULD_BE_REQUIRED = "Please set User's Email Textfield as Required";
export const TEXTFIELD_PRICE_INVALID = 'Price invalid';
export const SELECT_OPTIONS_NO_OPTIONS = 'Please include options in Select Options';
export const SELECT_OPTIONS_TITLE_EMPTY = 'Please enter title in all options for Select Options';
export const SELECT_OPTIONS_PRICE_INVALID = 'Price in one of Select Options is wrong';
export const INVALID_LINES_MULTILINE = 'Please enter valid number of lines in Multiline Text Input';

export const STORE_EMAIL_NOTIFICATION_SUCCESS = 'Email has been sent';
export const STORE_EMAIL_NOTIFICATION_ERROR = 'Error while sending email. Please try again';
export const STORE_EMAIL_NOTIFICATION_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};
export const QUESTION_SUCCESS = 'Form received. Someone from our team will be in touch with you shortly';
export const QUESTION_ERROR = 'Error while submitting the form';
export const REVIEW_SUCCESS = 'Review received successfully';
export const REVIEW_ERROR = 'Error while submitting your review';
export const DOMAIN_SUCCESS = 'Domain info received. Someone from our team will be in touch with you shortly';
export const DOMAIN_ERROR = 'Error while submitting Domain info';
export const FEEDBACK_SUCCESS = 'Thank you for your Feedback';
export const FEEDBACK_ERROR = 'Error while submitting Feedback. Please try again';
export const FUTURE_SUCCESS = 'Thank you for your Feedback';
export const FUTURE_ERROR = 'Error while submitting Feedback. Please try again.';
export const STORE_PRODUCTS_AD_SUCCESS = 'We have notified vendor about your interest';
export const STORE_PRODUCTS_AD_ERROR = 'There was an error while contacting. Please try again.';
export const CAHOOT_SHIPPING_SUCCESS = 'Thank you for submitting Cahoot Shipping Form';

export const GALLERY_MAX_LIMIT_EXCEEDED = `The current limit for images in a gallery is ${maxImagesInGallery} images`;

export const ERROR_UPLOADING_IMAGES_USING_IMAGE_EDIT_POP_UP = 'Error while uploading cropped image';

export const ERROR_FETCHING_TEAM_SUBSCRIPTION_INFO = 'Error while fetching team subscription info';
export const ERROR_FETCHING_WEBSITE_SUBSCRIPTION_PRODUCT = 'Error while fetching website subscription product. Contact OptimX Sports if you are seeing this error';
export const ERROR_FETCHING_DOMAIN_SUBSCRIPTION_PRODUCT = 'Error while fetching domain subscription product. Contact OptimX Sports if you are seeing this error';
export const ERROR_FETCHING_DOMAIN_EMAIL_SUBSCRIPTION_PRODUCT = 'Error while fetching domain email subscription product. Contact OptimX Sports if you are seeing this error';
export const ERROR_FETCHING_ACCOUNT_SUBSCRIPTION_STATUS = 'Error while fetching account subscription status. Contact OptimX Sports if you are seeing this error';
export const ERROR_INITIATING_SUBSCRIPTION = 'Error while starting your subscription. Contact OptimX Sports if you are seeing this error';

export const ERROR_FETCHING_SHIPPING_LABEL = 'Error while fetching shipping label';
export const ERROR_FETCHING_SHIPPING_PRICES = 'Error while fetching shipping prices';

export const DASHBOARD_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};

export const CAHOOT_SHIPPING_FIELD_MISSING = (fieldTitle: string) => {
    return `Please enter the ${fieldTitle.toLowerCase()}`;
};
