import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(() => {
    return {
        customPopper: {
            zIndex: '99',
        },
        customTooltip: {
            backgroundColor: '#303030',
            color: 'white',
            fontSize: '1.3rem !important',
            fontWeight: '300',
            padding: '5px 20px',
        },
        customArrow: {
            color: '#303030',
            zIndex: '99',
        },
    };
});

const TooltipComponent3 = (props) => {
    const { children, title, className, arrow, placement, interactive, enterDelay, leaveDelay, disableFocusListener, disableHoverListener } = props;
    const classes = useStyles();

    return (

        <Tooltip
            title={title}
            className={className}
            TransitionComponent={Zoom}
            arrow={arrow}
            placement={placement}
            classes={{
                popper: classes.customPopper,
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
            }}
            interactive={interactive}
            enterDelay={enterDelay}
            leaveDelay={leaveDelay}
            aria-label={title}
            disableFocusListener={disableFocusListener}
            disableHoverListener={disableHoverListener}
        >
            {children}
        </Tooltip>
    );
};

TooltipComponent3.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.any,
    className: PropTypes.string,
    arrow: PropTypes.bool,
    placement: PropTypes.string,
    interactive: PropTypes.bool,
    enterDelay: PropTypes.number,
    leaveDelay: PropTypes.number,
    disableFocusListener: PropTypes.bool,
    disableHoverListener: PropTypes.bool,
};

TooltipComponent3.defaultProps = {
    title: '',
    className: '',
    arrow: true,
    placement: 'bottom',
    interactive: false,
    enterDelay: 0,
    leaveDelay: 0,
    disableFocusListener: false,
    disableHoverListener: false,
};

export default memo(TooltipComponent3);
