import { createMailReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsMail';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { MailerSendMailObject } from '../interface/mailInterface/MailerSendMailInterface';
import { isValidString } from '../Validity';

export const createSignUpButtonPressedMailObject = (
    mailId: string,
    teamName: string,
    organizationName: string,
    sportsType: SportsType,
    referralId: string,
    email: string,
): MailerSendMailObject => {
    return {
        id: mailId,
        reference: createMailReference(mailId),
        teamId: '',
        teamReference: null,
        from: { email: 'admin@optimxsports.com', name: 'OptimX Sports' },
        to: [{ email: 'contact@optimxtechnologies.com', name: 'OptimX Sports' }],
        cc: [],
        bcc: [],
        reply_to: {},
        subject: createSignUpButtonPressedMailSubject(teamName, email, referralId),
        html: createSignUpButtonPressedMailText(teamName, organizationName, email, referralId),
        text: createSignUpButtonPressedMailText(teamName, organizationName, email, referralId),
        template_id: '',
        variables: [],
        personalization: [],
        tags: ['signup', 'signup_button'],
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

const createSignUpButtonPressedMailText = (
    teamName: string,
    organizationName: string,
    email: string,
    referralId: string,
) => {
    return `Signup Button Pressed --- Team Name is: ${teamName}. Organization Name is: ${organizationName}. Email is: ${email}. Referral Id: ${referralId}`;
};

const createSignUpButtonPressedMailSubject = (teamName: string, email: string, referralId: string) => {
    if (isValidString(referralId)) {
        return `SIGNUP BUTTON PRESSED FROM ${teamName} USING EMAIL: ${email} THROUGH: ${referralId}`;
    }
    return `SIGNUP BUTTON PRESSED FROM ${teamName} USING EMAIL: ${email}`;
};
