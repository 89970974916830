import { createMailReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsMail';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { MailerSendMailObject } from '../interface/mailInterface/MailerSendMailInterface';

export const createLicensingAgreementMailObject = (
    mailId: string,
    teamId: string,
    teamName: string,
    organizationId: string,
    organizationName: string,
    sportsType: SportsType,
    email: string,
    url: string,
): MailerSendMailObject => {
    return {
        id: mailId,
        reference: createMailReference(mailId),
        teamId,
        teamReference: createTeamReference(teamId),
        from: { email: 'admin@optimxsports.com', name: 'OptimX Sports' },
        to: [{ email: 'contact@optimxtechnologies.com', name: 'OptimX Sports' }],
        cc: [],
        bcc: [],
        reply_to: {},
        subject: createLicensingAgreementMailSubject(teamName, email),
        html: createLicensingAgreementMailText(teamId, teamName, organizationId, organizationName, email, url),
        text: createLicensingAgreementMailText(teamId, teamName, organizationId, organizationName, email, url),
        template_id: '',
        variables: [],
        personalization: [],
        tags: ['licensing_agreement', `licensing_agreement_${teamId}`, teamId],
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

const createLicensingAgreementMailText = (
    teamId: string,
    teamName: string,
    organizationId: string,
    organizationName: string,
    email: string,
    url: string,
) => {
    return `License Agreement Signed --- Team Name is: ${teamName}. Team Id is: ${teamId}. Organization Name is: ${organizationName}. Organization Id is: ${organizationId}. Email is: ${email}. URL is: ${url}.`;
};

const createLicensingAgreementMailSubject = (teamName: string, email: string) => {
    return `LICENSE AGREEMENT SIGNED FROM ${teamName} USING EMAIL: ${email}`;
};

export const createStoreLicensingAgreementMailObject = (
    mailId: string,
    teamId: string,
    teamName: string,
    organizationId: string,
    organizationName: string,
    sportsType: SportsType,
    email: string,
    url: string,
): MailerSendMailObject => {
    return {
        id: mailId,
        reference: createMailReference(mailId),
        teamId,
        teamReference: createTeamReference(teamId),

        from: { email: 'admin@optimxsports.com', name: 'OptimX Sports' },
        to: [{ email: 'contact@optimxtechnologies.com', name: 'OptimX Sports' }],
        cc: [],
        bcc: [],
        reply_to: {},
        subject: createStoreLicensingAgreementMailSubject(teamName, email),
        html: createStoreLicensingAgreementMailText(teamId, teamName, organizationId, organizationName, email, url),
        text: createStoreLicensingAgreementMailText(teamId, teamName, organizationId, organizationName, email, url),
        template_id: '',
        variables: [],
        personalization: [],
        tags: ['store_licensing_agreement', `store_licensing_agreement_${teamId}`, teamId],
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};

const createStoreLicensingAgreementMailText = (
    teamId: string,
    teamName: string,
    organizationId: string,
    organizationName: string,
    email: string,
    url: string,
) => {
    return `Store License Agreement Signed --- Team Name is: ${teamName}. Team Id is: ${teamId}. Organization Name is: ${organizationName}. Organization Id is: ${organizationId}. Email is: ${email}. URL is: ${url}.`;
};

const createStoreLicensingAgreementMailSubject = (teamName: string, email: string) => {
    return `STORE LICENSE AGREEMENT SIGNED FROM ${teamName} USING EMAIL: ${email}`;
};
