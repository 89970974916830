import React, { memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button2 from '../buttons/button2/Button2';
import OptimxWhiteIcon from '../../../assets/icons/optimxGreenIcon.png';
import Stripe from '../../../assets/stripe/trustByStripe.png';
import './css/Footer.css';

const Footer = () => {
    const history = useHistory();
    const params = useParams();

    useEffect(() => {
    }, [params]);

    const DRAWER_VISBLE_URL_SEGMENTS = ['portal'];
    const firstSegment = history.location.pathname.split('/')[1];

    return (
        <>
            {DRAWER_VISBLE_URL_SEGMENTS.includes(firstSegment) ? null : (
                <footer className="footer-container">
                    <img src={OptimxWhiteIcon} className="optimx-icon m-b-1" alt="OptimX Technologies" />
                    <div className="m-l-1 m-r-1 x-center">
                        <a className="footer-link text-no-underline" href="https://optimxtechnologies.com/privacypolicy">
                            <Button2 title="Privacy Policy" />
                        </a>
                        <a className="footer-link text-no-underline" href="https://optimxtechnologies.com/termsofservice">
                            <Button2 title="Terms Of Service" />
                        </a>
                    </div>
                    <img src={Stripe} className="stripe-badge" alt="Stripe Trust Badge" />
                    <p className="m-b-0 p-t-20px condensed-font-family font-weight-100 text-white">© 2024 OPTIMX SPORTS</p>
                </footer>
            )}
        </>
    );
};

export default memo(Footer);
