import { SportsType } from '../../catalog/enums/SportsType';
import { GalleryObject } from '../../catalog/interface/GalleryInterface';
import { PhotoObject } from '../../catalog/interface/PhotoInterface';
import { isValidObject } from '../../catalog/Validity';
import { db } from '../FirebaseLibrary';
import { createPhotoReference } from './FirebaseAPICallsPhoto';

export const createGalleriesReference = () => {
    return db.collection('galleries');
};

export const createGalleryReference = (galleryId: string) => {
    return createGalleriesReference().doc(galleryId);
};

export const fetchGalleryFirestoreAPI = (galleryId: string) => {
    return createGalleryReference(galleryId).get();
};

export const insertGalleryFirestoreAPI = (galleryObject: GalleryObject) => {
    return createGalleryReference(galleryObject.id).set(galleryObject);
};

export const insertGalleryAlongWithPhotosFirestoreAPI = (galleryObject: GalleryObject, photoObjects: PhotoObject[]) => {
    const batch = db.batch();
    photoObjects.forEach((photoObject) => {
        batch.set(createPhotoReference(photoObject.id), photoObject);
    });
    batch.set(createGalleryReference(galleryObject.id), galleryObject);
    return batch.commit();
};

export const updateGalleryFirestoreAPI = (galleryObject: any) => {
    return createGalleryReference(galleryObject.id).update(galleryObject);
};

export const updateGalleryAlongWithPhotosFirestoreAPI = (galleryObject: any, photoObjects: any[]) => {
    const batch = db.batch();
    photoObjects.forEach((photoObject) => {
        if (isValidObject(photoObject.reference)) {
            batch.set(createPhotoReference(photoObject.id), photoObject);
        } else {
            batch.update(createPhotoReference(photoObject.id), photoObject);
        }
    });
    batch.update(createGalleryReference(galleryObject.id), galleryObject);
    return batch.commit();
};

export const deleteGalleryFirestoreAPI = (galleryId: string) => {
    return createGalleryReference(galleryId).delete();
};

export const deleteGalleryAlongWithPhotosFirestoreAPI = (galleryId: string) => {
    const galleryRef = createGalleryReference(galleryId);
    return db.runTransaction(async (transaction) => {
        const galleryDoc: any = await transaction.get(galleryRef);
        if (galleryDoc.exists && isValidObject(galleryDoc.data())) {
            const { photoIds } = galleryDoc.data();
            await Promise.all(photoIds.map(async (photoId: any) => {
                const photoDoc: any = await transaction.get(createPhotoReference(photoId));
                if (photoDoc.exists && isValidObject(photoDoc.data())) {
                    transaction.delete(createPhotoReference(photoId));
                }
            }));
            transaction.delete(galleryRef);
        }
    });
};

export const fetchGalleriesFirestoreAPI = (teamId: string) => {
    return createGalleriesReference().where('teamId', '==', teamId).orderBy('timeOfGallery', 'desc').get();
};

export const fetchGalleriesByTagFirestoreAPI = (teamId: string, tagId: string, sportsType: SportsType) => {
    return createGalleriesReference().where('teamId', '==', teamId)
        .where('tagIds', 'array-contains', tagId)
        .where('sportsType', '==', sportsType)
        .orderBy('timeCreated', 'desc')
        .get();
};
