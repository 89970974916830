import { SportsType } from '../../../enums/SportsType';
import { TeamWebsiteImageObject } from '../../../interface/TeamInterface';

export const getDemoTeamWebsiteImagesObject = (sportsType: SportsType): TeamWebsiteImageObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return (
            [
                {
                    id: 'featureImage1',
                    required: true,
                    imageTitle: 'Feature Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FfeatureImage1.png?alt=media&token=f0c95123-3294-49be-a9cf-25d0ebff76a4',
                    title: 'Feature Image 1',
                    link: '',
                    description: 'This is the feature image 1 for the website',
                },
                {
                    id: 'featureImage2',
                    required: false,
                    imageTitle: 'Feature Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FfeatureImage2.png?alt=media&token=45c92697-703c-4fb6-a079-7889fca4c81d',
                    title: 'Feature Image 2',
                    link: '',
                    description: 'This is the feature image 2 for the website',
                },
                {
                    id: 'featureImage3',
                    required: false,
                    imageTitle: 'Feature Image 3',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FfeatureImage3.png?alt=media&token=6ff04f99-2894-4850-8680-395f27ce2320',
                    title: 'Feature Image 3',
                    link: '',
                    description: 'This is the feature image 3 for the website',
                },
                {
                    id: 'mFeatureImage1',
                    required: true,
                    imageTitle: 'Mobile Feature Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FfeatureImage1.png?alt=media&token=f0c95123-3294-49be-a9cf-25d0ebff76a4',
                    title: 'Mobile Feature Image 1',
                    link: '',
                    description: 'This is the feature image 1 for the website on mobile',
                },
                {
                    id: 'mFeatureImage2',
                    required: false,
                    imageTitle: 'Mobile Feature Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FfeatureImage2.png?alt=media&token=45c92697-703c-4fb6-a079-7889fca4c81d',
                    title: 'Mobile Feature Image 2',
                    link: '',
                    description: 'This is the feature image 2 for the website on mobile',
                },
                {
                    id: 'mFeatureImage3',
                    required: false,
                    imageTitle: 'Mobile Feature Image 3',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FfeatureImage3.png?alt=media&token=6ff04f99-2894-4850-8680-395f27ce2320',
                    title: 'Mobile Feature Image 3',
                    link: '',
                    description: 'This is the feature image 3 for the website on mobile',
                },
                {
                    id: 'bannerImage1',
                    required: false,
                    imageTitle: 'Banner Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FbannerImage2_1600x900.png?alt=media&token=bed8f29a-3e84-4641-b5f5-67e742585e25',
                    title: 'Banner Image 1',
                    link: '',
                    description: 'This is the banner image 1 for the website',
                },
                {
                    id: 'bannerImage2',
                    required: false,
                    imageTitle: 'Banner Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FbannerImage2_1600x900.png?alt=media&token=bed8f29a-3e84-4641-b5f5-67e742585e25',
                    title: 'Banner Image 2',
                    link: '',
                    description: 'This is the banner image 2 for the website',
                },
                {
                    id: 'rosterFeatureImage',
                    required: false,
                    imageTitle: 'Default Roster Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2FiceHockey%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=dc5869a4-2fd1-4b44-bd18-021497861dfd',
                    title: 'Default Roster Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every player if their feature image is not available',
                },
                {
                    id: 'articleFeatureImage',
                    required: false,
                    imageTitle: 'Default Article Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoArticle%2FiceHockey%2FdemoFeatureImage%2FdemoFeatureImage.png?alt=media&token=5f88ecb0-f454-4632-be94-37878a83e3cc',
                    title: 'Default Article Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every article if the feature image is not available',
                },
                {
                    id: 'gameFeatureImage',
                    required: false,
                    imageTitle: 'Default Game Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGame%2FiceHockey%2FdemoFeatureImage%2FdemoFeatureImage.png?alt=media&token=b570520e-f984-47ae-b480-67b5ca848837',
                    title: 'Default Game Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every game if the feature image is not available',
                },
                {
                    id: 'galleryFeatureImage',
                    required: false,
                    imageTitle: 'Default Gallery Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2FiceHockey%2FdemoArticleGallery%2FdemoGallery2.png?alt=media&token=28d75871-9412-4991-b6e7-d77ce3beb6ed',
                    title: 'Default Gallery Feature Image',
                    link: '',
                    description: 'This is the thumbnail image shown for every gallery if the gallery does not contain any images',
                },
                {
                    id: 'eventFeatureImage',
                    required: false,
                    imageTitle: 'Default Event Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67',
                    title: 'Default Event Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every event if the feature image is not available',
                },
                {
                    id: 'practiceFeatureImage',
                    required: false,
                    imageTitle: 'Default Practice Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67',
                    title: 'Default Practice Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every practice if the feature image is not available',
                },
                {
                    id: 'storeFeatureImage1',
                    required: false,
                    imageTitle: 'Store Feature Image',
                    image: '',
                    title: 'Store Feature Image',
                    link: '',
                    description: 'This is the feature image shown in Store',
                },
                {
                    id: 'mStoreFeatureImage1',
                    required: false,
                    imageTitle: 'Mobile Store Feature Image',
                    image: '',
                    title: 'Mobile Store Feature Image',
                    link: '',
                    description: 'This is the feature image shown in Store on mobile',
                },
            ]
        );
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return (
            [
                {
                    id: 'featureImage1',
                    required: true,
                    imageTitle: 'Feature Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoTeamImages%2FfeatureImage1_1600x900.png?alt=media&token=79783c58-1281-4266-a961-9f2af944aa2a',
                    title: 'Feature Image 1',
                    link: '',
                    description: 'This is the feature image 1 for the website',
                },
                {
                    id: 'featureImage2',
                    required: false,
                    imageTitle: 'Feature Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoTeamImages%2FfeatureImage2_1600x900.png?alt=media&token=45c92697-703c-4fb6-a079-7889fca4c81d',
                    title: 'Feature Image 2',
                    link: '',
                    description: 'This is the feature image 2 for the website',
                },
                {
                    id: 'featureImage3',
                    required: false,
                    imageTitle: 'Feature Image 3',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoTeamImages%2FfeatureImage3_1600x900.png?alt=media&token=6ff04f99-2894-4850-8680-395f27ce2320',
                    title: 'Feature Image 3',
                    link: '',
                    description: 'This is the feature image 3 for the website',
                },
                {
                    id: 'mFeatureImage1',
                    required: true,
                    imageTitle: 'Mobile Feature Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoTeamImages%2FfeatureImage1_1600x900.png?alt=media&token=f0c95123-3294-49be-a9cf-25d0ebff76a4',
                    title: 'Mobile Feature Image 1',
                    link: '',
                    description: 'This is the feature image 1 for the website on mobile',
                },
                {
                    id: 'mFeatureImage2',
                    required: false,
                    imageTitle: 'Mobile Feature Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoTeamImages%2FfeatureImage2_1600x900.png?alt=media&token=45c92697-703c-4fb6-a079-7889fca4c81d',
                    title: 'Mobile Feature Image 2',
                    link: '',
                    description: 'This is the feature image 2 for the website on mobile',
                },
                {
                    id: 'mFeatureImage3',
                    required: false,
                    imageTitle: 'Mobile Feature Image 3',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoTeamImages%2FfeatureImage3_1600x900.png?alt=media&token=6ff04f99-2894-4850-8680-395f27ce2320',
                    title: 'Mobile Feature Image 3',
                    link: '',
                    description: 'This is the feature image 3 for the website on mobile',
                },
                {
                    id: 'bannerImage1',
                    required: false,
                    imageTitle: 'Banner Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FbannerImage2_1600x900.png?alt=media&token=bed8f29a-3e84-4641-b5f5-67e742585e25',
                    title: 'Banner Image 1',
                    link: '',
                    description: 'This is the banner image 1 for the website',
                },
                {
                    id: 'bannerImage2',
                    required: false,
                    imageTitle: 'Banner Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoTeamImages%2FbannerImage2_1600x900.png?alt=media&token=bed8f29a-3e84-4641-b5f5-67e742585e25',
                    title: 'Banner Image 2',
                    link: '',
                    description: 'This is the banner image 2 for the website',
                },
                {
                    id: 'rosterFeatureImage',
                    required: false,
                    imageTitle: 'Default Roster Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2Frugby%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=dc5869a4-2fd1-4b44-bd18-021497861dfd',
                    title: 'Default Roster Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every player if their feature image is not available',
                },
                {
                    id: 'articleFeatureImage',
                    required: false,
                    imageTitle: 'Default Article Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoArticle%2Frugby%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=5f88ecb0-f454-4632-be94-37878a83e3cc',
                    title: 'Default Article Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every article if the feature image is not available',
                },
                {
                    id: 'gameFeatureImage',
                    required: false,
                    imageTitle: 'Default Game Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGame%2Frugby%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=b570520e-f984-47ae-b480-67b5ca848837',
                    title: 'Default Game Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every game if the feature image is not available',
                },
                {
                    id: 'galleryFeatureImage',
                    required: false,
                    imageTitle: 'Default Gallery Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Frugby%2FdemoGallery%2FdemoGallery1_1600x900.png?alt=media&token=9ab53bd4-a33b-4f7c-8910-bdd249f76e71',
                    title: 'Default Gallery Feature Image',
                    link: '',
                    description: 'This is the thumbnail image shown for every gallery if the gallery does not contain any images',
                },
                {
                    id: 'eventFeatureImage',
                    required: false,
                    imageTitle: 'Default Event Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67',
                    title: 'Default Event Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every event if the feature image is not available',
                },
                {
                    id: 'practiceFeatureImage',
                    required: false,
                    imageTitle: 'Default Practice Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67',
                    title: 'Default Practice Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every practice if the feature image is not available',
                },
                {
                    id: 'storeFeatureImage1',
                    required: false,
                    imageTitle: 'Store Feature Image',
                    image: '',
                    title: 'Store Feature Image',
                    link: '',
                    description: 'This is the feature image shown in Store',
                },
                {
                    id: 'mStoreFeatureImage1',
                    required: false,
                    imageTitle: 'Mobile Store Feature Image',
                    image: '',
                    title: 'Mobile Store Feature Image',
                    link: '',
                    description: 'This is the feature image shown in Store on mobile',
                },
            ]
        );
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return (
            [
                {
                    id: 'featureImage1',
                    required: true,
                    imageTitle: 'Feature Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoTeamImages%2FfeatureImage1_1600x900.png?alt=media&token=f0c95123-3294-49be-a9cf-25d0ebff76a4',
                    title: 'Feature Image 1',
                    link: '',
                    description: 'This is the feature image 1 for the website',
                },
                {
                    id: 'featureImage2',
                    required: false,
                    imageTitle: 'Feature Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoTeamImages%2FfeatureImage2_1600x900.png?alt=media&token=45c92697-703c-4fb6-a079-7889fca4c81d',
                    title: 'Feature Image 2',
                    link: '',
                    description: 'This is the feature image 2 for the website',
                },
                {
                    id: 'featureImage3',
                    required: false,
                    imageTitle: 'Feature Image 3',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoTeamImages%2FfeatureImage3_1600x900.png?alt=media&token=6ff04f99-2894-4850-8680-395f27ce2320',
                    title: 'Feature Image 3',
                    link: '',
                    description: 'This is the feature image 3 for the website',
                },
                {
                    id: 'mFeatureImage1',
                    required: true,
                    imageTitle: 'Mobile Feature Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoTeamImages%2FfeatureImage1_1600x900.png?alt=media&token=f0c95123-3294-49be-a9cf-25d0ebff76a4',
                    title: 'Mobile Feature Image 1',
                    link: '',
                    description: 'This is the feature image 1 for the website on mobile',
                },
                {
                    id: 'mFeatureImage2',
                    required: false,
                    imageTitle: 'Mobile Feature Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoTeamImages%2FfeatureImage2_1600x900.png?alt=media&token=45c92697-703c-4fb6-a079-7889fca4c81d',
                    title: 'Mobile Feature Image 2',
                    link: '',
                    description: 'This is the feature image 2 for the website on mobile',
                },
                {
                    id: 'mFeatureImage3',
                    required: false,
                    imageTitle: 'Mobile Feature Image 3',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoTeamImages%2FfeatureImage3_1600x900.png?alt=media&token=6ff04f99-2894-4850-8680-395f27ce2320',
                    title: 'Mobile Feature Image 3',
                    link: '',
                    description: 'This is the feature image 3 for the website on mobile',
                },
                {
                    id: 'bannerImage1',
                    required: false,
                    imageTitle: 'Banner Image 1',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoTeamImages%2FbannerImage2_1600x900.png?alt=media&token=bed8f29a-3e84-4641-b5f5-67e742585e25',
                    title: 'Banner Image 1',
                    link: '',
                    description: 'This is the banner image 1 for the website',
                },
                {
                    id: 'bannerImage2',
                    required: false,
                    imageTitle: 'Banner Image 2',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoTeamImages%2FbannerImage2_1600x900.png?alt=media&token=bed8f29a-3e84-4641-b5f5-67e742585e25',
                    title: 'Banner Image 2',
                    link: '',
                    description: 'This is the banner image 2 for the website',
                },
                {
                    id: 'rosterFeatureImage',
                    required: false,
                    imageTitle: 'Default Roster Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2Flacrosse%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=dc5869a4-2fd1-4b44-bd18-021497861dfd',
                    title: 'Default Roster Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every player if their feature image is not available',
                },
                {
                    id: 'articleFeatureImage',
                    required: false,
                    imageTitle: 'Default Article Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoArticle%2Flacrosse%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=5f88ecb0-f454-4632-be94-37878a83e3cc',
                    title: 'Default Article Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every article if the feature image is not available',
                },
                {
                    id: 'gameFeatureImage',
                    required: false,
                    imageTitle: 'Default Game Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGame%2Flacrosse%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=b570520e-f984-47ae-b480-67b5ca848837',
                    title: 'Default Game Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every game if the feature image is not available',
                },
                {
                    id: 'galleryFeatureImage',
                    required: false,
                    imageTitle: 'Default Gallery Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGallery%2Flacrosse%2FdemoArticleGallery%2FdemoGallery2_1600x900.png?alt=media&token=28d75871-9412-4991-b6e7-d77ce3beb6ed',
                    title: 'Default Gallery Feature Image',
                    link: '',
                    description: 'This is the thumbnail image shown for every gallery if the gallery does not contain any images',
                },
                {
                    id: 'eventFeatureImage',
                    required: false,
                    imageTitle: 'Default Event Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67',
                    title: 'Default Event Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every event if the feature image is not available',
                },
                {
                    id: 'practiceFeatureImage',
                    required: false,
                    imageTitle: 'Default Practice Feature Image',
                    image: 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67',
                    title: 'Default Practice Feature Image',
                    link: '',
                    description: 'This is the feature image shown for every practice if the feature image is not available',
                },
                {
                    id: 'storeFeatureImage1',
                    required: false,
                    imageTitle: 'Store Feature Image',
                    image: '',
                    title: 'Store Feature Image',
                    link: '',
                    description: 'This is the feature image shown in Store',
                },
                {
                    id: 'mStoreFeatureImage1',
                    required: false,
                    imageTitle: 'Mobile Store Feature Image',
                    image: '',
                    title: 'Mobile Store Feature Image',
                    link: '',
                    description: 'This is the feature image shown in Store on mobile',
                },
            ]
        );
    }
    return [];
};
