import { SportsType } from '../../enums/SportsType';
import { TeamInfoObject } from '../../interface/TeamInterface';

export const getDemoTeamInfoSlogan = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'Best hockey team';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'Best rugby team';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'Best Lacrosse team';
    }
    return '';
};
export const getDemoTeamInfoInitials = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'WHT';
    } if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'MHT';
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return 'WRT';
    } if (sportsType === SportsType.MENSRUGBY) {
        return 'MRT';
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'WLT';
    } if (sportsType === SportsType.MENSLACROSSE) {
        return 'MLT';
    }
    return '';
};
export const getDemoTeamInfoAbout = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'This is the Womens Hockey Team';
    } if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'This is the Mens Hockey Team';
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return 'This is the Womens Rugby Team';
    } if (sportsType === SportsType.MENSRUGBY) {
        return 'This is the Mens Rugby Team';
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'This is the Womens Lacrosse Team';
    } if (sportsType === SportsType.MENSLACROSSE) {
        return 'This is the Mens Lacrosse Team';
    }
    return '';
};
export const demoTeamInfoStadium = 'Main Stadium';
export const demoTeamInfoAddress = '107 S Indiana Ave, Bloomington, IN 47405';
export const demoTeamInfoContacts = [
    { title: 'Main', required: true, email: 'janedoe@optimxsports.com', tel: '+1-(123)-456-7890' },
];

export const getDemoTeamInfoObject = (teamName: string, sportsType: SportsType): TeamInfoObject => {
    return {
        alias: teamName,
        slogan: getDemoTeamInfoSlogan(sportsType),
        initials: getDemoTeamInfoInitials(sportsType),
        shortAbout: getDemoTeamInfoAbout(sportsType),
        longAbout: getDemoTeamInfoAbout(sportsType),
        stadiumName: demoTeamInfoStadium,
        address: demoTeamInfoAddress,
        contacts: demoTeamInfoContacts,
    };
};
