import { storageRef } from '../FirebaseLibrary';

function delay(t: any, v: any) {
    return new Promise((resolve) => {
        setTimeout(resolve.bind(null, v), t);
    });
}

export const fetchImageURLStorageAPI = (withResizedLink: boolean, triesRemaining: number, ref: any): any => {
    if (triesRemaining < 10) {
        // eslint-disable-next-line no-console
        console.log(triesRemaining);
    }

    return ref.getDownloadURL().then((url: string) => {
        return url;
    }).catch((error: any) => {
        switch (error.code) {
            case 'storage/object-not-found':
                if (triesRemaining < 0) {
                    return Promise.reject(error);
                }
                return delay(500, null).then(() => {
                    if (withResizedLink) {
                        return fetchImageURLStorageAPI(!withResizedLink, triesRemaining - 1, storageRef.child(ref.fullPath.replace('.png', '_1600x900.png')));
                    }
                    return fetchImageURLStorageAPI(!withResizedLink, triesRemaining - 1, storageRef.child(ref.fullPath.replace('_1600x900.png', '.png')));
                });
            default:
                return Promise.reject(error);
        }
    });
};

export const fetchImageURLWithoutErrorHandlingStorageAPI = (ref: any) => {
    return ref.getDownloadURL().then((url: string) => {
        return url;
    }).catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(error);
    });
};

export const fetchPDFURLStorageAPI = (triesRemaining: number, ref: any): any => {
    if (triesRemaining < 10) {
        // eslint-disable-next-line no-console
        console.log(triesRemaining);
    }

    return ref.getDownloadURL().then((url: string) => {
        return url;
    }).catch((error: any) => {
        switch (error.code) {
            case 'storage/object-not-found':
                if (triesRemaining < 0) {
                    return Promise.reject(error);
                }
                return delay(500, null).then(() => {
                    return fetchPDFURLStorageAPI(triesRemaining - 1, ref);
                });
            default:
                return Promise.reject(error);
        }
    });
};
