import { TeamSocialsObject } from '../../interface/TeamInterface';

export const demoTeamSocialTwitterURL = 'https://twitter.com/optimxsports';
export const demoTeamSocialFacebookURL = 'https://www.facebook.com/optimxsports';
export const demoTeamSocialTiktokURL = 'https://www.tiktok.com/@hockeyhousepod';
export const demoTeamSocialInstagramURL = 'https://www.instagram.com/optimxsports';
export const demoTeamSocialYoutubeURL = 'https://www.youtube.com/channel/UCqFMzb-4AUf6WAIbl132QKA';
export const demoTeamSocialYoutubeURL1 = 'https://www.youtube.com/channel/UC9tLrY2a3imtk0YDXiZlYfA';
export const demoTeamSocialSharingHashTags = '#OptimXSports, #CollegeSports';
export const demoTeamSocialInstagramWidget = '<!-- LightWidget WIDGET --><script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script><iframe src="https://cdn.lightwidget.com/widgets/a48388ba046259ccb4744ccfdd9851f7.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>';
export const demoTeamSocialInstagramWidget1 = '<div data-mc-src="486b93c7-2785-4543-9b1c-fe0791fc82e4#instagram"></div><script src="https://cdn2.woxo.tech/a.js#61c29330bcf6ba0016303197" async data-usrc></script>';
export const demoTeamSocialTwitterWidget = '<!-- SnapWidget --><script src="https://snapwidget.com/js/snapwidget.js"></script><iframe src="https://snapwidget.com/embed/978249" class="snapwidget-widget" allowtransparency="true" frameborder="0" scrolling="no" style="border:none; overflow:hidden;  width:100%; "></iframe>';
export const demoTeamSocialTwitterWidget1 = '<div data-mc-src="99ba12a3-fcdd-4661-ad7d-b1bed7a4d030#twitter"></div><script src="https://cdn2.woxo.tech/a.js#61c29330bcf6ba0016303197" async data-usrc></script>';
export const demoTeamSocialFacebookWidget = '<div data-mc-src="dd8adad4-ab8b-42c3-8268-52e98e6e6e38#facebook"></div><script src="https://cdn2.woxo.tech/a.js#61c29330bcf6ba0016303197" async data-usrc></script>';
export const demoTeamSocialTiktokWidget = '';
export const demoTeamSocialYoutubeWidget = '<script src="https://apps.elfsight.com/p/platform.js" defer></script><div class="elfsight-app-86a9bede-9fc2-44b9-afaf-beeb6d2fbee8"></div>';

export const demoTeamSocials: TeamSocialsObject = {
    twitterURL: demoTeamSocialTwitterURL,
    facebookURL: demoTeamSocialFacebookURL,
    tiktokURL: demoTeamSocialFacebookURL,
    instagramURL: demoTeamSocialInstagramURL,
    youtubeURL: demoTeamSocialYoutubeURL,
    sharingHashTags: demoTeamSocialSharingHashTags,
    twitterWidget: '',
    facebookWidget: '',
    tiktokwidget: '',
    instagramWidget: demoTeamSocialInstagramWidget,
    youtubeWidget: '',
};
