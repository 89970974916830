import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const ScrollToTop = (props) => {
    const { children } = props;
    const history = useHistory();

    useEffect(() => {
        // eslint-disable-next-line no-unused-vars
        const unlisten = history.listen((location, action) => {
            if (location.pathname.startsWith('/portal/store/overview')) {
                return;
            }
            // if (action === 'PUSH') {
            //     if (location.pathname.split('/').length <= 3) {
            window.scrollTo(0, 0);
            //     }
            // }
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return <>{children}</>;
};

ScrollToTop.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(ScrollToTop);
