import { createOrganizationReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../../DateTime';
import { SportsType } from '../../enums/SportsType';
import { createArticleReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsArticle';
import { TagType } from '../../enums/TagType';
import { createGalleryReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsGallery';
import { IceHockeyGameOurPenaltyObject, IceHockeyGameOpponentPenaltyObject, IceHockeyGameObject, IceHockeyGameOpponentGoalObject, IceHockeyGameOurGoalObject, IceHockeyGameFaceOffObject, IceHockeyGameBodyChecksObject, IceHockeyGamePowerplayObject, IceHockeyGameGoalieActionsObject, IceHockeyGameHighlightPerPeriodObject, IceHockeyGameHighlightPerPlayerObject } from '../../interface/gameInterface/iceHockey/IceHockeyGameInterface';
import { createGameReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsGame';
import { createSeasonReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsSeason';
import { demoOpponentName } from '../OpponentDemo';
import { getDemoSeasonPreDevelopedSeasonId, getDemoSeasonPreDevelopedSeasonReference } from '../SeasonDemo';
import { createLeagueReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsLeague';
import { createOpponentReference } from '../../../firebase/firebaseAPICalls/FirebaseAPICallsOpponent';
import { getDemoLeaguePreDevelopedConferenceId, getDemoLeaguePreDevelopedConferenceReference, getDemoLeaguePreDevelopedLeagueId, getDemoLeaguePreDevelopedLeagueReference } from '../LeagueDemo';
import { createGameTitle, demoGameAttendance, demoGameNotes, demoGameStatus, demoPromotionalText, demoPromotionalVideo } from './GameDemo';
import { demoTeamInfoStadium } from '../team/TeamInfoDemo';

export const demoIceHockeyGameFeatureImage = 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoGame%2FiceHockey%2FdemoFeatureImage%2FdemoFeatureImage.png?alt=media&token=b570520e-f984-47ae-b480-67b5ca848837';

export const getDemoIceHockeyGameWinOrLoss = (goals: (IceHockeyGameOurGoalObject | IceHockeyGameOpponentGoalObject)[]): ('win' | 'loss' | 'tied') => {
    let ourGoals = 0;
    let opponentGoals = 0;
    goals.forEach((goal) => {
        if (goal.team === 'our') {
            ourGoals += 1;
        } else {
            opponentGoals += 1;
        }
    });
    if (ourGoals > opponentGoals) {
        return 'win';
    } if (ourGoals === opponentGoals) {
        return 'tied';
    }
    return 'loss';
};
export const getDemoIceHockeyGameShutOut = (goals: (IceHockeyGameOurGoalObject | IceHockeyGameOpponentGoalObject)[]): boolean => {
    let opponentGoals = 0;
    goals.forEach((goal) => {
        if (goal.team === 'opponent') {
            opponentGoals += 1;
        }
    });
    if (opponentGoals === 0) {
        return true;
    }
    return false;
};
export const getDemoIceHockeyGameScore = (goals: (IceHockeyGameOurGoalObject | IceHockeyGameOpponentGoalObject)[], homeGame: boolean): string => {
    let ourGoals = 0;
    let opponentGoals = 0;
    goals.forEach((goal) => {
        if (goal.team === 'our') {
            ourGoals += 1;
        } else {
            opponentGoals += 1;
        }
    });
    if (homeGame) {
        return `${ourGoals}-${opponentGoals}`;
    }
    return `${opponentGoals}-${ourGoals}`;
};
export const getDemoIceHockeyGameGoals = (scorerId: string, assist1Id: string, assist2Id: string, goalieId: string): (IceHockeyGameOurGoalObject | IceHockeyGameOpponentGoalObject)[] => {
    return [{ team: 'our', period: 'p1', timeInSeconds: 540, scorerId, assist1Id, assist2Id, emptyNetGoal: false, shortHandedGoal: false, powerPlayGoal: false, gameWinningGoal: false }, { team: 'opponent', period: 'p1', timeInSeconds: 120, goalieId, emptyNetGoal: false, shortHandedGoal: false, powerPlayGoal: false, gameWinningGoal: false }];
};
export const getDemoIceHockeyGameHighlightsPerPeriod = (): IceHockeyGameHighlightPerPeriodObject[] => {
    return [
        { team: 'our', period: 'p1', type: 'shots', number: 8 },
        { team: 'our', period: 'p2', type: 'shots', number: 10 },
        { team: 'our', period: 'p1', type: 'blocks', number: 8 },
        { team: 'our', period: 'p2', type: 'blocks', number: 10 },
        { team: 'our', period: 'p1', type: 'saves', number: 8 },
        { team: 'our', period: 'p2', type: 'saves', number: 10 },
        { team: 'opponent', period: 'p1', type: 'shots', number: 8 },
        { team: 'opponent', period: 'p2', type: 'shots', number: 10 },
        { team: 'opponent', period: 'p1', type: 'blocks', number: 8 },
        { team: 'opponent', period: 'p2', type: 'blocks', number: 10 },
        { team: 'opponent', period: 'p1', type: 'saves', number: 8 },
        { team: 'opponent', period: 'p2', type: 'saves', number: 10 },
    ];
};
export const getDemoIceHockeyGameHighlightsPerPlayer = (playerId: string): IceHockeyGameHighlightPerPlayerObject[] => {
    return [
        { playerId, type: 'shots', number: 8 },
        { playerId, type: 'blocks', number: 8 },
    ];
};
export const getDemoIceHockeyGamePenalties = (playerId: string): (IceHockeyGameOurPenaltyObject | IceHockeyGameOpponentPenaltyObject)[] => {
    return [
        { team: 'our', period: 'p1', timeInSeconds: 1000, type: 'major', offence: 'butt', playerId, penaltyTimeInSeconds: 60 },
        { team: 'opponent', period: 'p2', timeInSeconds: 1000, type: 'minor', offence: 'boarding', penaltyTimeInSeconds: 120 },
    ];
};
export const getDemoIceHockeyGameFaceOffs = (playerId: string): IceHockeyGameFaceOffObject[] => {
    return [
        { team: 'our', playerId, wins: 60 },
        { team: 'opponent', playerId, wins: 120 },
    ];
};
export const getDemoIceHockeyGameGoalieActions = (playerId: string): IceHockeyGameGoalieActionsObject[] => {
    return [
        { period: 'p1', playerId, shotsOnGoal: 5, minutes: 60, shotsSaved: 3 },
        { period: 'p2', playerId, shotsOnGoal: 5, minutes: 60, shotsSaved: 3 },
    ];
};
export const getDemoIceHockeyGameBodyChecks = (playerId: string): IceHockeyGameBodyChecksObject[] => {
    return [
        { playerId, hits: 2 },
        { playerId, hits: 2 },
    ];
};
export const demoIceHockeyGamePowerplays: IceHockeyGamePowerplayObject[] = [{ team: 'our', period: 'p1', startTimeInSeconds: 20, endTimeInSeconds: 60, shots: 10, opponentShots: 14, goals: 1 }];

export const createDemoIceHockeyGame = (
    organizationId: string,
    teamId: string,
    gameId: string,
    tagIds: string[],
    tagReferences: any[],
    tagTypes: TagType[],
    seasonId: string,
    articleId: string,
    galleryId: string,
    leagueId: string,
    opponentId: string,
    playerId: string,
    sportsType: SportsType,
): IceHockeyGameObject => {
    const gameReference = createGameReference(gameId);
    const leagueReference = createLeagueReference(teamId, leagueId);
    const seasonReference = createSeasonReference(teamId, seasonId);
    const opponentReference = createOpponentReference(teamId, opponentId);
    const articleReference = createArticleReference(articleId);
    const galleryReference = createGalleryReference(galleryId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);
    const timeOfGame = new Date(2020, 12, 17, 0, 0, 0, 0);

    return {
        id: gameId,
        urlId: gameId,
        reference: gameReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        seasonId,
        seasonReference,
        preDevelopedSeasonId: getDemoSeasonPreDevelopedSeasonId(sportsType),
        preDevelopedSeasonReference: getDemoSeasonPreDevelopedSeasonReference(sportsType),
        leagueId,
        leagueReference,
        preDevelopedLeagueId: getDemoLeaguePreDevelopedLeagueId(sportsType),
        preDevelopedLeagueReference: getDemoLeaguePreDevelopedLeagueReference(sportsType),
        preDevelopedConferenceId: getDemoLeaguePreDevelopedConferenceId(sportsType),
        preDevelopedConferenceReference: getDemoLeaguePreDevelopedConferenceReference(sportsType),
        opponentId,
        opponentReference,
        articleId,
        articleReference,
        galleryId,
        galleryReference,
        title: createGameTitle(demoOpponentName, demoGameStatus, timeOfGame),
        sportsType,
        homeGame: true,
        status: demoGameStatus,
        timeOfGame,
        venue: demoTeamInfoStadium,
        attendance: demoGameAttendance,
        tagIds,
        tagReferences,
        tagTypes,
        featureImage: demoIceHockeyGameFeatureImage,
        articleImage: demoIceHockeyGameFeatureImage,
        winOrLoss: getDemoIceHockeyGameWinOrLoss(getDemoIceHockeyGameGoals(playerId, playerId, playerId, playerId)),
        gameScore: getDemoIceHockeyGameScore(getDemoIceHockeyGameGoals(playerId, playerId, playerId, playerId), true),
        shutOut: getDemoIceHockeyGameShutOut(getDemoIceHockeyGameGoals(playerId, playerId, playerId, playerId)),
        goals: getDemoIceHockeyGameGoals(playerId, playerId, playerId, playerId),
        highlightsPerPeriod: getDemoIceHockeyGameHighlightsPerPeriod(),
        highlightsPerPlayer: getDemoIceHockeyGameHighlightsPerPlayer(playerId),
        penalties: getDemoIceHockeyGamePenalties(playerId),
        faceOffs: getDemoIceHockeyGameFaceOffs(playerId),
        goalieActions: getDemoIceHockeyGameGoalieActions(playerId),
        bodyChecks: getDemoIceHockeyGameBodyChecks(playerId),
        powerplays: demoIceHockeyGamePowerplays,
        promotionalText: demoPromotionalText,
        promotionalVideo: demoPromotionalVideo,
        notes: demoGameNotes,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
