import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { createTeamSubscriptionInfoReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsSubscriptionInfo';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { SubscriptionInfoObject } from '../interface/SubscriptionInfoInterface';

export const subscriptionInfoId = 'subscription-info';

export const createDemoInfoSubscription = (organizationId: string, teamId: string, sportsType: SportsType): SubscriptionInfoObject => {
    const subscriptionInfoReference = createTeamSubscriptionInfoReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: subscriptionInfoId,
        reference: subscriptionInfoReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,

        websiteCharge: true,
        websiteTrial: true,
        websitePromoCode: '',

        domain: false,
        domainURL: '',
        domainCharge: false,
        domainTrial: false,
        domainPromoCode: '',

        domainEmails: [],
        emailCharge: true,
        emailTrial: false,
        emailPromoCode: '',

        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
