/**
 * Random function used through out the project
 */
import uniqid from 'uniqid';
import { lazy } from 'react';
import Resizer from 'react-image-file-resizer';
import { isValidObject, isValidString } from './Validity';

export const optimXDevelopment = false;
export const optimXTesting = optimXDevelopment || false;

export const contactEmail = 'contact@optimxtechnologies.com';
export const contactPhone = '317-748-0245';

export const maxImagesInGallery = 20;
export const analyticsMaxDataShown = 5;
export const loginsAllowedBeforeEmailVerification = 5;
export const referralPrice = 100;
export const freeTrialMonths = 1;

/**
 * This function creates random alpha numeric string for keys in maps and ids of items
 */
export const randomNumber = (): string => {
    // return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + (new Date()).getTime();
    return uniqid();
};

/**
 * This function creates random alpha numeric string for keys in maps and ids of items
 */
export const documentIdWithRandomNumber = (title: string): string => {
    if (isValidString(title)) {
        let finalTitle = title.trim().replace(/[^a-zA-Z0-9 ]/g, '');
        finalTitle = finalTitle.replace(/\s+/g, '-').toLowerCase();
        // return `${finalTitle}-${Math.random().toString(36).substring(2, 15)}`;
        return `${finalTitle}-${uniqid()}`;
    }
    return randomNumber();
};

/**
 * Dummy is added in links with firebasestorage to disable caching.
 * Otherwise images are not updated whenever the page appears.
 */
export const getImageLink = (link: string) => {
    if (link.startsWith('http')) { return `${link}?dummy=${randomNumber()}`; }
    return link;
};

/**
 * This function is used to get 1600x900 image url from the original image. This is the image that will be
 * used in the future.
 *
 * @param {*} url this contains the original url
 */
export const convertURLTo1600x900 = (url: string) => {
    if (url.includes('_1600x900.png')) {
        return url;
    }
    return `${url.slice(0, url.indexOf('.png?'))}_1600x900${url.slice(url.indexOf('.png?'), url.length)}`;
};

export const isObjectVisibleAfterSearch = (titleOfObject: string, searchField: string): boolean => {
    if (titleOfObject === null || titleOfObject === undefined || titleOfObject.length === 0) { return false; }
    if (searchField.length <= 0) { return true; }
    if (titleOfObject.toLowerCase().startsWith(searchField.toLowerCase())) { return true; }
    let titleParts = titleOfObject.split(' ');
    for (let i = 0; i < titleParts.length; i += 1) {
        if (titleParts[i] && titleParts[i].toLowerCase().startsWith(searchField.toLowerCase())) {
            return true;
        }
    }
    titleParts = titleOfObject.replace(/[-()!:]/g, ' ').split(' ');
    for (let i = 0; i < titleParts.length; i += 1) {
        if (titleParts[i].length > 0 && titleParts[i] && titleParts[i].toLowerCase().startsWith(searchField.toLowerCase())) {
            return true;
        }
    }
    return false;
};

export const getWebsiteURL = (globalState: any) => {
    if (isValidObject(globalState) && isValidObject(globalState.teamObject) && isValidObject(globalState.teamObject.url) && globalState.teamObject.url.length !== 0) {
        return `${globalState.teamObject.url}`;
    }
    return '';
};

export const getTestWebsiteURL = (globalState: any) => {
    if (isValidObject(globalState) && isValidString(globalState.teamId)) {
        return `https://optimx-testyourteamwebsite.web.app/?team=${globalState.teamId}`;
    }
    return '';
};

export const getTestWebsiteURL2 = (globalState: any) => {
    if (isValidObject(globalState) && isValidString(globalState.teamId)) {
        return `https://www.test.optimxsports.com/?team=${globalState.teamId}`;
    }
    return '';
};

export const getStoreWebsiteURL = (globalState: any) => {
    if (isValidObject(globalState) && isValidString(globalState.teamId)) {
        return `https://www.store.optimxsports.com/?team=${globalState.teamId}`;
    }
    return '';
};

export const getHTMLTextFromNormalText = (text: string) => {
    let finalText = '';
    text.replace(/(?:\r\n|\r|\n)/g, ' <br/> ').split(' ').forEach((word) => {
        if (word.includes('http')) {
            finalText += `<a href=${word} target="_blank" rel="noreferrer">${word}</a> `;
        } else if (word.includes('www')) {
            finalText += `<a href=${word} target="_blank" rel="noreferrer">https://${word}</a> `;
        } else if (word.includes('@') && word.includes('.')) {
            finalText += `<a href=mailto:${word}>${word}</a> `;
        } else {
            finalText += `${word} `;
        }
    });
    return finalText;
};

export const lazyWithRetry = (componentImport: any) => {
    return lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                'page-has-been-force-refreshed',
            ) || 'false',
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                'page-has-been-force-refreshed',
                'false',
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(
                    'page-has-been-force-refreshed',
                    'true',
                );
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
};

export const getReferralId = (referralId: string, globalState: any) => {
    if (isValidObject(referralId)) {
        return referralId;
    }

    if (isValidObject(globalState)) {
        return globalState.referralId;
    }

    return '';
};

export const getWebsiteImage = (websiteImages: any[], imageId: string) => {
    let image1 = null;
    websiteImages.forEach((image: any) => {
        if (image.id === imageId) {
            if (isValidString(image.image)) {
                image1 = image.image;
            } else {
                image1 = null;
            }
        }
    });

    if (!isValidObject(image1)) {
        websiteImages.forEach((image: any) => {
            if (image.id === 'featureImage1') {
                if (isValidString(image.image)) {
                    image1 = image.image;
                } else {
                    image1 = null;
                }
            }
        });
    }
    return image1;
};

export const getWebsiteImageIndex = (websiteImages: any[], imageId: string) => {
    let imageIndex = null;
    websiteImages.forEach((image: any, index: number) => {
        if (image.id === imageId) {
            imageIndex = index;
        }
    });
    return imageIndex;
};

export const resizeImage = (file: any, maxWidth: number = 300, maxHeight: number = 300, quality: number = 100, format: string = 'JPEG'): Promise<any> => {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            format,
            quality,
            0,
            (uri) => {
                resolve(uri);
            },
            'blob',
        );
    });
};
