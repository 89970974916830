import { createFormSubmittedReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsFormSubmitted';
import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createPreDevelopedFormReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPreDevelopedForm';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { FormType } from '../enums/FormType';
import { SportsType } from '../enums/SportsType';
import { FormSubmittedObject } from '../interface/FormSubmittedInterface';

export const getDemoFormSubmittedOtherOptions = (sportsType: SportsType) => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return (
            [
                {
                    id: 'firstName',
                    title: 'First Name',
                    value: 'John',
                },
                {
                    id: 'lastName',
                    title: 'Last Name',
                    value: 'Doe',
                },
                {
                    id: 'email',
                    title: 'Email',
                    value: 'johndoe@optimx.com',
                },
                {
                    id: 'phone',
                    title: 'Phone',
                    value: '+1-(123)-456-7890',
                },
                {
                    id: 'city',
                    title: 'City',
                    value: 'Bloomington',
                },
                {
                    id: 'state',
                    title: 'State',
                    value: 'Indiana',
                },
                {
                    id: 'position',
                    title: 'Position',
                    value: 'Forward',
                },
                {
                    id: 'shoots',
                    title: 'Shoots',
                    value: 'Right',
                },
                {
                    id: 'height',
                    title: 'Height',
                    value: '6\'2"',
                },
                {
                    id: 'weight',
                    title: 'Weight',
                    value: '184lbs',
                },
                {
                    id: 'admitted',
                    title: 'Admitted to the University?',
                    value: 'Yes',
                },
                {
                    id: 'attending',
                    title: 'If Yes, when will you be attending?',
                    value: 'Next Year',
                },
                {
                    id: 'additionalInfo',
                    title: 'Additional Information (Stats, Questions, Links to highlight videos, Etc...)',
                    value: '',
                },
            ]
        );
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return (
            [
                {
                    id: 'firstName',
                    title: 'First Name',
                    value: 'John',
                },
                {
                    id: 'lastName',
                    title: 'Last Name',
                    value: 'Doe',
                },
                {
                    id: 'email',
                    title: 'Email',
                    value: 'johndoe@optimx.com',
                },
                {
                    id: 'phone',
                    title: 'Phone',
                    value: '+1-(123)-456-7890',
                },
                {
                    id: 'city',
                    title: 'City',
                    value: 'Bloomington',
                },
                {
                    id: 'state',
                    title: 'State',
                    value: 'Indiana',
                },
                {
                    id: 'position',
                    title: 'Position',
                    value: 'Full Back',
                },
                {
                    id: 'shoots',
                    title: 'Shoots',
                    value: 'Right',
                },
                {
                    id: 'height',
                    title: 'Height',
                    value: '6\'2"',
                },
                {
                    id: 'weight',
                    title: 'Weight',
                    value: '184lbs',
                },
                {
                    id: 'admitted',
                    title: 'Admitted to the University?',
                    value: 'Yes',
                },
                {
                    id: 'attending',
                    title: 'If Yes, when will you be attending?',
                    value: 'Next Year',
                },
                {
                    id: 'additionalInfo',
                    title: 'Additional Information (Stats, Questions, Links to highlight videos, Etc...)',
                    value: '',
                },
            ]
        );
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return (
            [
                {
                    id: 'firstName',
                    title: 'First Name',
                    value: 'John',
                },
                {
                    id: 'lastName',
                    title: 'Last Name',
                    value: 'Doe',
                },
                {
                    id: 'email',
                    title: 'Email',
                    value: 'johndoe@optimx.com',
                },
                {
                    id: 'phone',
                    title: 'Phone',
                    value: '+1-(123)-456-7890',
                },
                {
                    id: 'city',
                    title: 'City',
                    value: 'Bloomington',
                },
                {
                    id: 'state',
                    title: 'State',
                    value: 'Indiana',
                },
                {
                    id: 'position',
                    title: 'Position',
                    value: 'Forward',
                },
                {
                    id: 'shoots',
                    title: 'Shoots',
                    value: 'Right',
                },
                {
                    id: 'height',
                    title: 'Height',
                    value: '6\'2"',
                },
                {
                    id: 'weight',
                    title: 'Weight',
                    value: '184lbs',
                },
                {
                    id: 'admitted',
                    title: 'Admitted to the University?',
                    value: 'Yes',
                },
                {
                    id: 'attending',
                    title: 'If Yes, when will you be attending?',
                    value: 'Next Year',
                },
                {
                    id: 'additionalInfo',
                    title: 'Additional Information (Stats, Questions, Links to highlight videos, Etc...)',
                    value: '',
                },
            ]
        );
    }
    return [];
};

const getPlayerFormId = (sportsType: SportsType) => {
    if (sportsType === SportsType.WOMENSICEHOCKEY) {
        return 'player-recruitment-form-womens-hockey';
    } if (sportsType === SportsType.MENSICEHOCKEY) {
        return 'player-recruitment-form-mens-hockey';
    } if (sportsType === SportsType.WOMENSRUGBY) {
        return 'player-recruitment-form-womens-rugby';
    } if (sportsType === SportsType.MENSRUGBY) {
        return 'player-recruitment-form-mens-rugby';
    } if (sportsType === SportsType.WOMENSLACROSSE) {
        return 'player-recruitment-form-womens-lacrosse';
    } if (sportsType === SportsType.MENSLACROSSE) {
        return 'player-recruitment-form-mens-lacrosse';
    }
    return '';
};

export const createDemoFormSubmitted = (organizationId: string, teamId: string, formId: string, sportsType: SportsType): FormSubmittedObject => {
    const formReference = createFormSubmittedReference(teamId, formId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(teamId);

    return {
        id: formId,
        reference: formReference,
        formId: getPlayerFormId(sportsType),
        formReference: createPreDevelopedFormReference(formId),
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        formType: FormType.PLAYER_RECRUITING_FORM,
        emails: ['johndoe@optimx.com'],
        otherOptions: JSON.stringify(getDemoFormSubmittedOtherOptions(sportsType)),
        read: false,
        replied: false,
        deleted: false,
        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
