import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { createEventReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsEvent';
import { EventType } from '../enums/EventType';
import { createArticleReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsArticle';
import { createGalleryReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsGallery';
import { EventObject } from '../interface/EventInterface';

export const demoEventTitle = 'Tryouts for next year';
export const demoEventVenue = '610 Purdue Mall, West Lafayette, IN 47907';
export const demoEventNotes = '- This event is for the tryouts for next year';

export const demoEventFeatureImage = (sportsType: SportsType) => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2FiceHockey%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Frugby%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoTeam%2Flacrosse%2FdemoEventImage%2FdemoEventImage_1600x900.png?alt=media&token=aefc9aa9-73fd-4713-84d5-82a0444d8c67';
    }
    return '';
};

export const createDemoEvent = (
    organizationId: string,
    teamId: string,
    eventId: string,
    articleId: string,
    galleryId: string,
    sportsType: SportsType,
): EventObject => {
    const eventReference = createEventReference(eventId);
    const articleReference = createArticleReference(articleId);
    const galleryReference = createGalleryReference(galleryId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: eventId,
        urlId: eventId,
        reference: eventReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        seasonId: '',
        seasonReference: null,
        articleId,
        articleReference,
        galleryId,
        galleryReference,
        eventType: EventType.EVENT,
        title: demoEventTitle,
        featureImage: demoEventFeatureImage(sportsType),
        venue: demoEventVenue,
        timeOfEvent: getCurrentDateTime(),
        endTimeOfEvent: getCurrentDateTime(),
        notes: demoEventNotes,
        tagIds: [],
        tagReferences: [],
        tagTypes: [],
        sportsType,
        published: true,
        deleted: false,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
