import { IceHockeyGameCoachObject } from '../../catalog/interface/gameInterface/iceHockey/IceHockeyGameCoachInterface';
import { RugbyGameCoachObject } from '../../catalog/interface/gameInterface/rugby/RugbyGameCoachInterface';
import { db } from '../FirebaseLibrary';

export const createGameCoachesReference = (gameId: string) => {
    return db.collection(`games/${gameId}/game-coaches`);
};

export const createGameCoachesCollectionGroupReference = () => {
    return db.collectionGroup('game-coaches');
};

export const createGameCoachReference = (gameId: string, coachId: string) => {
    return createGameCoachesReference(gameId).doc(coachId);
};

export const fetchGameCoachFirestoreAPI = (gameId: string, coachId: string) => {
    return createGameCoachReference(gameId, coachId).get();
};

export const insertGameCoachFirestoreAPI = (gameCoachObject: IceHockeyGameCoachObject | RugbyGameCoachObject) => {
    return createGameCoachReference(gameCoachObject.gameId, gameCoachObject.id).set(gameCoachObject);
};

export const updateGameCoachFirestoreAPI = (gameCoachObject: any) => {
    return createGameCoachReference(gameCoachObject.gameId, gameCoachObject.id).update(gameCoachObject);
};

export const deleteGameCoachFirestoreAPI = (gameId: string, coachId: string) => {
    return createGameCoachReference(gameId, coachId).delete();
};

export const fetchGameCoachesOfSingleGameFirestoreAPI = (gameId: string) => {
    return createGameCoachesReference(gameId).get();
};

export const fetchGameCoachesFirestoreAPI = (teamId: string, preDevelopedSeasonId: string, preDevelopedLeagueId: string, coachId: string) => {
    return createGameCoachesCollectionGroupReference().where('id', '==', coachId).where('teamId', '==', teamId).where('preDevelopedSeasonId', '==', preDevelopedSeasonId)
        .where('preDevelopedLeagueId', '==', preDevelopedLeagueId)
        .orderBy('timeCreated', 'desc')
        .get();
};
