import React, { useReducer, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, Button, IconButton, TextField, CircularProgress, Slide } from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';
import { CloseRounded } from '@material-ui/icons';
import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import { licensingAgreementAPI } from './LicensingAgeementAPICalls';
import { context } from '../../../../../globalState/GlobalState';
import './css/LicensingAgreement.css';
import Spinner2 from '../../../../uiElements/spinners/spinner2/Spinner2';
import LicensingAgreementDocument from './LicensingAgreementDocument';
import { isValidObject, isValidString } from '../../../../../catalog/Validity';
import { LICENSING_AGREEMENT_SIGNED } from '../../../../../catalog/Comments';
import { getSportsTypeName } from '../../../../../catalog/enums/SportsType';
import { getDateStringWithMonthsFromDate, getLocalTimeFromDatabaseTime } from '../../../../../catalog/DateTime';
import { licensingAgreementStillValid } from '../../../../../catalog/LicensingAgreement';
import PlaceholderComponent from '../../placeholderComponent/PlaceholderComponent';

const initialState = { name: '', title: '', address: '', signatureImageURL: '', loadingSpinner: false };

function reducer(state, action) {
    switch (action.type) {
        case 'setName':
            return { ...state, name: action.name };
        case 'setTitle':
            return { ...state, title: action.title };
        case 'setAddress':
            return { ...state, address: action.address };
        case 'setSignatureImageURL':
            return { ...state, signatureImageURL: action.url };
        case 'setLoadingSpinner':
            return { ...state, loadingSpinner: action.loadingSpinner };
        default:
            return initialState;
    }
}

export const licensingAgreementVersionNumber = 2;

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LicensingAgreement = (props) => {
    const { open, onCancel } = props;
    const [state, setState] = useReducer(reducer, initialState);
    const { globalState, setGlobalState } = useContext(context);
    const sigCanvas = useRef({});
    const { enqueueSnackbar } = useSnackbar();

    const clear = () => { sigCanvas.current.clear(); };

    const save = () => {
        setState({ type: 'setSignatureImageURL', url: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png') });
    };

    const onSubmit = async (file) => {
        setState({ type: 'setLoadingSpinner', loadingSpinner: true });
        await licensingAgreementAPI(
            globalState.organizationId,
            globalState.organizationName,
            globalState.teamId,
            globalState.teamName,
            globalState.sportsType,
            globalState.userEmail,
            globalState.teamObject.url,
            file,
            globalState.teamObject.timeCreated,
            setGlobalState,
            enqueueSnackbar,
        );
        setState({ type: 'setLoadingSpinner', loadingSpinner: false });
        onCancel();
    };

    const getClientName = () => {
        if (isValidObject(globalState) && isValidString(globalState.teamName) && isValidObject(globalState.sportsType)) {
            return `${globalState.teamName}(${globalState.organizationName} - ${getSportsTypeName(globalState.sportsType)})`;
        }
        return '';
    };

    const getAccountCreationTime = () => {
        if (isValidObject(globalState) && isValidObject(globalState.teamObject) && isValidObject(globalState.teamObject.timeCreated)) {
            return getDateStringWithMonthsFromDate(getLocalTimeFromDatabaseTime(globalState.teamObject.timeCreated));
        }
        return '';
    };

    return (
        <div>
            <Dialog
                className="pop-up"
                open={open}
                onClose={onCancel}
                scroll="paper"
                fullScreen
                TransitionComponent={Transition}
            >
                <div className="pop-up-title p-l-20px p-r-20px font-size-2xl text-white">
                    <p>OptimX Sports Licensing Agreement</p>
                    <IconButton onClick={onCancel}>
                        <CloseRounded className="pop-up-title-close" />
                    </IconButton>
                </div>
                {state.loadingSpinner || !globalState.userLogged ? <Spinner2 /> : (
                    <>
                        {licensingAgreementStillValid(globalState) ? <PlaceholderComponent text={LICENSING_AGREEMENT_SIGNED} isWithDrawer={false} /> : (
                            <DialogContent className="pop-up-content">
                                <div className="agreement-container">
                                    <PDFViewer className="agreement-viewer" filename="OptimX Sports Agreement.pdf">
                                        <LicensingAgreementDocument name={state.name} title={state.title} address={state.address} clientName={getClientName()} accountCreationTime={getAccountCreationTime()} sig={state.signatureImageURL} />
                                    </PDFViewer>
                                    <div className="sig-pad-container">
                                        <TextField
                                            className="textfield-full-width textfield-primary-color"
                                            label="Your Name"
                                            type="text"
                                            helperText="Please note that the updates might take a few seconds to reflect"
                                            value={state.name}
                                            required
                                            margin="normal"
                                            shrink={(state.name.length > 0).toString()}
                                            variant="outlined"
                                            onChange={(event) => { setState({ type: 'setName', name: event.target.value }); }}
                                        />
                                        <TextField
                                            className="textfield-full-width textfield-primary-color"
                                            label="Your Position in the Team"
                                            type="text"
                                            helperText="Please note that the updates might take a few seconds to reflect"
                                            value={state.title}
                                            required
                                            placeholder="Team President/Coach"
                                            margin="normal"
                                            shrink={(state.title.length > 0).toString()}
                                            variant="outlined"
                                            onChange={(event) => { setState({ type: 'setTitle', title: event.target.value }); }}
                                        />
                                        <TextField
                                            className="textfield-full-width textfield-primary-color"
                                            label="Team Address"
                                            type="text"
                                            helperText="Please note that the updates might take a few seconds to reflect"
                                            value={state.address}
                                            required
                                            margin="normal"
                                            shrink={(state.address.length > 0).toString()}
                                            variant="outlined"
                                            onChange={(event) => { setState({ type: 'setAddress', address: event.target.value }); }}
                                        />
                                        <p className="m-b-10px">Sign the document here</p>
                                        <SignaturePad
                                            ref={sigCanvas}
                                            canvasProps={{
                                                className: 'sig-pad',
                                            }}
                                        />
                                        <div className="sig-pad-buttons">
                                            <Button className="red-transparent-button m-t-10px" onClick={clear}>Clear</Button>
                                            <Button className="green-transparent-button m-t-10px" onClick={save}>Save Signature</Button>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                        )}
                        <DialogActions className="pop-up-actions">
                            <Button className="red-transparent-button" onClick={onCancel} autoFocus>Dismiss</Button>
                            <BlobProvider document={(<LicensingAgreementDocument name={state.name} title={state.title} address={state.address} clientName={getClientName()} accountCreationTime={getAccountCreationTime()} sig={state.signatureImageURL} />)}>
                                {({ blob, loading }) => {
                                    return (
                                        <Button className="transparent-button" onClick={() => { onSubmit(blob); }} disabled={state.loadingSpinner || !isValidString(state.name) || !isValidString(state.title) || !isValidString(state.address) || !isValidString(state.signatureImageURL)}>
                                            {loading ? (
                                                <CircularProgress
                                                    className=""
                                                    variant="indeterminate"
                                                    size={23}
                                                    thickness={5}
                                                    style={{
                                                        color: '#2bb673',
                                                    }}
                                                />
                                            ) : 'Submit' }
                                        </Button>
                                    );
                                }}
                            </BlobProvider>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
};

LicensingAgreement.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default LicensingAgreement;
