import { IceHockeyGamePlayerObject } from '../../catalog/interface/gameInterface/iceHockey/IceHockeyGamePlayerInterface';
import { RugbyGamePlayerObject } from '../../catalog/interface/gameInterface/rugby/RugbyGamePlayerInterface';
import { db } from '../FirebaseLibrary';

export const createGamePlayersReference = (gameId: string) => {
    return db.collection(`games/${gameId}/game-players`);
};

export const createGamePlayersCollectionGroupReference = () => {
    return db.collectionGroup('game-players');
};

export const createGamePlayerReference = (gameId: string, playerId: string) => {
    return createGamePlayersReference(gameId).doc(playerId);
};

export const fetchGamePlayerFirestoreAPI = (gameId: string, playerId: string) => {
    return createGamePlayerReference(gameId, playerId).get();
};

export const insertGamePlayerFirestoreAPI = (gamePlayerObject: IceHockeyGamePlayerObject | RugbyGamePlayerObject) => {
    return createGamePlayerReference(gamePlayerObject.gameId, gamePlayerObject.id).set(gamePlayerObject);
};

export const updateGamePlayerFirestoreAPI = (gamePlayerObject: any) => {
    return createGamePlayerReference(gamePlayerObject.gameId, gamePlayerObject.id).update(gamePlayerObject);
};

export const deleteGamePlayerFirestoreAPI = (gameId: string, playerId: string) => {
    return createGamePlayerReference(gameId, playerId).delete();
};

export const fetchGamePlayersOfSingleGameFirestoreAPI = (gameId: string) => {
    return createGamePlayersReference(gameId).get();
};

export const fetchGamePlayersFirestoreAPI = (teamId: string, preDevelopedSeasonId: string, preDevelopedLeagueId: string, playerId: string) => {
    return createGamePlayersCollectionGroupReference().where('id', '==', playerId).where('teamId', '==', teamId).where('preDevelopedSeasonId', '==', preDevelopedSeasonId)
        .where('preDevelopedLeagueId', '==', preDevelopedLeagueId)
        .orderBy('timeCreated', 'desc')
        .get();
};
