import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './css/Button2.css';

const Button2 = (props) => {
    const { onClick, title } = props;

    return (
        <button
            className="button-container-2"
            onClick={onClick}
            type="button"
        >
            {title}
        </button>
    );
};

Button2.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
};

Button2.defaultProps = {
    onClick: null,
};

export default memo(Button2);
