import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './css/PlaceholderComponent.css';

const PlaceholderComponent = (props) => {
    const { text, isWithDrawer } = props;

    const getClassName = () => {
        if (isWithDrawer) {
            return 'placeholder-component is-with-drawer p-t-20px p-b-20px p-l-20px p-r-20px font-size-5xl font-weight-100 text-gray';
        }
        return 'placeholder-component p-t-20px p-b-20px p-l-20px p-r-20px font-size-5xl font-weight-100 text-gray';
    };

    return (
        <div className={getClassName()}>
            <p>{text}</p>
        </div>
    );
};

PlaceholderComponent.propTypes = {
    text: PropTypes.string.isRequired,
    isWithDrawer: PropTypes.bool,
};

PlaceholderComponent.defaultProps = {
    isWithDrawer: true,
};

export default memo(PlaceholderComponent);
