import React, { memo } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { StyleSheet, css } from 'aphrodite';

const Button4 = (props) => {
    const { title, disabled, showArrow, darkBackground, transparent, fontSize, fontFamily, fontWeight, height, width, backgroundColor, fontColor, hoverFontColor, borderColor, borderRadius, borderWidth, onClick } = props;

    const styles = StyleSheet.create({
        disabled: {
            height,
            width,
            fontSize,
            fontFamily,
            fontWeight,
            borderRadius,
            borderWidth,
            textTransform: 'capitalize',
        },
        button: {
            backgroundColor,
            height,
            width,
            fontSize,
            fontFamily,
            fontWeight,
            color: fontColor,
            borderRadius,
            borderWidth,
            textTransform: 'capitalize',
            cursor: 'pointer',
        },
        transparentButton: {
            backgroundColor: 'transparent',
            zIndex: 0,
            borderColor,
            height,
            width,
            fontSize,
            fontWeight,
            color: borderColor,
            borderRadius,
            borderWidth,
            transitionDuration: '0.5s',
            textTransform: 'capitalize',
            cursor: 'pointer',
        },
        lighBackground: {
            ':hover': {
                backgroundColor,
                borderColor: backgroundColor,
                color: hoverFontColor,
                transitionDuration: '0.5s',
            },
        },
        darkBackground: {
            ':hover': {
                backgroundColor: 'white',
                color: '#171d28',
                transitionDuration: '0.5s',
            },
        },
    });

    const getClassName = () => {
        if (disabled) {
            return `button ${css(styles.disabled)}`;
        }
        let className = '';
        if (transparent) {
            className += `transparent-button ${css(styles.transparentButton)}`;
        } else {
            className += `button ${css(styles.button)}`;
        }
        if (darkBackground) {
            className += ` ${css(styles.darkBackground)}`;
        } else {
            className += ` ${css(styles.lighBackground)}`;
        }

        return className;
    };

    return (
        <button
            className={getClassName()}
            onClick={onClick}
            type="button"
            disabled={disabled}
        >
            {title}
            {showArrow ? (<KeyboardArrowRightIcon className="m-t-0 m-b-0" />) : null}
        </button>
    );
};

Button4.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    showArrow: PropTypes.bool,
    darkBackground: PropTypes.bool,
    transparent: PropTypes.bool,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
    hoverFontColor: PropTypes.string,
    borderColor: PropTypes.string,
    borderRadius: PropTypes.string,
    borderWidth: PropTypes.string,
    onClick: PropTypes.func,
};

Button4.defaultProps = {
    disabled: false,
    showArrow: false,
    darkBackground: false,
    transparent: false,
    fontSize: '1rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    height: '50px',
    width: '150px',
    backgroundColor: '#171d28',
    fontColor: 'black',
    hoverFontColor: 'black',
    borderColor: '#171d28',
    borderRadius: '5px',
    borderWidth: '1px',
    onClick: () => {},
};

export default memo(Button4);
