import React, { Suspense, useReducer, createRef, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';
import NavBar from '../components/uiElements/navBar/NavBar';
import Footer from '../components/uiElements/footer/Footer';
import { defaultState, reducer, Provider } from '../globalState/GlobalState';
import AuthenticationCheck from '../components/uiElements/authenticationCheck/AuthenticationCheck';
import ExpandedDrawer from '../components/uiElements/drawer/expandedDrawer/ExpandedDrawer';
import ScrollToTop from '../components/uiElements/scrollToTop/ScrollToTop';
import { sendWebsiteAnalytics } from '../catalog/Analytics';
import Spinner3 from '../components/uiElements/spinners/spinner3/Spinner3';
import { blog1NavlinkURL } from '../components/homePage/blogs/blog/blog1/Blog1';
import { lazyWithRetry, optimXDevelopment } from '../catalog/Others';
import NavBar2 from '../components/uiElements/navBar/NavBar2';
import { WebsiteType } from '../catalog/enums/WebsiteType';

const Demo = lazyWithRetry(() => { return import('../components/homePage/demo/Demo'); });
const Referral = lazyWithRetry(() => { return import('../components/homePage/pages/referral/Referral'); });
const Prices = lazyWithRetry(() => { return import('../components/homePage/pages/prices/Prices'); });
const ReferralTerms = lazyWithRetry(() => { return import('../components/homePage/pages/referral/ReferralTerms'); });
const ContactUs = lazyWithRetry(() => { return import('../components/homePage/contactUs/ContactUs'); });
const Schedule = lazyWithRetry(() => { return import('../components/homePage/contactUs/Schedule'); });
const Blogs = lazyWithRetry(() => { return import('../components/homePage/blogs/Blogs'); });
const Blog1 = lazyWithRetry(() => { return import('../components/homePage/blogs/blog/blog1/Blog1'); });
const TeamSignIn = lazyWithRetry(() => { return import('../components/authentication/TeamSignIn'); });
const SignIn = lazyWithRetry(() => { return import('../components/authentication/SignIn'); });
const SignUp = lazyWithRetry(() => { return import('../components/authentication/SignUp'); });
const NewPassword = lazyWithRetry(() => { return import('../components/authentication/NewPassword'); });
const HomePage = lazyWithRetry(() => { return import('../components/homePage/homePage/HomePage'); });
const Version = lazyWithRetry(() => { return import('../components/version/Version'); });
const Players = lazyWithRetry(() => { return import('../components/portal/players/Players'); });
const Opponents = lazyWithRetry(() => { return import('../components/portal/opponents/Opponents'); });
const Leagues = lazyWithRetry(() => { return import('../components/portal/leagues/Leagues'); });
const Sponsors = lazyWithRetry(() => { return import('../components/portal/sponsors/Sponsors'); });
const Coaches = lazyWithRetry(() => { return import('../components/portal/coaches/Coaches'); });
const Staffs = lazyWithRetry(() => { return import('../components/portal/staffs/Staffs'); });
const Seasons = lazyWithRetry(() => { return import('../components/portal/seasons/Seasons'); });
const Events = lazyWithRetry(() => { return import('../components/portal/events/Events'); });
const Articles = lazyWithRetry(() => { return import('../components/portal/articles/Articles'); });
const Galleries = lazyWithRetry(() => { return import('../components/portal/galleries/Galleries'); });
const Settings = lazyWithRetry(() => { return import('../components/portal/settings/Settings'); });
const Website = lazyWithRetry(() => { return import('../components/portal/website/Website'); });
const Forms2 = lazyWithRetry(() => { return import('../components/portal/forms/Forms'); });
const Tutorials = lazyWithRetry(() => { return import('../components/portal/help/Help'); });
const WebsiteAnalytics = lazyWithRetry(() => { return import('../components/portal/analytics/websiteAnalytics/WebsiteAnalytics'); });
const StoreAnalytics = lazyWithRetry(() => { return import('../components/portal/analytics/storeAnalytics/StoreAnalytics'); });
const CustomContents = lazyWithRetry(() => { return import('../components/portal/custom/CustomContents'); });
const Store = lazyWithRetry(() => { return import('../components/portal/store/Store'); });
const ScanTicket1 = lazyWithRetry(() => { return import('../components/portal/scanTicket/ScanTicket1'); });
const ScanTicket2 = lazyWithRetry(() => { return import('../components/portal/scanTicket/ScanTicket2'); });
const Dashboard = lazyWithRetry(() => { return import('../components/portal/dashboard/Dashboard'); });
const Marketplace = lazyWithRetry(() => { return import('../components/portal/marketplace/Marketplace'); });
const DashboardJogPartnerPopUp = lazyWithRetry(() => { return import('../components/portal/dashboard/dashboardPartners/DashboardJogPartnerPopUp'); });
const DashboardHGPSPartnerPopUp = lazyWithRetry(() => { return import('../components/portal/dashboard/dashboardPartners/DashboardHGPSPartnerPopUp'); });

function App() {
    const [globalState, setGlobalState] = useReducer(reducer, defaultState);

    useEffect(() => {
        sendWebsiteAnalytics();
    }, []);

    const notistackRef = createRef();
    const onClickDismiss = (key) => {
        return () => {
            notistackRef.current.closeSnackbar(key);
        };
    };

    return (
        <BrowserRouter>
            <SnackbarProvider
                dense
                hideIconVariant
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                maxSnack={10}
                ref={notistackRef}
                action={(key) => {
                    return (
                        <Button style={{ color: 'white', fontWeight: 800 }} onClick={onClickDismiss(key)}>
                            X
                        </Button>
                    );
                }}
            >
                <Provider value={{ globalState, setGlobalState }}>
                    <AuthenticationCheck>
                        <NavBar />
                        <NavBar2 />
                        <main>
                            <ExpandedDrawer>
                                <Suspense fallback={<Spinner3 marginTop={100} />}>
                                    <ScrollToTop>
                                        <Switch>
                                            <Route path="/portal/website" render={() => { return <Website />; }} />
                                            <Route path="/portal/roster" render={() => { return <Players />; }} />
                                            <Redirect from="/portal/alumni" to="/portal/roster" />
                                            <Route path="/portal/opponents" render={() => { return <Opponents />; }} />
                                            <Route path="/portal/events" render={() => { return <Events />; }} />
                                            <Route path="/portal/articles" render={() => { return <Articles />; }} />
                                            <Route path="/portal/leagues" render={() => { return <Leagues />; }} />
                                            <Route path="/portal/sponsors" render={() => { return <Sponsors />; }} />
                                            <Route path="/portal/marketplace" render={() => { return <Marketplace />; }} />
                                            <Route path="/portal/dashboard/partner/jog" render={() => { return <DashboardJogPartnerPopUp />; }} />
                                            <Route path="/portal/dashboard/partner/hgps" render={() => { return <DashboardHGPSPartnerPopUp />; }} />
                                            <Route path="/portal/dashboard" render={() => { return <Dashboard />; }} />
                                            <Redirect from="/portal/dashboard/partner" to="/portal/dashboard/partner/jog" />
                                            <Route path="/portal/analytics/website" render={() => { return <WebsiteAnalytics />; }} />
                                            <Route path="/portal/analytics/store" render={() => { return <StoreAnalytics />; }} />
                                            <Redirect from="/portal/analytics" to={globalState.websiteType === WebsiteType.STORE ? '/portal/analytics/store' : '/portal/analytics/website'} />
                                            <Route path="/portal/coaches" render={() => { return <Coaches />; }} />
                                            <Route path="/portal/executiveboard" render={() => { return <Staffs />; }} />
                                            <Route path="/portal/seasons" render={() => { return <Seasons />; }} />
                                            <Route path="/portal/galleries" render={() => { return <Galleries />; }} />
                                            <Route path="/portal/custom" render={() => { return <CustomContents />; }} />
                                            <Route path="/portal/settings" render={() => { return <Settings />; }} />
                                            <Route path="/portal/tutorials" render={() => { return <Tutorials />; }} />
                                            <Route path="/portal/forms" render={() => { return <Forms2 />; }} />
                                            <Route path="/portal/store" render={() => { return <Store />; }} />
                                            <Route path="/portal/scanticket" exact render={() => { return <ScanTicket1 />; }} />
                                            <Route path="/portal/readticket" exact render={() => { return <ScanTicket2 />; }} />
                                            <Redirect from="/portal" to="/portal/dashboard" />
                                            <Route path="/schedule" exact render={() => { return <Schedule />; }} />
                                            <Route path="/contactus" exact render={() => { return <ContactUs />; }} />
                                            <Redirect from="/contact" to="/contactus" />
                                            <Route path="/newpassword" exact render={() => { return <NewPassword />; }} />
                                            <Route path="/signin" exact render={() => { return <SignIn />; }} />
                                            <Redirect from="/login" to="/signin" />
                                            {optimXDevelopment ? <Route path="/teamsignin" exact render={() => { return <TeamSignIn />; }} /> : null}
                                            <Route path="/signup" exact render={() => { return <SignUp />; }} />
                                            <Redirect from="/signup/:referralid" to="/signup?referral=:referralid" />
                                            <Route path="/demo/:demoid" exact render={() => { return <Demo />; }} />
                                            <Redirect from="/demo" to="/demo/hockey" />
                                            <Route path="/version" exact render={() => { return <Version />; }} />
                                            <Route path="/blogs" exact render={() => { return <Blogs />; }} />
                                            <Route path={blog1NavlinkURL} exact render={() => { return <Blog1 />; }} />
                                            <Route path="/pricing" exact render={() => { return <Prices />; }} />
                                            <Redirect from="/prices" to="/pricing" />
                                            <Redirect from="/price" to="/pricing" />
                                            <Redirect from="/subscription" to="/pricing" />
                                            <Redirect from="/subscriptions" to="/pricing" />
                                            <Route path="/referral" exact render={() => { return <Referral />; }} />
                                            <Route path="/referralterms" exact render={() => { return <ReferralTerms />; }} />
                                            <Redirect from="/:referralid" to="?referral=:referralid" />
                                            <Route path="/" exact render={() => { return <HomePage />; }} />
                                            <Route render={() => { return <Redirect to={{ pathname: '/' }} />; }} />
                                        </Switch>
                                    </ScrollToTop>
                                </Suspense>
                            </ExpandedDrawer>
                        </main>
                        <Footer />
                    </AuthenticationCheck>
                </Provider>
            </SnackbarProvider>
        </BrowserRouter>
    );
}

export default App;
