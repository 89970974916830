import React, { memo, useEffect, useContext } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import './css/NavBar.css';
import { ButtonType } from '../buttons/ButtonType.ts';
import NavItems from './NavItems';
import DropDownNavItems from './DropDownNavItems';
import OptimxGreenIcon from '../../../assets/icons/optimxGreenIcon.png';
import OptimxNameIcon from '../../../assets/icons/optimxNameIcon.png';
import { context } from '../../../globalState/GlobalState';
import { referralPrice } from '../../../catalog/Others';

const NavBar = () => {
    const history = useHistory();
    const params = useParams();
    const { globalState } = useContext(context);

    const NON_PORTAL_URL_SEGMENTS = ['', 'signin', 'signup', 'newpassword', 'contactus', 'blogs', 'demo', 'referral'];
    const PORTAL_URL_SEGMENTS = ['portal'];
    const SIGNIN_URL_SEGMENTS = ['signin'];
    const SIGNUP_URL_SEGMENTS = ['signup'];
    const CONTACTUS_URL_SEGMENTS = ['contactus'];
    const BLOGS_URL_SEGMENTS = ['blogs'];
    const DEMO_URL_SEGMENTS = ['demo'];
    const REFERRAL_URL_SEGMENTS = ['referral'];
    const PRICING_URL_SEGMENTS = ['pricing'];

    useEffect(() => {
    }, [history, params]);

    const firstSegment = history.location.pathname.split('/')[1];

    const navItemsVisibility = () => {
        if (globalState.userLogged) {
            if (NON_PORTAL_URL_SEGMENTS.includes(firstSegment)) {
                return [
                    { buttonType: ButtonType.Button2, title: 'Dashboard', link: '/portal/dashboard', exact: true },
                    { buttonType: ButtonType.Button2, title: 'Analytics', link: '/portal/analytics', exact: true },
                    { buttonType: ButtonType.Button2, title: 'Website', link: '/portal/website', exact: true },
                    { buttonType: ButtonType.Button4, title: 'Contact Us', link: '/contactus', exact: true },
                ];
            } if (PORTAL_URL_SEGMENTS.includes(firstSegment)) {
                return [
                    { buttonType: ButtonType.Button2, title: 'Setup your Online Store', link: '/portal/store', exact: true },
                    { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
                    { buttonType: ButtonType.Button4, title: 'Contact Us', link: '/contactus', exact: true },
                ];
            }
        }

        if (SIGNIN_URL_SEGMENTS.includes(firstSegment)) {
            return [
                { buttonType: ButtonType.Button2, title: 'Demo', link: '/demo', exact: true },
                { buttonType: ButtonType.Button2, title: 'Pricing', link: '/pricing', exact: true },
                { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
                { buttonType: ButtonType.Button2, title: 'Contact Us', link: '/contactus', exact: true },
                { buttonType: ButtonType.Button2, title: 'Log In', link: '/signin', exact: true },
                { buttonType: ButtonType.Button4, title: 'Sign Up Free', link: '/signup', exact: true },
            ];
        } if (SIGNUP_URL_SEGMENTS.includes(firstSegment)) {
            return [
                { buttonType: ButtonType.Button2, title: 'Demo', link: '/demo', exact: true },
                { buttonType: ButtonType.Button2, title: 'Pricing', link: '/pricing', exact: true },
                { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
                { buttonType: ButtonType.Button2, title: 'Contact Us', link: '/contactus', exact: true },
                { buttonType: ButtonType.Button2, title: 'Log In', link: '/signin', exact: true },
                { buttonType: ButtonType.Button4, title: 'Sign Up Free', link: '/signup', exact: true },
            ];
        } if (CONTACTUS_URL_SEGMENTS.includes(firstSegment)) {
            return [
                { buttonType: ButtonType.Button2, title: 'Demo', link: '/demo', exact: true },
                { buttonType: ButtonType.Button2, title: 'Pricing', link: '/pricing', exact: true },
                { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
                { buttonType: ButtonType.Button2, title: 'Contact Us', link: '/contactus', exact: true },
                { buttonType: ButtonType.Button2, title: 'Log In', link: '/signin', exact: true },
                { buttonType: ButtonType.Button4, title: 'Sign Up Free', link: '/signup', exact: true },
            ];
        } if (BLOGS_URL_SEGMENTS.includes(firstSegment)) {
            return [
                { buttonType: ButtonType.Button2, title: 'Demo', link: '/demo', exact: true },
                { buttonType: ButtonType.Button2, title: 'Pricing', link: '/pricing', exact: true },
                { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
                { buttonType: ButtonType.Button2, title: 'Contact Us', link: '/contactus', exact: true },
                { buttonType: ButtonType.Button2, title: 'Log In', link: '/signin', exact: true },
                { buttonType: ButtonType.Button4, title: 'Sign Up Free', link: '/signup', exact: true },
            ];
        } if (DEMO_URL_SEGMENTS.includes(firstSegment)) {
            return [
                { buttonType: ButtonType.Button2, title: 'Demo', link: '/demo', exact: true },
                { buttonType: ButtonType.Button2, title: 'Pricing', link: '/pricing', exact: true },
                { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
                { buttonType: ButtonType.Button2, title: 'Contact Us', link: '/contactus', exact: true },
                { buttonType: ButtonType.Button2, title: 'Log In', link: '/signin', exact: true },
                { buttonType: ButtonType.Button4, title: 'Sign Up Free', link: '/signup', exact: true },
            ];
        } if (REFERRAL_URL_SEGMENTS.includes(firstSegment)) {
            return [
                { buttonType: ButtonType.Button2, title: 'Demo', link: '/demo', exact: true },
                { buttonType: ButtonType.Button2, title: 'Pricing', link: '/pricing', exact: true },
                { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
                { buttonType: ButtonType.Button2, title: 'Contact Us', link: '/contactus', exact: true },
                { buttonType: ButtonType.Button2, title: 'Log In', link: '/signin', exact: true },
                { buttonType: ButtonType.Button4, title: 'Sign Up Free', link: '/signup', exact: true },
            ];
        } if (PRICING_URL_SEGMENTS.includes(firstSegment)) {
            return [
                { buttonType: ButtonType.Button2, title: 'Demo', link: '/demo', exact: true },
                { buttonType: ButtonType.Button2, title: 'Pricing', link: '/pricing', exact: true },
                { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
                { buttonType: ButtonType.Button2, title: 'Contact Us', link: '/contactus', exact: true },
                { buttonType: ButtonType.Button2, title: 'Log In', link: '/signin', exact: true },
                { buttonType: ButtonType.Button4, title: 'Sign Up Free', link: '/signup', exact: true },
            ];
        }
        return [
            { buttonType: ButtonType.Button2, title: 'Demo', link: '/demo', exact: true },
            { buttonType: ButtonType.Button2, title: 'Pricing', link: '/pricing', exact: true },
            { buttonType: ButtonType.Button2, title: `Invite teams, get $${referralPrice}`, link: '/referral', exact: true },
            { buttonType: ButtonType.Button2, title: 'Contact Us', link: '/contactus', exact: true },
            { buttonType: ButtonType.Button2, title: 'Log In', link: '/signin', exact: true },
            { buttonType: ButtonType.Button4, title: 'Sign Up Free', link: '/signup', exact: true },
        ];
    };

    return (
        <header className="navbar-container">
            <NavLink className="icon-container" to="/" exact>
                <img src={OptimxGreenIcon} className="icon m-r-1" alt="OptimX Sports" />
                <img src={OptimxNameIcon} className="name-icon m-l-1" alt="OptimX Sports" />
            </NavLink>
            <nav className="nav-items-container y-center">
                <NavItems navItems={navItemsVisibility()} />
                <DropDownNavItems navItems={navItemsVisibility()} />
            </nav>
        </header>
    );
};

export default memo(NavBar);
