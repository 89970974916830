import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './css/Button3.css';

const Button3 = (props) => {
    const { onClick, title } = props;

    return (
        <button
            className="button-container-3 x-center"
            onClick={onClick}
            type="button"
        >
            {title}
        </button>
    );
};

Button3.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
};

Button3.defaultProps = {
    onClick: null,
};

export default memo(Button3);
