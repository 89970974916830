import { db } from '../FirebaseLibrary';
import { MailerSendMailObject } from '../../catalog/interface/mailInterface/MailerSendMailInterface';

export const createMailsReference = () => {
    return db.collection('mailersend-mails');
};

export const createMailReference = (mailId: string) => {
    return createMailsReference().doc(mailId);
};

export const insertMailFirestoreAPI = (mailObject: MailerSendMailObject) => {
    return createMailReference(mailObject.id).set(mailObject);
};
