import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import './css/LicensingAgreement.css';
import { isValidObject, isValidString } from '../../../../../catalog/Validity';
import { getCurrentDateTime, getDateStringWithMonthsFromDate } from '../../../../../catalog/DateTime';

const LicensingAgreementDocument = (props) => {
    const { name, title, address, clientName, accountCreationTime, sig } = props;

    const styles = StyleSheet.create({
        page: {
            padding: 40,
        },
        h1: {
            fontSize: 20,
            fontWeight: 'heavy',
            textAlign: 'center',
            marginTop: 20,
        },
        h2: {
            fontSize: 15,
            fontWeight: 'heavy',
            marginTop: 20,
        },
        h3: {
            fontSize: 15,
            fontWeight: 'heavy',
            marginTop: 20,
        },
        p: {
            fontSize: 12,
            marginTop: 20,
        },
        l: {
            marginLeft: 20,
            fontSize: 12,
            marginTop: 20,
        },
        signingSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-around',
            fontSize: 12,
        },
        signingSubSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: 20,
        },
        signingSubSectionHeading: {
            textDecoration: 'underline',
            marginBottom: 20,
        },
        signingSubSubSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginBottom: 10,
        },
        clientSignature: {
            width: 200,
            height: 100,
        },
        optimXSignature: {
            textDecoration: 'underline',
            fontStyle: 'italic',
            fontWeight: 100,
            fontFamily: 'Helvetica',
            letterSpacing: 2,
        },
    });

    const getClientName = () => {
        if (isValidString(clientName)) {
            return clientName;
        }
        return '';
    };

    const getAccountCreationTime = () => {
        if (isValidObject(accountCreationTime)) {
            return accountCreationTime;
        }
        return '';
    };

    return (
        <Document
            title={`${getClientName()} Licensing Agreement`}
            author="OptimX Sports"
            subject="Licensing Agreement"
            creator="OptimX Sports"
            producer="OptimX Sports"
        >
            <Page size="A4" style={styles.page}>
                <View>
                    <Text style={styles.h1}>OptimX Technologies LLC Licensing Agreement</Text>
                    <Text style={styles.p}>
                        This Licensing Agreement is made on
                        {' '}
                        {getAccountCreationTime()}
                        {' '}
                        (the &ldquo;Effective Date&rdquo;), between OptimX Technologies LLC (incl. DBA OptimX Sports) and
                        {' '}
                        {getClientName()}
                        {isValidString(address) ? ` at its principal place of operation at ${address}` : ''}
                        .
                    </Text>
                    <Text style={styles.h2}>1. Term:</Text>
                    <Text style={styles.p}>This agreement begins on the Effective Date and will continue until terminated.</Text>
                    <Text style={styles.h2}>2. Grant of License to Access and Use Service:</Text>
                    <Text style={styles.p}>
                        OptimX Technologies LLC hereby grants to
                        {' '}
                        {getClientName()}
                        , a non-exclusive, non-sublicensable, non-assignable, royalty-free, and worldwide license to access and use the Site (the &ldquo;Site&rdquo;) owned by OptimX Technologies LLC (incl. DBA OptimX Sports) solely for
                        {' '}
                        {getClientName()}
                        {' '}
                        internal business operations in accordance with the terms of this agreement and OptimX Technologies&apos;s online terms of service.
                    </Text>
                    <Text style={styles.h2}>3. Definitions:</Text>
                    <Text style={styles.p}>3.1 &ldquo;Aggregated Data&rdquo; means any non-personally identifiable, technical, statistical or analytical data gathered or generated directly by use of the Site. OptimX Technologies may use this information to monitor and improve its products, services, or to provide customized services or technologies to their customers. OptimX Technologies may collect and use this information in accordance with its privacy policies and in accordance with applicable data protection laws. </Text>
                    <Text style={styles.p}>3.2 &ldquo;Authorized End User&rdquo; means any individual authorized, by virtue of such individual&apos;s relationship to, or permissions from, Customer, to access the Services pursuant to Customer&apos;s rights under this Agreement. </Text>
                    <Text style={styles.p}>3.3 &ldquo;Customer&rdquo; means either you as an individual, or a corporate entity or other business organization to whom OptimX Technologies provides the Subscription Services for use by itself or by Authorized End Users.</Text>
                    <Text style={styles.p}>3.4 &ldquo;Customer Data&rdquo; means all the data and/or information provided by Authorized End Users and/or Customers during the use of the Site, including all text, graphics, pictures, photos, profiles, reviews, messages, notes, and/or any other uploaded content, published or displayed on or through the Site, or transmitted to or shared with other users. </Text>
                    <Text style={styles.p}>3.5 &ldquo;Professional Services&rdquo; means additional and separate service(s) not included in this Agreement, and that are geared towards special requirements of clients, such as configuration, adaptation, development of customized features or functionalities to the Subscription Services, and the like. Professional Services are provided by OptimX Technologies on a time and materials basis under the terms of a separate agreement.</Text>
                    <Text style={styles.p}>3.6 &ldquo;Subscription Services&rdquo; means the hosted services provided by OptimX Technologies to Authorized End Users and/or Customers by means of accessing and using the features and functions of the OptimX Technologies software, as contemplated in this Agreement.</Text>
                    <Text style={styles.p}>3.7 &ldquo;User License&rdquo; shall mean a license with a unique user identification and password to grant access to a single named user to the Subscription Services, solely for the term such services are contracted. </Text>
                    <Text style={styles.h2}>4. Subscription Services:</Text>
                    <Text style={styles.h3}>4.1. User Licenses:</Text>
                    <Text style={styles.p}>User Licenses are for designated users and cannot be shared or used by more than one Authorized End User, but may be re-designated to new users, as necessary, provided such re-designation is not used to circumvent the prohibition on sharing User Licenses. Unless otherwise specified in the relevant subscription order.</Text>
                    <Text style={styles.h2}>5. OptimX Technologies Responsibilities and Rights:</Text>
                    <Text style={styles.h3}>5.1. Services:</Text>
                    <Text style={styles.p}>OptimX Technologies will provide the Subscription Services in a professional manner consistent with general and reasonable industry standards. </Text>
                    <Text style={styles.h3}>5.2. Initial Support:</Text>
                    <Text style={styles.p}>For the 12-month period beginning on the Effective Date, OptimX Technologies will provide customer with the following support:</Text>
                    <Text style={styles.l}>1. Electronic support during customer&apos;s normal business hours in order to help Customer correct problems with the Software, and</Text>
                    <Text style={styles.l}>2. Platform-based support system generally available during normal business hours. </Text>
                    <Text style={styles.h3}>5.3. Renewed Subcription:</Text>
                    <Text style={styles.p}>After the initial subscription period, and against after any subsequent subscription period, the Customer&apos;s subscription will automatically commence on the first day following the end of such period and continue for an additional equivalent period, at OptimX Technologies&apos; then-current price for such subscription, unless Customer cancels the subscription at least fifteen (15) days prior to the renewal commencement date.  Except as set forth in the Terms, all fees for the Subscription Service are non-refundable. </Text>
                    <Text style={styles.p}>Payment for the Subscription Services is due in advance of each applicable subscription period (e.g., monthly or annually). EXCEPT WHERE PROHIBITED BY LOCAL LAW, MONTHLY AND ANNUAL SUBSCRIPTIONS MAY AUTOMATICALLY RENEW AND THE CUSTOMER&apos;S CREDIT CARD OR OTHER PAYMENT METHOD OPTIMX TECHNOLOGIES MAY HAVE BILLED FOR THE CUSTOMER WILL BE CHARGED ON THE FIRST DAY OF EACH RENEWAL PERIOD UNTIL THE CUSTOMER&apos;S SUBSCRIPTION IS TERMINATED BY OPTIMX TECHNOLOGIES OR CANCELED BY THE CUSTOMER. IF ANY OF OPTIMX TECHNOLOGIES&apos; CHARGES ARE REJECTED FROM THE PAYMENT METHOD THE CUSTOMER HAS INDICATED IS THE PREFERABLE PAYMENT METHOD, CUSTOMER AUTHORIZES OPTIMX TECHNOLOGIES (WITHOUT NOTICE TO THE CUSTOMER, UNLESS REQUIRED BY APPLICABLE LAW) TO, IN OPTIMX TECHNOLOGIES&apos; SOLE DISCRETION: (I) RETRY SUCH PAYMENT METHOD; AND/OR (II) COLLECT FROM ANY OTHER PAYMENT METHOD THE CUSTOMER PROVIDED TO OUR BILLING SERVICE. Customer agrees to pay all applicable fees for subscription services and all other use of the Subscription Services, plus any applicable taxes or other charges as may be required, when due and payable. All fees and charges are payable in advance and non-refundable, including after cancellation, unless otherwise disclosed at the time of purchase. To cancel the Subscription Service, contact the OptimX Technologies team.</Text>
                    <Text style={styles.h3}>5.4. Hosting:</Text>
                    <Text style={styles.p}>Customer agrees and understands that OptimX Technologies has entered into arrangements with one or more third parties for the hosting of OptimX Technologies&apos;s applications and platform, whereby any such third party will perform the hosting obligations, provided that OptimX Technologies shall ensure that any such third party shall be contractually bound to provide substantially the same level of protection with respect to Customer Data as is provided by the terms of this Agreement (our &ldquo;Third-Party Hosting Provider&rdquo;). Customer acknowledges and agrees that the Third-Party Hosting Provider&apos;s service levels, use policies and terms of service will apply to Customer and that Customer is bound by such terms of service and use policies (the &ldquo;Third-Party Hosting Terms of Service&rdquo;). </Text>
                    <Text style={styles.h3}>5.5. Aggregated Data:</Text>
                    <Text style={styles.p}>Customer acknowledges and agrees that OptimX Technologies may compile anonymous Aggregated Data. To the extent necessary, Customer hereby grants OptimX Technologies a royalty-free, nonexclusive, irrevocable, right and license (with the right to sublicense) to develop anonymous Aggregated Data from the use of the Subscription Services. </Text>
                    <Text style={styles.h3}>5.6. System Notifications:</Text>
                    <Text style={styles.p}>Customer shall receive reasonable system notifications pertaining to the Subscription Services. </Text>
                    <Text style={styles.h3}>5.7. Professional Services:</Text>
                    <Text style={styles.p}>Customer may purchase additional Professional Services pursuant to a separate Professional Services Agreement (with its related Statements of Work) that has been mutually negotiated, agreed and separately executed by the Parties. Customer agrees and understands that absent such separate Professional Services Agreement, OptimX Technologies has no obligation to perform any such services.</Text>
                    <Text style={styles.h3}>5.8. Data Protection:</Text>
                    <Text style={styles.p}>OptimX Technologies shall implement appropriate safeguards to prevent unauthorized access to, use of, or disclosure of the Protected Information.</Text>
                    <Text style={styles.h3}>5.9. Data Privacy:</Text>
                    <Text style={styles.p}>OptimX Technologies may collect, use and process customer data only in accordance with OptimX Technologies&apos;s online privacy policy.</Text>
                    <Text style={styles.h2}>6. Customer Responsibilities and Restrictions:</Text>
                    <Text style={styles.h3}>6.1. Passwords and Unauthorized Access:</Text>
                    <Text style={styles.p}>Customer is responsible for maintaining the confidentiality of the passwords assigned to Customer. Customer will immediately notify OptimX Technologies if it becomes aware that a password is lost, stolen, disclosed to an unauthorized third party, or otherwise compromised. Customer will be responsible for any and all activities made under Customer&apos;s User Licenses.  Customer shall (i) use commercially reasonable efforts to prevent unauthorized access to, or use of, the Subscription Services, and notify OptimX Technologies promptly of any such unauthorized access or use, and (ii) comply with all applicable laws in using the Services. </Text>
                    <Text style={styles.h3}>6.2. Restrictions:</Text>
                    <Text style={styles.p}>Customer and its Authorized End Users shall not (i) modify, copy or create derivative works based on the Subscription Services; (ii) create Internet &ldquo;links&rdquo; to or reproduce any content forming part of the Subscription Services, other than for its own internal business purposes; (iii) disassemble, reverse engineer, or decompile the Subscription Services or part thereof, or access it in order to copy any ideas, features, content, functions or graphics of the Subscription Services; (iv) interfere with or disrupt the integrity or performance of the Subscription Services; (vi) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortuous material via the Subscription Services; (vii) send or store viruses or malicious code via the Subscription Services; (viii) attempt to gain unauthorized access to the Subscription Services or its related applications, software, systems, platforms or networks; or (ix) use any components provided with the Services separately from the Subscription Services. </Text>
                    <Text style={styles.h3}>6.3. Customer Data:</Text>
                    <Text style={styles.p}>Customer agrees that itself, or its Authorized End Users, are solely responsible for Customer Data, and it shall require Authorized End Users not to post, transmit, or share Customer Data on the Subscription Services that they do not have permission to post. Customer represents and warrants (i) that it has the right to disclose and provide to OptimX Technologies any data provided through use and access of the Subscription Services, and that no materials of any kind submitted as Customer Data or otherwise posted, transmitted, or shared on or through the Subscription Services will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libelous, defamatory or otherwise unlawful material; and (ii) that Customer&apos;s (and that of its Authorized End Users&apos;) use of and access to the Subscription Services complies with all applicable laws, rules and regulations.</Text>
                    <Text style={styles.h2}>7. Confidentiality:</Text>
                    <Text style={styles.h3}>7.1. Definition of Confidential Information:</Text>
                    <Text style={styles.p}>&ldquo;Confidential Information&rdquo; means all information disclosed by a party (&ldquo;Disclosing Party&rdquo;) to the other party (&ldquo;Receiving Party&rdquo;), whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. Customer&apos;s Confidential Information includes Customer Data. OptimX Technologies Confidential Information includes the Subscription Services, Our Technology and Aggregated Data. The Confidential Information of each party includes the terms and conditions of this Agreement, as well as business and marketing plans, technology and technical information, product plans and designs, and business processes disclosed by such party. However, Confidential Information does not include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party.</Text>
                    <Text style={styles.h3}>7.2. Protection of Confidential Information:</Text>
                    <Text style={styles.p}>The Receiving Party will use the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind (but not less than reasonable care) (i) not to use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement, and (ii) except as otherwise authorized by the Disclosing Party in writing, to limit access to Confidential Information of the Disclosing Party to those of its and its Affiliates&apos; employees and contractors who need that access for purposes consistent with this Agreement and who have signed confidentiality agreements with the Receiving Party containing protections no less stringent than those herein. Neither party will disclose the terms of this Agreement, legal counsel and accountants without the other party&apos;s prior written consent, provided that a party that makes any such disclosure to its Affiliate, legal counsel or accountants will remain responsible for such Affiliate&apos;s, legal counsel&apos;s or accountant&apos;s compliance with this Section 7.2. </Text>
                    <Text style={styles.h3}>7.3. Compelled Disclosure:</Text>
                    <Text style={styles.p}>The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent compelled by law to do so, provided the Receiving Party gives the Disclosing Party prior notice of the compelled disclosure (to the extent legally permitted) and reasonable assistance, at the Disclosing Party&apos;s cost, if the Disclosing Party wishes to contest the disclosure. If the Receiving Party is compelled by law to disclose the Disclosing Party&apos;s Confidential Information as part of a civil proceeding to which the Disclosing Party is a party, and the Disclosing Party is not contesting the disclosure, the Disclosing Party will reimburse the Receiving Party for its reasonable cost of compiling and providing secure access to that Confidential Information.</Text>
                    <Text style={styles.h2}>8. PROPRIETARY RIGHTS:</Text>
                    <Text style={styles.p}>As between OptimX Technologies and Customer, OptimX Technologies, retains all right, title and interest to the Subscription Services, the Aggregated Data, and all related software, applications, programming, documentation, templates, questionnaires, methodologies, models, charts, specifications, reports, and any other intellectual property or items used to deliver the Subscription Services or made available to Customer as a result of the Site (&ldquo;Our Software and Technology&rdquo;). The Subscription Services and Our related Technology are protected by applicable intellectual property laws and rights, including rights deriving from copyright, trade secret, patents, trademarks and related industrial property. Customer&apos;s access and use of the Subscription Services, Our Technology and any related materials shall be governed by the terms of this Agreement. There are no licenses granted by implication in this Agreement and OptimX Technologies reserves and retains any rights not expressly granted to Customer. As between OptimX Technologies and Customer, OptimX Technologies owns all rights, title and interest in and to Customer Data. In the event Customer (or its Authorized End Users) provides OptimX Technologies with any suggestions, enhancement requests, recommendations or other feedback relating to the Subscription Services or Technology (&ldquo;Feedback&rdquo;), Customer hereby grants OptimX Technologies a royalty free, worldwide, transferable, sublicensable, irrevocable, perpetual license to use or incorporate such Feedback into any OptimX Technologies products or services, provided it does not include any of Customer&apos;s Confidential Information.</Text>
                    <Text style={styles.h2}>9. Fees:</Text>
                    <Text style={styles.p}>Customer of the Subscription Services agree to pay all fees or charges to their subscription account in accordance with the fees, charges and billing terms in effect at the time a fee or charge is due and payable (e.g. annual or monthly payment). Customer using the Subscription Services must provide OptimX Technologies with a valid payment method which may be credit card or banking details (Visa, MasterCard, etc.) (&ldquo;Payment Provider&rdquo;), ACH or purchase order information as a condition to signing up for the Subscription Services. Customer&apos;s Payment Provider agreement governs the use of the designated credit card or bank account, and Customer must refer to that agreement and not the Terms to determine rights and liabilities. </Text>
                    <Text style={styles.p}>By providing OptimX Technologies with an acceptable form of payment method (ex. credit card, banking information) and associated payment information, Customer agrees that OptimX Technologies is authorized to immediately invoice Customer&apos;s Subscription Service Account for all fees and charges due and payable to OptimX Technologies hereunder and that no additional notice or consent is required. OptimX Technologies does not store credit card information or banking details. Payments of Customer are processed by third party services via Stripe.</Text>
                    <Text style={styles.p}>Customer using the Subscription Service agree to immediately notify OptimX Technologies if any change in their billing address or the credit card used for payment hereunder. OptimX Technologies reserves the right at any time to change its prices and billing methods, either immediately upon posting or by e-mail delivery to Customer. Customer agrees to reimburse OptimX Technologies for any collections fees and expenses reasonably incurred by OptimX Technologies in collecting any unpaid amounts.</Text>
                    <Text style={styles.h3}>9.1. Promotions:</Text>
                    <Text style={styles.p}>OptimX Technologies may from time to time offer free trials for some of the Subscription Services. Such trials may require the Customer to register with a valid credit card to commence the Subscription Service. Customers that sign up for a free trial membership at a particular point in time will be automatically renewed at the promoted subscription rate for that level at the end of the trial period unless the Customer cancels the subscription at least before the end of the trial, unless the terms of the offer explicitly state otherwise. Customers can cancel a free trial online by contacting OptimX Technologies  (incl. DBA OptimX Sports). </Text>
                    <Text style={styles.h2}>10. Termination:</Text>
                    <Text style={styles.h3}>10.1. Termination on Notice:</Text>
                    <Text style={styles.p}>The Customer of this subscription agreement may terminate this agreement for any reason on 15 days&apos; notice to OptimX Technologies (Incl. DBA OptimX Sports).</Text>
                    <Text style={styles.h3}>10.2. Termination for Material Breach:</Text>
                    <Text style={styles.p}>Each party may terminate this agreement with immediate effect by delivering notice of the termination to the other party, if</Text>
                    <Text style={styles.l}>1. the other party fails to perform, has made or makes any inaccuracy in, or otherwise materially breaches, any of its obligations, covenants, or representations, and</Text>
                    <Text style={styles.l}>2. the failure, inaccuracy, or breach continues for a period of 30 days&apos; after the injured party delivers notice to the breaching party reasonably detailing the breach.</Text>
                    <Text style={styles.h3}>10.3. Effect of Termination:</Text>
                    <Text style={styles.l}>1. Discontinuance of Use. The customer shall cease all use of the Service upon the effective date of the termination.</Text>
                    <Text style={styles.l}>2. Recovery of Data. The customer of this subscription agreement will have 30 days from the date of termination to retrieve any of data that customer wishes to keep.</Text>
                    <Text style={styles.h2}>11. DISCLAIMERS AND LIMITS ON LIABILITY:</Text>
                    <Text style={styles.h3}>11.1. Disclaimer:</Text>
                    <Text style={styles.p}>CUSTOMER ACKNOWLEDGES THAT, EXCEPT AS OTHERWISE EXPRESSLY PROVIDED HEREIN, OPTIMX TECHNOLOGIES, MAKE NO WARRANTY, EXPRESS, IMPLIED OR STATUTORY WITH RESPECT TO THE SERVICES OR USE THEREOF. OPTIMX TECHNOLOGIES, ITS AFFILIATES AND LICENSORS HEREBY EXPRESSLY DISCLAIMS ALL OTHER WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY THAT SERVICES WILL BE UNINTERRUPTED, ERROR FREE OR WITHOUT DELAY, AND THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INTERFERENCE, NON-INFRINGEMENT AND INFORMATION CONTENT.</Text>
                    <Text style={styles.h3}>11.2. Disclaimer of Damages:</Text>
                    <Text style={styles.p}>EXCEPT FOR A BREACH OF SECTION 6 AND EACH PARTY&apos;S RESPONSIBILITIES IN SECTION 12, NEITHER PARTY OR ITS AFFILIATES ARE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES RELATING TO OR ARISING OUT OF THIS AGREEMENT OR THE SERVICES (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST COMPUTER USAGE TIME, AND DAMAGE TO, OR LOSS OF USE OF, DATA), EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND IRRESPECTIVE OF ANY NEGLIGENCE OF A PARTY OR WHETHER SUCH DAMAGES RESULT FROM A CLAIM ARISING UNDER TORT OR CONTRACT LAW.</Text>
                    <Text style={styles.h3}>11.3. Limits on Liability:</Text>
                    <Text style={styles.p}>NEITHER PARTY OR ITS AFFILIATES SHALL HAVE AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE SERVICES, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY</Text>
                    <Text style={styles.h2}>12. Infringement Claims:</Text>
                    <Text style={styles.h3}>12.1. Indemnification by OptimX Technologies:</Text>
                    <Text style={styles.p}>Indemnification for Infringement Claims. OptimX Technologies shall indemnify the customer of this subscription agreement against all losses and expenses arising out of any proceeding</Text>
                    <Text style={styles.l}>1. brought by a third party, and</Text>
                    <Text style={styles.l}>2. arising out of a claim that the Service infringe the third party&apos;s Intellectual Property rights.</Text>
                    <Text style={styles.h3}>12.2. Notice and Failure to Notify:</Text>
                    <Text style={styles.p}>Notice Requirement. Before bringing a claim for indemnification, the customer of this subscription agreement must</Text>
                    <Text style={styles.l}>1. notify OptimX Technologies of the indemnifiable proceeding, and</Text>
                    <Text style={styles.l}>2. deliver to OptimX Technologies all legal pleadings and other documents reasonably necessary to indemnify or defend the indemnifiable proceeding.</Text>
                    <Text style={styles.p}>Failure to Notify. If the customer fails to notify OptimX Technologies of the indemnifiable proceeding, OptimX Technologies will be relieved of its indemnification obligations.</Text>
                    <Text style={styles.h3}>12.3. Exclusive Remedy:</Text>
                    <Text style={styles.p}>The customers&apos; right to indemnification is the exclusive remedy available with respect to a claim of indemnification.</Text>
                    <Text style={styles.h2}>13. General Provisions:</Text>
                    <Text style={styles.h3}>13.1. Entire Agreement:</Text>
                    <Text style={styles.p}>This agreement represents the entire understanding between the parties with respect to its subject matter and supersedes any previous communication or agreements that may exist.</Text>
                    <Text style={styles.h3}>13.2. Amendment:</Text>
                    <Text style={styles.p}>This agreement can be amended only by a writing signed by both parties.</Text>
                    <Text style={styles.h3}>13.3. Assignment:</Text>
                    <Text style={styles.p}>Neither party may assign this agreement or any of their rights or obligations under this agreement without the other party&apos;s written consent.</Text>
                    <Text style={styles.h3}>13.4. Notices:</Text>
                    <Text style={styles.p}>Method of Notice. The parties will give all notices and communications between the parties in writing by (i) personal delivery, (ii) a nationally-recognized, next-day courier service, (iii) first-class registered or certified mail, postage prepaid to the address that a party has notified to be that party&apos;s address for the purposes of this section.</Text>
                    <Text style={styles.p}>Receipt of Notice. A notice given under this agreement will be effective on</Text>
                    <Text style={styles.l}>1. the other party&apos;s receipt of it, or</Text>
                    <Text style={styles.l}>2. if mailed, the earlier of the other party&apos;s receipt of it and the fifth business day after mailing it.</Text>
                    <Text style={styles.h3}>13.5. Governing Law:</Text>
                    <Text style={styles.p}>This agreement will be governed, construed, and enforced in accordance with the laws of the State of Illinois, without regard to its conflict of laws rules.</Text>
                    <Text style={styles.h3}>13.6. Severability:</Text>
                    <Text style={styles.p}>If any part of this agreement is declared unenforceable or invalid, the remainder will continue to be valid and enforceable.</Text>
                    <Text style={styles.h3}>13.7. Waiver:</Text>
                    <Text style={styles.p}>The failure or neglect by a party to enforce any of the rights under this agreement will not be deemed to be a waiver of that party&apos;s rights. </Text>
                    <Text style={styles.h3}>13.8. Force Majeure:</Text>
                    <Text style={styles.p}>A party shall not be liable for any failure of or delay in the performance of this agreement for the period that such failure or delay is</Text>
                    <Text style={styles.l}>a. beyond the reasonable control of a party,</Text>
                    <Text style={styles.l}>b. materially affects the performance of any of its obligations under this agreement, and</Text>
                    <Text style={styles.l}>c. could not reasonably have been foreseen or provided against, but</Text>
                    <Text style={styles.p}>will not be excused for failure or delay resulting from only general economic conditions or other general market effects.</Text>
                    <Text style={styles.h2}>13. MISCELLANEOUS:</Text>
                    <Text style={styles.p}>OptimX Technologies is not liable for its failure to perform any of its obligations under this Agreement during any period in which performance is delayed by Customer or circumstances beyond OptimX Technologies&apos;s reasonable control. The parties are independent contractors and this Agreement does not create a partnership, franchise, joint venture, agency, fiduciary, or employment relationship between the parties. This Agreement constitutes the entire agreement between Customer and OptimX Technologies and supersedes any prior or contemporaneous negotiations or agreements, whether oral or written concerning the Subscription Services. Customer agrees and accepts that OptimX Technologies Third-Party Hosting Provider is a third party beneficiary to this Agreement. There are no other third party beneficiaries under this Agreement. No modification or waiver of any provision hereof will be effective unless made in a writing signed by both OptimX Technologies and Customer. Customer may not assign or transfer this Agreement or the Subscription Services to a third party, whether by merger or otherwise. Should any provision of this Agreement be invalid or unenforceable, the remainder of the provisions will remain in effect. The failure of OptimX Technologies to act with respect to a breach of this Agreement by Customer or others does not constitute a waiver and shall not limit OptimX Technologies&apos;s rights with respect to such breach or any subsequent breaches. All notices under this Agreement will be in writing, in English and will be deemed to have been duly given when received, as duly confirmed by return receipt; or if transmitted during normal business hours by facsimile or e-mail when receipt is electronically confirmed. Headings and captions are for convenience only and are not to be used in the interpretation of this Agreement.</Text>
                    <Text style={styles.p}>This agreement has been signed by the parties.</Text>
                    <View style={styles.signingSection}>
                        <View style={styles.signingSubSection}>
                            <Text style={styles.signingSubSectionHeading}>Contract Agreement Party A</Text>
                            <View style={styles.signingSubSubSection}>
                                <Text>Date: </Text>
                                <Text>{getDateStringWithMonthsFromDate(getCurrentDateTime())}</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Name: </Text>
                                <Text>{name}</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Signature: </Text>
                                {isValidString(sig) ? <Image style={styles.clientSignature} src={sig} cache={false} /> : null}
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Title: </Text>
                                <Text>{title}</Text>
                            </View>
                        </View>
                        <View style={styles.signingSubSection}>
                            <Text style={styles.signingSubSectionHeading}>Contract Agreement Party B</Text>
                            <View style={styles.signingSubSubSection}>
                                <Text>Date: </Text>
                                <Text>{getAccountCreationTime()}</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Name: </Text>
                                <Text>Jonathan Schubauer</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Signature: </Text>
                                <Text style={styles.optimXSignature}>Jonathan Schubauer</Text>
                            </View>
                            <View style={styles.signingSubSubSection}>
                                <Text>Title: </Text>
                                <Text>President of Operations</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

LicensingAgreementDocument.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    accountCreationTime: PropTypes.any.isRequired,
    sig: PropTypes.string.isRequired,
};

export default LicensingAgreementDocument;
