import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { getCurrentDateTime } from '../DateTime';
import { SportsType } from '../enums/SportsType';
import { OptimXAdminInfoObject } from '../interface/OptimXAdminInfoInterface';
import { createOptimXAdminInfoReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOptimXAdminInfo';

export const optimXAdminInfoId = 'optimx-admin-info';

export const createDemoOptimXAdminInfo = (organizationId: string, teamId: string, sportsType: SportsType): OptimXAdminInfoObject => {
    const optimXAdminInfoReference = createOptimXAdminInfoReference(teamId);
    const teamReference = createTeamReference(teamId);
    const organizationReference = createOrganizationReference(organizationId);

    return {
        id: optimXAdminInfoId,
        reference: optimXAdminInfoReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,

        active: true,

        sportsType,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
