import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, LinkedinShareButton, WhatsappIcon, WhatsappShareButton, RedditShareButton, RedditIcon, TumblrIcon, TumblrShareButton, PinterestShareButton, PinterestIcon, EmailIcon } from 'react-share';

const ShareBlogComponent = (props) => {
    const { url, shareTitle, hashtags } = props;

    return (
        <div className="flex flex-direction-column flex-justify-content-start flex-align-items-start mobile-flex-direction-row">
            <FacebookShareButton
                className="m-r-20px m-b-5px mobile-m-r-5px mobile-m-b-20px"
                url={url}
                quote={shareTitle}
                description={shareTitle}
            >
                <FacebookIcon size={30} />
            </FacebookShareButton>
            <TwitterShareButton
                className="m-r-20px m-b-5px mobile-m-r-5px mobile-m-b-20px"
                title={shareTitle}
                url={url}
                hashtags={hashtags}
            >
                <TwitterIcon size={30} />
            </TwitterShareButton>
            <a
                className="m-r-20px m-b-5px mobile-m-r-5px mobile-m-b-20px"
                href={`mailto:?subject=${shareTitle}&body=${shareTitle}: ${url}`}
            >
                <EmailIcon size={30} />
            </a>
            <WhatsappShareButton
                className="m-r-20px m-b-5px mobile-m-r-5px mobile-m-b-20px"
                title={shareTitle}
                url={url}
            >
                <WhatsappIcon size={30} />
            </WhatsappShareButton>
            <LinkedinShareButton
                className="m-r-20px m-b-5px mobile-m-r-5px  mobile-m-b-20px"
                title={shareTitle}
                summary={shareTitle}
                source={url}
                url={url}
            >
                <LinkedinIcon size={30} />
            </LinkedinShareButton>
            <RedditShareButton
                className="m-r-20px m-b-5px mobile-m-r-5px mobile-m-b-20px"
                title={shareTitle}
                summary={shareTitle}
                source={url}
                url={url}
            >
                <RedditIcon size={30} />
            </RedditShareButton>
            <TumblrShareButton
                className="m-r-20px m-b-5px  mobile-m-r-5px mobile-m-b-20px"
                title={shareTitle}
                summary={shareTitle}
                source={url}
                url={url}
            >
                <TumblrIcon size={30} />
            </TumblrShareButton>
            <PinterestShareButton
                className="m-r-20px m-b-5px  mobile-m-r-5px mobile-m-b-20px"
                title={shareTitle}
                summary={shareTitle}
                source={url}
                url={url}
            >
                <PinterestIcon size={30} />
            </PinterestShareButton>
        </div>
    );
};

ShareBlogComponent.propTypes = {
    url: PropTypes.string.isRequired,
    shareTitle: PropTypes.string.isRequired,
    hashtags: PropTypes.array.isRequired,
};

export default memo(ShareBlogComponent);
