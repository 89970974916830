import { createOrganizationReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsOrganization';
import { createPlayerReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsPlayer';
import { createTeamReference } from '../../firebase/firebaseAPICalls/FirebaseAPICallsTeam';
import { PlayerDetailObject, PlayerObject, PlayerTextObject, PlayerDetailPlaceholderObject, PlayerTextPlaceholderObject } from '../interface/PlayerInterface';
import { SportsType } from '../enums/SportsType';
import { getCurrentDateTime } from '../DateTime';

export const demoPlayerName = 'John Doe';
export const demoPlayerEmailAddress = 'johndoe@optimxsports.com';
export const demoPlayerPhone = '+1-(123)-456-7890';
export const getDemoPlayerDetails = (sportsType: SportsType): PlayerDetailObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return ([
            { title: 'Team Number', required: true, value: '8' },
            { title: 'Weight', required: false, value: '178lbs' },
            { title: 'Height', required: false, value: '5\'11”' },
            { title: 'Hometown', required: false, value: 'Skillman, NJ' },
            { title: 'Position', required: true, value: 'Goalie' },
            { title: 'Year', required: false, value: 'Freshman' },
            { title: 'Born', required: false, value: '2000' },
            // { title: 'Nationality', required: false, value: 'Spain' },
            { title: 'Shoots', required: false, value: 'Right' },
            { title: 'Previous Team', required: false, value: 'Demo University' },
        ]);
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return ([
            { title: 'Team Number', required: false, value: '8' },
            { title: 'Weight', required: false, value: '178lbs' },
            { title: 'Height', required: false, value: '5\'11”' },
            { title: 'Hometown', required: false, value: 'Skillman, NJ' },
            { title: 'Position', required: true, value: 'Full Back' },
            { title: 'Year', required: false, value: 'Freshman' },
            { title: 'Born', required: false, value: '2000' },
            // { title: 'Nationality', required: false, value: 'Spain' },
            { title: 'Previous Team', required: false, value: 'Demo University' },
        ]);
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return ([
            { title: 'Team Number', required: true, value: '8' },
            { title: 'Weight', required: false, value: '178lbs' },
            { title: 'Height', required: false, value: '5\'11”' },
            { title: 'Hometown', required: false, value: 'Skillman, NJ' },
            { title: 'Position', required: true, value: 'Goalie' },
            { title: 'Year', required: false, value: 'Freshman' },
            { title: 'Born', required: false, value: '2000' },
            // { title: 'Nationality', required: false, value: 'Spain' },
            { title: 'Shoots', required: false, value: 'Right' },
            { title: 'Previous Team', required: false, value: 'Demo University' },
        ]);
    }
    return [];
};
export const getPlaceholderPlayerDetails = (sportsType: SportsType): PlayerDetailPlaceholderObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return ([
            { title: 'Team Number', required: true, value: '', placeholder: '8' },
            { title: 'Weight', required: false, value: '', placeholder: '178lbs' },
            { title: 'Height', required: false, value: '', placeholder: '5\'4”' },
            { title: 'Hometown', required: false, value: '', placeholder: 'Skillman, NJ' },
            { title: 'Position', required: true, value: 'Goalie', placeholder: 'Goalie' },
            { title: 'Year', required: false, value: '', placeholder: 'Freshman/2025' },
            { title: 'Born', required: false, value: '', placeholder: '2000' },
            { title: 'Nationality', required: false, value: '', placeholder: 'Spain' },
            { title: 'Previous Team', required: false, value: '', placeholder: 'Demo University' },
            { title: 'Shoots', required: false, value: '', placeholder: 'Right' },
        ]);
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return ([
            { title: 'Team Number', required: false, value: '', placeholder: '8' },
            { title: 'Weight', required: false, value: '', placeholder: '178lbs' },
            { title: 'Height', required: false, value: '', placeholder: '5\'4”' },
            { title: 'Hometown', required: false, value: '', placeholder: 'Skillman, NJ' },
            { title: 'Position', required: true, value: 'Full Back', placeholder: 'Full Back' },
            { title: 'Year', required: false, value: '', placeholder: 'Freshman/2025' },
            { title: 'Born', required: false, value: '', placeholder: '2000' },
            { title: 'Nationality', required: false, value: '', placeholder: 'Spain' },
            { title: 'Previous Team', required: false, value: '', placeholder: 'Demo University' },
        ]);
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return ([
            { title: 'Team Number', required: true, value: '', placeholder: '8' },
            { title: 'Weight', required: false, value: '', placeholder: '178lbs' },
            { title: 'Height', required: false, value: '', placeholder: '5\'4”' },
            { title: 'Hometown', required: false, value: '', placeholder: 'Skillman, NJ' },
            { title: 'Position', required: true, value: 'Goalie', placeholder: 'Goalie' },
            { title: 'Year', required: false, value: '', placeholder: 'Freshman/2025' },
            { title: 'Born', required: false, value: '', placeholder: '2000' },
            { title: 'Nationality', required: false, value: '', placeholder: 'Spain' },
            { title: 'Previous Team', required: false, value: '', placeholder: 'Demo University' },
            { title: 'Shoots', required: false, value: '', placeholder: 'Right' },
        ]);
    }
    return [];
};
export const demoPlayerBio = '';
export const getDemoPlayerProfileImage = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2FiceHockey%2FdemoProfileImage%2FdemoProfileImage.png?alt=media&token=10f4cdaa-7503-4123-a0ac-049368214131';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2Frugby%2FdemoProfileImage%2FdemoProfileImage_1600x900.png?alt=media&token=10f4cdaa-7503-4123-a0ac-049368214131';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2Flacrosse%2FdemoProfileImage%2FdemoProfileImage_1600x900.png?alt=media&token=10f4cdaa-7503-4123-a0ac-049368214131';
    }
    return '';
};
export const getDemoPlayerFeatureImage = (sportsType: SportsType): string => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2FiceHockey%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=dc5869a4-2fd1-4b44-bd18-021497861dfd';
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2Frugby%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=dc5869a4-2fd1-4b44-bd18-021497861dfd';
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return 'https://firebasestorage.googleapis.com/v0/b/optimx-sports.appspot.com/o/demo%2FdemoPlayer%2Flacrosse%2FdemoFeatureImage%2FdemoFeatureImage_1600x900.png?alt=media&token=dc5869a4-2fd1-4b44-bd18-021497861dfd';
    }
    return '';
};
export const getDemoPlayerTexts = (sportsType: SportsType): PlayerTextObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return [
            { title: 'Personal Achievement', required: false, value: '- Scored highest number of goals in 2019-20 season' },
        ];
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return [
            { title: 'Personal Achievement', required: false, value: '- Scored most points in 2019-20 season' },
        ];
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return [
            { title: 'Personal Achievement', required: false, value: '- Scored highest number of goals in 2019-20 season' },
        ];
    }
    return [];
};
export const getDemoPlaceholderPlayerTexts = (sportsType: SportsType): PlayerTextPlaceholderObject[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return ([
            { title: 'Personal Achievement', required: false, value: '', placeholder: '- Scored highest number of goals in 2019-20 season' },
        ]);
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return ([
            { title: 'Personal Achievement', required: false, value: '', placeholder: '- Scored most points in 2019-20 season' },
        ]);
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return ([
            { title: 'Personal Achievement', required: false, value: '', placeholder: '- Scored highest number of goals in 2019-20 season' },
        ]);
    }
    return [];
};

export const getPlayerPositions = (sportsType: SportsType): string[] => {
    if (sportsType === SportsType.WOMENSICEHOCKEY || sportsType === SportsType.MENSICEHOCKEY) {
        return ['Forward', 'Winger', 'Center', 'Defensemen', 'Goalie'];
    }
    if (sportsType === SportsType.WOMENSRUGBY || sportsType === SportsType.MENSRUGBY) {
        return ['Hooker', 'Tight Head Prop', 'Second Row', 'Flanker', 'Blind-Side Flanker', 'Open-Side Flanker', 'Number 8', 'Scrum-Half', 'Fly-Half', 'Center', 'Wing', 'Left Wing', 'Inside Center', 'Outside Center', 'Right Wing', 'Full Back', 'Lock', 'Prop', 'Back Row'];
    }
    if (sportsType === SportsType.WOMENSLACROSSE || sportsType === SportsType.MENSLACROSSE) {
        return ['Attackmen', 'Forward', 'Winger', 'Left Winger', 'Right Winger', 'Defender', 'Center', 'Midfielder', 'Long Pikes', 'D Poles', 'Goalie', 'Face Off Specialist', 'Long Stick Midfielder'];
    }
    return [];
};

export const createDemoPlayer = (organizationId: string, teamId: string, playerId: string, sportsType: SportsType): PlayerObject => {
    const organizationReference = createOrganizationReference(organizationId);
    const teamReference = createTeamReference(teamId);
    const playerReference = createPlayerReference(playerId);

    return {
        id: playerId,
        urlId: playerId,
        reference: playerReference,
        teamId,
        teamReference,
        organizationId,
        organizationReference,
        name: demoPlayerName,
        sportsType,
        email: demoPlayerEmailAddress,
        phone: demoPlayerPhone,
        details: getDemoPlayerDetails(sportsType),
        summary: '',
        bio: '',
        profileImage: getDemoPlayerProfileImage(sportsType),
        featureImage: getDemoPlayerFeatureImage(sportsType),
        texts: getDemoPlayerTexts(sportsType),
        alumni: false,
        overridingStats: {},
        deleted: false,
        timeModified: getCurrentDateTime(),
        timeCreated: getCurrentDateTime(),
    };
};
