import { db } from '../FirebaseLibrary';
import { optimXAdminInfoId } from '../../catalog/demo/OptimXAdminInfoDemo';
import { OptimXAdminInfoObject } from '../../catalog/interface/OptimXAdminInfoInterface';

export const createOptimXAdminInfoReference = (teamId: string) => {
    return db.collection(`teams/${teamId}/read-only`).doc(optimXAdminInfoId);
};

export const insertOptimXAdminInfoFirestoreAPI = (teamId: string, optimXAdminObject: OptimXAdminInfoObject) => {
    return createOptimXAdminInfoReference(teamId).set(optimXAdminObject);
};

export const fetchOptimXAdminInfoFirestoreAPI = (teamId: string) => {
    return createOptimXAdminInfoReference(teamId).get();
};

export const deleteOptimXAdminInfoFirestoreAPI = (teamId: string) => {
    return createOptimXAdminInfoReference(teamId).delete();
};
