import { db } from '../FirebaseLibrary';
import { BulkBCCMailObject } from '../../catalog/interface/mailInterface/BulkBCCMailInterface';
import { CSVMailObject } from '../../catalog/interface/mailInterface/CSVMailInterface';

export const createMailsReference = () => {
    return db.collection('mail');
};

export const createMailReference = (mailId: string) => {
    return createMailsReference().doc(mailId);
};

export const insertBulkBCCMailFirestoreAPI = (mailObject: BulkBCCMailObject) => {
    return createMailReference(mailObject.id).set(mailObject);
};

export const insertCSVMailFirestoreAPI = (mailObject: CSVMailObject) => {
    return createMailReference(mailObject.id).set(mailObject);
};
